export const steps = [
  {
    title: 'Patient Schedule',
    text: 'This is the list of patients scheduled with your provider\'s care team for the day selected. This list is organized by the time of visit.',
    selector: '.apt-card.card.blue-bkg.white-text',
    position: 'top',
    type: 'hover',
    name: 'patient-schedule',
  },
  {
    title: 'Mini Chart',
    text: 'This is the patient\'s minichart. It\'s a curated list of clinical information pulled from your EHR and is designed to help you with pre visit planning.',
    selector: '.fixed-mini-chart-column',
    position: 'left',
    type: 'hover',
    name: 'mini-chart',
  },
  {
    title: 'Patient-Specific Pre-Visit Planning',
    text: 'This section of Avhana contains patient-specific pre-visit planning features to help you with charting the encounter ahead of time.',
    selector: '.recommendation-box',
    position: 'right',
    type: 'hover',
    name: 'previsit-planning-center-panel',
  },
  {
    title: 'Recommendations',
    text: 'Select the recommendations tab to view the list of Avhana interventions that apply for this patient. You can queue these follow up actions to be submitted when the patient checks in by selecting the Order button.',
    selector: '.recs-tab',
    position: 'top',
    type: 'hover',
    name: 'recommendations-tab',
  },
  {
    title: 'Problems & Notes',
    text: 'Select the problems & notes tabs to queue up notes and documentation. Diagnosis notes will be recorded in the encounter assessment when the patient checks in and Assessment and HPI notes will be recorded in their corresponding sections within the encounter.',
    selector: '.doc-tab',
    position: 'left',
    type: 'hover',
    name: 'documentation-tab',
  },
  {
    title: 'Onboarding Video',
    text: 'If you still feel lost use this link to access an onboarding video we created just for you: https://youtu.be/OyNvP1Iszyg',
    selector: '.apt-card',
    position: 'center',
    type: 'hover',
    name: 'onboarding-video',
  }
]
