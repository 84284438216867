import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export default class MaterializeTextInput extends React.Component {
  constructor (props) {
    super(props)
    this.updateFormState = this.updateFormState.bind(this)
  }

  updateFormState (event) {
    const key = this.props.data.key
    const value = event.target.value
    this.props.updateFormState({ key: key, value: value })
  }

  render () {
    return (
      <div>
        <label htmlFor={ this.props.label + this.props.formId }>{this.props.label}</label>
        <input className="default-text-color"
          id={ this.props.label + this.props.formId }
          type="text"
          value={this.props.data.value}
          onChange={this.updateFormState}
          disabled={this.props.disabled || false}
        />
      </div>
    )
  }
}

MaterializeTextInput.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.string,
    key: PropTypes.string,
  })
}
