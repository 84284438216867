import React, { useState } from 'react'
import { ThemeProvider, styled, createTheme } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import MenuItem from '@mui/material/MenuItem'
import Table from './Table'

const theme = createTheme()

const styles = {
  checkedIcon: { fill: "#1684F9" },
  checkbox: { backgroundColor: "transparent" },
}

const StyledSelect = styled(Select)({
  minWidth: '200px',
})

const NewValueSet = () => {
  const [query, setQuery] = useState('')
  const [data, setData] = useState([])
  const allOption = { description: "All", code: "", code_system: "" }
  const [valueSets, setValueSets] = useState([])
  const [codeSystem, setCodeSystem] = useState([])  
  const [allSelected, setAllSelected] = useState(false)
  const [checkAllBox, setCheckAllBox] = useState(false)
  const [lastRequest, setLastRequest] = useState(null)

  const compareDescriptions = (a, b) => {
    const lowerA = a.description.toLowerCase()
    const lowerB = b.description.toLowerCase()
    if (lowerA < lowerB) return -1
    if (lowerA > lowerB) return 1
    return 0
  } 

  const handleChange = (event) => {
    setCodeSystem(event.target.value)
  }

  const handleCall = (event) => {
    const newQuery = event.target.value
    setQuery(newQuery)
    setAllSelected(false)
    if (lastRequest) {
      lastRequest.abort()
    }
    const request = $.ajax({
      method: 'get',
      url: '/root/search_athena_rxnorm',
      dataType: 'json',
      contentType: 'application/json',
      data: { api_query: newQuery, code_systems: codeSystem },
    })
      .done((response) => {
        const optionsSorted = response.value_sets.slice().sort(compareDescriptions)
        if (optionsSorted.length !== 0)  setData([allOption].concat(optionsSorted))
        setCheckAllBox(false)
      })
      .fail((error) => {
        console.error(error)
      })
    setLastRequest(request)
  }

  const debouncedAthenaCall = _.debounce(handleCall, 300)
  
  const optionText = (option) => {
    let text = option.description
    if (option.code_system === "ATHENA_ORDER_TYPE") {
      text = `${option.description} (Athena)`
    } else if (option.code_system === "RXNORM") {
      text = `${option.description} (RxNorm)`
    } else if (option.code_system === "ICD10CM") {
      text = `${option.description} (ICD10)`
    } else if (option.code_system === "SNOMEDCT") {
      text = `${option.description} (SNOMED)`
    }
    return text
  }

  const optionSelect = (event, value, reason) => {
    let selectedText
    if (reason === "clear") {
      setValueSets([])
      setAllSelected(false)
      setCheckAllBox(false)
      setQuery("")
    } else if (reason === "selectOption" || reason === "removeOption") {
      selectedText = event.currentTarget.innerText
    }

    if (selectedText === "All" && checkAllBox === false) {
      setAllSelected(true)
      setCheckAllBox(true)
      setValueSets(valueSets.concat(data.slice(1)).filter((v, i, a) => 
        a.indexOf(v) === i
      ))
    } else if (selectedText !== "All" && allSelected === false) {
      setValueSets(value)
    } else if (selectedText !== "All" && allSelected === true) {
      setAllSelected(false)
      setCheckAllBox(true)
      setValueSets(valueSets.filter(valueSet => optionText(valueSet) !== selectedText))
    } else if (selectedText === "All" && checkAllBox === true) {
      setAllSelected(false)
      setCheckAllBox(false)
      setValueSets(valueSets.filter(valueSet => !data.slice(1).includes(valueSet)))
    }
  }
  
  const icon = <CheckBoxOutlineBlank fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" style={styles.checkedIcon}/>
  
  // Value Sets to be added will be sent to the form via "description" because form can only take a ValueSet field (ex. description, code, code_system, etc.) as an input
  $('#description').val(JSON.stringify(valueSets))

  return (
    <ThemeProvider theme={theme}>
      <form>
        <div>
          <InputLabel id="multiple-code-system-label">Code Systems</InputLabel>
          <StyledSelect
            multiple
            id="multiple-code-system"
            labelId="multiple-code-system"
            value={codeSystem}
            onChange={handleChange}
            input={<Input />}
            label="Code Systems"
            margin="dense"
            variant="outlined"
            renderValue={(selected) => selected.join(', ')}
          >
            <MenuItem value="athena">
              <Checkbox icon={icon} style={styles.checkbox} checkedIcon={checkedIcon} checked={codeSystem.indexOf("athena") > -1} />
              Athena
            </MenuItem>
            <MenuItem value="rxnorm">
              <Checkbox icon={icon} style={styles.checkbox} checkedIcon={checkedIcon} checked={codeSystem.indexOf("rxnorm") > -1} />
              RxNorm (Full Name)
            </MenuItem>
            <MenuItem value="icd10cm">
              <Checkbox icon={icon} style={styles.checkbox} checkedIcon={checkedIcon} checked={codeSystem.indexOf("icd10cm") > -1} />
              Icd10
            </MenuItem>
            <MenuItem value="snomedct">
              <Checkbox icon={icon} style={styles.checkbox} checkedIcon={checkedIcon} checked={codeSystem.indexOf("snomedct") > -1} />
              Snomed
            </MenuItem>
          </StyledSelect>
        </div>
        <Autocomplete
          multiple
          id="value-set-search"
          options={data}
          disableCloseOnSelect
          getOptionLabel={(option) => option.description}
          onChange={optionSelect}
          value={valueSets}
          limitTags={3}
          filterOptions={(options) => options}
          renderOption={(props, option) => {
            const checked = (checkAllBox && option.description === "All") ? true : valueSets.includes(option)
            return (
              <li {...props}>
                <Checkbox
                  disableRipple
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={checked}
                />
                {optionText(option)}
              </li>
            )
          }}
          renderInput={(params) => (
            <TextField {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
              onChange={debouncedAthenaCall}
              value={query}
              label="Search APIs"
              margin="dense"
              variant="outlined"
              placeholder="Search APIs"
            />
          )}
        />
      </form>

      {valueSets.length === 0 ? <div className="non-matching-message">No value sets selected</div> : <Table data={valueSets} />}
    </ThemeProvider>
  )
}

export default NewValueSet