/* global TweenLite */

import React from 'react'
import PropTypes from 'prop-types'

const setWidget = ($el) => {
  // define set widget function
  const getTotalPoints = $('.point').length
  const getIndex = $el.index()
  const getCompleteIndex = $('.point--active').index()

  TweenLite.to($('.bar__fill'), 0.6, {
    width: `${((getIndex - 1) / (getTotalPoints - 1)) * 100}%`,
  })

  if (getIndex >= getCompleteIndex) {
    $('.point--active').addClass('point--complete').removeClass('point--active')

    $el.addClass('point--active')
    $el.prevAll().addClass('point--complete')
    $el.nextAll().removeClass('point--complete')
  }
}

export default class EncounterStatusWidget extends React.Component {
  componentDidUpdate() {
    // check encounter status and set widget properly
    // if encounter is nil then no encounter at all aka previsit
    // if encounter is present & status is not closed or deleted then checkedin
    // if encounter is present & status is closed or deleted then checkedout
    const encounter = this.props.encounter
    let $el
    if (!encounter) {
      $el = $('#previsit')
    } else {
      // encounter status: "CLOSED", "OPEN", "DELETED", "PEND", "REVIEW", "SCHEDULE", nil
      const encounterStatus = window.getEncounterStatusText(encounter)

      if (encounterStatus) {
        if (encounterStatus === 'Checked In') {
          $el = $('#checkedin')
        } else if (encounterStatus === 'Checked Out') {
          $el = $('#checkedout')
        } else if (encounterStatus === 'Pre-Visit') {
          $el = $('#previsit')
        }
      } else {
        $el = $('#previsit')
      }
    }
    setWidget($el)
  }

  render() {
    return (
      <div className="col s9 offset-s3">
        <div className="boxes">
          <div className="box">
            <div className="progress">
              <div className="bar">
                <div style={{ width: '0%' }} className="bar__fill" />
              </div>
              <div className="point point--active" id="previsit">
                <div className="bullet" />
                <label className="label">Pre-Visit</label>
              </div>
              <div className="point" id="checkedin">
                <div className="bullet" />
                <label className="label">Checked In</label>
              </div>
              <div className="point" id="checkedout">
                <div className="bullet" />
                <label className="label">Checked Out</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EncounterStatusWidget.propTypes = {
  encounter: PropTypes.shape({}),
}
