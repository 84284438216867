import {
  bool, func, shape, number,
} from 'prop-types'
import React, { Component } from 'react'
import algoliasearch from 'algoliasearch'
import { TextField, InputAdornment } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import SearchIcon from '@mui/icons-material/Search'

import { ID, Options, Styles } from '../athenaTypes'

const propTypes = {
  pref: shape({
    id: ID,
    options: Options,
  }).isRequired,
  rec: shape({
    id: ID,
    options: Options,
  }),
  updateOrderPref: bool.isRequired,
  updateOptions: func.isRequired,
  columns: number,
  orderAnything: bool,
  style: Styles,
}

const defaultProps = {
  columns: 8,
  style: {},
  rec: {},
  orderAnything: false,
}


/**
 * @function formatTypeaheadData uses obj from algolia creates proper display text for typeahead
 */
const formatTypeaheadData = (obj) => obj.entry_name

/**
 * This component takes in values from the options column on an OrderPreference
 * and implements Typeahead so user can change the order pref.
 * @prop {pref} an OrderPreference ActiveRecord
 */
export default class AllscriptsOrderPrefTypeahead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entry: '',
      options: [],
      prevOptions: [],
    }

    this.handleChange = this.handleChange.bind(this)
    this.clearInput = this.clearInput.bind(this)
  }

  /**
   * @method getDict fires onChange of the Typeahead
   * @param {text} user input text from Typeahead
   */
  getDict(text) {
    const client = algoliasearch('Q5ZBLQF81V', 'df6aba0fce0130170bd701c1b4089506')
    const index = client.initIndex('AllscriptsOrderCode')
    // Can do stricter ordering by changing the ranking order on algolia to put filters
    // first (before typo, geo, etc.). See here:
    // https://www.algolia.com/doc/guides/relevance/ranking#ranking-formula-a-tie-breaking-algorithm
    index.search({ query: text }, (err, content) => {
      if (err) throw err
      const { options } = this.state
      this.setState({ prevOptions: options, options: content.hits })
    })
  }

  /**
   * @method handleChange clears existing timer and sets new timer
   * with getDict
   * @param {event} event with value of Typeahead input
   */
  handleChange(event) {
    // need to use this to access these vars within setTimeout scope
    const { value } = event.target
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.getDict(value)
    }, 300)
    this.setState({ entry: value })
  }

  /**
   * @method clearInput clears input on typeahead and sets cursor focus to search input
   */
  clearInput() {
    this.typeahead.setState({ entryValue: '', selection: '' })
    this.typeahead.focus()
  }

  /**
   * @method optionSelected fires when a Typeahead choice is selected
   * and makes AJAX call to update the order preference in Rails for that OrderPreference id
   * or change state
   * @param {opt} object from the array of order options in the Typeahead -
   */
  optionSelected(name) {
    const {
      rec, pref, orderAnything, updateOrderPref, updateOptions,
    } = this.props
    const { prevOptions, options } = this.state
    const recId = rec ? rec.id : null
    const prefId = pref ? pref.id : null

    let opt = options.find((o) => o.name === name)
    if (!opt) {
      opt = prevOptions.find((o) => o.name === name)
    }

    const orderOptions = {
      entry_name: opt.entry_name,
      order_id: opt.order_id,
      entry_code: opt.entry_code,
    }
    // controller will update order pref based on updateOrderPref boolean
    // updates the recommendation either way in updateRecInDb unless updateRecInDb is false
    // passes back opt in proper format to be set in top level state
    if (!orderAnything) {
      const data = JSON.stringify({
        ...pref,
        options: orderOptions,
        rec_id: recId,
      })
      if (updateOrderPref) {
        // AJAX call to update organization order preference in db if flag set
        $.ajax({
          method: 'put',
          url: `/admin/order_preferences/${prefId}`,
          dataType: 'json',
          contentType: 'application/json',
          data
        })
          .done((response) => {
            this.setState(response.order)
            updateOptions(recId, response)
            flash.success('Order changed successfully', 50000) // previously had 3000 second timeout
          })
          .fail((error) => {
            flash.error('Order could not be changed.')
            console.error(error, { context: `AllscriptsOrderPrefTypeahead update order pref error message: ${error.responseText}` })
          })
      } else {
        // AJAX call to update organization order preference in db if flag set
        $.ajax({
          method: 'put',
          url: '/recommendations/update',
          dataType: 'json',
          contentType: 'application/json',
          data
        })
          .done((response) => {
            this.setState(response.order)
            updateOptions(recId, response)
            flash.success('Order changed successfully', 50000) // previously had 3000 second timeout
          })
          .fail((error) => {
            flash.error('Order could not be changed.')
            console.error(error, { context: `AllscriptsOrderPrefTypeahead update order pref error message: ${error.responseText}` })
          })
      }
    } else {
      this.setState(options.order)
      updateOptions(recId, options)
    }
  }

  render() {
    const { orderAnything } = this.props
    const { entry, options } = this.state
    let helperText
    if (!orderAnything) {
      helperText = (
        <p className="thin left" style={{ padding: '0', fontSize: '0.95em', fontWeight: 'bold' }}>
          Use the search box above to change the order for this guideline.
        </p>
      )
    }

    return (
      <>
        <Autocomplete
          freeSolo
          options={options.map((o) => formatTypeaheadData(o))}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={this.handleChange}
              label="Search orders here"
            />
          )}
          onChange={(_event, value, reason) => {
            if (reason === 'selectOption') {
              this.optionSelected(value)
            }
          }}
          value={entry}
          id="autocomplete"
        />
        {helperText}
      </>
    )
  }
}

AllscriptsOrderPrefTypeahead.defaultProps = defaultProps
AllscriptsOrderPrefTypeahead.propTypes = propTypes
