import React, { useState } from 'react'

export default function MmeCalculation() {
  const [file, setFile] = useState(undefined)
  const handleFileSelect = (event) => {
    setFile(event.target.files[0])
  }
  const [removeInput, setRemoveInput] = useState("")
  const handleRemoveInputChange = (event) => {
    setRemoveInput(event.target.value)
  }
  const handleFileSubmit = (event) => {
    const formData = new FormData()
    formData.append("file", file)
    event.preventDefault()
    $.ajax({
      method: 'post',
      url: '/root/mme_calculation/med_equivalency_csv',
      contentType: false,
      processData: false,
      data: formData,
      enctype: 'multipart/form-data'
    })
      .done(() => {
        flash.success('Med Equivalency csv successfully imported', 50000)
      })
      .fail((error) => {
        flash.error(`Error importing your med equivalency csv.. ${error.responseJSON.errors}`, 50000)
        console.error(error)
      })
  }

  const handleRemoveEquivalencies = (event) => {
    event.preventDefault()
    const requestData = {
      input: removeInput,
    }
    $.ajax({
      method: "post",
      url: "/root/mme_calculation/remove_equivalency_class",
      data: JSON.stringify(requestData),
      contentType: "application/json",
    })
      .done(() => {
        flash.success('Med Equivalencies have been removed successfully', 50000)
      })
      .fail((error) => {
        flash.error(`Error removing med equivalencies by equivalency class. ${error.responseJSON.errors}`, 50000)
        console.error(error)
      })
  }

  return (
    <>
      <form onSubmit={handleFileSubmit}>
        <h6>
          Med Equivalency import(csv)
        </h6>
        <input
          type="file"
          id="file-select"
          accept=".csv"
          onChange={handleFileSelect}
        />
        <button type="submit">
          Upload
        </button>
      </form>
      <form onSubmit={handleRemoveEquivalencies}>
      <h6>
          Remove Med Equivalency
      </h6>
      <input
        type="text"
        id="remove-input"
        value={removeInput}
        onChange={handleRemoveInputChange}
        placeholder="Enter Equivalency Class"
       />
        <button type="submit">
          Remove
        </button>
      </form>
    </>
  )
}