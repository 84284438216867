import React, { useState } from 'react'
import { arrayOf, bool, shape, string } from 'prop-types'
import AthenaOrderPrefTypeaheadBasic from '../../reusableComponents/AthenaOrderPrefTypeaheadBasic'

const propTypes = {
  order_preferences: arrayOf(
    shape({
      name: string,
      order_type: string,
      ordertypeid: string,
    })
  ),
  order_set: bool,
  selections: bool
}

const defaultProps = {
  order_preferences: [],
  order_set: false,
  selections: false
}

const OrderPreferenceList = ({ order_preferences, order_set, selections }) => {
  const [orderPreferences, setOrderPreferences] = useState(order_preferences)
  const [getSelections, setSelections] = useState(selections || false)
  const [orderSet, setOrderSet] = useState(order_set || false)

  const deleteOrderPreference = (index) => {
    const newOrderPreferences = orderPreferences.filter(
      (op, i) => i !== index
    )
    if (newOrderPreferences.length <= 1) setSelections(false)
    setOrderPreferences(newOrderPreferences)
  }

  const addOrderPreference = () => {
    if (!getSelections) setSelections(true)
    if (
      orderPreferences.length > 0 &&
      orderPreferences[orderPreferences.length - 1] !== {}
    ) {
      setOrderPreferences([...orderPreferences, {}])
    }
  }

  const toggleComboOrder = () => {
    const firstOrder = orderPreferences.slice(0, 1)
    if (orderSet) {
      setOrderPreferences(firstOrder)
    } else {
      setSelections(false)
      setOrderPreferences([...firstOrder, {}])
    }
    setOrderSet(!orderSet)
  }


  const optionSelected = ({ name, order_type, ordertypeid }, index) => {
    const newOrderPreference = {
      order_name: name,
      order_type_id: ordertypeid,
      order_type
    }
    const newOrderPreferences =
      orderPreferences.length > 0
        ? orderPreferences.map((o, i) => (i === index ? newOrderPreference : o))
        : [newOrderPreference]
    setOrderPreferences(newOrderPreferences)
  }

  return (
    <div>
      {orderPreferences.map((op, index) => (
        <React.Fragment key={op.order_name || "empty order preference"}>
          <AthenaOrderPrefTypeaheadBasic
            optionSelected={(option) => optionSelected(option, index)}
            column="12"
            orderName={op.order_name}
          />
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          { !orderSet && (
            <a style={{ float: 'right' }} href="#" onClick={() => deleteOrderPreference(index)}>
              Remove
            </a>
          )}
          { orderSet && index === 0 && (
            <span>Combo Order</span>
          )}
        </React.Fragment>
      ))}

      {orderPreferences.length === 0 && (
        <AthenaOrderPrefTypeaheadBasic
          optionSelected={(option) => optionSelected(option, 0)}
          column="12"
        />
      )}

      <div>
        { !orderSet && orderPreferences.length < 4 && (
          <button type="button" onClick={() => addOrderPreference()}>
            Add Order Preference
          </button>
        )}
        { !orderSet && orderPreferences.length === 4 && (
          <span>Maximum of 4 Orders</span>
        )}
        <button type="button" onClick={() => toggleComboOrder()}>
          { orderSet ? "Revert to Single Order" : "Make Combo Order" }
        </button>
      </div>


      <input
        type="hidden"
        name="rule[order_preferences]"
        value={JSON.stringify(orderPreferences)}
        required
      />
      <input
        type="hidden"
        name="rule[order_set]"
        value={orderSet}
        required
      />
      <input 
        type="hidden"
        name="rule[selections]"
        value={getSelections}
        required
      />

    </div>
  )
}

OrderPreferenceList.propTypes = propTypes
OrderPreferenceList.defaultProps = defaultProps

export default OrderPreferenceList
