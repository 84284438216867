import React from 'react'
import PropTypes from 'prop-types'
import MaterializeSwitch from '../../../reusableComponents/MaterializeSwitch.jsx'

const hasBledForm = [
  {
    droolsKey: 'Essential Hypertension',
    label: 'Hypertension History',
    helperText: 'Uncontrolled, >160 mmHg systolic',
    value: 0,
  },
  {
    droolsKey: 'Renal Disease',
    label: 'Renal Disease',
    helperText: 'Dialysis, transplant, Cr >2.26 mg/dL or >200 µmol/L',
    value: 0,
  },
  {
    droolsKey: 'Liver Disease',
    label: 'Liver Disease',
    helperText: 'Cirrhosis or Bilirubin >2x Normal or AST/ALT/AP >3x Normal',
    value: 0,
  },
  {
    droolsKey: 'Stroke History',
    label: 'Stroke History',
    helperText: '',
    value: 0,
  },
  {
    droolsKey: 'Bleeding',
    label: 'Prior Major Bleeding or Predisposition to Bleeding',
    helperText: '',
    value: 0,
  },
  {
    droolsKey: 'Labile INR',
    label: 'Labile INR',
    helperText: 'Unstable/high INRs, Time in Therapeutic Range < 60%',
    value: 0,
  },
  {
    droolsKey: 'Age',
    label: 'Age > 65',
    helperText: '',
    value: 0,
  },
  {
    droolsKey: 'Medication Bleeding',
    label: 'Medication Usage Predisposing to Bleeding',
    helperText: 'Antiplatelet agents, NSAIDs',
    value: 0,
  },
  {
    droolsKey: 'Alcohol or Drug Use',
    label: 'Alcohol or Drug Usage History',
    helperText: '≥ 8 drinks/week',
    value: 0,
  },
]

export default class HasBledScore extends React.Component {
  constructor(props) {
    super(props)
    this.state = { score: 0, formValues: {}, text: { msg: '', recs: '' } }
    this.calculateHasBled = this.calculateHasBled.bind(this)
    this.hasBledMessage = this.hasBledMessage.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.updateParentScoreState = this.updateParentScoreState.bind(this)
  }

  UNSAFE_componentWillMount() {
    // merge data from drools with react data
    const hashFromDrools = this.props.options.form_info

    const mergedHasBledForm = hasBledForm.map(hasBled => ({
      ...hasBled,
      value: hashFromDrools[hasBled.droolsKey] || 0,
    }))

    this.setState({ formValues: mergedHasBledForm })
  }

  componentDidMount() {
    this.calculateHasBled()
  }

  updateFormState(formEl) {
    const newFormValues = this.state.formValues
    const oldFormEl = newFormValues.find(hasBledEl => hasBledEl.droolsKey === formEl.key)
    oldFormEl.value = parseInt(formEl.value, 10)
    this.setState({ formValues: newFormValues })
    this.calculateHasBled()
  }

  calculateHasBled() {
    const formValsState = this.state.formValues
    let newScore = 0
    formValsState.forEach((formEl) => { newScore += formEl.value })
    this.setState({ score: newScore })
    this.hasBledMessage(newScore)
    this.updateParentScoreState(newScore)
  }

  hasBledMessage(newScore) {
    let msg = ''
    let recs = ''
    if (newScore === 0) {
      msg = 'Risk was 0.9% in one validation study and 1.13 bleeds per 100 ' +
        'patient-years in another validation study.'
      recs = 'Anticoagulation should be considered: Patient has a relatively ' +
        'low risk for major bleeding (~1/100 patient-years).'
    } else if (newScore === 1) {
      msg = 'Risk was 3.4% in one validation study and 1.02 bleeds per 100 ' +
      'patient-years in another validation study.'
      recs = 'Anticoagulation should be considered: Patient has a relatively ' +
        'low risk for major bleeding (~1/100 patient-years).'
    } else if (newScore === 2) {
      msg = 'Risk was 4.1% in one validation study and 1.88 bleeds per 100 ' +
       'patient-years in another validation study.'
      recs = 'Anticoagulation can be considered, however patient does have ' +
        'moderate risk for major bleeding (~2/100 patient-years).'
    } else if (newScore > 2) {
      recs = 'Alternatives to anticoagulation should be considered: Patient is ' +
        'at high risk for major bleeding.'
      if (newScore === 3) {
        msg = 'Risk was 5.8% in one validation study and 3.72 bleeds per 100 ' +
          'patient-years in another validation study.'
      } else if (newScore === 4) {
        msg = 'Risk was 8.9% in one validation study and 8.70 bleeds per 100 ' +
          'patient-years in another validation study.'
      } else if (newScore === 5) {
        msg = 'Risk was 9.1% in one validation study and 12.50 bleeds per 100 ' +
          'patient-years in another validation study.'
      } else if (newScore > 5) {
        msg = 'Scores greater than 5 were too rare to determine risk.'
      }
    }

    this.setState({ text: { msg, recs } })
  }

  updateParentScoreState(score) {
    this.props.updateParentScoreState(score)
  }

  render() {
    const switches = this.state.formValues.map((formEl, index) => {
      // generate helper text markup for some questions
      let helperDiv
      if (formEl.helperText) {
        helperDiv = (
          <div className="col s10 offset-s1">
            <div className="switch-helper-text">
              {formEl.helperText}
            </div>
          </div>
        )
      }

      return (
        <MaterializeSwitch
          key={index}
          label={formEl.label}
          valueOn={1}
          valueOff={0}
          selected={!!formEl.value}
          data={{ key: formEl.droolsKey, value: 1 }}
          containerClassName="has-bled-switch"
          helperDiv={helperDiv}
          updateFormState={this.updateFormState.bind(this)}
        />
      )
    })

    return (
      <form onChange={this.calculateHasBled} className="recommendation-body body-container">
        <div className="row">
          <div className="col s6">
            {switches}
          </div>
          <div className="col s6 chad-score-container">
            <div className="row">
              <div className="col s7">
                <div className="chad-score-header">HAS-BLED Score</div>
              </div>
              <div className="col s5 score-number-container">
                <div data-testid="has-bled-score" className="chad-score-text bubble-number-large">{this.state.score}</div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col s10 chad-score-message">
                {this.state.text.msg}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col s10 chad-score-message">
                {this.state.text.recs}
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

HasBledScore.propTypes = {
  options: PropTypes.shape({
    form_info: PropTypes.shape({}),
  }),
  updateParentScoreState: PropTypes.func,
}
