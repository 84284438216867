import React from 'react'
import PropTypes from 'prop-types'
import RadioButtons from '../../../reusableComponents/RadioButtons.jsx'

export default class MorseFallScale extends React.Component {
  constructor(props) {
    super(props)
    this.state = { score: 0, formValues: [] }
    this.updateFormState = this.updateFormState.bind(this)
    this.calculateScore = this.calculateScore.bind(this)
    this.documentUpload = this.documentUpload.bind(this)
  }

  updateFormState(formEl) {
    let formValuesState = this.state.formValues
    formValuesState.unshift(formEl)
    formValuesState = _.uniqBy(formValuesState, 'key')
    this.calculateScore(formValuesState)
  }

  calculateScore(newFormValues) {
    let newScore = 0
    const sortedValues = _.sortBy(newFormValues, 'id')
    _.forEach(sortedValues, (entry) => {
      newScore += parseInt(entry.value, 10)
    })

    this.setState({ formValues: newFormValues, score: newScore })
  }

  documentUpload(event) {
    event.preventDefault()
    const answers = this.state.formValues
    const totalScore = this.state.score
    const morseFallScaleData = {
      answers,
      total_score: totalScore,
      rec_id: this.props.id,
      athena_name: 'Morse Fall Scale',
    }
    const $card = $(event.target).closest('.collection-item')
    flash.notice('Questionnaire sent (please wait)', 1000)
    $.post('/orders/upload', morseFallScaleData)
    .done(() => {
      $card.toggle('slide', { direction: 'left' }, 300, () => { this.remove() })

      flash.success('Questionnaire submitted successfully', 50000) // previously had 3000 second timeout
    })
    .fail((error) => {
      console.error(error, { context: `FallScale Upload Error message: ${error.responseText}` })
      _.forEach(error.responseJSON.errors, (err) => {
        flash.error(
          `${_.capitalize(err)}. Contact support@avhana.com if you need further assistance.`
        )
      })
    })
  }

  render() {
    const radiosData1 =
      [
        {
          questionId: '1',
          groupName: '1. History of Falling (immediate or within 3 months)',
          labels: [
            { name: 'No', value: 0 },
            { name: 'Yes', value: 25 },
          ],
        },
        {
          questionId: '2',
          groupName: '2. Secondary Diagnosis',
          labels: [
            { name: 'No', value: 0 },
            { name: 'Yes', value: 15 },
          ],
        },
        {
          questionId: '3',
          groupName: '3. Ambulatory Aid',
          labels: [
            { name: 'None/Bed rest/nurse assist', value: 0 },
            { name: 'Crutches/cane/walker', value: 15 },
            { name: 'Furniture', value: 30 },
          ],
        },
      ]

    const radiosData2 =
      [
        {
          questionId: '4',
          groupName: '4. Intravenous Therapy/Saline Lock',
          labels: [
            { name: 'No', value: 0 },
            { name: 'Yes', value: 20 },
          ],
        },
        {
          questionId: '5',
          groupName: '5. Gait',
          labels: [
            { name: 'Normal/bed rest/wheelchair', value: 0 },
            { name: 'Weak', value: 10 },
            { name: 'Impaired', value: 20 },
          ],
        },
        {
          questionId: '6',
          groupName: '6. Mental Status',
          labels: [
            { name: 'Oriented to own ability', value: 0 },
            { name: 'Overestimates/forgets limitations', value: 15 },
          ],
        },
      ]

    const radios1 =
      _.map(radiosData1, (radio, index) =>
        <RadioButtons
          key={index}
          containerClassName="morse-radio-container"
          questionId={radio.questionId}
          formId={this.props.id}
          groupName={radio.groupName}
          labels={radio.labels}
          updateFormState={this.updateFormState}
        />
      )
    const radios2 =
      _.map(radiosData2, (radio, index) =>
        <RadioButtons
          key={index}
          containerClassName="morse-radio-container"
          questionId={radio.questionId}
          formId={this.props.id}
          groupName={radio.groupName}
          labels={radio.labels}
          updateFormState={this.updateFormState}
        />
      )

    return (
      <div className="row">
        <div className="col s11 offset-s1">
          <form>
            <div className="row">
              <div className="col s6">
                {radios1}
              </div>
              <div className="col s6">
                {radios2}
              </div>
            </div>
            <div className="row">
              <p className="bold-text">
                {`Total Score: ${this.state.score} (out of 125 possible )`}
              </p>
              <button
                className="waves-effect waves-light btn blue-bkg col s2"
                disabled={this.state.formValues.length === 6}
                onClick={this.documentUpload}
              >
                Submit
              </button>
            </div>
          </form >
        </div>
      </div>
    )
  }
}

MorseFallScale.propTypes = {
  id: PropTypes.string,
}
