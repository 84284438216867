import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { bool } from 'prop-types'
import UploadCsv from './UploadCsv'
import Table from './Table'


const propTypes = {
  notProduction: bool.isRequired,
  isStaging: bool.isRequired,
}

const ValueSet = ({ notProduction, isStaging  }) => {
  const [file, setFile] = useState(undefined)
  const [query, setQuery] = useState('')
  const [data, setData] = useState([])

  const handleChange = (event) => {
    const newQuery = event.target.value
    setQuery(newQuery)
    if (newQuery.length >= 3) {
      $.ajax({
        method: 'get',
        url: '/root/value_sets_search',
        dataType: 'json',
        contentType: 'application/json',
        data: { query: newQuery },
      })
        .done((response) => {
          setData(response.value_sets)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setData([])
  }}

  const debouncedHandleChange = _.debounce(handleChange, 300)

  return (
    <>
      <h1>Value Sets</h1>
      <Autocomplete
        freeSolo
        id="value-set-search"
        options={[]}
        renderInput={(params) => (
          <TextField {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            onChange={debouncedHandleChange}
            value={query}
            label="Search Value Sets"
            margin="normal"
            variant="outlined"
          />
        )}        
      />
      <a className="waves-effect waves-light btn" href="value_sets/new">Add New Value Sets</a>
      {isStaging && (
        <a className="waves-effect waves-light btn" href="value_sets/show">Migration Value Sets</a>
      )}
      <UploadCsv file={file} setFile={setFile} notProduction={notProduction} /> 
      {data.length === 0 ? <div className="non-matching-message"> No value sets matching "{query}"</div> : <Table data={data} setData={setData} notProduction={notProduction} />}
    </>
  )  
}

ValueSet.propTypes = propTypes

const mountReact = () => {
  const reactNode = document.querySelector('#value-set')
  const reactData = $(reactNode).data()
  if (reactData) {
    ReactDOM.render(
      <ValueSet
        notProduction={reactData.notProduction}
        isStaging={reactData.isStaging}
      />,
     reactNode
    )
  }
}

$(mountReact)
