import PropTypes from 'prop-types'
import React, { Component } from 'react'
import algoliasearch from 'algoliasearch'
import { InputAdornment, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import * as R from 'ramda'

const propTypes = {
  orderIndex: PropTypes.number,
  orderPreference: PropTypes.shape({
    id: PropTypes.number,
    options: PropTypes.shape({
      orders: PropTypes.arrayOf(
        PropTypes.shape({
          order_type: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  rec: PropTypes.shape({
    id: PropTypes.number,
    options: PropTypes.shape({
      orders: PropTypes.arrayOf(
        PropTypes.shape({
          order_type: PropTypes.string,
        })
      ),
    }),
  }),
  updateOrderPref: PropTypes.bool.isRequired,
  updateOrderPreference: PropTypes.func.isRequired,
  orderAnything: PropTypes.bool,
  submitOrder: PropTypes.func,
  columns: PropTypes.string,
  style: PropTypes.shape({}),
  showHelperText: PropTypes.bool
}

const defaultProps = {
  columns: '8',
  style: {},
  submitOrder: null,
  orderAnything: null,
  rec: null,
  orderIndex: 0,
  showHelperText: true
}

/**
 * @function formatTypeaheadData uses obj from algolia creates proper display text for typeahead
 */
const formatTypeaheadData = (obj) => {
  if (obj.order_set) {
    return `(${obj.orders.length}) ${obj.name}`
  }
  return obj.name
}

/**
 * This component takes in values from the options column on an OrderPreference
 * and implements Typeahead so user can change the order pref.
 * @prop {pref} an OrderPreference ActiveRecord
 */
export default class CernerOrderPrefTypeahead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderType: '',
      options: [],
      prevOptions: [],
    }

    this.handleChange = this.handleChange.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.clearState = this.clearState.bind(this)
    this.optionSelected = this.optionSelected.bind(this)
  }

  componentDidMount() {
    // If you have the rec, use it, otherwise fall back to OP
    // This also happens in optionSelected method
    let orderType
    const { rec, orderPreference } = this.props
    try {
      if (rec) {
        orderType = rec.options.orders[0].order_type
      } else {
        orderType = orderPreference.options.orders[0].order_type
      }
    } catch (e) {
      orderType = ''
    }

    this.setState({ orderType })
  }

  /**
   * @method getDict fires onChange of the Typeahead
   * @param {text} user input text from Typeahead
   * TODO Using Athena algolia credentials for now. Update with Cerner data source.
   */
  getDict(text) {
    const client = algoliasearch(
      'Q5ZBLQF81V',
      'df6aba0fce0130170bd701c1b4089506'
    )
    const { orderType } = this.state
    // Can do stricter ordering by changing the ranking order on algolia to put filters
    // first (before typo, geo, etc.). See here: https://www.algolia.com/doc/guides/relevance/ranking#ranking-formula-a-tie-breaking-algorithm
    const queries = [
      {
        indexName: 'CernerOrderCode',
        query: text,
        params: {
          hitsPerPage: 35,
          optionalFacetFilters: [`order_type:${orderType}`],
        },
      }
    ]

    client.search(queries)
      .then((content) => {
        this.searchCallback(content)
      })
      .catch((err) => {
        if (err) {
          console.error(err)
          return
        }
      })
  }

  /**
   * @method searchCallback callback invoked upon successful search; updates state for typeahead options
   * @param {content} content results returned from search
   */
  searchCallback(content) {
    const orderCodesResults = content.results[0]
    const orderSetsResults = content.results[1]

    if (
      orderCodesResults.hits.length > 0
    ) {
      const options = orderCodesResults.hits
      this.setState({ prevOptions: this.state.options, options })
    }
  }

  /**
   * @method handleChange clears existing timer and sets new timer
   * with getDict
   * @param {event} event with value of Typeahead input
   */
  handleChange(event) {
    // need to use this to access these vars within setTimeout scope
    const { value } = event.target
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.getDict(value)
    }, 300)
  }

  clearState() {
    this.setState({ options: [] })
  }

  /**
   * @method clearInput clears input on typeahead and sets cursor focus to search input
   */
  clearInput() {
    this.clearState()
    this.input.focus()
  }

  /**
   * @method optionSelected fires when a Typeahead choice is selected
   * and update the order preference in Rails for that OrderPreference id
   * or change state
   * @param {opt} object from the array of order options in the Typeahead -
   */
  optionSelected(name) {
    const { prevOptions, options } = this.state
    let order = options.find((o) => o.name === name)
    if (!order) {
      order = prevOptions.find((o) => o.name === name)
    }
    order = {
      order_name: order.name,
      order_type_id: order.ordertypeid,
      order_type: order.order_type
    }
    // only set order set id if we do in fact have an order set not an OrderCode
    const orderSetId = order.order_set ? order.objectID : null
    const {
      rec,
      orderPreference,
      orderAnything,
      updateOrderPref,
      submitOrder,
      updateOrderPreference,
      orderIndex
    } = this.props

    const recId = rec ? rec.id : null

    // controller will update order pref based on updateOrderPref boolean
    // updates the recommendation either way in updateRecInDb unless updateRecInDb is false
    // passes back opt in proper format to be set in top level state
    if (!orderAnything) {
      const orderLens = R.lensPath(['options', 'orders', orderIndex])
      console.log(R.view(orderLens, orderPreference))
      const updatedOrderPreference = R.set(orderLens, order, orderPreference) 
      console.log(updatedOrderPreference)
      const data = JSON.stringify({
        ...updatedOrderPreference,
        rec_id: recId,
        order_set_id: orderSetId,
      })

      if (updateOrderPref) {
        // AJAX call to update organization order preference in db if flag set
        $.ajax({
          method: 'put',
          url: `/admin/order_preferences/${orderPreference.id}`,
          dataType: 'json',
          contentType: 'application/json',
          data,
        })
          .done((response) => {
            this.setState(response.orders[0])
            flash.success('Order changed successfully', 50000)
            updateOrderPreference(updatedOrderPreference)
          })
          .fail((error) => {
            flash.error('Order could not be changed.')
            console.error(error, {
              context: `CernerOrderPrefTypeahead update order pref error message: ${error.responseText}`,
            })
          })
      } else {
        // AJAX call to update organization order preference in db if flag set
        $.ajax({
          method: 'put',
          url: '/recommendations/update',
          dataType: 'json',
          contentType: 'application/json',
          data
        })
          .done((response) => {
            this.setState(response.orders[0])
            updateOrderPreference(updatedOrderPreference)
            flash.success('Order changed successfully', 50000)
          })
          .fail((error) => {
            flash.error('Order could not be changed.')
            console.error(error, {
              context: `CernerOrderPrefTypeahead update order pref error message: ${error.responseText}`,
            })
          })
      }
    } else {
      this.setState({ ...newOptions.orders[0] })
      updateOptions(recId, newOptions)
      // submit order on select instead on submit button for the DX pre planning section
      if (submitOrder) {
        submitOrder()
      }
    }
  }

  render() {
    const { orderAnything, orderPreference, orderIndex, showHelperText } = this.props
    const { options } = this.state

    let helperText
    if (!orderAnything && showHelperText) {
      helperText = (
        <p
          className="thin left"
          style={{ padding: '0', fontSize: '0.95em', fontWeight: 'bold' }}
        >
          Use the search box above to change the order for this guideline.
        </p>
      )
    }

    const path = R.lensPath(['options', 'orders', orderIndex, 'order_name'])
    const orderName = R.view(path, orderPreference) || ''
    return (
      <>
        <Autocomplete
          value={orderName}
          freeSolo
          options={options.map((o) => formatTypeaheadData(o))}
          style={{ 
            paddingLeft: '2.25em',
            paddingRight: '2.25em'
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={this.handleChange}
              label="Search orders here"
            />
          )}
          onChange={(_event, value, reason) => {
            if (reason === 'selectOption') {
              this.optionSelected(value)
            }
          }}
          id="autocomplete"
        />
        {helperText}
      </>
    )
  }
}

CernerOrderPrefTypeahead.propTypes = propTypes
CernerOrderPrefTypeahead.defaultProps = defaultProps