import React from 'react'
import RadioButtons from '../../../reusableComponents/RadioButtons.jsx'
import MaterializeSwitch from '../../../reusableComponents/MaterializeSwitch.jsx'
import MaterializeTextInput from '../../../reusableComponents/MaterializeTextInput.jsx'

require('../../../utilities/ascvd_calc.js')

export default class AscvdScore extends React.Component {
  constructor (props) {
    super(props)
    this.state = { ten_year_score: 0, lifetime_score: 0, formValues: {}, rec_title: '', rec_description: '' }
    this.calculateAscvd = this.calculateAscvd.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.updateParentScoreState = this.updateParentScoreState.bind(this)
  }

  UNSAFE_componentWillMount () {
    var valuesFromDrools = this.props.options.form_info
    var race = valuesFromDrools.Race

    // sanitize race value from drools to match UI value
    if (race) {
      if ((race.toLowerCase().includes('african american')) || (race.toLowerCase().includes('black'))) {
        race = 'African American'
      } else if (race.toLowerCase().includes('white')) {
        race = 'White'
      } else {
        race = 'Other'
      }
    } else {
      race = 'Other'
    }
    valuesFromDrools.Race = race
    this.setState({ formValues: valuesFromDrools })
    this.calculateAscvd(valuesFromDrools)
  }

  calculateAscvd (formInfo) {
    // convert all user input to string
    let gender = formInfo.Sex.toString()
    let age = formInfo.Age.toString()
    let race = formInfo.Race.toString()
    let chol = formInfo['Total Cholesterol'].toString()
    let hdl = formInfo['HDL'].toString()
    let sys = formInfo['Systolic Blood Pressure'].toString()
    let hyp = formInfo['Essential Hypertension'].toString()
    let diab = formInfo['Diabetes'].toString()
    let smoker = formInfo['Tobacco User'].toString()

    // sanitize user input to prop data types for calculator
    const userInput = new AscvdUserInput(gender, age, race, chol, hdl, sys, hyp, diab, smoker)

    // calculate risk score
    const TenYearRisk = new TenYearASCVD(userInput)
    const LifetimeRisk = new LifetimeASCVD(userInput)
    const formattedTenYearRisk = TenYearRisk.formattedRisk
    const formattedLifetimeRisk = LifetimeRisk.formattedRisk
    let headerText
    if ((formattedTenYearRisk != 'NA') && (formattedLifetimeRisk != 'NA')) {
      headerText = `${formattedTenYearRisk} Ten-Year, ${formattedLifetimeRisk} Lifetime`
    } else if (formattedTenYearRisk != 'NA') {
      headerText = `${formattedTenYearRisk} Ten-Year`
    } else if (formattedLifetimeRisk != 'NA') {
      headerText = `${formattedLifetimeRisk} Lifetime`
    } else {
      headerText = 'NA' //Shouldn't happen unless they change age in card
    }

    const ascvdRec = new AscvdGetRecommendationText(userInput.diabetes, userInput.age, TenYearRisk.YourRisk)
    this.setState({ ten_year_score: formattedTenYearRisk, lifetime_score: formattedLifetimeRisk, rec_title: ascvdRec.title, rec_description: ascvdRec.description })
    this.updateParentScoreState(headerText)
  }

  updateFormState (formEl) {
    var key = formEl.key
    var newFormValues = this.state.formValues
    newFormValues[key] = formEl.value
    this.setState({ formValues: newFormValues })
    this.calculateAscvd(newFormValues)
  }

  updateParentScoreState (score) {
    if (this.props.updateParentScoreState) {
      this.props.updateParentScoreState(score)
    }
  }

  render () {
    var formInfo = this.state.formValues

    return (
      <form className="row ascvd-form">
        <div className="row">
          <div className="col s6">
            <div className="row">
              <div className="col s5">
                <RadioButtons
                  formId={this.props.id}
                  groupName={'Sex'}
                  labels={[
                    { name: 'Male', value: 'Male' },
                    { name: 'Female', value: 'Female' },
                  ]}
                  selected={formInfo['Sex']}
                  updateFormState={this.updateFormState}
                />
              </div>
              <div className="col s7">
                <RadioButtons
                  formId={this.props.id}
                  groupName={'Race'}
                  labels={[{name:'White', value:'White'},
                           {name:'African American', value:'African American'},
                           {name:'Other', value:'Other'},
                         ]}
                  selected={formInfo['Race']}
                  updateFormState={this.updateFormState}
                />
              </div>
            </div>
            <br/>
            <MaterializeSwitch
              label="History of Diabetes"
              valueOn={true}
              valueOff={false}
              data={{ key: 'Diabetes', value: formInfo['Diabetes'] }}
              selected={formInfo['Diabetes']}
              updateFormState={this.updateFormState}
            />
            <MaterializeSwitch
              label="Treatment for Hypertension"
              valueOn={true}
              valueOff={false}
              data={{ key: 'Essential Hypertension', value: formInfo['Essential Hypertension'] }}
              selected={formInfo['Essential Hypertension']}
              value={1}
              updateFormState={this.updateFormState}
            />
            <MaterializeSwitch
              label="Smoker"
              valueOn={true}
              valueOff={false}
              data={{ key: 'Tobacco User', value: formInfo['Tobacco User'] }}
              selected={formInfo['Tobacco User']}
              value={1}
              updateFormState={this.updateFormState}
            />
            <br/>
            <div className="row">
              <div className="offset-s1 col s2">
                <MaterializeTextInput
                  formId={this.props.id}
                  label="Age in Years"
                  data={{ key: 'Age', value: formInfo['Age'] }}
                  updateFormState={this.updateFormState}
                />
              </div>
              <div className="col s3">
                <MaterializeTextInput
                  formId={this.props.id}
                  label="Total Cholesterol (mg/dL)"
                  data={{ key: 'Total Cholesterol', value: formInfo['Total Cholesterol'] }}
                  updateFormState={this.updateFormState}
                />
              </div>
              <div className="col s3">
                <MaterializeTextInput
                  formId={this.props.id}
                  label="HDL Cholesterol (mg/dL)"
                  data={{ key: 'HDL', value: formInfo['HDL'] }}
                  updateFormState={this.updateFormState}
                />
              </div>
              <div className="col s3">
                <MaterializeTextInput
                  formId={this.props.id}
                  label="Systolic Blood Pressure"
                  data={{ key: 'Systolic Blood Pressure', value: formInfo['Systolic Blood Pressure'] }}
                  updateFormState={this.updateFormState}
                />
              </div>
            </div>
          </div>
          <div className="offset col s6">
            <div className="row">
              <div className="col s6">
                <div className="ascvd-score-header">10-Year ASCVD Risk</div>
              </div>
              <div className="col s6">
                <div className="ascvd-score-header">Lifetime ASCVD Risk</div>
              </div>
            </div>
            <div className="row">
              <div className="col s6">
                <div className="chad-score-text bubble-number-xl center_bubble">{this.state.ten_year_score}</div>
              </div>
              <div className="col s6">
                <div className="chad-score-text bubble-number-xl center_bubble">{this.state.lifetime_score}</div>
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col s10 chad-score-message">
                <div className="bold-text">{this.state.rec_title}</div>
                <br/>
                <div>{this.state.rec_description}</div>
                <br/>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: '0em 3em' }}>
          <a target="_blank" rel="noopener noreferrer" href="https://statindecisionaid.mayoclinic.org/" className="left">View patient decision aid for statin</a>
          <a target="_blank" rel="noopener noreferrer" href="http://tools.acc.org/ASCVD-Risk-Estimator/#page_about" className="right">View source</a>
        </div>
      </form>
    )
  }
}
