import React from 'react'
import NotesSubmitButton from '../../../reusableComponents/NotesSubmitButton.jsx'
import RadioButtons from '../../../reusableComponents/RadioButtons.jsx'

export default class AmdHasExam extends React.Component {
  constructor (props) {
    super(props)
    this.state = { text: 'Macular degeneration severity is ', exception: '' }
    this.submitNote = this.submitNote.bind(this)
    this.updateTextBox = this.updateTextBox.bind(this)
    this.updateException = this.updateException.bind(this)
  }

  updateTextBox (event) {
    var newText = event.target.value
    this.setState({ text: newText })
  }

  updateException (formEl) {
    let exception = formEl.value
    this.setState({ exception: exception })
  }

  submitNote () {
    let diagnosisCode = this.props.options.diagnosis_code

    let customOrderPref = {
      order_type: 'patient_info',
      order_type_id: '343579',
      diagnosis_code: diagnosisCode,
      provider_note: this.state.exception + '. ' + this.state.text,
    }
    const recId = this.props.id
    const orderInfo = { recId: recId, customOrderPref: customOrderPref }
    $.post('/orders/send', orderInfo)
    .done(response => {
      this.props.submittedToEhr(this.props.rule.name)
    })
    .fail(error => {
      flash.error('There was an error submitting the note to the EHR.')
      console.error(error, {context: `Submit AmdHasExam Note Error message: ${error.responseText}`})
    })
  }

  render () {
    return (
      <div className="row">
        <div className="row">
          <RadioButtons
            formId={this.props.id}
            groupName={'Patient has Age-Related Macular Degeneration (AMD) with a Dilated Macular Examination (DME). Please document:'}
            labels={[
              { name: 'Macular thickening', value: 'Macular thickening' },
              { name: 'No macular thickening', value: 'No macular thickening' },
              { name: 'Hemorrhage', value: 'Hemorrhage' },
            ]}
            selected={this.state.exception}
            updateFormState={this.updateException}
          />
        </div>
        <div className="row">
          <div className="input-field col s10 offset-s1">
            <textarea
              id="amd"
              className="materialize-textarea"
              onChange={this.updateTextBox}
              value={this.state.text}
              placeholder={this.state.text}
            />
            <label htmlFor="icon_prefix2"></label>
          </div>
          <NotesSubmitButton
            encounter={this.props.encounter}
            submitNote={this.submitNote}
          />
        </div>
      </div>
    )
  }
}
