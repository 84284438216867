import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default class Flu extends React.Component {

  constructor (props) {
    super(props)
    this.state = { fluDate: moment().toDate() }
    this.updateFluDate = this.updateFluDate.bind(this)
    this.postImmunization = this.postImmunization.bind(this)
  }

  updateFluDate (date) {
    this.setState({ fluDate: date })
  }

  postImmunization() {
    let info = {
      patient_id: this.props.patient.id,
      administer_date: this.state.fluDate.format('MM/DD/YYYY'),
      cvx: 88, // influenza unspecified formulation
    }
    $.post('/orders/historical_vaccines', info)
    .done(response => {
      this.props.submittedToEhr(this.props.rule.name)
    })
    .fail(error => {
      flash.error(error.responseJSON.error)
      console.error(error, {context: `Submit Flu Imm Error message: ${error.responseText}`})
    })
  }

  render () {
    return (
      <div className="row">
        <div className="row">
          <div className="row">
            <div className="col s11 offset-s1">
              {'Choose a date and then click the button below to document influenza immunization date in the EHR,' +
                ' or press ORDER above to order an influenza immunization.'}
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col s3 offset-s1 padding-top-1em">Influenza vaccine received</div>
            <div className="col s4">
              <DatePicker
                showTodayButton="Today"
                selected={this.state.fluDate}
                onChange={this.updateFluDate}
                placeholderText="Select a date"
              />
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col s5 offset-s1">
              <button className='btn' onClick={this.postImmunization}>Send Flu Date</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
