import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  href: PropTypes.string.isRequired,
}

const ExternalLink = ({ href }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer noopener"
  >
    Link to rule source
  </a>
)

ExternalLink.propTypes = propTypes

export default ExternalLink
