import React from 'react'
import moment from 'moment'

export default class Appointment extends React.Component {

  render() {
    const props = this.props
    const { apt, isFiltered } = props

    const emrPatientId = apt.emr_patient_id
    const department = apt.department
    const departmentId = department ? department.emr_department_id : undefined

    const parseAptTime = (aptTime, format) => moment(aptTime).utcOffset(props.timeZoneOffset).format(format)
    const aptTime = parseAptTime(apt.appointment_time, 'h:mm a')
    const aptDate = parseAptTime(apt.appointment_time, 'M/D')

    let cssClasses = 'apt-card card button'
    if (apt.id === props.activeAppointmentId) {
      cssClasses += ' blue-bkg white-text z-depth-0'
    } else if (isFiltered) {
      cssClasses += ' filtered-appointment'
    } else {
      cssClasses += ' white'
    }

    return (
      <div
        role="option"
        className={cssClasses}
        key={apt.id}
        style={{ cursor: 'pointer' }}
        onClick={props.getRecommendations.bind(null, { emrPatientId, departmentId, apt })}
      >
        <div className="card-content" style={{ padding: '20px 0px' }}>
          <div className="row" style={{ margin: 0 }}>
            <div className="col s9">
              <p>{aptTime}</p>
            </div>
            <div className="col s12">
              <div className="patient-name-apt" style={{ fontSize: '1.4em' }}>{apt.patient_full_name}</div>
            </div>

            {!isFiltered && renderPatientDetails(apt, props.emr)}
            {!isFiltered && renderAptProviderName(apt.user, props.currentUser)}
            {!isFiltered && renderEncounterDetails(apt)}

            <div className="col s12">
              <p>{`${apt.duration} min - ${apt.reason}`}</p>
            </div>

            {!isFiltered && renderInsuranceDetails(apt.patient)}
            {!isFiltered && renderActionCount(apt)}
          </div>
        </div>
      </div>
    )
  }
}

const renderAptProviderName = (user, currentUser) => {
  let aptProviderName

  if (currentUser.medical_assistant) {
    aptProviderName =
      (<span className="col s12">
        <b>
          <i
            style={{ fontSize: '0.8rem', marginRight: '5px' }}
            className="material-icons"
          >
            person_pin
          </i>
          {user.full_name}
        </b>
      </span>)
  }

  return aptProviderName
}

const renderAptDate = (aptDate) => {
  return (
    <div className="col s3">
      <p>{aptDate}</p>
    </div>
  )
}

const renderPatientDetails = (apt, emr) => {
  const patient = apt.patient
  const ageFormattedText = formatAge(patient)
  const sex = patient ? patient.sex.charAt(0) : 'gender not found'
  const emrPatientIdDisplay = formatEmrPatientId(apt.emr_patient_id, patient, emr)

  const patientDetails = (
    <div className="col s12" style={{ paddingBottom: '0.5em' }}>
      <div style={{ fontSize: '1.2em' }}>
        {`${ageFormattedText} ${sex}`}{emrPatientIdDisplay}
      </div>
    </div>
  )

  return patientDetails
}

// TODO test this when js tests work again
const formatAge = (patient) => {
  let ageFormattedText

  if (patient) {
    const birthDate = patient.birthdate
    if (patient.age === 0) {
      const monthsOld = moment().diff(birthDate, 'months')
      ageFormattedText = `${monthsOld}mo `
    } else if (patient.age <= 3) {
      const leftOverMonths = moment().diff(birthDate, 'months') % 12
      ageFormattedText = `${patient.age}y${leftOverMonths}mo `
    } else if (patient.age >= 3) {
      ageFormattedText = `${patient.age}yo `
    }
  }
  ageFormattedText = ageFormattedText ? ageFormattedText : 'age not found '

  return ageFormattedText
}

const formatEmrPatientId = (emrPatientId, patient, emr) => {
  let emrPatientIdDisplay = ' | '

  if (emr === 'allscripts' && patient.mrn) {
    emrPatientIdDisplay += patient.mrn
  } else if (emrPatientId) {
    emrPatientIdDisplay += emrPatientId
  } else {
    emrPatientIdDisplay += 'emr patient id not found'
  }

  return emrPatientIdDisplay
}

const renderEncounterDetails = (apt) => {
  const encounterStatus = getEncounterStatusText(apt.encounter, apt.appointment_status)

  const encounterDetails = (
    <div className="col s12">
      <p>{encounterStatus}</p>
    </div>
  )

  return encounterDetails
}

const renderInsuranceDetails = (patient) => {
  let planName

  if (patient && patient.insurance_policy) {
    planName = patient.insurance_policy.plan_name
  } else {
    planName = 'No insurance found'
  }

  const insuranceDetails = (
    <div className="col s12">
      <p>{planName}</p>
    </div>
  )

  return insuranceDetails
}

const renderActionCount = ({ actions: count }) => {
  const actionCount = (
    <div className="col s12">
      <p>{count + ' action(s)'}</p>
    </div>
  )

  return actionCount
}
