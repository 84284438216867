import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@mui/material'

const UUID = ({ uuid }) => { 

  return (
    <Grid
      container
      direction="row"
    >
      <Grid
        item
        xs={3}
      >
        <b>Rule UUID</b>
      </Grid>
      <Grid
        item
        xs={9}
      >
        {uuid}
      </Grid>
    </Grid>
  )
}

UUID.propTypes = {
  uuid: PropTypes.string.isRequired
}

export default UUID
