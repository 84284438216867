import React from 'react'
import ReactDOM from 'react-dom'
import MaterializeSwitch from '../../../reusableComponents/MaterializeSwitch.jsx';
import RadioButtons from '../../../reusableComponents/RadioButtons.jsx';
import _ from 'lodash'

export default class ChadScore extends React.Component {
  constructor (props) {
    super(props)
    this.state = { score: 0, formValues: {}, percentOne: '', percentTwo: '' }
    this.calculateChadScore = this.calculateChadScore.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.updateParentScoreState = this.updateParentScoreState.bind(this)
  }

  componentDidMount () {
    var valuesFromDrools = this.props.options.form_info
    var scoreFromDrools = this.props.options.diagnosis_code
    this.setState({ score: scoreFromDrools, formValues: valuesFromDrools })
    this.chadMessage(scoreFromDrools)
  }

  updateFormState (formEl) {
    var key = formEl.key
    var newFormValues = this.state.formValues
    newFormValues[key] = parseInt(formEl.value)
    this.setState({ formValues: newFormValues })
    this.calculateChadScore.bind(this)
  }

  calculateChadScore () {
    var formValsState = this.state.formValues
    var newScore = 0
    _.forEach(formValsState, value => { newScore += value })
    this.setState({ score: newScore })
    this.chadMessage(newScore)
    this.updateParentScoreState(newScore)
  }

  chadMessage (score) {
    var percentArray = [['0.2%', '0.3%'], ['0.6%', '0.9%'], ['2.2%', '2.9%'], ['3.2%', '4.6%'],
                        ['4.8%', '6.7%'], ['7.2%', '10.0%'], ['9.7%', '13.6%'], ['11.2%', '15.7%'],
                        ['10.8%', '15.2%'], ['12.2%', '17.4%'],];
    var percents = percentArray[score];
    this.setState({ percentOne: percents[0], percentTwo: percents[1] })
  }

  updateParentScoreState (score) {
    this.props.updateParentScoreState(score)
  }

  render () {
    var formInfo = this.props.options.form_info
    var conditions = ['Heart Failure', 'Essential Hypertension', 'Hemorrhagic Stroke', 'Ischemic Vascular Disease', 'Diabetes']
    var switches = _.map(conditions, (condition, index) => {
      var conditionVal = condition === 'Hemorrhagic Stroke' ? 2 : 1
      return (
        <MaterializeSwitch
          key={index}
          label={'History of ' + condition}
          valueOn={conditionVal}
          valueOff={0}
          selected={formInfo[condition] ? true : false}
          data={{ key: condition, value: conditionVal }}
          updateFormState={this.updateFormState.bind(this)}
        />
      )
    })

    return (
      <form onChange={this.calculateChadScore} className="chad-score-form recommendation-body body-container">
        <div className="row">
          <div className="col s6">
            <div className="row">
              <div className="col s6">
                <RadioButtons
                  formId={this.props.id}
                  groupName={'Age'}
                  labels={[
                    { name: '< 65', value: 0 },
                    { name: '65 - 74', value: 1 },
                    { name: '> 74', value: 2 },
                  ]}
                  selected={formInfo['Age']}
                  updateFormState={this.updateFormState}
                />
              </div>
              <div className="col s6">
                <RadioButtons
                  formId={this.props.id}
                  groupName={'Sex'}
                  labels={[
                    { name: 'Male', value: 0 },
                    { name: 'Female', value: 1 },
                  ]}
                  selected={formInfo['Sex']}
                  updateFormState={this.updateFormState}
                />
              </div>
            </div>
            {switches}
          </div>
          <div className="col s6 chad-score-container">
            <div className="row">
              <div className="col s7">
                <div className="chad-score-header">CHA2DS2-VASc Score</div>
              </div>
              <div className="col s5 score-number-container">
                <div data-testid='chad-score' className="chad-score-text bubble-number-large">{this.state.score}</div>
              </div>
            </div>
            <div className="row">
              <span className="col s10 chad-score-message" style={{ padding: '1em 0em' }}>
                Stroke risk was <b>{this.state.percentOne}</b> per year
                and <b>{this.state.percentTwo}</b> risk of stroke/TIA/Systemic
                embolism (the Swedish Atrial Fibrillation Cohort
                Study) Eur Heart J 2012 Jun;33(12):1500-10. doi: 10.1093.
              </span>
              <div className="col s10 chad-score-messag chad_list">
                <ul>Recommendations suggest:
                  <li>0 score is low risk and may not require anticoagulation</li>
                  <li>1 score is low to moderate risk and should consider anti-platelet or anticoagulation therapy</li>
                  <li>> 2 is moderate to high risk and should be consider anticoagulation therapy</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: '0em 3em' }}>
          <a target="_blank" rel="noopener noreferrer" href="http://patient.info/decision-aids/atrial-fibrillation-medication-options" className="left">View patient decision aid for medication options</a>
        </div>
      </form>
    )
  }
}
