/* eslint-disable import/extensions */
/* eslint no-console:0 */
/* eslint-disable import/imports-first */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import jQuery from 'jquery'

// Load Rails UJS - for automatic CSRF protection in AJAX requests 
import railsUJS from '@rails/ujs'

// Importing and using cash.isArray so it doesn't get removed by UglifyJS.
import M from 'materialize-css'

// Polyfill modules
import 'core-js/stable'
import 'regenerator-runtime/runtime' // eslint-disable-line

// load npm modules
import pluralize from 'pluralize'

railsUJS.start()

// eslint-disable-next-line
window.$ = window.jQuery = $ = jQuery // this is required to make find.js.erb work.
window.pluralize = pluralize

window.pluralize.addIrregularRule('meets', 'meet')

require.context('./images/', true)

// require all utilities
function requireAll(r) { r.keys().forEach(r) }
requireAll(require.context('./utilities/', true, /\.js$/))

require('./flash/flashMessages.jsx')
require('./preVisitDashboard/preVisitDashboard.jsx')
require('./userMenu/userMenu.jsx')
require('./avhanaRulesManager/avhanaRulesFilter.jsx')
require('./orderPreferenceReference/orderPreferenceReferenceForm.jsx')
require('./components/rules/rulesFilter.jsx')
require('./analyticsDashboard/analyticsDashboard.jsx')
require('./qualityMeasuresTimeline/qualityMeasuresTimeline.jsx')
require('./recommendations/RecommendationsIframe.jsx')
require('./createUserForm/userFormStateMachine.jsx')
require('./userManagement/approveUsers.jsx')
require('./userManagement/departmentSearch.jsx')
require('./careTeam/departmentSearch.jsx')
require('./careTeam/userSearch.jsx')
require('./ValueSet/index.jsx')
require('./components/NewValueSet/index.jsx')

document.addEventListener('DOMContentLoaded', () => {
  const elems = document.querySelectorAll('select')
  M.FormSelect.init(elems, {})
  window.initializeRuleToggle()
})
// Support component names relative to this directory:
const componentRequireContext = require.context("./components", true)

const ReactRailsUJS = require("react_ujs")

ReactRailsUJS.useContext(componentRequireContext)
