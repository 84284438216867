import React from 'react'
import PropTypes from 'prop-types'
import NotesSubmitButton from '../../../reusableComponents/NotesSubmitButton.jsx'
import RadioButtons from '../../../reusableComponents/RadioButtons.jsx'

export default class AmdNoExam extends React.Component {
  constructor(props) {
    super(props)
    this.state = { text: '', exception: '' }
    this.submitNote = this.submitNote.bind(this)
    this.updateTextBox = this.updateTextBox.bind(this)
    this.updateException = this.updateException.bind(this)
  }

  updateTextBox(event) {
    this.setState({ text: event.target.value })
  }

  updateException(formEl) {
    this.setState({ exception: formEl.value })
  }

  submitNote() {
    const diagnosisCode = this.props.options.diagnosis_code

    const customOrderPref = {
      order_type: 'patient_info',
      order_type_id: '343579',
      diagnosis_code: diagnosisCode,
      provider_note: `${this.state.exception}: ${this.state.text}`,
    }
    const recId = this.props.id
    const orderInfo = { recId, customOrderPref }
    $.post('/orders/send', orderInfo)
    .done(() => {
      this.props.submittedToEhr(this.props.rule.name)
    })
    .fail((error) => {
      flash.error('There was an error submitting the note to the EHR.')
      console.error(error, { context: `Submit AmdNoExam Note Error message: ${error.responseText}` })
    })
  }

  render() {
    return (
      <div className="row">
        <div className="row">
          <RadioButtons
            formId={this.props.id}
            groupName={'Patient has Age-Related Macular Degeneration (AMD) and no Dilated Macular Examination (DME). Please document reason:'}
            labels={[
              { name: 'Dilated Macular Examination not performed for medical reason', value: 'Dilated Macular Examination not performed for medical reason' },
              { name: 'Dilated Macular Examination not performed for patient reason', value: 'Dilated Macular Examination not performed for patient reason' },
              { name: 'Dilated Macular Examination not performed', value: 'Dilated Macular Examination not performed' },
              { name: 'Other exception', value: 'Other exception' },
            ]}
            selected={this.state.exception}
            updateFormState={this.updateException}
          />
        </div>
        <div className="row">
          <div className="input-field col s10 offset-s1">
            <textarea
              id="amd"
              className="materialize-textarea"
              onChange={this.updateTextBox}
              value={this.state.text}
              placeholder="Enter note here documenting exception"
            />
            <label htmlFor="icon_prefix2" />
          </div>
          <NotesSubmitButton
            encounter={this.props.encounter}
            submitNote={this.submitNote}
          />
        </div>
      </div>
    )
  }
}

AmdNoExam.propTypes = {
  encounter: PropTypes.shape({}),
  id: PropTypes.string,
  rule: PropTypes.shape({
    name: PropTypes.string,
  }),
  submittedToEhr: PropTypes.func,
  options: PropTypes.shape({
    diagnosis_code: PropTypes.string,
  }),
}
