import React from 'react'
import PropTypes from 'prop-types'

const Diagnosis = ({ diagnosis, ruleType }) => {
  if (ruleType === 'remove') {
    return null
  }

  return (
    <span>
      Orders using diagnosis code
      {' '}
      <b>{diagnosis}</b>
    </span>
  )
}

Diagnosis.propTypes = {
  diagnosis: PropTypes.string.isRequired,
  ruleType: PropTypes.string.isRequired
}

export default Diagnosis
