import React from 'react'
import PropTypes from 'prop-types'

const FraminghamScore = (props) => {
  const formInfo = props.options.form_info
  const ldlScore = formInfo.ldl_score
  const cholScore = formInfo.chol_score
  return (
    <div className="row">
      <div className="col s12">
        <div className="row">
          <div className="col s3 center-align">
            <div className="chad-score-text bubble-number-xl center-align">{ldlScore}%</div>
            <h5 className="center-align">LDL Risk</h5>
          </div>
          <div className="col s3 center-align">
            <div className="chad-score-text bubble-number-xl center-align">{cholScore}%</div>
            <h5 className="center-align">Total Cholesterol Risk</h5>
          </div>
          <div className="col s6">
            <p>This risk assessment tool uses information from the Framingham
            Heart Study to predict a person’s chance of having a heart attack
            in the next 10 years. The score is
            calculated using the patient’s age, sex, total cholesterol level,
            HDL cholesterol level, smoking status, systolic blood pressure,
            and whether the patient is currently on medication to treat high
            blood pressure.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

FraminghamScore.propTypes = {
  options: PropTypes.shape({
    form_info: PropTypes.shape({
      ldl_score: PropTypes.number,
      chol_score: PropTypes.number,
    })
  })
}

export default FraminghamScore
