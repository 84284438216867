import React from 'react'
import { bool } from 'prop-types'


const propTypes = {
  notProduction: bool.isRequired,
}

const UploadCsv = ({ file, setFile, notProduction }) => {
  const handleFileSelect = (event) => {
    setFile(event.target.files[0])
  }
  
  const handleFileSubmit = (event) => {
    const formData = new FormData()
    formData.append("file", file)
    event.preventDefault()
    $.ajax({
      method: 'post',
      url: '/root/value_sets/upload_csv',
      contentType: false,
      processData: false,
      data: formData,
      enctype: 'multipart/form-data'
    })
      .done(() => {
        flash.success('Value sets successfully uploaded!', 50000)

      })
      .fail((error) => {
        flash.error(`Error uploading your value sets file. ${error.responseJSON.errors}`, 50000)
        console.error(error)
      })
  }
  
  return (
    <>
      {notProduction && (
        <form onSubmit={handleFileSubmit}>
          <h6>
            Upload value sets csv with headers "value_set_name", "description", "code", and "code_system"
          </h6>
          <input
            type="file"
            id="file-select"
            accept=".csv"
            onChange={handleFileSelect}
          />
          <button type="submit">
            Upload
          </button>
        </form>
      )}
    </>
  )
}

UploadCsv.propTypes = propTypes

export default UploadCsv