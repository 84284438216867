import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import { ascend, prop, sortWith, partition } from 'ramda'

import ExpandableCheckboxList from '../../reusableComponents/expandableCheckboxList'
import LoadingSkeleton from '../../loadingSkeleton/LoadingSkeleton'
import RulesList from './rulesList'

const UUIDV4_REGEXP = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

// validate prop types
const propTypes = {
  allActiveRules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  emr: PropTypes.string.isRequired,
  orgId: PropTypes.number,
  pgId: PropTypes.number.isRequired,
  isStaging: PropTypes.bool.isRequired,
  providerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const defaultProps = {
  orgId: null,
}

// function to create JSX for filter criteria chips on top of page
const createCriteriaChips = (state) => {
  const ruleSourcesCriteria =
    state.checkedRuleSources.length > 0
      ? state.checkedRuleSources
      : ['All Rule Sources']
  const diseaseTagCriteria =
    state.checkedDiseaseTags.length > 0
      ? state.checkedDiseaseTags
      : ['All Tags']
  const textCriteria =
    state.textQuery.length > 0 ? [`Contains text '${state.textQuery}'`] : []

  const criteria = ruleSourcesCriteria.concat(diseaseTagCriteria, textCriteria)

  criteria.map((name) => (
    <div
      key={name}
      className="chip"
      style={{
        marginLeft: '5px',
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#2f91fa',
        fontWeight: '300',
      }}
    >
      {name}
    </div>
  ))
}

export const filterFunction = (
  rule,
  textQuery,
  diseaseTagFilters,
  ruleSourceFilters,
  hideInactiveSubscriptions,
  showTestingRules,
) => {
  let diseaseTagFiltersPresentOnCurrentRule
  let ruleSourceFiltersPresentOnCurrentRule

  // filter rule source if some are selected otherwise display all
  if (ruleSourceFilters.length > 0) {
    ruleSourceFiltersPresentOnCurrentRule = ruleSourceFilters.includes(
      rule.source
    )
  } else {
    ruleSourceFiltersPresentOnCurrentRule = true
  }

  // filter disease tags if some are selected otherwise display all
  if (diseaseTagFilters.length > 0) {
    const tagsOnThisRule = rule.tags.map((t) => t.name)
    diseaseTagFiltersPresentOnCurrentRule =
      _.intersection(diseaseTagFilters, tagsOnThisRule).length > 0
  } else {
    diseaseTagFiltersPresentOnCurrentRule = true
  }

  // filter on text present in rule description
  const lowerCaseTextQuery = textQuery.toLowerCase().trim()

  const textQueryOnCurrentRule =
    (rule.description &&
      rule.description.toLowerCase().includes(lowerCaseTextQuery)) ||
    (rule.name && rule.name.toLowerCase().includes(lowerCaseTextQuery)) ||
    (rule.default_display_code &&
      rule.default_display_code.toLowerCase().includes(lowerCaseTextQuery)) ||
    (UUIDV4_REGEXP.test(textQuery) &&
      rule.uuid.toLowerCase() === lowerCaseTextQuery)
  
  // filter on subscription on or off
  const subscriptionBool = hideInactiveSubscriptions
    ? rule.subscription && rule.subscription.active_silent !== 'off'
    : true

  // filter on migration_status testing
  const migrationStatusBool = !showTestingRules 
    ? rule.subscription && rule.migration_status === 'testing'
    : true  

  return (
    subscriptionBool &&
    migrationStatusBool &&
    ruleSourceFiltersPresentOnCurrentRule &&
    diseaseTagFiltersPresentOnCurrentRule &&
    textQueryOnCurrentRule
  )
}

const sortByRuleType = (a, b) => {
  const cdsRules = ['advisory', 'remove']
  const isCds = (r) => cdsRules.includes(r.rule_type)
  return isCds(a) && isCds(b)
}

const sortRules = sortWith([
  ascend(sortByRuleType),
  ascend(prop('default_display_code')),
  ascend(prop('name')),
])

// React RulesFilter class
export default class RulesFilter extends Component {
  /**
   * @prop orgId -> avhana organization id
   * @prop allActiveRuless -> array of objects, all Rule records
   * @prop tags -> all tags present on allActiveRules
   * @prop sources -> all sources present on allActiveRules
   * @prop isStaging -> only show guideline on staging
   */

  constructor(props) {
    super(props)
    const { allActiveRules, pgId } = props
    const rulesWithActiveSubscriptions = allActiveRules
      .filter((r) => r.subscription && r.subscription.active_silent !== 'off')
      .sort(sortRules)

    const rulesWithTestingStatus = allActiveRules
      .filter((r) => r.migration_status === 'testing')
      .sort(sortRules)

    this.state = {
      displayedRules: rulesWithActiveSubscriptions,
      allRules: allActiveRules,
      checkedRuleSources: [],
      checkedDiseaseTags: [],
      textQuery: '',
      showAllRulesSources: false,
      showAllDiseaseTags: false,
      hideInactiveSubscriptions: true,
      providerGroupId: pgId,
      showTestingRules: rulesWithTestingStatus
    }

    this.filterOnCheck = this.filterOnCheck.bind(this)
    this.turnSubscriptionActiveSilent = this.turnSubscriptionActiveSilent.bind(this)
    this.updateSubscriptionOnRule = this.updateSubscriptionOnRule.bind(this)
    this.toggleStepTherapy = this.toggleStepTherapy.bind(this)
    this.toggleUIActive = this.toggleUIActive.bind(this)

    // this.switchDescription = this.switchDescription.bind(this)
    this.updateOrderPreference = this.updateOrderPreference.bind(this)
    this.filterOnText = this.filterOnText.bind(this)
    this.showHideRules = this.showHideRules.bind(this)
    this.showMigrationStatus = this.showMigrationStatus.bind(this)
    this.lookupSubsAndOpsForProviderGroup = this.lookupSubsAndOpsForProviderGroup.bind(
      this
    )
  }

  turnSubscriptionActiveSilent(subscriptionId, activeSilent) {
    $.ajax({
      method: 'put',
      url: '/subscriptions/update',
      data: { subscription_id: subscriptionId, active_silent: activeSilent },
    })
      .done((data) => {
        this.updateSubscriptionOnRule(data.rule_id, activeSilent)
      })
      .fail((err) => {
        if (checkTimeout(err) === true) {
          redirectToLogin()
        } else {
          flash.error(
            'Error updating your subscription. Contact support@avhana.com if you need further assistance.'
          )
          console.error(err)
        }
      })
  }

  toggleStepTherapy(ruleId, orderPreference, useStepTherapy) {
    $.ajax({
      method: 'put',
      url: `/admin/order_preferences/${orderPreference.id}`,
      data: {
        order_preference: {...orderPreference, step_therapy: useStepTherapy }
      },
    })
      .done(() => {
        this.updateOrderPreference(orderPreference.id, ['step_therapy'], useStepTherapy)
      })
      .fail((err) => {
        if (checkTimeout(err) === true) {
          redirectToLogin()
        } else {
          flash.error(
            'Error updating your order preference. Contact support@avhana.com if you need further assistance.'
          )
          console.error(err)
        }
      })
  }

  switchOrderSet(
    ruleId,
    orderPreferenceId,
    switchAction,
    switchDescription,
    useOrderSet
  ) {
    $.ajax({
      method: 'put',
      url: `/root/order_preferences/${orderPreferenceId}`,
      data: {
        order_preference_id: orderPreferenceId,
        switchAction,
        switchDescription,
        options,
      },
    })
      .done(() => {
        this.updateOrderPreference(ruleId, ['order_set_id'], useOrderSet)
      })
      .fail((err) => {
        if (checkTimeout(err) === true) {
          redirectToLogin()
        } else {
          flash.error(
            'Error updating your order preference. Contact support@avhana.com if you need further assistance.'
          )
          console.error(err)
        }
      })
  }

  toggleUIActive(ruleId) {
    const { allActiveRules } = this.props
    const rule = allActiveRules.find((r) => r.id === ruleId)
    if (rule) {
      rule.uiActive = !rule.uiActive
    }
  }

  lookupSubsAndOpsForProviderGroup(event) {
    const newPgId = event.target.value
    const {
      textQuery,
      checkedDiseaseTags,
      checkedRuleSources,
      hideInactiveSubscriptions,
      showTestingRules,
    } = this.state
    this.setState({ loading: true })
    $.get(
      '/admin/rules/get_subscriptions_and_order_preferences_for_provider_group',
      {
        provider_grouping_id: newPgId,
      }
    )
      .always(() => this.setState({ loading: false }))
      .done((allRules) => {
        const rulesWithActiveSubscriptions = allRules
          .filter(
            (r) => r.subscription && r.subscription.active_silent !== 'off'
          )
          .sort(sortRules)
        // set displayedRules to all active Rules to on first load
        this.setState({
          displayedRules: rulesWithActiveSubscriptions,
          providerGroupId: newPgId,
          allRules,
        })
        this.updateRulesFilterState(
          checkedRuleSources,
          checkedDiseaseTags,
          textQuery,
          hideInactiveSubscriptions,
          showTestingRules,
        )
      })
      .fail((errors) => {
        if (checkTimeout(errors) === true) {
          redirectToLogin()
        } else {
          console.error(errors.statusText, errors.responseText)
          flash.error(
            'There was a problem. Contact support@avhana.com for further assistance.'
          )
        }
      })
  }

  updateSubscriptionOnRule(ruleId, activeSilent) {
    // update UI here
    const { allRules } = this.state
    const foundRule = allRules.find((r) => r.id === ruleId)
    foundRule.subscription.active_silent = activeSilent
    this.setState({ allRules })
  }

  updateOrderPreference(orderPreference) {
    const { allRules } = this.state
    const foundRule = allRules.find((r) => r.order_preference.id === orderPreference.id)
    try {
      const prevOrderPreference = foundRule.order_preference
      const data = JSON.stringify(orderPreference)
      foundRule.order_preference = orderPreference
      this.setState({ allRules })
      $.ajax({
        method: 'put', 
        url: `/admin/order_preferences/${orderPreference.id}`,
        dataType: 'json',
        contentType: 'application/json',
        data
      })
        .fail(() => {
          flash.error("Failed to update order preference")
          this.updateOrderPreference(prevOrderPreference)
        })
    }
    catch(e) {
      console.error('Unable to update order preference')
    }
  }

  buildFilterPanel() {
    const { sources, tags, isStaging } = this.props
    return (
      <form
        onSubmit={(event) => {
          event.preventDefault()
        }}
      >
        <div style={{ padding: '1em 0em 0em 1em' }}>
          <h6>Refine by text search</h6>
          <div style={{ padding: '0em 1em 1em 0em' }} className="input-field">
            <input
              type="text"
              placeholder="Type search text here"
              onChange={this.filterOnText}
            />
          </div>
        </div>
        <div style={{ paddingLeft: '1em' }}>
          <div className="row">
            <label htmlFor="showhideallrules">
              <input
                className="filled-in"
                type="checkbox"
                id="showhideallrules"
                data-type="showhideallrules"
                onClick={this.showHideRules}
                name="Show All Available Guidelines"
              />
              <span>Show Inactive Guidelines</span>
            </label>
          </div>
        </div>
        {isStaging && (
        <div style={{ paddingLeft: '1em' }}>
          <div className="row">
            <label htmlFor="testingstatus">
              <input
                className="filled-in"
                type="checkbox"
                id="testingstatus"
                data-type="testingstatus"
                onClick={this.showMigrationStatus}
                name="Show All Testing Guidelines"
              />
              <span>Show Testing Guidelines</span>
            </label>
          </div>
        </div>
        )}
        <div style={{ paddingLeft: '1em' }}>
          <h6>Refine by Tags</h6>
          <div className="row">
            <ExpandableCheckboxList
              list={tags}
              uniqueNameKey="disease"
              numberVisible={5}
              filterOnCheck={this.filterOnCheck}
            />
          </div>
        </div>
        <hr style={{ margin: '1.5em 1em 1.5em 0em' }} />
        <div style={{ paddingLeft: '1em' }}>
          <h6>Refine by Rule Source</h6>
          <div className="row">
            <ExpandableCheckboxList
              list={sources}
              uniqueNameKey="source"
              numberVisible={5}
              filterOnCheck={this.filterOnCheck}
            />
          </div>
        </div>
      </form>
    )
  }

  filterOnText(event) {
    const {
      checkedDiseaseTags,
      checkedRuleSources,
      hideInactiveSubscriptions,
      showTestingRules,
    } = this.state
    // need to use this to access these vars within setTimeout scope
    this.text = event.target.value

    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.updateRulesFilterState(
        checkedRuleSources,
        checkedDiseaseTags,
        this.text,
        hideInactiveSubscriptions,
        showTestingRules,
      )
    }, 300)
  }

  filterOnCheck(event) {
    const { hideInactiveSubscriptions, textQuery, showTestingRules} = this.state
    let {
      checkedRuleSources: ruleSourceFilters,
      checkedDiseaseTags: diseaseTagFilters,
    } = this.state
    const checkName = event.target.name
    const checkType = event.target.dataset.type

    if (event.target.checked && checkType === 'source') {
      ruleSourceFilters.push(checkName)
    } else if (event.target.checked && checkType === 'disease') {
      diseaseTagFilters.push(checkName)
    } else if (!event.target.checked && checkType === 'source') {
      ruleSourceFilters = _.reject(ruleSourceFilters, (f) => f === checkName)
    } else if (!event.target.checked && checkType === 'disease') {
      diseaseTagFilters = _.reject(diseaseTagFilters, (f) => f === checkName)
    }

    this.updateRulesFilterState(
      ruleSourceFilters,
      diseaseTagFilters,
      textQuery,
      hideInactiveSubscriptions,
      showTestingRules,
    )
  }

  showHideRules(event) {
    const { textQuery, checkedDiseaseTags, checkedRuleSources, showTestingRules} = this.state
    const hideInactiveSubscriptions = !event.target.checked
    this.setState({ hideInactiveSubscriptions })
    this.updateRulesFilterState(
      checkedRuleSources,
      checkedDiseaseTags,
      textQuery,
      hideInactiveSubscriptions,
      showTestingRules,
    )
  }

  showMigrationStatus(event) {
    const { textQuery, checkedDiseaseTags, checkedRuleSources, hideInactiveSubscriptions } = this.state
    const showTestingRules = !event.target.checked
    this.setState({ showTestingRules })
    this.updateRulesFilterState(
      checkedRuleSources,
      checkedDiseaseTags,
      textQuery,
      hideInactiveSubscriptions,
      showTestingRules
    )
  }

  updateRulesFilterState(
    ruleSourceFilters,
    diseaseTagFilters,
    textQuery,
    hideInactiveSubscriptions,
    showTestingRules
  ) {
    const { allRules } = this.state

    const displayedRules = allRules.filter((rule) =>
      filterFunction(
        rule,
        textQuery,
        diseaseTagFilters,
        ruleSourceFilters,
        hideInactiveSubscriptions,
        showTestingRules
      )
    )

    this.setState({
      displayedRules,
      checkedRuleSources: ruleSourceFilters,
      checkedDiseaseTags: diseaseTagFilters,
      textQuery,
    })
  }

  render() {
    const { providerGroups, orgId, emr, pgId } = this.props
    const { displayedRules, loading } = this.state
    const facetFilter = this.buildFilterPanel()
    let rulesBox
    const displayedRulesCount = displayedRules.length

    const sortedProviderGroups = providerGroups.reduce((a, b) => {
      if (b.id === pgId) {
        return [b, ...a]
      }
      return [...a, b]
    }, [])

    const providerGroupOptions = sortedProviderGroups.map((group) => (
      <option key={group.id} value={group.id}>
        {group.name}
      </option>
    ))

    if (loading) {
      rulesBox = <LoadingSkeleton />
    } else {
      const rulesWithHeadings = displayedRules

      rulesBox = (
        <RulesList
          orgId={orgId}
          emr={emr}
          displayedRules={rulesWithHeadings}
          turnSubscriptionActiveSilent={this.turnSubscriptionActiveSilent}
          updateOrderPreference={this.updateOrderPreference}
          category="Previsit"
          toggleStepTherapy={this.toggleStepTherapy}
        />
      )
    }

    return (
      <div className="row">
        <div
          className="appointments-col col s12 m3"
          style={{ height: '100vh', overflowY: 'scroll' }}
        >
          {facetFilter}
        </div>
        <div
          className="subscriptions-container col s8 offset-s3"
          style={{
            width: '71%',
            padding: '3em',
            height: '100vh',
            overflowY: 'scroll',
          }}
        >
          <div className="row">
            <div className="col s4">
              <label htmlFor="providerGroupSelect">Provider Group</label>
              <select
                id="providerGroupSelect"
                className="browser-default"
                onChange={this.lookupSubsAndOpsForProviderGroup}
                defaultValue="Choose a provider group"
              >
                {providerGroupOptions}
              </select>
            </div>
          </div>
          <div className="row" style={{ paddingBottom: '1em' }}>
            <div className="col s12">
              <span style={{ marginLeft: '10px', fontSize: '16px' }}>
                Showing guidelines for:
              </span>
              {createCriteriaChips}
            </div>
            <div
              className="col s12"
              style={{
                marginLeft: '12px',
                fontSize: '16px',
                marginTop: '1.5em',
              }}
            >
              <b>
                {`${displayedRulesCount} ${pluralize(
                  'guideline',
                  displayedRulesCount
                )}`}
              </b>
              {` ${pluralize('meet', displayedRulesCount)} this criteria.`}
            </div>
          </div>
          {rulesBox}
        </div>
      </div>
    )
  }
}

RulesFilter.propTypes = propTypes
RulesFilter.defaultProps = defaultProps
