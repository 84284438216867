import React from 'react'
import PropTypes from 'prop-types'

/**
 * component to render markdown
 * takes in prop of type string that has markdown and coverts it to html
 */

const Asa = (props) => {
  let text = ''
  let generalBleeding = null

  if (props.options.form_info.sex === 'Female') {
    text = (
      <div data-testid="sex-text" className="asaText">
        The U.S. Preventive Services Task Force (USPSTF) recommends the use of aspirin for
        the primary prevention of cardiovascular disease (CVD) when a net benefit is present.
        A net benefit means that the potential benefit from taking aspirin outweighs the harms,
        mainly gastrointestinal (GI) bleeding. Specifically: Aspirin is recommended for women
        age 55-79 to reduce risk of ischemic stroke when a net benefit is present.
      </div>
    )
  } else {
    text = (
      <div data-testid="sex-text" className="asaText">
        The U.S. Preventive Services Task Force (USPSTF) recommends the use of aspirin for
        the primary prevention of cardiovascular disease (CVD) for men when a net benefit is
        present. A net benefit means that the potential benefit from taking aspirin outweighs
        the harms, mainly gastrointestinal (GI) bleeding. Specifically: Aspirin is recommended
        for men age 45-79 to reduce risk of myocardial infarction (MI) when a net benefit is
        present.
      </div>
    )
  }

  if (props.options.form_info.name != null) {
    generalBleeding = (
      <div className="generalBleedingText">
        <br />
        <div>
          Patient has <b data-testid="name">{props.options.form_info.name}</b> that may
          effect the use of aspirin.
        </div>
      </div>
    )
  }

  return (
    <div className="row">
      <div className="col s12">
        {generalBleeding}
        <br />
        {text}
      </div>
    </div>
  )
}

Asa.propTypes = {
  options: PropTypes.shape({
    form_info: PropTypes.shape({
      sex: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
}

export default Asa
