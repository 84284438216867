import React, { useState, useRef } from 'react' 
import PropTypes from 'prop-types' 

const propTypes = {
  orderPreference: PropTypes.object, // Pass the specific order preference object
  updateOrderPreferences: PropTypes.func.isRequired,
  orderIndex: PropTypes.object,
} 

const EditOrderPreferenceButton = ({ orderPreference, updateOrderPreferences, orderIndex }) => {
  const [editing, setEditing] = useState(false) 
  const [facilityId, setFacilityId] = useState(orderPreference?.options?.facilityId || '') 
  const [noteToLab, setNoteToLab] = useState(orderPreference?.options?.noteToLab || '') 

  const facilityIdInput = useRef(null) 
  const noteToLabInput = useRef(null) 

  const handleEditClick = () => {
    setEditing(true) 
  } 

  const handleSaveClick = () => {
    
    const updatedOrder = {
      ...orderPreference.options.orders[orderIndex],
      facility_id: facilityId,
      note_to_lab: noteToLab
    }

    const updatedOrders = [
      ...orderPreference.options.orders
    ]
    updatedOrders[orderIndex] = updatedOrder
    const updatedOptions = {
      ...orderPreference.options,
      orders: updatedOrders
    }
    
    updateOrderPreferences(updatedOptions) 

    setEditing(true) 
  } 

  return (
    <div>
      {editing ? (
        <div>
          <input
            type="text"
            placeholder="Facility ID"
            value={facilityId}
            onChange={(e) => setFacilityId(e.target.value)}
            ref={facilityIdInput}
          />
          <input
            type="text"
            placeholder="Note to Lab"
            value={noteToLab}
            onChange={(e) => setNoteToLab(e.target.value)}
            ref={noteToLabInput}
          />
          <button onClick={handleSaveClick}>Save</button>
        </div>
      ) : (
        <button onClick={handleEditClick}>Edit Order Preference</button>
      )}
    </div>
  ) 
} 

EditOrderPreferenceButton.propTypes = propTypes 

export default EditOrderPreferenceButton 
