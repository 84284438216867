window.checkTimeout = function checkTimeout(json) {
  if(json && json.status === 440){
    return true
  } else {
    return false
  }
}

window.redirectToLogin = function redirectToLogin() {
  document.location.assign('/login')
  flash.notice(
    'Your previous session has expired, please log back in to continue.')
}


window.handleErrorOrRedirectToLogin = function handleErrorOrRedirectToLogin(error, context) {
  if (checkTimeout(error) === true) {
    redirectToLogin()
  } else {
    flash.error('An unknown error occurred. If you continue to have problems, contact support@avhana.com')
    console.error(error)
  }
}
