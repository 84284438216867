import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import MUITable from '@mui/material/Table'
import Header from './Header'
import Body from './Body'

const Table = ({ data, setData, notProduction}) => (
  <>
    <TableContainer>
      <MUITable>
        <Header notProduction={notProduction} />
        <Body data={data} setData={setData} notProduction={notProduction} />
      </MUITable>
    </TableContainer>
  </>
)

export default Table
