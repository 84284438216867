import M from 'materialize-css'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import DashboardDiagnosisList from '../recommendations/DashboardDiagnosisList'
import MiniChart from '../miniChart/miniChart'
import Notes from '../notes/Notes'
import RecommendationsList from '../recommendations/RecommendationsList'
import { prop } from 'ramda'
import { OrdersDisabledEmr } from '../athenaTypes'

const formatHeader = ({ patient }) => {
  const formattedDate = moment(patient.birthdate).format('MM/DD/YYYY')
  let header = ''
  header += patient.full_name || ''
  header += patient.preferred_name ? ` "${patient.preferred_name}"` : ''
  header += formattedDate ? ` (${formattedDate})` : ''
  return header
}

const propTypes = {
  activeAppointment: PropTypes.shape({
    planned_diagnoses: PropTypes.arrayOf(PropTypes.shape({})),
    past_encounter: PropTypes.shape({
      details: PropTypes.shape({
        encounterdate: PropTypes.string,
        providerlastname: PropTypes.string,
      }),
    }),
  }).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  emr: PropTypes.string.isRequired,
  emrDepartmentId: PropTypes.string.isRequired,
  emrPatientId: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    pull_documentation_flag: PropTypes.bool,
  }).isRequired,
  organizationId: PropTypes.number.isRequired,
  patientSearchPage: PropTypes.bool,
  prebuilt: PropTypes.bool.isRequired,
  setUpdatedActiveAppointment: PropTypes.func.isRequired,
  updateActiveAppointment: PropTypes.func.isRequired,
}

const defaultProps = {
  patientSearchPage: false,
}

export default class PatientBox extends React.Component {
  constructor(props) {
    super(props)

    const { patientSearchPage, activeAppointment } = props
    const initialActiveTab = 'Recommendations'

    const plannedDiagnoses = !patientSearchPage
      ? activeAppointment.planned_diagnoses
      : []

    this.state = {
      activeTab: initialActiveTab,
      plannedDiagnosesList: plannedDiagnoses,
      loading: true,
      header: '',
      recsList: [],
      patients: {},
      encounter: {},
      appointment: {},
    }

    this.markIfActive = this.markIfActive.bind(this)
    this.onTabClick = this.onTabClick.bind(this)
    this.updatePlannedDiagnosis = this.updatePlannedDiagnosis.bind(this)
    this.updateRecsList = this.updateRecsList.bind(this)
    this.updateRecStatus = this.updateRecStatus.bind(this)
    this.addRecToRecsList = this.addRecToRecsList.bind(this)
    this.removeRecommendation = this.removeRecommendation.bind(this)
    this.updateEncounter = this.updateEncounter.bind(this)
    this.updateOptions = this.updateOptions.bind(this)
    this.undoOrder = this.undoOrder.bind(this)
    this.undoOrders = this.undoOrders.bind(this)
    this.pullPreviousDocumentation = this.pullPreviousDocumentation.bind(this)
  }

  componentDidMount() {
    this.getData(this.props)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { emrPatientId, patientSearchPage } = this.props
    const newPatient = newProps.emrPatientId !== emrPatientId
    // otherwise will make patients call any time there are updates upstream
    if (newPatient) {
      this.setState({ loading: true, header: '' })
      this.getData(newProps)
    }

    if (!patientSearchPage) {
      const plannedDiagnoses = newProps.activeAppointment.planned_diagnoses
      this.setState({ plannedDiagnosesList: plannedDiagnoses })
    }
  }

  componentDidUpdate() {
    // initialize materializecss javascript
    const collapsibleElements = document.querySelectorAll('.collapsible')
    if (collapsibleElements) {
      M.Collapsible.init(collapsibleElements, {})
    }
    const tooltipElements = document.querySelectorAll('.tooltipped')
    if (tooltipElements) {
      M.Tooltip.init(tooltipElements, { delay: 50 })
    }

    // Check that header matches currently selected user, not the last one that responded.
    const { emrPatientId } = this.props
    const { patients } = this.state
    const patient = patients[emrPatientId]

    if (!patient) {
      this.getData(this.props)
    }
  }

  onTabClick(event) {
    const clickedTab = event.target.textContent
    this.setState({ activeTab: clickedTab })
  }

  getData(props) {
    $.get('/recommendations/recommend', {
      organization_id: props.organizationId,
      emr_patient_id: props.emrPatientId,
      emr_department_id: props.emrDepartmentId,
      prebuilt: props.prebuilt,
    })
      .done((data) => {
        const recsListData = data.recs.map((rec) => ({
          ...rec,
          checked: !(
            rec.options &&
            rec.options.orders &&
            rec.options.orders[0].outstanding_order
          ),
        }))

        const { patients } = this.state
        this.setState({
          recsList: recsListData,
          patients: { ...patients, [props.emrPatientId]: data.patient },
          appointment: data.appointment,
          header: formatHeader(data),
          encounter: data.encounter,
        })
      })
      .always(() => this.setState({ loading: false }))
      .fail((errors) => {
        if (checkTimeout(errors) === true) {
          redirectToLogin()
        } else {
          if (typeof errors === 'object') errors = JSON.stringify(errors)
          console.error(errors, { context: 'Recommendations failed to load' })
          this.setState({ header: 'Recommendations failed to load.' })
          flash.error(
            'There was a problem loading recommendations. Contact support@avhana.com for further assistance.'
          )
        }
      })
  }

  markIfActive(tabName) {
    const { activeTab } = this.state
    return tabName === activeTab ? ' active' : ''
  }

  pullPreviousDocumentation(activeAppointment) {
    const { setUpdatedActiveAppointment } = this.props
    $.get(`/appointment/${activeAppointment.id}/pull_previous_documentation`)
      .done(({ appointment }) => {
        if (appointment) {
          setUpdatedActiveAppointment(appointment)
        } else {
          flash.error(
            'There was an error pulling previous documentation, contact support@avhana.com'
          )
        }
      })
      .fail((error) =>
        window.handleErrorOrRedirectToLogin(
          error,
          'Pull previous documentation error'
        )
      )
  }

  updatePlannedDiagnosis(plannedDiagnosis) {
    const { plannedDiagnosesList } = this.state
    const plannedDx = plannedDiagnosesList.find(
      (dx) => dx.id === plannedDiagnosis.id
    )
    if (plannedDx) {
      Object.assign(plannedDx, plannedDiagnosis)
    }
    this.setState({ plannedDiagnosesList })
  }

  updateRecsList(recsList) {
    this.setState({ recsList })
  }

  updateRecStatus(recId, newStatus) {
    const { recsList } = this.state
    const recommendation = recsList.find((rec) => rec.id === recId)
    recommendation.status = newStatus
    recommendation.updated_at = moment()
    this.setState({ recsList })
  }

  addRecToRecsList(rec) {
    const { recsList } = this.state
    this.setState({ recsList: [...recsList, rec] })
  }

  removeRecommendation(recId) {
    // remove tooltips and reinitialize them in componentDidUpdate
    // this avoids unexepcted behavior where tooltip lingers after click
    const elems = document.querySelectorAll('.tooltipped')
    for (let i = 0; i < elems.length; i += 1) {
      const instance = M.Tooltip.getInstance(elems[i])
      instance.close()
    }
    const { recsList } = this.state
    const updatedRecsList = recsList.filter((r) => r.id !== recId)
    this.setState({ recsList: updatedRecsList })
  }

  updateEncounter(encounter) {
    this.setState({ encounter })
  }

  updateOptions(recId, newOptions) {
    const { recsList } = this.state
    if (recId) {
      const newRecsList = recsList.slice()

      // find rec
      const rec = recsList.find((r) => r.id === recId)

      // Doing extend so we update rather than set
      rec.options = { ...rec.options, ...newOptions }

      // update state with new order preference
      this.setState({ recsList: newRecsList })
    } else {
      console.error('Order Preference Change Error', {
        context: 'RecList error changing order preference message',
      })
      flash.error(
        'There was a problem changing order preferences Contact support@avhana.com for further assistance.'
      )
    }
  }

  undoOrders(recommendations) {
    recommendations.forEach((rec) => this.undoOrder(rec))
  }

  undoOrder(recommendation) {
    const recId = parseInt(recommendation.id, 10)
    $.ajax({
      url: `/recommendations/undo_order/${recId}`,
      method: 'PUT',
      success: (response) => {
        if (response.action === 'update') {
          this.updateRecStatus(recId, 'pending')
        } else {
          this.removeRecommendation(recId)
        }
      },
    })
  }

  render() {
    const {
      activeAppointment,
      currentUser,
      emr,
      emrDepartmentId,
      emrPatientId,
      organization,
      organizationId,
      prebuilt,
      patientSearchPage,
      setUpdatedActiveAppointment,
      updateActiveAppointment,
    } = this.props
    const {
      appointment,
      encounter,
      header,
      loading,
      patients,
      plannedDiagnosesList,
      recsList,
    } = this.state
    const patient = patients[emrPatientId]
    let recommendations
    let problemsAndNotes
    let miniChart

    if (emrPatientId) {
      // set up Recommendations tab panel as recommendations
      const queuedRecsList = _.chain(recsList)
        .partition({ rule_type: 'score' })
        .nth(1)
        .partition({ status: 'order queued' })
        .nth(0)
        .value()

      const sentRecsList = recsList.filter(
        (r) => r.status === 'order successful'
      )

      const preVisitDashboard = (
        emr === 'athena'
      )

      recommendations = patient ? (
        <RecommendationsList
          activeAppointment={activeAppointment}
          emr={emr}
          emrPatientId={emrPatientId}
          emrDepartmentId={emrDepartmentId}
          organizationId={organizationId}
          prebuilt={prebuilt}
          patientSearchPage={patientSearchPage}
          loading={loading}
          header={header}
          recsList={recsList}
          patient={patient}
          encounter={encounter}
          appointment={appointment}
          updateRecsList={this.updateRecsList}
          updateRecStatus={this.updateRecStatus}
          addRecToRecsList={this.addRecToRecsList}
          removeRecommendation={this.removeRecommendation}
          updateEncounter={this.updateEncounter}
          updateOptions={this.updateOptions}
          undoOrder={this.undoOrder}
          currentUser={currentUser}
          {...this.props}
        />
      ) : null


      // set up Problems & Notes tab panel as problemsAndNotes
      let pullDocumentationButton
      if (activeAppointment) {
        const pastEncounter = activeAppointment.past_encounter
        const pullDocumentationFeatureFlag =
          organization.pull_documentation_flag
        if (
          pastEncounter &&
          pastEncounter.details &&
          pullDocumentationFeatureFlag
        ) {
          const currentEncounter = encounter

          const encounterDate = pastEncounter.details.encounterdate
          const providerLastName = pastEncounter.details.providerlastname
          const pullDocumentationButtonText = `Pull previous documentation (${encounterDate}, ${providerLastName})`

          pullDocumentationButton = (
            <div id="pull-prev-doc-button" className="col s12 right-align">
              <button
                type="button"
                className="waves-effect waves-light btn blue-bkg multi-order-button"
                onClick={() => {
                  this.pullPreviousDocumentation(activeAppointment)
                }}
                disabled={!!currentEncounter}
              >
                {pullDocumentationButtonText}
              </button>
            </div>
          )
        }
      }

      let dashboardDiagnosisList
      if (activeAppointment && patient) {
        dashboardDiagnosisList = (
          <DashboardDiagnosisList
            plannedDiagnosesList={activeAppointment.planned_diagnoses}
            queuedOrdersList={queuedRecsList}
            activeAppointment={activeAppointment}
            updateActiveAppointment={updateActiveAppointment}
            // props below are all needed to render queued order <Recommendation />
            emr={emr}
            patient={patient}
            encounter={encounter}
            updatePlannedDiagnosis={this.updatePlannedDiagnosis}
            updateOptions={this.updateOptions}
            undoOrder={this.undoOrder}
            updateRecStatus={this.updateRecStatus}
            removeRecommendation={this.removeRecommendation}
            addRecToRecsList={this.addRecToRecsList} // also used in <OrderAnythingForDiagnosis />
            currentUser={currentUser}
          />
        )
      }
      if (preVisitDashboard) {
        problemsAndNotes = (
          <div
            className="problems-notes-container"
            style={{ paddingRight: '1.5%' }}
          >
            {pullDocumentationButton}
            {dashboardDiagnosisList}
            <Notes
              activeAppointment={activeAppointment}
              currentUser={currentUser}
              setUpdatedActiveAppointment={setUpdatedActiveAppointment}
            />
          </div>
        )
      } else {
        problemsAndNotes = (
          <div
            className="problems-notes-container"
            style={{ paddingRight: '5%' }}
          >
            {pullDocumentationButton}
            {dashboardDiagnosisList}
          </div>
        )
      }

      // set up MiniChart side bar as miniChart
      if (preVisitDashboard) {
        const queuedPlannedDiagnosesList = plannedDiagnosesList.filter(
          (plannedDiagnosis) => plannedDiagnosis.status === 'diagnosis queued'
        )

        miniChart = (
          <MiniChart
            activeAppointment={activeAppointment}
            todayOpenEncounter={encounter}
            emrPatientId={emrPatientId}
            emrDepartmentId={emrDepartmentId}
            organizationId={organizationId}
            queuedPlannedDiagnosesList={queuedPlannedDiagnosesList}
            queuedRecsList={queuedRecsList}
            sentRecsList={sentRecsList}
            updateRecStatus={this.updateRecStatus}
            removeRecommendation={this.removeRecommendation}
            undoOrders={this.undoOrders}
            patientSearchPage={patientSearchPage}
          />
        )
      }
    }

    // for patientSearchPage only show recs and queued orders
    if (patientSearchPage) {
      if (emr === 'athena') {
        return (
          <div className="row patient-box">
            <div
              className="col recommendation-box-pt-search"
              style={{ padding: '0 0 0 1.5%' }}
            >
              <div
                className="row recommendations-list"
                style={{ height: '100vh', overflowY: 'scroll', padding: '2%' }}
              >
                <div>{recommendations}</div>
              </div>
            </div>
            <div
              className="col offset-s8 hide-on-small-only fixed-mini-chart-column-pt-search"
              style={{ zIndex: '100' }}
            >
              {miniChart}
            </div>
          </div>
        )
      } else {
        return (
          <div className="row patient-box">
            <div
              className="col recommendation-box col s12"
            >
              <div
                className="row recommendations-list col s12"
                style={{ height: '100vh', padding: '4%' }}
              >
                <div>{recommendations}</div>
              </div>
            </div>
          </div>
        )
      }
    }

    let screenExtended = 'recommendation-box col s9 m7 offset-m2'
    if (OrdersDisabledEmr.includes(emr)) {
      screenExtended = 'recommendation-box col s10 offset-m2'
    }


    // for pre visit planning dashboard
    return (
      <div className="patient-box row">
        <div
          className={screenExtended}
          style={{
            zIndex: '1000',
            padding: '0 1%',
            position: 'fixed',
            marginRight: '1rem',
          }}
        >
          <div className="patient-header" style={{ height: '4rem' }}>
            <h5
              className="patient-name-birthday"
              style={{ margin: '0', padding: '1rem', textAlign: 'center' }}
            >
              {header}
            </h5>
          </div>
          <div className="tabs-container">
            <div
              className="tab-nav"
              style={{
                borderBottom: '0px',
                backgroundColor: '#eceff1',
                paddingRight: '5%',
              }}
            >
              <div className="tab-nav-items row">
                <div
                  role="tab"
                  tabIndex="0"
                  className={`col s6 tab-nav-item previsit-tabs recs-tab ${this.markIfActive(
                    'Recommendations'
                  )}`}
                  onClick={this.onTabClick}
                  onKeyDown={() => null}
                >
                  <h6 className="center">Recommendations</h6>
                </div>
                <div
                  role="tab"
                  tabIndex="0"
                  className={`col s6 tab-nav-item previsit-tabs doc-tab ${this.markIfActive(
                    'Problems & Notes'
                  )}`}
                  onClick={this.onTabClick}
                  onKeyDown={() => null}
                >
                  <h6 className="center">Problems & Notes</h6>
                </div>
              </div>
            </div>
            <div className="tab-panels">
              <div
                className={`tab-panel row recommendations-list ${this.markIfActive(
                  'Recommendations'
                )}`}
                style={{
                  height: '100vh',
                  overflowY: 'scroll',
                  padding: '1rem 0 10rem 0px',
                  paddingRight: `${emr === 'athena' ? null : '5%'}`
                }}
              >
                {recommendations}
              </div>
              <div
                className={`tab-panel row problems-plan ${this.markIfActive(
                  'Problems & Notes'
                )}`}
                style={{
                  height: '100vh',
                  overflowY: 'scroll',
                  padding: '1rem 0 10rem 0px',
                }}
              >
                {problemsAndNotes}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col m3 offset-m9 hide-on-small-only fixed-mini-chart-column"
          style={{ zIndex: '100' }}
        >
          {miniChart}
        </div>
      </div>
    )
  }
}

PatientBox.propTypes = propTypes
PatientBox.defaultProps = defaultProps

// mountReact is only called to mount PatientBox to the patient search page
// PatientBox is mounted via PreVisitDashboard component for our home page
const mountReact = () => {
  const reactNode = document.getElementById('render-recommendations-react')
  const reactData = $(reactNode).data()
  if (reactData) {
    ReactDOM.render(
      <PatientBox
        emr={reactData.emr}
        emrPatientId={reactData.emrpatientid}
        emrDepartmentId={reactData.emrdepartmentid}
        organization={reactData.organization.organization}
        organizationId={reactData.organization.organization.id}
        currentUser={reactData.currentuser.user}
        patientSearchPage
      />,
      reactNode
    )
  }
}

$(mountReact)
