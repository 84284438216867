import React from 'react'
import ReactDOM from 'react-dom'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import ListItemIcon from "@mui/material/ListItemIcon"
import { Help, Settings, Logout} from "@mui/icons-material"
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import setCurrentUser from '../helperFunctions/setCurrentUser'

const StyledButton = styled(Button)({
  '&:focus': {
    outline: 'none',
    backgroundColor: 'transparent',
  },
})

const UserMenu = ({ currentUserInitials, currentUserId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const menuButton = <div role="button" className="initials">{currentUserInitials}</div>

  return (
    <div className="user-bubble">
      <Box sx={{ minWidth: 0 }}>
        <StyledButton 
          id="basic-button"
          onClick={handleClick}
          style={{ padding: '0px', fontSize: '1rem'}}
        >
          {menuButton}
        </StyledButton>
      </Box>
      <Menu
        role="menu"
        anchorEl={anchorEl}
        id="account-menu"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          role="menuitem"
          style={{ cursor: 'pointer' }}
          id="Help"
          component="a"
          href="http://help.avhana.com/avhana-support"
        >
        <ListItemIcon>
          <Help fontSize="small" />
        </ListItemIcon>
        Help
      </MenuItem>
        <MenuItem 
          role="menuitem"
          style={{ cursor: 'pointer' }}
          id="Account-Settings"
          component="a"
          href={`/users/${currentUserId}/edit`}
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Account Settings
        </MenuItem>
        <MenuItem 
          role="menuitem"
          style={{ cursor: 'pointer' }}
          component="a"
          href= '/logout'
          id="Sign-Out"
          rel="nofollow"
          data-method="delete"
          >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  )
}

UserMenu.propTypes = {
  currentUserInitials: PropTypes.string.isRequired,
  currentUserId: PropTypes.number.isRequired,
}

const mountReact = () => {
  // config user data for menu
  const reactNode = document.getElementById('sign-out-icon-menu')
  
  const reactData = $(reactNode).data()
  // set CurrentUser as global
  window.CurrentUser = setCurrentUser(reactData)

  if (reactData) {
    ReactDOM.render(
      <UserMenu

        currentUserId={reactData.currentuserid}
        currentUserInitials={reactData.currentuserinitials}
      />,
      reactNode
    )
  }
}

$(mountReact)
