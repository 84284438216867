import React from 'react'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

const Body = ({ data }) => 
   (
    <>
    <TableBody>
      {data.map((row) => (
        <TableRow key={row.description}>
          <TableCell align="left">{row.description}</TableCell>
          <TableCell align="left">{row.code}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </>
  )

export default Body
