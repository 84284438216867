import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ascend, sortWith, prop } from 'ramda'

import RulesList from './avhanaRulesList'
import { Rules, Tags, Sources } from '../athenaTypes'
import { bool } from 'prop-types'
import ExpandableCheckboxList from '../reusableComponents/expandableCheckboxList'

const UUIDV4_REGEXP = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

// validate prop types
const propTypes = {
  rules: Rules.isRequired,
  tags: Tags.isRequired,
  sources: Sources.isRequired,
  isStaging: bool.isRequired,
}

// function to create JSX for filter criteria chips on top of page
const createCriteriaChips = (state) => {
  const ruleSourcesCriteria =
    state.checkedRuleSources.length > 0
      ? state.checkedRuleSources
      : ['All Rule Sources']
  const diseaseTagCriteria =
    state.checkedDiseaseTags.length > 0
      ? state.checkedDiseaseTags
      : ['All Tags']
  const textCriteria =
    state.textQuery.length > 0 ? [`Contains text '${state.textQuery}'`] : []

  const criteria = ruleSourcesCriteria.concat(diseaseTagCriteria, textCriteria)

  const criteriaChips = _.map(criteria, (name, i) => (
    <div
      key={i}
      className="chip"
      style={{
        marginLeft: '5px',
        fontSize: '14px',
        color: 'white',
        backgroundColor: '#2f91fa',
        fontWeight: '300',
      }}
    >
      {name}
    </div>
  ))

  return criteriaChips
}

/* eslint-disable import/prefer-default-export */
export const filterFunction = (
  rule,
  textQuery,
  diseaseTagFilters,
  ruleSourceFilters
) => {
  let diseaseTagFiltersPresentOnCurrentRule
  let ruleSourceFiltersPresentOnCurrentRule

  // filter rule source if some are selected otherwise display all
  if (ruleSourceFilters.length > 0) {
    ruleSourceFiltersPresentOnCurrentRule = ruleSourceFilters.includes(
      rule.source
    )
  } else {
    ruleSourceFiltersPresentOnCurrentRule = true
  }

  // filter disease tags if some are selected otherwise display all
  if (diseaseTagFilters.length > 0) {
    const tagsOnThisRule = rule.tags.map((t) => t.name)
    diseaseTagFiltersPresentOnCurrentRule =
      _.intersection(diseaseTagFilters, tagsOnThisRule).length > 0
  } else {
    diseaseTagFiltersPresentOnCurrentRule = true
  }

  // filter on text present in rule description
  const lowerCaseTextQuery = textQuery.toLowerCase().trim()

  const textQueryOnCurrentRule =
    (rule.description &&
      rule.description.toLowerCase().includes(lowerCaseTextQuery)) ||
    (rule.name && rule.name.toLowerCase().includes(lowerCaseTextQuery)) ||
    (rule.default_display_code &&
      rule.default_display_code.toLowerCase().includes(lowerCaseTextQuery)) ||
    (UUIDV4_REGEXP.test(textQuery) &&
      rule.uuid.toLowerCase() === lowerCaseTextQuery)

  return (
    ruleSourceFiltersPresentOnCurrentRule &&
    diseaseTagFiltersPresentOnCurrentRule &&
    textQueryOnCurrentRule
  )
}

const sortRules = sortWith([
  ascend(prop('default_display_code')),
  ascend(prop('name')),
])

// React RulesFilter class
class RulesFilter extends Component {
  /**
   * @prop rules -> array of objects, all Rule records
   * @prop tags -> all tags
   */

  constructor(props) {
    super(props)
    this.state = {
      displayedRules: [],
      allRules: [],
      checkedRuleSources: [],
      checkedDiseaseTags: [],
      textQuery: '',
      showAllRulesSoures: false,
      showAllDiseaseTags: false,
    }

    this.filterOnCheck = this.filterOnCheck.bind(this)
    this.turnRuleOnOff = this.turnRuleOnOff.bind(this)
    this.toggleMigrationStatus = this.toggleMigrationStatus.bind(this)
    this.filterOnText = this.filterOnText.bind(this)
  }

  componentDidMount() {
    const { rules } = this.props
    // set displayedRules to all rules on first load
    this.setState({
      displayedRules: sortRules(rules),
      allRules: rules,
    })
  }

  turnRuleOnOff(ruleId, active) {
    $.ajax({
      method: 'put',
      url: `/root/rules/${ruleId}/turn_on_off_rule`,
      data: { active },
    })
      .done(() => {
        const { allRules } = this.state
        const foundRule = allRules.find(r => r.id === ruleId)
        try {
          foundRule.active = active
          this.setState({ allRules })
        } catch (e) {
          console.error("Unable to update rule active status")
        }
      })
      .fail((err) => {
        if (checkTimeout(err) === true) {
          redirectToLogin()
        } else {
          flash.error(
            'Error updating your rule. Contact support@avhana.com if you need further assistance.'
          )
          console.error(err)
        }
      })
  }

  toggleMigrationStatus(ruleId) {
    $.ajax({
      method: 'post',
      url: `/root/rules/${ruleId}/toggle_ready`,
    })
    .done(() => {
      const { allRules } = this.state
      const foundRule = allRules.find((r) => r.id === ruleId)
      try {
        foundRule.migration_status = "ready"
        this.setState({ allRules })
      } catch(e) {
        console.error('Unable to toggle migration status')
      }
    })
    .fail((err) => {
      if (checkTimeout(err) === true) {
        redirectToLogin()
      } else {
        flash.error(
          'Error updating your migration status. Contact support@avhana.com if you need further assistance.'
        )
        console.error(err)
      }
    })
  }

  buildFilterPanel() {
    const { sources, tags } = this.props
    const tagNames = _.map(tags, (t) => t.name)
    return (
      <form
        onSubmit={(event) => {
          event.preventDefault()
        }}
      >
        <div style={{ padding: '1em 0em 0em 1em' }}>
          <h6>Refine by text search</h6>
          <div style={{ padding: '0em 1em 1em 0em' }} className="input-field">
            <input
              type="text"
              placeholder="Type search text here"
              onChange={this.filterOnText}
            />
          </div>
        </div>
        <div style={{ paddingLeft: '1em' }}>
          <h6>Refine by Tags</h6>
          <div className="row">
            <ExpandableCheckboxList
              list={tagNames}
              uniqueNameKey="disease"
              numberVisible={5}
              filterOnCheck={this.filterOnCheck}
            />
          </div>
        </div>
        <hr style={{ margin: '1.5em 1em 1.5em 0em' }} />
        <div style={{ paddingLeft: '1em' }}>
          <h6>Refine by Rule Source</h6>
          <div className="row">
            <ExpandableCheckboxList
              list={sources}
              uniqueNameKey="source"
              numberVisible={5}
              filterOnCheck={this.filterOnCheck}
            />
          </div>
        </div>
      </form>
    )
  }

  filterOnText(event) {
    const { checkedDiseaseTags, checkedRuleSources } = this.state
    // need to use this to access these vars within setTimeout scope
    this.text = event.target.value

    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.updateRulesFilterState(
        checkedDiseaseTags,
        checkedRuleSources,
        this.text
      )
    }, 300)
  }

  filterOnCheck(event) {
    const { checkedDiseaseTags, checkedRuleSources, textQuery } = this.state
    const checkName = event.target.name
    const checkType = event.target.dataset.type

    let ruleSourceFilters = checkedRuleSources
    let diseaseTagFilters = checkedDiseaseTags

    if (event.target.checked) {
      if (checkType === 'source') {
        ruleSourceFilters.push(checkName) // handle sources
      } else if (checkType === 'disease') {
        diseaseTagFilters.push(checkName) // handle disease tags
      }
    } else if (checkType === 'source') {
      ruleSourceFilters = _.reject(ruleSourceFilters, (f) => f === checkName)
    } else if (checkType === 'disease') {
      diseaseTagFilters = _.reject(diseaseTagFilters, (f) => f === checkName)
    }

    this.updateRulesFilterState(ruleSourceFilters, diseaseTagFilters, textQuery)
  }

  updateRulesFilterState(ruleSourceFilters, diseaseTagFilters, textQuery) {
    const { allRules } = this.state
    const displayedRules = sortRules(
      allRules.filter((rule) =>
        filterFunction(rule, textQuery, diseaseTagFilters, ruleSourceFilters)
      )
    )

    this.setState({
      displayedRules,
      checkedRuleSources: ruleSourceFilters,
      checkedDiseaseTags: diseaseTagFilters,
      textQuery,
    })
  }

  render() {
    const { tags, isStaging } = this.props
    const { displayedRules } = this.state
    const facetFilter = this.buildFilterPanel()
    const rulesBox = (
      <RulesList
        displayedRules={displayedRules}
        turnRuleOnOff={this.turnRuleOnOff}
        toggleMigrationStatus={this.toggleMigrationStatus}
        tags={tags}
        isStaging={isStaging}
      />
    )
    const displayedRulesCount = displayedRules.length
    const filterCriteriaChips = createCriteriaChips(this.state)

    return (
      <div className="row">
        <div className="appointments-col col s12 m3">
          <div className="row" style={{ height: '100vh', overflowY: 'scroll' }}>
            {facetFilter}
          </div>
        </div>
        <div
          className="subscriptions-container col s8 offset-s3"
          style={{
            width: '71%',
            padding: '3em',
            height: '100vh',
            overflowY: 'scroll',
          }}
        >
          <div className="row" style={{ paddingBottom: '1em' }}>
            <div className="col s12">
              <span style={{ marginLeft: '10px', fontSize: '16px' }}>
                Showing guidelines for:
              </span>
              {filterCriteriaChips}
            </div>
            <div
              className="col s12"
              style={{
                marginLeft: '12px',
                fontSize: '16px',
                marginTop: '1.5em',
              }}
            >
              <b>
                {`${displayedRulesCount} ${pluralize(
                  'guideline',
                  displayedRulesCount
                )}`}
              </b>
              {` ${pluralize('meet', displayedRulesCount)} this criteria.`}
            </div>
          </div>
          {rulesBox}
        </div>
      </div>
    )
  }
}

RulesFilter.propTypes = propTypes

const mountReact = () => {
  const reactNode = document.getElementById('avhana-rules-filter')
  const reactData = $(reactNode).data()
  if (reactData) {
    ReactDOM.render(
      <RulesFilter
        rules={reactData.rules}
        tags={reactData.tags}
        sources={reactData.sources}
        isStaging={reactData.isStaging}
      />,
      reactNode
    )
  }
}

$(mountReact)
