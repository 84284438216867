import React from 'react'
import PropTypes from 'prop-types'

export default class TobaccoScreening extends React.Component {
  constructor(props) {
    super(props)
    this.state = { text: '', cessation: '' }
    this.updateText = this.updateText.bind(this)
    this.postSocialHistory = this.postSocialHistory.bind(this)
    this.postAndOrder = this.postAndOrder.bind(this)
  }

  updateText(event) {
    const newText = event.target.value
    let cessation
    if (newText === 'CurrentlyEveryDay' || newText === 'CurrentlySomeDays') {
      cessation = (
        <div>
          <div className="col s6">
            Record tobacco use and order {this.props.rec.options.order_name}
          </div>
          <div className="col s5 offset-s1">
            <button className="btn right" onClick={this.postAndOrder}>Record and Order</button>
          </div>
        </div>
      )
    } else {
      cessation = (
        <div className="row">
          <div className="col s11 offset-s1">
            <button className="btn right" onClick={this.postSocialHistory}>
              Record Smoking Status
            </button>
          </div>
        </div>
      )
    }

    this.setState({ cessation })
    this.setState({ text: newText })
  }

  postAndOrder() {
    this.postSocialHistory()
    this.props.sendOrder()
  }

  postSocialHistory() {
    const smokingStatus = {
      patient_id: this.props.patient.id,
      smoking_status: this.state.text,
      rec_id: this.props.id,
    }
    $.post('/orders/smoking', smokingStatus)
    .done(() => null)
    .fail((error) => {
      flash.error('There was an error submitting the note to the EHR.')
      console.error(error, { context: `Submit Tobacco Screening Error message: ${error.responseText}` })
    })
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col s12">
            <label htmlFor="tobacco-status">Select Tobacco Status</label>
            <select name="tobacco-status" className="browser-default" onChange={this.updateText}>
              <option value="" disabled selected>Choose your option</option>
              <option value="Never">Never smoker</option>
              <option value="Formerly">Former smoker</option>
              <option value="CurrentlyEveryDay">Current every day smoker</option>
              <option value="CurrentlySomeDays">Current some day smoker</option>
              <option value="CurrentStatusUnknown">Smoker - current status unknown</option>
              <option value="UnknownIfEver">Unknown if ever smoked</option>
            </select>
          </div>
        </div>
        <br />
        <div className="row">
          {this.state.cessation}
        </div>
      </div>
    )
  }
}

TobaccoScreening.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string,
  }),
  id: PropTypes.string,
  sendOrder: PropTypes.func,
  rec: PropTypes.shape({
    options: PropTypes.shape({
      order_name: PropTypes.string,
    }),
  }),
}
