import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

export default class TagSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: null,
      ruleTags: props.ruleTags,
      allTags: props.tags,
    }

    this.handleChange = this.handleChange.bind(this)
    this.tagOptions = this.tagOptions.bind(this)
  }

  handleChange(selectedOption) {
    const { parentHandler } = this.props
    const { ruleTags } = this.state
    this.setState({ ruleTags: ruleTags.concat(selectedOption) })
    parentHandler({ id: selectedOption.value, name: selectedOption.label })
  }

  tagOptions() {
    const { allTags } = this.state
    return allTags.map((t) => ({
      value: t.id,
      label: t.name,
    }))
  }

  render() {
    const { selectedOption } = this.state

    return (
      <Select
        calssName="tagSelect"
        classNamePrefix="tagSelect"
        placeholder="Add a tag"
        value={selectedOption}
        onChange={this.handleChange}
        options={this.tagOptions()}
      />
    )
  }
}

const TAG = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
})

TagSelect.propTypes = {
  parentHandler: PropTypes.func.isRequired,
  ruleTags: PropTypes.arrayOf(TAG).isRequired,
  tags: PropTypes.arrayOf(TAG).isRequired,
}
