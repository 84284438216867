import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import Backfill from '../backfill/index'
import Enrollments from '../enrollment/index'
import MedEquivalency from '../MmeCalculation/index'
import CareCenter from '../careCenter/import'
import ExternalRule from '../externalRule/index'



const ImportUi = () => {
  const [selectedOption, setSelectedOption] = useState('')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const renderSelectedComponent = () => {
    if (selectedOption === 'backfill') {
      return <Backfill />
    }
    if (selectedOption === 'enrollments') {
      return <Enrollments />
    }
    if (selectedOption === 'medequivalency') {
      return <MedEquivalency />
    }
    if (selectedOption === 'carecenter') {
      return <CareCenter />
    }
    if (selectedOption === 'externalrule') {
      return <ExternalRule />
    }
    return null
  }

  return (
    <div>
      <FormControl margin='dense' variant='standard' fullWidth sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="tool-selection-label">Select an option</InputLabel>
        <Select 
          labelId="tool-selection-label"
          id="tool-selection"
          value={selectedOption} 
          onChange={handleOptionChange}
          margin="dense"
          variant="outlined"
          label="Select an option"
        >
          <MenuItem value="">Select an option</MenuItem>
          <MenuItem value="backfill">Backfill</MenuItem>
          <MenuItem value="enrollments">Enrollments</MenuItem>
          <MenuItem value="medequivalency">Med Equivalency</MenuItem>
          <MenuItem value="carecenter">Care Center</MenuItem>
          <MenuItem value="externalrule">External Rule</MenuItem>
        </Select>
      </FormControl>
      {renderSelectedComponent()}
    </div>
  )
}

export default ImportUi
