import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { bool } from 'prop-types'

const propTypes = {
  isStaging: bool.isRequired,
}

const MigrateButton = ({ isStaging }) => {
  const [disabled, setDisabled] = useState(false)
  const [creationCount, setCreationCount] = useState(0)
  const [deletionCount, setDeletionCount] = useState(0)
  const [selfPayCount, setSelfPayCount] = useState(0)
  const [isDialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    $.ajax({
      method: 'get',
      url: '/root/value_set_migration_count',
      dataType: 'json',
      contentType: 'application/json',
    })
      .done((response) => {
        setCreationCount(response.creations)
        setDeletionCount(response.deletions)
        setSelfPayCount(response.selfPay)
      })
      .fail((error) => {
        console.error(error)
      })
  })

  const handleMigration = () => {
    setDisabled(true)
    $.ajax({
      method: 'post',
      url: '/root/value_sets/migrate_value_sets',
      dataType: 'json',
      contentType: 'application/json',
    })
      .done((response) => {
        console.log(response)
      })
      .fail((error) => {
        console.error(error)
      })
    
      setDialogOpen(false)
  }
  
  const handleButtonClick = () => {
    setDialogOpen(true)
  }

  const handleCancel = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <div>
        <Button 
          className='migration-button' 
          disabled={disabled}
          onClick={handleButtonClick}
          variant="contained" 
          color="primary"
        >
          Create {creationCount} and Delete {deletionCount} Value Set Items in Production. {selfPayCount} Self pay items in Value Set.
        </Button>

        <Dialog open={isDialogOpen} onClose={handleCancel}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Are you sure you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleMigration} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  )
}

MigrateButton.propTypes = propTypes

export default MigrateButton