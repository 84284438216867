import React from 'react'
import PropTypes from 'prop-types'
import NativeListener from 'react-native-listener'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { ThemeProvider, createTheme } from '@mui/material/styles'

export default class EnhancedDismissButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { openMenu: false }
    this.handleOnRequestChange = this.handleOnRequestChange.bind(this)
    this.optionSelect = this.optionSelect.bind(this)
  }

  handleOnRequestChange(value) {
    this.setState({ openMenu: value })
  }

  optionSelect(value) {
    const reasonText = value.target.innerText
    this.props.dismissRec(this.props.rule.name, reasonText)
  }

  render() {
    const menuButtonClassName = this.props.menuButtonClassName || 'custom-dismiss-button'

    const menuButton = (
      <NativeListener stopClick onClick={this.handleOnRequestChange}>
        <a className={menuButtonClassName}>Hide</a>
      </NativeListener>
    )

    const muiTheme = createTheme({
      palette: {
        accent1Color: '#1684F9',
      },
    })

    return (
      <ThemeProvider theme={muiTheme}>
        <Menu
          iconButtonElement={menuButton}
          open={this.state.openMenu}
          onRequestChange={this.handleOnRequestChange}
          anchorOrigin={{ horizontal: 'middle', vertical: 'top' }}
          targetOrigin={{ vertical: 'top', horizontal: 'middle' }}
        >
          <p className="center-align" style={{ fontSize: '14px', fontFamily: 'Helvetica', fontWeight: 'bold', padding: '0px 0px' }}>Why are you dismissing this recommendation?</p>
          <MenuItem
            style={{ fontSize: '13px', color: 'rgb(3, 155, 229)' }}
            primaryText="Incorrect"
            id="incorrect"
            value={1}
            onClick={this.optionSelect}
          />
          <MenuItem
            style={{ fontSize: '13px', color: 'rgb(3, 155, 229)' }}
            primaryText="Already satisfied"
            id="false-positive"
            value={2}
            onClick={this.optionSelect}
          />
          <MenuItem
            style={{ fontSize: '13px', color: 'rgb(3, 155, 229)' }}
            primaryText="Not important for my practice"
            id="not-relevant"
            value={3}
            onClick={this.optionSelect}
          />
          <MenuItem
            style={{ fontSize: '13px', color: 'rgb(3, 155, 229)' }}
            primaryText="Patient declined"
            id="declined"
            value={1}
            onClick={this.optionSelect}
          />
          <MenuItem
            style={{ fontSize: '13px', color: 'rgb(3, 155, 229)' }}
            primaryText="Other"
            id="Other"
            value={4}
            onClick={this.optionSelect}
          />
        </Menu>
      </ThemeProvider>
    )
  }
}

EnhancedDismissButton.propTypes = {
  dismissRec: PropTypes.func,
  rule: PropTypes.shape({
    name: PropTypes.string,
  }),
  menuButtonClassName: PropTypes.string,
}
