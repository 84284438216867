import React, { useState } from 'react'
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  )
}

const StyledSelect = styled(Select)({
  minWidth: '200px',
})

const ProviderGroupingFinder = ({ organizations }) => {
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [selectedPg, setSelectedPg] = useState('')
  const [pgData, setPgData] = useState([])

  const handleOrganizationChange = (event) => {
    const newOrganization = event.target.value
    setSelectedOrganization(newOrganization)
    $.ajax({
      method: 'get',
      url: '/admin/provider_grouping_search',
      dataType: 'json',
      contentType: 'application/json',
      data: { org: newOrganization }
    })
    .done((response) => {
      setPgData(response)
    })
    .fail((error) => {
      console.error(error)
    }) 
  }

  const handlePgChange = (event) => {
    setSelectedPg(event.target.value)
  }

  return (
    <>
      <form>
        <FormControl margin='normal' variant='standard' fullWidth sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="organization-label">Organization</InputLabel>
          <StyledSelect value={selectedOrganization} onChange={handleOrganizationChange}>
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>
                {org.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
        <FormControl margin='normal' variant='standard' fullWidth sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="provider-group-label">Provider Group</InputLabel>
          <StyledSelect value={selectedPg} onChange={handlePgChange}>
            {pgData.map((pg) => (
              <MenuItem key={pg.id} value={pg.id}>
                {pg.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </form>
      <input type="hidden" name="user[provider_grouping_id]" value={selectedPg} />
      <input type="hidden" name="user[organization_id]" value={selectedOrganization} />
    </>
  )
}

ProviderGroupingFinder.propTypes = propTypes

export default ProviderGroupingFinder
