import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class RadioButtons extends Component {
  // important that this.props.formID and props.groupName are not repeated
  // on same page otherwise id and htmlFor will not be unique
  updateFormState (event) {
    const key = this.props.groupName
    const value = event.target.value
    const questionId = this.props.questionId
    var formElData = { key, value, questionId }
    // add an alt value, need this for screening questionnaires
    if (event.target.attributes["data-altvalue"]) {
      let altValue = event.target.attributes["data-altvalue"].value
      formElData = _.assign(formElData, { altValue })
    }
    this.props.updateFormState(formElData)
  }

  render () {
    const labels = this.props.labels
    const radioButtons = _.map(labels, (label, index) =>
      <p key={index} className="radio-button-container">
        <label htmlFor={`${this.props.formId}_${this.props.groupName}_${label.name}`.toLowerCase()}>
          <input
            name={this.props.groupName}
            type="radio"
            id={`${this.props.formId}_${this.props.groupName}_${label.name}`.toLowerCase()}
            value={label.value}
            data-altvalue={label.name}
            defaultChecked={label.value === this.props.selected}
            onClick={this.updateFormState.bind(this)}
            disabled={this.props.disabled || false}
          />
          <span>{label.name}</span>
        </label>
      </p>
    )

    return (
      <div className={this.props.containerClassName}>
        <p className="radio-button-container">
          {this.props.groupName}
        </p>
        {radioButtons}
      </div>
    )
  }
}

// input tag needs a name, id and label tab needs a for attribute that matches
// input tag id for Materialize radio buttons to work properly
RadioButtons.propTypes = {
  labels: PropTypes.array.isRequired,
  groupName: PropTypes.string.isRequired,
  updateFormState: PropTypes.func.isRequired,
}
