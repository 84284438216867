import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'

const RemoveOrderButton = ({ orderIndex, updateOrderPreference, orderPreference }) => {
  const onClick = () => {
    const ordersLens = R.lensPath(['options', 'orders'])
    const selectionsLens = R.lensPath(['options', 'selections'])
    const orders = R.view(ordersLens, orderPreference)
    const updatedOrders = orders.filter((_, index) => index !== orderIndex)
    let updatedOrderPreference = R.set(ordersLens, updatedOrders, orderPreference)
    if (updatedOrders.length <= 1) updatedOrderPreference = R.set(selectionsLens, false, updatedOrderPreference)
    updateOrderPreference(updatedOrderPreference)
  }

  return (
    <button
      type="button"
      onClick={onClick}
    >
      Remove Order
    </button>
  )
}

RemoveOrderButton.propTypes = {
  orderIndex: PropTypes.number.isRequired,
  updateOrderPreference: PropTypes.func.isRequired,
  orderPreference: PropTypes.shape({
    options: PropTypes.shape({
      orders: PropTypes.arrayOf(PropTypes.shape())
    })
  }).isRequired,
}

export default RemoveOrderButton

