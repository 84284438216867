import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import LoadingSkeleton from '../loadingSkeleton/LoadingSkeleton.jsx'

export default class QualityMeasuresTimeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      reportId: '',
      iframeSrc: '',
      csvLink: '#',
      pdfLink: '#',
      startDate: moment().subtract(26, 'weeks'),
      endDate: moment().subtract(2, 'weeks'),
      measureId: undefined,
    }
    this.handleStartChange = this.handleStartChange.bind(this)
    this.handleEndChange = this.handleEndChange.bind(this)
    this.handleIframeLoad = this.handleIframeLoad.bind(this)
    this.getIframeSrc = this.getIframeSrc.bind(this)
    this.updateSelectedMeasures = this.updateSelectedMeasures.bind(this)
  }

  componentDidMount() {
    this.getIframeSrc(this.state)
  }

  getIframeSrc() {
    this.setState({ loaded: false, csvLink: '', iframeSrc: '' })
    $.get(
      '/analytics/quality_measures_report',
      {
        start_date: this.state.startDate.toISOString(),
        end_date: this.state.endDate.toISOString(),
        measure_id: this.state.measureId,
      }
    ).done(
      (data) => {
        this.setState({
          reportId: data.report_id,
          iframeSrc: data.iframe_src,
          csvLink: data.csv_link,
          pdfLink: data.pdf_link,
        })
      }
    )
  }

  handleStartChange(newStartDate) {
    this.setState({ startDate: newStartDate })
  }

  handleEndChange(newEndDate) {
    this.setState({ endDate: newEndDate })
  }

  handleIframeLoad() {
    // ensure that download button appears only after csv is ready for export
    if (!_.isEmpty(this.state.csvLink)) {
      this.setState({ loaded: true })
    }
  }

  updateSelectedMeasures(event) {
    this.setState({ measureId: event.target.value })
  }

  render() {
    const measures = _.map(this.props.measureNames, measure => (
      <option key={measure[0]} value={measure[1]}>{measure[0]}</option>
    ))

    return (
      <div className="analytics-dashboard row">
        <div className="col s10 offset-s1">
          <div className="card date-range-pickers">
            <div className="card-content" style={{ padding: '5px' }}>
              <div className="row" style={{ marginBottom: '0px' }}>
                <div className="col s12">
                  <div className="row" style={{ marginBottom: '0px' }}>
                    <p style={{ padding: '0.5em' }}>Avhana Utilization Report</p>
                    <div className="col 4">
                      <label htmlFor="startDatepicker">
                        Select a start date
                      </label>
                      <div>
                        <DatePicker
                          name="startDatepicker"
                          selected={this.state.startDate}
                          selectsStart
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          maxDate={moment().subtract(2, 'weeks')}
                          onChange={this.handleStartChange}
                        />
                      </div>
                    </div>
                    <div className="col 4">
                      <label htmlFor="endDatepicker">
                        Select an end date
                      </label>
                      <div>
                        <DatePicker
                          name="endDatepicker"
                          selected={this.state.endDate}
                          selectsEnd
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          maxDate={moment().subtract(2, 'weeks')}
                          onChange={this.handleEndChange}
                        />
                      </div>
                    </div>
                    <div className="col 4">
                      <label htmlFor="measure-select">
                        Select a measure
                      </label>
                      <div>
                        <select
                          name="measure-select"
                          className="browser-default"
                          onChange={this.updateSelectedMeasures}
                        >
                          <option value="" disabled selected>Choose measure</option>
                          { measures }
                        </select>
                      </div>
                    </div>
                    <div className="col 4" style={{ padding: '1em 0em' }}>
                      <button className="btn blue-bkg waves-effect" onClick={this.getIframeSrc} style={{ margin: '0em 1em' }}>
                        Refresh Report
                      </button>
                      {
                        this.state.loaded &&
                        <a
                          href={this.state.csvLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="custom-dismiss-button"
                          style={{ paddingLeft: '1em' }}
                        >
                          Export As CSV
                        </a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mode-container" style={{ height: '500px' }}>
            {
              this.state.loaded ? undefined : <LoadingSkeleton />
            }
            <iframe
              src={this.state.iframeSrc}
              onLoad={this.handleIframeLoad}
              height="300%"
              width="100%"
              frameBorder="0"
            />
          </div>
        </div>
      </div>
    )
  }
}

QualityMeasuresTimeline.propTypes = {
  measureNames: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
}

const mountReact = () => {
  const reactNode = document.getElementById('render-quality-measure-report')
  const reactData = $(reactNode).data()
  // render dashboard on pages where the proper div exists
  if (reactNode) {
    ReactDOM.render(
      <QualityMeasuresTimeline
        measureNames={reactData.measurenames}
        orgStartDate={reactData.orgstartdate}
      />, reactNode)
  }
}

$(mountReact)
