import React from 'react'

export default class DateNavigator extends React.Component {
  render() {
    return (
      <div className="row date-navigator">
        <i
          role="button"
          id="subtract-day"
          className="material-icons left"
          onClick={this.props.dateChange}
          style={{ marginRight: 0 }}
        >
          keyboard_arrow_left
        </i>

        <span style={{ display: 'inline-flex', justifyContent: 'center' }}>
          <span style={{ marginRight: '0.25rem' }}>{this.props.selectedMomentDate.format('ddd M/D')}</span>
          <i
            role="button"
            id="show-calendar"
            className="material-icons"
            style={{ marginBottom: '2px' }}
            onClick={this.props.toggleCalendar}
          >
            date_range
          </i>
        </span>

        <i
          role="button"
          id="add-day"
          className="material-icons right"
          onClick={this.props.dateChange}
          style={{ marginLeft: 0 }}
        >
          keyboard_arrow_right
        </i>
      </div>
    )
  }
}
