import NativeListener from 'react-native-listener'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { RuleContext } from './rulesList'

import AllscriptsOrderPrefTypeahead from '../../reusableComponents/AllscriptsOrderPrefTypeahead'
import DiagnosisTypeahead from '../../reusableComponents/DiagnosisTypeahead'
import { Rule, Styles } from '../../athenaTypes'

const propTypes = {
  rule: Rule.isRequired,
  turnSubscriptionActiveSilent: PropTypes.func.isRequired,
  style: Styles.isRequired,
  updateOrderPreference: PropTypes.func.isRequired
}

const styles = {
  collapsibleHeader: {
    lineHeight: '2em',
    padding: '1.5em 1em',
  },
  chipContainer: {
    paddingLeft: '0.5em',
  },
  chip: {
    backgroundColor: '#f2f2f2',
    fontSize: '12px',
  },
  ruleNameSpan: {
    paddingLeft: '1em',
    fontSize: '17px',
  },
  noPadding: { padding: '0' },
}

const AllscriptsRule = ({ rule, turnSubscriptionActiveSilent, style, index, updateOrderPreference }) => {
  const [orderDisplayText, setOrderDisplayText] = useState('No follow up action is currently associated with this guideline')
  const [diagnosisName, setDiagnosisName] = useState('')
  const { setSize } = React.useContext(RuleContext)
  const header = React.useRef()
  const body = React.useRef()

  const handleClick = () => {
    setTimeout(() => {
      const headerHeight = header.current.getBoundingClientRect().height
      const bodyHeight = body.current.getBoundingClientRect().height
      const newHeight = bodyHeight > 100
        ? headerHeight + bodyHeight + 20
        : headerHeight
      setSize(index, newHeight)
    }, 300)
  }

  React.useEffect(() => {
    let newOrderDisplayText = 'No follow up action is currently associated with this guideline'
    const orderPrefName = path(['order_preference', 'options', 'entry_name'], rule)
    if (orderPrefName) {
      newOrderDisplayText = `Order ${orderPrefName}`
    }

    let newDiagnosisName = path(['snomed_diagnosis_name'], rule)
    const diagnosisNameFromDb = path(['order_preference', 'options', 'diagnosis_name'], rule)
    if (diagnosisNameFromDb) {
      newDiagnosisName = diagnosisNameFromDb
    }
    setOrderDisplayText(newOrderDisplayText)
    setDiagnosisName(newDiagnosisName)
  })

  const updateOrderPrefName = (_, newOrderPrefOption) => {
    let newOrderDisplayText = 'No follow up action is currently associated with this guideline'

    const orderPrefName = path(['entry_name'], newOrderPrefOption)
    if (orderPrefName) {
      newOrderDisplayText = `Order ${orderPrefName}`
    }
    setOrderDisplayText(newOrderDisplayText)
  }

  const updateDiagnosisName = (_, diagnosisNameObject) => {
    setDiagnosisName(diagnosisNameObject.diagnosis_name)
  }

  const handleSelect = ({ target }) => {
    const subscriptionId = rule.subscription.id
    const activeSilent = target.value
    turnSubscriptionActiveSilent(subscriptionId, activeSilent)
  }

  const buildPreferenceComponent = (orderPreference) => {
    if (orderPreference) {
      const diagnosisTypeahead = (
        <DiagnosisTypeahead
          pref={orderPreference}
          rule={rule}
          updateOptions={updateDiagnosisName}
          updateOrderPref
          updateOrderPreference={updateOrderPreference}
          columns={12}
        />
      )

      const orderTypeahead = (
        <AllscriptsOrderPrefTypeahead
          pref={orderPreference}
          updateOptions={updateOrderPrefName}
          updateOrderPref
          columns={12}
        />
      )

      return (
        <div>
          <div className="row">
            <div className="col s12" style={{ marginBottom: '-4em' }}>
              <h6><b>Follow Up Action</b></h6>
            </div>
            <div className="col s12">
              {orderTypeahead}
            </div>
          </div>
          <div className="row">
            <div className="col s12" style={{ marginBottom: '-4em', marginTop: '1em' }}>
              <h6><b>Diagnosis</b></h6>
            </div>
            <div className="col s12">
              {diagnosisTypeahead}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col s12">
          <h6><b>Follow Up Action</b></h6>
        </div>
        <div className="col s12">
          <p style={{ padding: '0.2rem' }}>No follow up action is currently associated with this guideline</p>
        </div>
      </div>
    )
  }

  const buildCurrentDiagnosisDisplay = () => {
    if (rule.rule_type !== 'remove') {
      return (
        <div className="col s12" style={{ padding: '0em 0em 1em 1em', lineHeight: '1.5em' }}>
          <span className="rule-action-subtitle-black">
            Orders using diagnosis code
            {' '}
            <b>{diagnosisName}</b>
          </span>
        </div>
      )
    }
  }
  const sourceTag = <div className="chip" style={styles.chip}>{rule.source}</div>
  const ruleTags = rule.tags.map((t) => (
    <div key={t.id} style={styles.chip} className="chip">
      {t.name}
    </div>
  ))
  const orderPreferenceComponent = buildPreferenceComponent(rule.order_preference)
  const currentDiagnosisDisplay = buildCurrentDiagnosisDisplay()

  return (
    <li className="collection-item avatar recommendation-card" style={style}>
      <div className="collapsible-header rule-header" style={styles.collapsibleHeader} ref={header} onClick={handleClick}>
        <div className="row">
          <div className="col s9">
            <div className="row">
              <div className="col s12" style={{ paddingLeft: '1em', lineHeight: '1.5em' }}>
                <span className="bold-text">{rule.defaultDisplayCode || rule.source}</span>
                <span style={styles.ruleNameSpan}>{rule.name}</span>
              </div>
              <div className="col s12" style={{ padding: '0.5em 0em .7em 1em', lineHeight: '1.5em' }}>
                <span className="rule-action-subtitle-black"><b>{orderDisplayText}</b></span>
              </div>
              {currentDiagnosisDisplay}
              <div className="col s12 tags-container" style={styles.chipContainer}>
                {ruleTags}
                {sourceTag}
              </div>
            </div>
          </div>
          <NativeListener stopClick>
            <div className="col s3" style={{ paddingTop: '1em' }}>
              <label htmlFor="active-state">Active Mode</label>
              <div className="input-field col s12">
                <select
                  id="active-state"
                  className="browser-default"
                  onChange={handleSelect}
                  defaultValue={rule.subscription ? rule.subscription.active_silent : 'off'}
                >
                  <option value="off">Off</option>
                  <option value="silent">Silent</option>
                  <option value="active">Active</option>
                </select>
              </div>
            </div>
          </NativeListener>
        </div>
      </div>
      <div className="collapsible-body" ref={body}>
        <div className="row" style={{ padding: '1em 1em 2em 1em' }}>
          <div className="col s12">
            {orderPreferenceComponent}
            <div className="row" style={{ padding: '1em 0em 0em 0.8em' }}>
              <h6><b>Description</b></h6>
              <p style={styles.noPadding}>{rule.description}</p>
              <br />
              <a href={rule.link} target="_blank" rel="noopener noreferrer" style={styles.noPadding}>Link to rule source</a>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

AllscriptsRule.propTypes = propTypes

export default AllscriptsRule
