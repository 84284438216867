import React from 'react'
import PropTypes from 'prop-types'
import NotesSubmitButton from '../../../reusableComponents/NotesSubmitButton.jsx'
import RadioButtons from '../../../reusableComponents/RadioButtons.jsx'

export default class PqrsHba1c extends React.Component {
  constructor(props) {
    super(props)
    this.state = { text: '', exception: '' }
    this.submitNote = this.submitNote.bind(this)
    this.updateTextBox = this.updateTextBox.bind(this)
    this.updateException = this.updateException.bind(this)
  }

  updateTextBox(event) {
    this.setState({ text: event.target.value })
  }

  updateException(formEl) {
    const exception = formEl.value

    // don't reset text box with prefilled text until state has been mutated
    this.setState({ exception }, () => this.resetText())
  }

  resetText() {
    this.setState({ text: this.state.exception })
  }

  submitNote() {
    const diagnosisCode = this.props.options.diagnosis_code

    const customOrderPref = {
      order_type: 'patient_info',
      order_type_id: '286130', // diabetes - A1C education information
      diagnosis_code: diagnosisCode,
      provider_note: this.state.text,
    }
    const recId = this.props.id
    const orderInfo = { recId, customOrderPref }
    $.post('/orders/send', orderInfo)
    .done(() => {
      this.props.submittedToEhr(this.props.rule.name)
    })
    .fail((error) => {
      flash.error('There was an error submitting the note to the EHR.')
      console.error(error, { context: `Submit PQRS HGBA1C Error message: ${error.responseText}` })
    })
  }

  render() {
    let hba1c
    if (this.props.options.form_info && this.props.options.form_info.hba1c) {
      hba1c = this.props.options.form_info.hba1c
    } else {
      hba1c = 'not found'
    }

    return (
      <div className="row">
        <div className="row">
          <p className="left" style={{ float: 'left' }}>The latest Hemoglobin A1c value was <span className="bold-text">{hba1c}</span></p>
        </div>
        <div className="row">
          <RadioButtons
            formId={this.props.id}
            groupName={'Please document action or follow up'}
            labels={[
              { name: 'Consult to primary care physician', value: 'Consult to primary care physician' },
              { name: 'Consult to Diabetes Clinic', value: 'Consult to Diabetes Clinic' },
              { name: 'Counseled on Diabetic control', value: 'Counseled on Diabetic control' },
            ]}
            selected={this.state.exception}
            updateFormState={this.updateException}
          />
        </div>
        <div className="row">
          <div className="input-field col s10 offset-s1">
            <textarea
              id="abdominal-pain"
              className="materialize-textarea"
              onChange={this.updateTextBox}
              value={this.state.text}
              placeholder="Enter note here"
            />
            <label htmlFor="icon_prefix2" />
          </div>
          <NotesSubmitButton
            encounter={this.props.encounter}
            submitNote={this.submitNote}
          />
        </div>
      </div>
    )
  }
}

PqrsHba1c.propTypes = {
  id: PropTypes.string,
  options: PropTypes.shape({
    diagnosis_code: PropTypes.string,
    form_info: PropTypes.shape({
      hba1c: PropTypes.string,
    }),
  }),
  rule: PropTypes.shape({
    name: PropTypes.string,
  }),
  submittedToEhr: PropTypes.func,
  encounter: PropTypes.shape({}),
}
