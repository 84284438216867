import React, { useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'


export default function ExternalRule() {
  const [file, setFile] = useState(undefined)
  const [selectedOption, setSelectedOption] = useState('')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleFileSelect = (event) => {
    setFile(event.target.files[0])
  }

  const checkInput = () => {
    if (!file) {
      flash.error('Please select a file to upload', 5000)
      return false
    }
    if (!selectedOption) {
      flash.error('Please select an option', 5000)
      return false
    }
    return true
  }

  const handleFileSubmit = (event) => {
    event.preventDefault()
    if (!checkInput()) {
      return
    }
    const formData = new FormData()
    formData.append("file", file)
    formData.append("type", selectedOption)
    $.ajax({
      method: 'post',
      url: '/root/external_rule/external_rule_csv',
      contentType: false,
      processData: false,
      data: formData,
      enctype: 'multipart/form-data'
    })
      .done(() => {
        flash.success('External rule csv successfully imported', 50000)

      })
      .fail((error) => {
        flash.error(`Error importing your External rule. ${error.responseJSON.errors}`, 50000)
        console.error(error)
      })
  }

  return (
    <>
      <form onSubmit={handleFileSubmit}>
        <h6>
          External rule import(csv)
        </h6>
        <div>
          <FormControl margin='dense' variant='standard' sx={{ m: 1, minWidth: 200 }} required>
          <InputLabel id="type-selection-label">Select import type</InputLabel>
            <Select 
              labelId="type-selection-label"
              id="type-selection"
              value={selectedOption} 
              onChange={handleOptionChange}
              margin="dense"
              variant="outlined"
              label="Select an option"
            >
              <MenuItem value="">Select an option</MenuItem>
              <MenuItem value="Stewart Risk Adjustment">Stewart Risk Adjustment</MenuItem>
              <MenuItem value="Privia GA Diabetes Bundle">Privia GA Diabetes Bundle</MenuItem>
            </Select>
          </FormControl>
        </div>
        <input
          type="file"
          id="file-select"
          accept=".csv"
          onChange={handleFileSelect}
        />
        <button type="submit">
          Upload
        </button>
      </form>
    </>
  )
}