export default function determineQueuedRecTitle(rec) {
  let recOptions = {}
  if (rec) {
    recOptions = rec.options || {}
  }

  let title = ''
  // Add Order, Prescribe or Administer depending on if vaccine
  if (recOptions.orders && recOptions.order_set === false) {
    if (recOptions.orders[0]["order_type"] === 'vaccination') {
      if (recOptions.orders[0].ordering_mode) {
        title = recOptions.orders[0].ordering_mode + ' '
      } else {
        title = 'Administer '
      }
    }
  }

  if (recOptions.order_set) {
    title += recOptions.name
  } else if (recOptions.orders) {
    title += recOptions.orders[0].order_name
  } else if (rec.rule && rec.rule.rule_type === 'documentation') {
    title = rec.rule.action.substr(rec.rule.action.indexOf(' ') + 1)
  } else {
    title += recOptions.entry_name
  }

  return title
}
