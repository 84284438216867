import React from 'react'
import PropTypes from 'prop-types'
import NotesSubmitButton from '../../../reusableComponents/NotesSubmitButton.jsx'

export default class AbnormalVitals extends React.Component {
  constructor(props) {
    super(props)
    this.state = { text: '' }
    this.submitNote = this.submitNote.bind(this)
    this.updateTextBox = this.updateTextBox.bind(this)
  }

  updateTextBox(event) {
    this.setState({ text: event.target.value })
  }

  submitNote() {
    const customOrderPref = {
      order_type: 'patient_info',
      order_type_id: this.props.options.form_info.order_type_id,
      diagnosis_code: this.props.options.form_info.snomed,
      icd10: this.props.options.form_info.icd10,
      provider_note: this.state.text,
    }
    const recId = this.props.id
    const orderInfo = { recId, customOrderPref }
    $.post('/orders/send', orderInfo)
    .done(() => {
      this.props.submittedToEhr(this.props.rule.name)
    })
    .fail((error) => {
      flash.error('There was an error submitting the note to the EHR.')
      console.error(error, { context: `AbdominalVitals Error message: ${error.responseText}` })
    })
  }

  render() {
    const vitalName = this.props.options.form_info.vital_name
    const vitalValue = this.props.options.form_info.value

    return (
      <div>
        <div className="row">
          <div className="row">
            <div className="col s10 offset-s1">
              <i className="material-icons">error</i>
              <div className="alert-text-for-icon">
                {`${vitalName} is ' ${vitalValue}. Enter a note explaining why this vital is out of range and press submit to document this in the EHR`}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="input-field col s10 offset-s1">
              <textarea
                id="abnormal-vitals"
                className="materialize-textarea"
                onChange={this.updateTextBox}
                value={this.state.text}
                placeholder="Enter note here with vital name and explanation"
              />
              <label htmlFor="icon_prefix2" />
            </div>
            <NotesSubmitButton
              encounter={this.props.encounter}
              submitNote={this.submitNote}
            />
          </div>
        </div>
      </div>
    )
  }
}

AbnormalVitals.propTypes = {
  encounter: PropTypes.shape({}),
  rule: PropTypes.shape({
    name: PropTypes.string,
  }),
  submittedToEhr: PropTypes.func,
  options: PropTypes.shape({
    form_info: PropTypes.shape({
      vital_name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      order_type_id: PropTypes.string,
      snomed: PropTypes.string,
      icd10: PropTypes.string,
    }),
  }),
  id: PropTypes.number,
}
