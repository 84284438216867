import React, { useState, useEffect } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Table from './Table'

const diagnosisCodes = () => {
  const insuranceParam = (new URL(window.location.href)).searchParams.get('insurance')
  const orderParam = (new URL(window.location.href)).searchParams.get('order')
  const [insuranceList, setInsuranceList] = useState([])
  const [insurance, setInsurance] = insuranceParam ? useState(insuranceParam) : useState('')
  const [orderList, setOrderList] = useState([])
  const [order, setOrder] = orderParam ? useState(orderParam) : useState('')
  const [data, setData] = useState([])

  const sortDataByDescription = (data) => data.sort((a, b) => {
      if (a.description < b.description) return -1
      if (a.description > b.description) return 1
      return 0
    })

  useEffect(() => {
    $.ajax({
      method: 'get',
      url: '/insurance_list',
      dataType: 'json',
      contentType: 'application/json'
    })
      .done((response) => {
        setInsuranceList(response)
      })
      .fail((error) => {
        console.error(error)
      })

    $.ajax({
      method: 'get',
      url: '/order_list',
      dataType: 'json',
      contentType: 'application/json',
      data: { insurance: insuranceParam }
    })
      .done((response) => {
        setOrderList(response)
      })
      .fail((error) => {
        console.error(error)
      })

    $.ajax({
      method: 'get',
      url: '/codes',
      dataType: 'json',
      contentType: 'application/json',
      data: { insurance: insuranceParam, order: orderParam }
    })
      .done((response) => {
        const sortedData = sortDataByDescription(response)
        setData(sortedData)
      })
      .fail((error) => {
        console.error(error)
      })
  }, [])

  const handleInsuranceChange = (event) => {
    setInsurance(event.target.value)
    if (order) {
      const queryParams = { insurance: event.target.value, order }
      const params = new URLSearchParams(queryParams)
      window.history.pushState({}, "", `/diagnosis_codes?${params.toString()}`)
    }
    $.ajax({
      method: 'get',
      url: '/order_list',
      dataType: 'json',
      contentType: 'application/json',
      data: { insurance: event.target.value }
    })
      .done((response) => {
        setOrderList(response)
      })
      .fail((error) => {
        console.error(error)
      })
  }

  const handleOrderChange = (event) => {
    setOrder(event.target.value)
    const queryParams = { insurance, order: event.target.value }
    $.ajax({
      method: 'get',
      url: '/codes',
      dataType: 'json',
      contentType: 'application/json',
      data: queryParams
    })
      .done((response) => {
        const params = new URLSearchParams(queryParams)
        window.history.pushState({}, "", `/diagnosis_codes?${params.toString()}`)
        const sortedData = sortDataByDescription(response)
        setData(sortedData)
      })
      .fail((error) => {
        console.error(error)
      })
  }

  const formatInsuranceValue = (value) => {
    if (value.startsWith("BCBS")) {
      return "BCBS"
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }  

  return (
    <>
      <h1>Lab Coverage Codes</h1>
      <Box sx={{ minWidth: 120, marginBottom: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="insurance-select-label">Insurance</InputLabel>
          <Select
            labelId="insurance-select-label"
            id="insurance-select"
            value={insurance}
            label="Insurance"
            onChange={handleInsuranceChange}
          >
            {insuranceList.sort().map((ins) => (
              <MenuItem key={ins} value={ins}>{formatInsuranceValue(ins)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: 120, marginBottom: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="order-select-label">Lab Order</InputLabel>
          <Select
            labelId="order-select-label"
            id="order-select"
            value={order}
            label="Lab Order"
            onChange={handleOrderChange}
          >
            {orderList.sort().map((ord) => (
              <MenuItem key={ord} value={ord}>{ord.charAt(0).toUpperCase() + ord.slice(1).toLowerCase()}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Table data={data} setData={setData} />
    </>
  )
}

export default diagnosisCodes