import M from 'materialize-css'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

import LoadingSkeleton from '../loadingSkeleton/LoadingSkeleton.jsx'
import Recommendation from './Recommendation.jsx'


export default class RecommendationsIframe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      recsList: [],
      loading: true,
    }
    this.removeRecommendation = this.removeRecommendation.bind(this)
    this.updateRecStatus = this.updateRecStatus.bind(this)
    this.addRecToRecsList = this.addRecToRecsList.bind(this)
    this.updateOptions = this.updateOptions.bind(this)
    this.updateEncounter = this.updateEncounter.bind(this)
  }

  componentDidMount() {
    this.getData(this.props)
  }

  componentWillReceiveProps(newProps) {
    const newPatient = newProps.emrPatientId !== this.props.emrPatientId
    // otherwise will make patients call any time there are updates upstream
    if (newPatient) {
      this.getData(newProps)
    }
  }

  componentDidUpdate() {
    // initialize materializecss javascript
    const collapsibleElems = document.querySelector('.collapsible')
    M.Collapsible.init(collapsibleElems, {})
    const tooltips = document.querySelectorAll('.tooltipped')
    M.Tooltip.init(tooltips, { delay: 50 })
  }

  getData(props) {
    this.myProps = props
    this.setState({ loading: true, header: '' })
    $.get('/iframe/recommend', {
      organization_id: props.organizationId,
      emr_patient_id: props.emrPatientId,
      emr_department_id: props.emrDepartmentId,
      api_key: '3d6a98af-fdc9-48ae-a1f5-d0d46a1bdcd2',
    })
    .done((data) => {
      this.setState({
        recsList: data.recs,
        patient: data.patient,
      })
    })
    .always(() => this.setState({ loading: false }))
    .fail((errors) => {
      if (checkTimeout(errors) === true) {
        redirectToLogin()
      } else {
        console.error(errors.statusText, errors.responseText)
        this.setState({ header: 'Recommendations failed to load.' })
        flash.error(
          'There was a problem loading recommendations.' +
          'Contact support@avhana.com for further assistance.')
      }
    })
  }


  removeRecommendation(recId) {
    // remove tooltips and reinitialize them in componentDidUpdate
    // this avoids unexepcted behavior where tooltip lingers after click
    const elems = document.querySelectorAll('.tooltipped')
    for (let i = 0; i < elems.length; i += 1) {
      const instance = M.Tooltip.getInstance(elems[i])
      instance.close()
    }
    const updatedRecsList = _.reject(this.state.recsList, { id: recId })
    this.setState({ recsList: updatedRecsList })
  }

  updateRecStatus(recId, newStatus) {
    const recsList = this.state.recsList
    const rec = recsList.find(r => r.id === recId)
    rec.status = newStatus
    rec.updated_at = moment()
    this.setState({ recsList })
  }

  addRecToRecsList(rec) {
    const recsList = this.state.recsList
    recsList.push(rec)
    this.setState({ recsList })
  }

  updateOptions(recId, newOptions) {
    if (recId) {
      const newRecsList = this.state.recsList.slice()

      // find rec
      const rec = this.state.recsList.find(r => r.id === recId)
      // Doing extend so we update rather than set
      const currentOptions = rec.options
      rec.options = $.extend(currentOptions, newOptions)

      // update state with new order preference
      this.setState({ recsList: newRecsList })
    } else {
      console.error('iFrame Display Error', { context: 'iFrame error changing order preference' })
      flash.error(
        'There was a problem changing order preferences' +
        'Contact support@avhana.com for further assistance.')
    }
  }

  updateEncounter(encounter) {
    this.setState({ encounter })
  }

  render() {
    // sub component declarations, see return method below
    const recsList = this.state.recsList
    const patient = this.state.patient
    let recGroupings

    if (!this.state.loading) {
      if (recsList.length > 0) {
        // separate scores and all other recs
        const recsListPartitioned = _.partition(recsList, { rule_type: 'score' })
        const recsListNoScores = recsListPartitioned[1]

        // separate queued orders and all other recs
        const recsListNoScoresPartitioned = _.partition(recsListNoScores, { status: 'order queued' })
        let queuedOrdersList = recsListNoScoresPartitioned[0]
        const recsListNoQueued = recsListNoScoresPartitioned[1]

        const recGroups = _.groupBy(recsListNoQueued, (rec) => {
          const diagnosis = rec.rule.snomed_diagnosis_name || 'Health Maintenance'
          return diagnosis
        })

        // Object elements are sorted by order in which they were created.
        // Want alphabetical order but with immunizations at top
        const sortedRecGroups = _.pick(recGroups, 'Immunization')
        Object.keys(_.omit(recGroups, 'Immunization')).sort().forEach((key) => {
          sortedRecGroups[key] = recGroups[key]
        })

        if (queuedOrdersList.length > 0) {
          queuedOrdersList = _.sortBy(queuedOrdersList, rec => moment(rec.updated_at)).reverse()
        }

        recGroupings = _.map(sortedRecGroups, (value, key) =>
          <RecommendationsGroup
            key={key}
            emr={this.props.emr}
            recsList={value}
            diagnosisKey={key}
            updateOptions={this.updateOptions}
            updateRecStatus={this.updateRecStatus}
            addRecToRecsList={this.addRecToRecsList}
            removeRecommendation={this.removeRecommendation}
            encounter={this.state.encounter}
            patient={patient}
          />
        )
      } else {
        recGroupings =
          <p className="bold-text no-recs-text" style={{ paddingLeft: '1em' }}>There are no recommendations for this patient. Click <a href="/rules">here</a> to view which guidelines are active in your system.</p>
      }
    } else {
      recGroupings = <LoadingSkeleton />
    }

    return (
      <div style={{ marginRight: '10%' }}>
        {recGroupings}
      </div>
    )
  }
}

RecommendationsIframe.propTypes = {
  emrPatientId: PropTypes.string,
  emr: PropTypes.string,
}

const renderRecommendation = (
  rec, emr, updateRecStatus, addRecToRecsList,
  removeRecommendation, updateOptions, encounter, patient
) => (
  <Recommendation
    key={rec.id}
    rec={rec}
    patient={patient}
    encounter={encounter}
    emr={emr}
    updateOptions={updateOptions}
    updateRecStatus={updateRecStatus}
    addRecToRecsList={addRecToRecsList}
    removeRecommendation={removeRecommendation}
    noActionButtons
    {...rec}
  />
)

const renderRecommendations = (
  recsList, emr, updateRecStatus, addRecToRecsList,
  removeRecommendation, updateOptions, encounter, patient
) => (
  recsList.map(rec => (
    renderRecommendation(
      rec, emr, updateRecStatus, addRecToRecsList,
      removeRecommendation, updateOptions, encounter, patient
    )
  ))
)

// destructure and spread better here
// functions for organizing Recommendations
const RecommendationsGroup = ({
  recsList, diagnosisKey, emr, updateRecStatus, addRecToRecsList,
  removeRecommendation, updateOptions, encounter, patient,
}) => {
  const recs = renderRecommendations(
    recsList, emr, updateRecStatus, addRecToRecsList,
    removeRecommendation, updateOptions, encounter, patient
  )

  return (
    <div>
      <span className="rule-problem-group">{diagnosisKey}</span>
      {recs}
      <br />
    </div>
  )
}

RecommendationsGroup.propTypes = {
  recsList: PropTypes.arrayOf(PropTypes.shape({})),
  diagnosisKey: PropTypes.string,
  emr: PropTypes.string,
  updateRecStatus: PropTypes.func,
  addRecToRecsList: PropTypes.func,
  removeRecommendation: PropTypes.func,
  updateOptions: PropTypes.shape({}),
  encounter: PropTypes.shape({}),
  patient: PropTypes.shape({}),
}

const mountReact = () => {
  const reactNode = document.getElementById('render-iframe-react')
  const reactData = $(reactNode).data()

  if (reactData) {
    ReactDOM.render(
      <RecommendationsIframe
        emr={reactData.emr}
        emrPatientId={reactData.emrpatientid}
        emrDepartmentId={reactData.emrdepartmentid}
        organizationId={reactData.organizationid}
      />,
      reactNode
    )
  }
}

$(mountReact)
