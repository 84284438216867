import React from 'react'
import Chip from '@mui/material/Chip'

const styles = {
  section: {
    fontSize: "14px",
    color: "white",
    backgroundColor: "rgba(2, 47, 64, 0.7)",
    padding: "5px",
  },
  wrapper: {
    textAlign: "right",
    margin: "0 auto",
    marginTop: "5px",
    marginRight: "5px"
  }
}

const StagingChip = () => (
    <>
      <div style={styles.wrapper}>
        <Chip label="STAGING" style={styles.section}/>
      </div>
    </>
  )

export default StagingChip