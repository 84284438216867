import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import DepartmentsTypeahead from '../reusableComponents/DepartmentsTypeahead'

const DEPARTMENT = PropTypes.shape({
  name: PropTypes.string,
})

const propTypes = {
  departments: PropTypes.arrayOf(DEPARTMENT).isRequired,
  currentDepartment: DEPARTMENT,
  organizationId: PropTypes.number.isRequired,
}

const defaultProps = {
  currentDepartment: {
    name: '',
  },
}

const onOptionSelected = (opt, context) => {
  context.setState({ entry: opt.name })
  const hiddenInput = document.querySelector('#care_team_department_id')
  hiddenInput.value = opt.id
}

class DepartmentSearch extends Component {
  constructor(props) {
    super(props)
    const { currentDepartment } = props
    this.state = {
      department: currentDepartment,
    }
  }

  render() {
    const { departments, organizationId } = this.props
    const { department } = this.state
    return (
      <DepartmentsTypeahead
        department={department || undefined}
        organizationId={organizationId}
        onOptionSelected={onOptionSelected}
        name="care_team[department_id]"
        options={departments}
      />
    )
  }
}

DepartmentSearch.propTypes = propTypes
DepartmentSearch.defaultProps = defaultProps

const mountReact = () => {
  const reactNode = document.getElementById('care-team-department')
  const reactData = $(reactNode).data()
  if (reactData) {
    const {
      organizationId,
      departments,
      currentDepartment,
    } = reactData
    ReactDOM.render(
      <DepartmentSearch
        organizationId={organizationId}
        departments={departments}
        currentDepartment={currentDepartment}
      />,
      reactNode,
    )
  }
}

$(mountReact)
