import React from 'react'

export default class LabWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = { expanded: false, shortDisplayCount: 5 }

    this.labElement = this.labElement.bind(this)
    this.buildLabWidgetUi = this.buildLabWidgetUi.bind(this)
    this.toggleExpanded = this.toggleExpanded.bind(this)
  }

  render() {
    const labs = _.chain(this.props.observations)
                  .filter({ 'observation_type': 'lab' })
                  .sortBy(obs => obs.date).reverse()
                  .sortBy(obs => obs.date == null) // Put labs with nil date at the end of the list
                  .slice(0, 50)
                  .value()

    const labWidgetUi =
      labs.length > 0 ? this.buildLabWidgetUi(labs) : <li>None Available</li>

    return (
      <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem', paddingTop: '10px' }}>
        <div className="last-visit bold-text" style={{ fontSize: '16px' }}>
          Past Labs
        </div>
        <div>
          <ul style={{ paddingLeft: '0.5rem', marginTop: '2px' }}>
            {labWidgetUi}
          </ul>
        </div>
      </div>
    )
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded })
  }

  labElement(observation) {
    let el
    if (observation.value) {
      const analyteKeys = Object.keys(observation.value)
      if (analyteKeys.length > 0) {
        const analytes =
          analyteKeys.map(key =>
            <div className="row" style={{ marginBottom: '0px', marginRight: 0, marginLeft: 0, display: 'flex' }}>
              <div className="col s6" style={{ paddingLeft: 0 }}>
                {key}
              </div>
              <div className="col s3" style={{ textAlign: 'right', margin: 'auto' }}>
                { observation.value[key] ? observation.value[key]['value'] : '' }
              </div>
              <div className="col s3" style={{ textAlign: 'left', margin: 'auto' }}>
                { observation.value[key] ? observation.value[key]['units'] : '' }
              </div>
            </div>
          )

        el = (
          <div style={{ marginBottom: '10px' }} key={observation.id}>
            <div className="row" style={{ marginRight: 0, marginLeft: 0, marginBottom: '0px', display: 'flex' }}>
              <div className="col s6" style={{ paddingLeft: '0px', margin: 'auto', fontWeight: 'bold' }}>
                {observation.name}
              </div>
              <div className="col s6" style={{ textAlign: 'right', margin: 'auto' }}>
                {observation.date || 'No Date'}
              </div>
            </div>
            {analytes}
          </div>
        )
      }
    }

    return el
  }

  buildLabWidgetUi(sortedObservations) {
    let choppedSortedObservations = sortedObservations
    if (!this.state.expanded) {
      choppedSortedObservations = sortedObservations.slice(0, this.state.shortDisplayCount)
    }

    const remaining = sortedObservations.length - this.state.shortDisplayCount
    let showMoreButton
    if (remaining > 0) {
      showMoreButton = (
        <a style={{ cursor: 'pointer' }} onClick={this.toggleExpanded}>
          { this.state.expanded ? 'Collapse' : `Show ${remaining} More Labs` }
        </a>
      )
    }

    return (
      <div>
        {_.map(choppedSortedObservations, observation =>
          <li key={observation.id}>
            {this.labElement(observation)}
          </li>
        )}
        <div>
          {showMoreButton}
        </div>
      </div>
    )
  }
}
