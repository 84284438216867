import PropTypes from 'prop-types'
import React, { Component } from 'react'
import algoliasearch from 'algoliasearch'
import { TextField, InputAdornment } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'

const propTypes = {
  optionSelected: PropTypes.func.isRequired,
  columns: PropTypes.string,
  style: PropTypes.shape({}),
}

const defaultProps = {
  columns: '8',
  style: {},
}

const formatTypeaheadData = (obj) => {
  if (obj.order_set) {
    return `(${obj.orders.length}) ${obj.name}`
  }
  return obj.name
}

/**
 * This component takes in values from the options column on an OrderPreference
 * and implements Typeahead so user can change the order pref.
 * Pared down version of AthenaOrderPrefTypeahead that omits the callbacks
 * @prop {pref} an OrderPreference ActiveRecord
 */
export default class AthenaOrderPrefTypeaheadBasic extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderName: props.orderName || '',
      orderType: '',
      options: [],
      prevOptions: [],
    }

    this.handleChange = this.handleChange.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.optionSelected = this.optionSelected.bind(this)
  }

  /**
   * @method getDict fires onChange of the Typeahead
   * @param {text} user input text from Typeahead
   */
  getDict(text) {
    const { orderType } = this.state
    const client = algoliasearch(
      'Q5ZBLQF81V',
      'df6aba0fce0130170bd701c1b4089506'
    )
    // Can do stricter ordering by changing the ranking order on algolia to put filters
    // first (before typo, geo, etc.). See here: https://www.algolia.com/doc/guides/relevance/ranking#ranking-formula-a-tie-breaking-algorithm
    const queries = [
      {
        indexName: 'AthenaOrderCode',
        query: text,
        params: {
          hitsPerPage: 35,
          optionalFacetFilters: [`order_type:${orderType}`],
        },
      },
      {
        indexName: 'AthenaOrderSet',
        query: text,
        params: {
          hitsPerPage: 5,
          filters: `organization_id=${window.CurrentUser.organization_id}`,
        },
      },
    ]

    client.search(queries)
      .then((content) => {
        this.searchCallback(content)
      })
      .catch((err) => {
        if (err) {
          console.error(err)
          return
        }
      })
  }

  /**
   * @method searchCallback callback invoked upon successful search; updates state for typeahead options
   * @param {content} content results returned from search
   */
  searchCallback(content) {
    const orderCodesResults = content.results[0]
    const orderSetsResults = content.results[1]

    if (
      orderCodesResults.hits.length > 0 ||
      orderSetsResults.hits.length > 0
    ) {
      // needed to differentiate between hits for the two different Algolia indices
      const formattedOrderSetsHits = orderSetsResults.hits.map((hit) => ({
        ...hit,
        order_set: true,
      }))

      const { options } = this.state
      this.setState({
        prevOptions: options,
        options: formattedOrderSetsHits.concat(orderCodesResults.hits),
      })
    }
  }

  optionSelected(orderName) {
    const { optionSelected } = this.props
    const { prevOptions, options } = this.state
    let opt = options.find((o) => o.name === orderName)
    if (!opt) {
      opt = prevOptions.find((o) => o.name === orderName)
    }
    this.setState({ orderName })
    optionSelected(opt)
  }

  /**
   * @method handleChange clears existing timer and sets new timer
   * with getDict
   * @param {event} event with value of Typeahead input
   */
  handleChange(event) {
    // need to use this to access these vars within setTimeout scope
    const { value } = event.target
    this.setState({ orderName: value })
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.getDict(value)
    }, 300)
  }

  /**
   * @method clearInput clears input on typeahead and sets cursor focus to search input
   */
  clearInput() {
    this.setState({ orderName: '', options: [] })
    this.input.focus()
  }

  /**
   * @method formatTypeaheadData uses obj from algolia creates proper display text for typeahead
   */

  render() {
    const { options, orderName } = this.state

    return (
      <Autocomplete
        freeSolo
        options={options.map((o) => formatTypeaheadData(o))}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              type: 'search',
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={this.handleChange}
            label="Search orders here"
          />
        )}
        onChange={(_event, value, reason) => {
          if (reason === 'selectOption') {
            this.optionSelected(value)
          }
        }}
        value={orderName}
        id="autocomplete"
      />
    )
  }
}

AthenaOrderPrefTypeaheadBasic.propTypes = propTypes
AthenaOrderPrefTypeaheadBasic.defaultProps = defaultProps
