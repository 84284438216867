import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import LoadingSkeleton from '../../loadingSkeleton/LoadingSkeleton'

const columns = [
  { field: 'valueSetName', headerName: 'Name', width: 200 },
  { field: 'description', headerName: 'Description', width: 200},
  { field: 'codeSystem', headerName: 'Code System', width: 200},
  { field: 'code', headerName: 'Code', width: 200},
  { field: 'type', headerName: 'Type', width: 200},
]

// Custom function to generate unique IDs

const ShowMigrationValueSets = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    const fetchData = async () => {
      $.ajax({
        method: 'get',
        url: '/root/value_sets_migration_list',
        dataType: 'json',
        contentType: 'application/json',
      })
        .done((response) => {
          setData(response.value_sets)
          setLoading(false)
        })
        .fail((errors) => {
          setError(errors)
          console.error(errors)
        })
    }
    fetchData()
  }, [])

  const getRowId = (row) => row.description + row.valueSetName + row.codeSystem + row.code + row.type

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ marginBottom: '50px' }}>
            <h1>Migration Value Sets</h1>
      </div>
      {loading ? ( 
        <LoadingSkeleton />
      ) : (
      <DataGrid
        rows={data}
        columns={columns}
        autoHeight
        getRowId={getRowId}
        pageSize={5}
      />
      )}
    </div>
  )
}

export default ShowMigrationValueSets