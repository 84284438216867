import React, { useState }  from 'react'
import PropTypes from 'prop-types'
import { path, pathOr } from 'ramda'
import { Grid, InputLabel, MenuItem, Select } from '@mui/material'
import { styled } from '@mui/material/styles'


const propTypes = {
  pref: PropTypes.shape().isRequired,
  dropdownOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const StyledGrid = styled(Grid)({
  marginTop: '1em', 
  marginBottom: '1em'
})

const StyledInputLabel = styled(InputLabel)({
  marginBottom: '1em'
})

const StyledSelect = styled(Select)({
  minWidth: '200px'
})

const defaultProps = {}

const OrderingModeDropdown = ({ pref, dropdownOptions }) => {
  const orderSet = path(['options', 'order_set'], pref)
  const orderType = path(['options', 'orders', 0, 'order_type'], pref)

  if (orderSet || orderType !== 'vaccination') {
    return null
  }

  const initialOrderingMode = pathOr('Administer', ['options', 'orders', 0, 'ordering_mode'], pref)
  const [orderingMode, setOrderingMode] = useState(initialOrderingMode)

  const updateOrderingMode = ({ target: { value } }) => {
    const options = {
      orders: [{ ordering_mode: value }]
    }

    const data = JSON.stringify({
      ...pref,
      options,
    })

    $.ajax({
      method: 'put',
      url: `/admin/order_preferences/${pref.id}`,
      dataType: 'json',
      contentType: 'application/json',
      data
    }).
      done((response) => {
        setOrderingMode(response.orders[0].ordering_mode)
        // Toast...
      })
      .fail((error) => {
        // Toast error
        console.error(error.responseText, { context: `OrderingModeDropdown update error message: ${error.responseText}` })
      })
  }

  const menuItems = dropdownOptions.map((option) => (
    <MenuItem key={option} value={option.toLowerCase()}>{option}</MenuItem>
  ))

  return (
    <StyledGrid
      container
      xs={12}
      direction="column"
    >
      <Grid item>
        <StyledInputLabel id="ordering-mode">Select Ordering Mode</StyledInputLabel>
      </Grid>
      <Grid item>
        <StyledSelect labelId="ordering-mode" value={orderingMode.toLowerCase()} onChange={updateOrderingMode}>
          {menuItems}
        </StyledSelect>
      </Grid>
    </StyledGrid>
  )
}

OrderingModeDropdown.propTypes = propTypes
OrderingModeDropdown.defaultProps = defaultProps

export default OrderingModeDropdown
