import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'

const ToggleComboOrderButton = ({ orderPreference, updateOrderPreference }) => {
  const orderSetPath = R.lensPath(['options', 'order_set'])
  const ordersPath = R.lensPath(['options', 'orders'])
  const selectionsPath = R.lensPath(['options', 'selections'])
  const orderSet = R.view(orderSetPath, orderPreference)
  const orders = R.view(ordersPath, orderPreference)
  const onClick = () => {
    let updatedOrderPreference = R.set(orderSetPath, !orderSet, orderPreference)
    updatedOrderPreference = R.set(selectionsPath, false, updatedOrderPreference)
    /* Combo orders MUST have two orders. If switching to a combo order and
     * there are none, add two empty objects. If there is an initial order, add
     * one empty object. If switching from a combo order, keep the first order
     * (this will still work if there is no first order.)
     */
    const firstOrder = orders.slice(0, 1)
    let newOrders
    if (firstOrder.length === 0 && !orderSet) {
      newOrders = [{}, {}]
    } else if (firstOrder.length === 1 && !orderSet) {
      newOrders = [...firstOrder, {}]
    } else if (firstOrder.length === 1 && firstOrder[0].order_name === undefined && orderSet) {
      newOrders = []
    } else if (orderSet) {
      newOrders = firstOrder
    }
    updatedOrderPreference = R.set(ordersPath, newOrders, updatedOrderPreference)
    updateOrderPreference(updatedOrderPreference)
  }
  if (orderSet) {
    return <button type="button" onClick={onClick}>Revert to Single Order</button>
  }
    return <button type="button" onClick={onClick}>Make Combo Order</button>
}

ToggleComboOrderButton.propTypes = {
  orderPreference: PropTypes.shape({
    id: PropTypes.number.isRequired,
    options: PropTypes.shape({
      order_set: PropTypes.bool
    })
  }).isRequired,
  updateOrderPreference: PropTypes.func.isRequired,
}

export default ToggleComboOrderButton
