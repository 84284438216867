import React from 'react'
import Appointment from './Appointment.jsx'

const renderAppointments = (props) => {
  const appointmentsList = props.appointmentsList
  const emr = props.emr
  const orgId = props.organizationId
  const aptTypesBlacklist = props.aptTypesBlacklist
  const timeZoneOffset = props.timeZoneOffset
  const getRecommendations = props.getRecommendations
  const activeAppointmentId = props.activeAppointment && props.activeAppointment.id
  const appointments = appointmentsList.map((apt, index) => {
    // for react joyride
    let appointmentCardClassName = '.apt-card'
    if (index === 0) {
      appointmentCardClassName = '.apt-card-react-joyride'
    }
    const isFiltered = _.includes(aptTypesBlacklist, apt.reason) || apt.has_queued_orders

    let aptCard
    if (apt.patient_id) {
      aptCard = (
        <Appointment
          key={apt.id}
          className={appointmentCardClassName}
          apt={apt}
          emr={emr}
          currentUser={props.currentUser}
          orgId={orgId}
          activeAppointmentId={activeAppointmentId}
          timeZoneOffset={timeZoneOffset}
          isFiltered={isFiltered}
          getRecommendations={getRecommendations}
        />
      )
    } else {
      console.error('renderAppointments error', { context: `error on appointment ${apt.id}` })
      aptCard = (
        <div key={apt.id} className="apt-card card white">
          <div className="card-content">
            <div className="row">
              <h6>{`The data for Patient #${apt.emr_patient_id} is invalid.`}</h6>
              <p>
                Verify that the patient's birthdate and sex exist.
                If you have further questions contact support@avhana.com
              </p>
            </div>
          </div>
        </div>
      )
    }

    return aptCard
  })

  return appointments
}

const AppointmentsTable = props => (
  <div role="listbox" className="appointments-list" style={{ paddingBottom: '18px' }}>
    {renderAppointments(props)}
  </div>
)

export default AppointmentsTable
