import React from 'react'
import { bool } from 'prop-types'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const propTypes = {
  notProduction: bool.isRequired,
}

// Create header for ValueSet Table

const Header = ({ notProduction }) => (
  <TableHead>
    <TableRow>
      <TableCell align="left">
        <h4>
          Name
        </h4>
      </TableCell>
      <TableCell align="left">
        <h4>
          Description
        </h4>
      </TableCell>
      <TableCell align="left">
        <h4>
          Code
        </h4>
      </TableCell>
      <TableCell align="left">
        <h4>
          Code System
        </h4>
      </TableCell>
      {notProduction && (
        <TableCell align="left">
          <h4>
            Action
          </h4>
        </TableCell>
      )}
    </TableRow>
  </TableHead>
)
Header.propTypes = propTypes

export default Header
