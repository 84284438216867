import M from 'materialize-css'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

import AppointmentsTable from './AppointmentsTable'
import PatientBox from './PatientBox'
import LoadingSkeleton from '../loadingSkeleton/LoadingSkeleton'
import CareTeamSelector from './CareTeamSelector'
import DateNavigator from './DateNavigator'
import PreVisitWalkthrough from '../onboarding/PreVisitWalkthrough'

/**
 * @class PreVisitDashboard This is the main component for the Appointments page
 * and is where we handle the first load and subsequent reloads of the Appointments
 * page
 * @prop appointmentIdFromSso Appointment id from SSO
 * @prop appointmentDateFromSso Appointment date from SSO
 * @prop currentOrganization A serialized organization
 * @prop currentUser A serialized user
 * @prop timeZoneOffset Time zone offset
 * @prop emr A string specifying the emr of the current user
 */

class PreVisitDashboard extends React.Component {
  /**
   * @attribute selectedDate A string either yesterday, today, or tomorrow
   * @attribute appointmentsList An array of appointment objects
   * @attribute loading A boolean flag for loading state
   * @attribute emr A string of current user's emr
   */

  constructor(props) {
    super(props)
    this.state = {
      selectedMomentDate: moment(),
      appointmentsList: [],
      loading: true,
      emr: '',
      selectedEmrPatientId: '',
      emrDepartmentId: '',
      organizationId: '',
      activeAppointment: null,
      prebuilt: true,
      careTeam: '',
    }
    this.searchForNewAppointments = this.searchForNewAppointments.bind(this)
    this.getRecommendations = this.getRecommendations.bind(this)
    this.dateChange = this.dateChange.bind(this)
    this.toggleCalendar = this.toggleCalendar.bind(this)
    this.handleDatepickerClose = this.handleDatepickerClose.bind(this)
    this.setUpdatedActiveAppointment = this.setUpdatedActiveAppointment.bind(
      this
    )
    this.setActiveAppointment = this.setActiveAppointment.bind(this)
    this.setNearestAppointment = this.setNearestAppointment.bind(this)
    this.updateActiveAppointment = this.updateActiveAppointment.bind(this)
    this.careTeamChangeHandler = this.careTeamChangeHandler.bind(this)
    this.debouncedApptsSearch = _.debounce(this.searchForNewAppointments, 1000)
  }

  /**
   * @method componentDidMount call appointments/ and populate appoinments
   * dashboard on initial load of page
   */
  componentDidMount() {
    const {
      emr,
      currentOrganization,
      currentUser: { care_team },
      timeZoneOffset,
    } = this.props

    const careTeam = care_team ? care_team.id : null
    const { id: organizationId, appointmentSmartFilter } = currentOrganization
    const aptTypesBlacklist = appointmentSmartFilter
      ? appointmentSmartFilter.appointment_types
      : []

    const avhanaHostUrl = process.env.AVHANA_HOST_URL
    console.log('avhana host url', avhanaHostUrl)

    // set states for default page load of appointments
    this.setState({
      aptTypesBlacklist,
      careTeam,
      emr,
      organizationId,
      selectedMomentDate: moment(),
      timeZoneOffset,
    })

    const _this = this
    // day is in format "YYYY-MM-DD" whether it comes from props or moment
    let parsedMomentDate
    let stringDate
    if (this.props.appointmentDateFromSso) {
      parsedMomentDate = moment(this.props.appointmentDateFromSso, 'YYYY-MM-DD')
      stringDate = this.props.appointmentDateFromSso
    } else {
      parsedMomentDate = moment()
      stringDate = parsedMomentDate.format('YYYY-MM-DD')
    }

    // Init datepicker.
    const element = document.querySelector('.datepicker')
    const options = {
      defaultDate: moment().toDate(),
      setDefaultDate: true,
      onClose: this.handleDatepickerClose,
      minDate: moment().subtract(3, 'days').toDate(),
      maxDate: moment().add(6, 'months').toDate(),
    }

    this.datepicker = M.Datepicker.init(element, options)
    // make call to populate dashboard on first load
    $.get('/appointments', { date: stringDate })
      .done((appointments) => {
        this.setState({ appointmentsList: appointments })
        if (appointments.length > 0) {
          if (_this.props.appointmentIdFromSso) {
            const appointmentFromSso = _.find(appointments, (apt) => {
              return apt.id === _this.props.appointmentIdFromSso
            })
            if (appointmentFromSso) {
              let emrDepartmentId
              const department = appointmentFromSso.department
              if (department) {
                emrDepartmentId = department.emr_department_id
              }
              this.setState({
                selectedEmrPatientId: appointmentFromSso.emr_patient_id,
                emrDepartmentId: emrDepartmentId,
                activeAppointment: appointmentFromSso,
                selectedMomentDate: parsedMomentDate,
                prebuilt: 'true',
              })
            } else {
              console.error({
                name: 'Apointments load Error',
                message: `appointment ${this.props.appointmentIdFromSso}`,
              })
              this.setNearestAppointment(appointments)
            }
          } else {
            this.setNearestAppointment(appointments)
          }
        }
      })
      .always((data) => this.setState({ loading: false }))
      .fail((errors) => {
        if (checkTimeout(errors) === true) {
          redirectToLogin()
        } else {
          flash.notice(
            'There was a problem loading appointments. ' +
              'Contact support@avhana.com for further assistance.'
          )
          console.error({
            name: 'Apointments load Error',
            message: 'Appointment load error',
          })
        }
      })
  }

  /**
   * @method componentDidUpdate
   */
  componentDidUpdate() {
    // set active apt card in the view
    const activeCard = document.getElementsByClassName(
      'apt-card card blue-bkg white-text'
    )[0]
    if (activeCard) {
      activeCard.scrollIntoView()
    }
  }

  searchForNewAppointments() {
    const params = {
      date: this.state.selectedMomentDate.format('YYYY-MM-DD'),
    }
    $.get('/appointments', params)
      .done((appointments) => {
        this.setState({ appointmentsList: appointments })
        if (appointments.length > 0) {
          this.setNearestAppointment(appointments)
        } else {
          this.setState({
            selectedEmrPatientId: undefined,
            emrDepartmentId: undefined,
            activeAppointment: undefined,
          })
        }
      })
      .always((data) => this.setState({ loading: false }))
      .fail((errors) => {
        if (checkTimeout(errors) === true) {
          redirectToLogin()
        } else {
          flash.notice(
            'There was a problem loading appointments. ' +
              'Contact support@avhana.com for further assistance.'
          )
          console.error(errors, { context: 'Appointments Load Error' })
        }
      })
  }

  departmentId(appointment) {
    if (appointment.department) {
      return appointment.department.emr_department_id
    } else {
      return ''
    }
  }

  // updates activeAppointment and active appointment in appointmentsList
  // used by pull previous documentation that receives an updated active appointment
  setUpdatedActiveAppointment(activeAppointment) {
    const appointmentsList = this.state.appointmentsList
    const activeAppointmentToUpdate = _.find(
      appointmentsList,
      (appointment) => appointment.id === activeAppointment.id
    )
    if (activeAppointmentToUpdate) {
      Object.assign(activeAppointmentToUpdate, activeAppointment)
    }

    this.setState({ activeAppointment, appointmentsList })
  }

  setActiveAppointment(appointment) {
    this.setState({
      selectedEmrPatientId: appointment.emr_patient_id,
      emrDepartmentId: this.departmentId(appointment),
      activeAppointment: appointment,
    })
  }

  setNearestAppointment(appointments) {
    let appointment = _.find(appointments, (apt) => {
      // this function compares the appointment time to now and returns the appointment
      // within 30 minutes of now
      // moment.diff() accepts a time and compares it to now, and the second
      // parameter defines the unit returned.
      return Math.abs(moment().diff(apt.appointment_time, 'minutes')) <= 30
    })
    if (!appointment) {
      appointment = appointments[0]
    }

    this.setState({
      selectedEmrPatientId: appointment.emr_patient_id,
      emrDepartmentId: this.departmentId(appointment),
      activeAppointment: appointment,
    })
  }

  updateActiveAppointment(appointmentId) {
    const cachedActiveAppointment = { ...this.state.activeAppointment }

    const activeAppointmentWhenReloading = {
      ...this.state.activeAppointment,
      planned_diagnoses: ['reloading'],
    }
    this.setState({ activeAppointment: activeAppointmentWhenReloading })

    $.get(`/appointments/${appointmentId}`, { prebuilt: 'false' })
      .done((activeAppointment) => {
        const appointmentsList = this.state.appointmentsList
        const aptToUpdate = _.find(
          appointmentsList,
          (apt) => apt.id === activeAppointment.id
        )
        if (aptToUpdate) {
          Object.assign(aptToUpdate, activeAppointment)
        }
        this.setState({ activeAppointment, appointmentsList })
      })
      .fail((errors) => {
        this.setState({ activeAppointment: cachedActiveAppointment })
        console.error(errors, { context: 'Problem list refresh failed' })
        flash.error(
          'There was a problem pulling the latest problem list for this patient.' +
            'Please contact support@avhana.com for further assistance.'
        )
      })
  }

  getRecommendations(event) {
    // pass in active card id here and set activeCard (id) state
    // set prebuilt to true expect on sso to previsit page
    this.setState({
      selectedEmrPatientId: event.emrPatientId,
      emrDepartmentId: event.departmentId,
      activeAppointment: event.apt,
      prebuilt: true,
    })
  }

  dateChange(event) {
    const currentMomentDate = this.state.selectedMomentDate

    let newMomentDate
    if (event.target.id === 'add-day') {
      newMomentDate = currentMomentDate.add(1, 'day')
    } else if (event.target.id === 'subtract-day') {
      newMomentDate = currentMomentDate.subtract(1, 'day')
    }

    this.setState({ selectedMomentDate: newMomentDate, loading: true })
    // debounce appts search until the user has stopped searching ahead/behind
    event.persist()
    this.debouncedApptsSearch(event)
  }

  toggleCalendar(event) {
    if (this.datepicker.isOpen) {
      this.datepicker.close()
    } else {
      this.datepicker.gotoDate(moment().toDate())
      this.datepicker.open()
    }
  }

  handleDatepickerClose(event) {
    const date = moment(this.datepicker.date)
    this.setState({ selectedMomentDate: date, loading: true })
    this.debouncedApptsSearch(event)
  }

  careTeamChangeHandler(event, index, value) {
    const newCareTeamId = value
    $.post(`/care_teams/${newCareTeamId}/users?id=${this.props.currentUser.id}`)
      .done((response) => {
        this.setState({ careTeam: newCareTeamId, loading: true })
        event.persist()
        this.debouncedApptsSearch(event)
      })
      .fail((error) => {
        if (checkTimeout(error) === true) {
          redirectToLogin()
        } else {
          this.setState({
            alertText: 'There was an error joining the care team you selected',
          })
          console.error(error, { context: 'CareTeamPicker Error' })
        }
      })
  }

  render() {
    // view logic allscripts only print schedule button
    let printSchedButton
    if (this.props.emr === 'allscripts') {
      printSchedButton = (
        <div className="center-align print-btn-container">
          <a
            href={`/previsit_report/download.pdf?user_id=${
              this.props.currentUser.id
            }&date=${this.state.selectedMomentDate.format('YYYY-MM-DD')}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className="btn blue-bkg center-align"
            style={{ marginHeight: '0px' }}
            id="print-schedule-button"
          >
            <i className="material-icons left">print</i>
            Print Schedule
          </a>
        </div>
      )
    }
    // view logic for appointments table show
    let appointmentsTable
    if (this.state.loading) {
      appointmentsTable = <LoadingSkeleton />
    } else if (this.state.appointmentsList.length > 0) {
      appointmentsTable = (
        <div>
          <AppointmentsTable
            currentUser={this.props.currentUser}
            emr={this.state.emr}
            organizationId={this.state.organizationId}
            aptTypesBlacklist={this.state.aptTypesBlacklist}
            appointmentsList={this.state.appointmentsList}
            timeZoneOffset={this.state.timeZoneOffset}
            getRecommendations={this.getRecommendations}
            activeAppointment={this.state.activeAppointment}
          />
          {printSchedButton}
        </div>
      )
    } else {
      appointmentsTable = (
        <div style={{ fontSize: '1.25rem' }}>
          <p className="center-align">
            There are either no appointments for this date, or you are not a
            member of a care team. Select a care team in the dropdown menu above
            and get started with pre-visit planning.
          </p>
        </div>
      )
    }

    let patientBox
    if (this.state.selectedEmrPatientId) {
      patientBox = (
        <PatientBox
          organization={this.props.currentOrganization}
          activeAppointment={this.state.activeAppointment}
          emr={this.state.emr}
          emrPatientId={this.state.selectedEmrPatientId}
          emrDepartmentId={this.state.emrDepartmentId}
          organizationId={this.state.organizationId}
          prebuilt={this.state.prebuilt}
          currentUser={this.props.currentUser}
          updateActiveAppointment={this.updateActiveAppointment}
          setUpdatedActiveAppointment={this.setUpdatedActiveAppointment}
          refreshProblemList={this.refreshProblemList}
        />
      )
    }

    let careTeamSelector
    if (this.props.currentUser.medical_assistant) {
      careTeamSelector = (
        <CareTeamSelector
          selectedCareTeam={this.state.careTeam}
          careTeamChangeHandler={this.careTeamChangeHandler}
          careTeams={this.props.currentUser.accessible_care_teams}
        />
      )
    }

    let onboardingWalkthrough
    const finishedTour = sessionStorage.getItem('finishedTour')
    if (
      this.props.currentUser.total_logins_count < 3 &&
      finishedTour !== 'true' &&
      this.state.emr != 'allscripts'
    ) {
      onboardingWalkthrough = <PreVisitWalkthrough />
    }
    return (
      <div className="row">
        {onboardingWalkthrough}
        <div
          style={{ zIndex: '100' }}
          className="fixed-pre-visit-column col s2 hide-on-small-only"
        >
          <div className="appt-selectors-container">
            <DateNavigator
              selectedMomentDate={this.state.selectedMomentDate}
              dateChange={this.dateChange}
              toggleCalendar={this.toggleCalendar}
            />
            <div className="row" style={{ marginBottom: '0px' }}>
              {careTeamSelector}
            </div>
          </div>
          <div className="row" style={{ height: '100vh', overflowY: 'scroll' }}>
            {appointmentsTable}
          </div>
        </div>
        {patientBox}
      </div>
    )
  }
}

PreVisitDashboard.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.number,
    appointmentSmartFilter: PropTypes.shape({
      appointment_types: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  currentUser: PropTypes.shape({
    care_team: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  emr: PropTypes.string.isRequired,
  timeZoneOffset: PropTypes.string.isRequired,
}

const mountReact = () => {
  const reactNode = document.getElementsByClassName(
    'appointments-filter-react'
  )[0]
  const reactData = $(reactNode).data()

  // this data is set in the DashboardController
  if (reactData) {
    ReactDOM.render(
      <PreVisitDashboard
        appointmentIdFromSso={reactData.appointmentidfromsso}
        appointmentDateFromSso={reactData.appointmentdatefromsso}
        currentOrganization={reactData.org}
        currentUser={reactData.currentuser.user}
        timeZoneOffset={reactData.timezoneoffset}
        emr={reactData.emr}
      />,
      reactNode
    )
  }
}

$(mountReact)
