import React from 'react'
import PropTypes from 'prop-types'

export default class ExpandableCheckboxList extends React.Component {
  /**
   * @prop list -> array, full array of data objects for checkboxes
   * @prop uniqueNameKey -> string, unique identifier of the checkbox set
   * @prop numberVisible -> integer, how many checkboxes to display for short list
   * @prop filterOnCheck -> function, update state in parent
   */

  constructor(props) {
    super(props)
    this.state = {
      viewMore: false,
      viewText: 'View More',
    }

    this.viewMoreLess = this.viewMoreLess.bind(this)
  }

  viewMoreLess() {
    const { viewMore } = this.state
    const viewText = viewMore ? 'View More' : 'View Fewer'
    this.setState({
      viewMore: !viewMore,
      viewText,
    })
  }

  render() {
    const {
      list, numberVisible, uniqueNameKey, filterOnCheck,
    } = this.props
    const { viewMore, viewText } = this.state
    const arrayToDisplay = viewMore ? list : list.slice(0, numberVisible)

    const checkboxes = arrayToDisplay.map((value, index) => {
      // no checkbox sets on the same page can have the same uniqueNameKey
      const id = `${uniqueNameKey}${index}`
      return (
        <p key={id}>
          <label htmlFor={id}>
            <input className="filled-in" type="checkbox" id={id} data-type={uniqueNameKey} onClick={filterOnCheck} name={value} />
            <span>{value}</span>
          </label>
        </p>
      )
    })

    return (
      <div>
        { checkboxes }
        <a role="button" tabIndex="0" className="center" onClick={this.viewMoreLess} onKeyDown={() => null}>{viewText}</a>
      </div>
    )
  }
}

ExpandableCheckboxList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  numberVisible: PropTypes.number.isRequired,
  filterOnCheck: PropTypes.func.isRequired,
  uniqueNameKey: PropTypes.string.isRequired,
}
