window.parseVital =  function parseVital(key, value) {
  switch (key) {
    case 'height':
      return value + ' cm'
    case 'weight':
      return value + ' kg'
    case 'heart_rate':
      return value + ' bpm'
    case 'blood_pressure_systolic':
    case 'blood_pressure_diastolic':
      return value + ' mm Hg'
    case 'measured_bmi':
      return value + ' kg/m2'
    case 'temperature':
      return value + ' 	\xB0F'
    case 'o2_saturation':
      return value + '%'
    default:
      return value
  }
}
