/* eslint react/no-danger: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import { marked } from 'marked'

/**
 * @method to convert markdown to html
 * sanitize and convert markdown string to html
 */
const rawMarkup = markup => ({
  __html: marked(markup, { sanitize: false, gfm: false }),
})

/**
 * @class MarkdownParser to render markdown
 * @prop string of markdown (ex. "# this is an h1 tag")
 */
const MarkdownParser = ({ markdown }) => (
  <div dangerouslySetInnerHTML={rawMarkup(markdown)} />
)

MarkdownParser.propTypes = {
  markdown: PropTypes.string,
}

export default MarkdownParser
