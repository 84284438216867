import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import TextareaAutosize from 'react-textarea-autosize'

const Item = ({ entity: { shortcut, expansion } }) => (
  <div style={{ cursor: 'pointer' }}>
    <b>{shortcut}</b>: {expansion}
  </div>
)

Item.propTypes = {
  entity: PropTypes.shape({
    shortcut: PropTypes.string,
    expansion: PropTypes.string,
  }).isRequired,
}
const Loading = () => <div>Loading</div>

const ResizeTextArea = React.forwardRef((props, ref) => (
  <TextareaAutosize inputRef={ref} {...props} />
))

export default class AssessmentNotes extends Component {
  constructor(props) {
    super(props)

    const assessmentNote = props.activeAppointment.assessment_note
    this.state = {
      draftSaved: true,
      text: (assessmentNote && assessmentNote.section_note) || '',
      checkedIn: !!props.activeAppointment.today_open_encounter,
    }

    this.handleTextBoxChanges = this.handleTextBoxChanges.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.debouncedHandleSubmit = _.debounce(this.handleSubmit, 1000)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { activeAppointment } = this.props
    const { text } = this.state
    const newAssessmentNote = newProps.activeAppointment.assessment_note
    const newNote = (newAssessmentNote && newAssessmentNote.section_note) || ''

    const noteChanged = (newNote !== text)
    const appointmentChanged = newProps.activeAppointment.id !== activeAppointment.id

    if (noteChanged || appointmentChanged) {
      this.setState({
        text: newNote,
        checkedIn: !!newProps.activeAppointment.today_open_encounter,
      })
    }
  }

  // this prevents un-needed re-renders, and also resolves the cursor issue?
  shouldComponentUpdate(newProps, newState) {
    const { checkedIn, draftSaved, text } = this.state
    const draftSavedChanged = (newState.draftSaved !== draftSaved)
    const noteChanged = (newState.text !== text)
    const checkedInChange = (newState.checkedIn !== checkedIn)
    if (draftSavedChanged || noteChanged || checkedInChange) {
      return true
    }
    return false
  }

  handleTextBoxChanges(event) {
    const text = event.target.value
    this.setState({ text, draftSaved: false })
    this.debouncedHandleSubmit(event)
  }

  handleSubmit() {
    const { activeAppointment, setUpdatedActiveAppointment } = this.props
    const { text } = this.state
    const { patient_id, id } = activeAppointment
    const params = { patient_id, section_note: text, appointment_id: id }
    $.post('/assessment_notes', params)
      .done(() => {
        this.setState({ draftSaved: true })

        const updatedAssessmentNote = {
          ...activeAppointment.assessment_note,
          section_note: text,
        }
        const updatedActiveAppointment = {
          ...activeAppointment,
          assessment_note: updatedAssessmentNote,
        }
        setUpdatedActiveAppointment(updatedActiveAppointment)
      })
      .fail((error) => window.handleErrorOrRedirectToLogin(error, 'AssessmentNotes Save Error'))
  }

  render() {
    const { currentUser: { text_macros: textMacros } } = this.props
    const { checkedIn, draftSaved, text } = this.state
    let helpText
    let updatedText
    let placeholderText

    if (draftSaved && text !== '') {
      updatedText = 'Draft saved. Note will be sent to next patient encounter.'
      helpText = 'To view entire saved note, click inside the text box and press the down arrow key'
    }

    if (checkedIn && text !== '') {
      updatedText = 'Patient is currently checked in. Note has been sent to the current encounter.'
    }

    if (checkedIn) {
      placeholderText = 'Notes cannot be entered if the patient is already checked in'
    } else {
      placeholderText = 'Type your note here'
    }

    const ua = window.navigator.userAgent
    const usingIE = ua.indexOf('MSIE') > 0 || ua.indexOf('Trident') > 0
    const textarea = usingIE
      ? (
        <textarea
          className="materialize-textarea"
          placeholder={placeholderText}
          disabled={checkedIn}
          value={text}
          onChange={this.handleTextBoxChanges}
        />
      ) : (
        <ReactTextareaAutocomplete
          className="materialize-textarea"
          trigger={{
            '.': {
              dataProvider: (token) => textMacros
                .filter((t) => t.shortcut.startsWith(token))
                .map(({ shortcut, expansion }) => ({ shortcut, expansion }))
                .slice(0, 5),
              component: Item,
              output: (item) => item.expansion,
              allowWhitespace: true,
            },
          }}
          textAreaComponent={ResizeTextArea}
          loadingComponent={Loading}
          placeholder={placeholderText}
          disabled={checkedIn}
          value={text}
          onChange={this.handleTextBoxChanges}
          itemStyle={{
            marginTop: '0.5rem',
            marginBottom: '0.5rem',
            cursor: 'pointer',
          }}
          minChar={2}
          innerRef={(el) => { this.textarea = el }}
        />
      )

    return (
      <div>
        <div className="card-title queued-notes-title" style={{ paddingBottom: '0em' }}>
          Assessment Notes
        </div>
        <div>
          {textarea}
        </div>
        <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
          {updatedText}
        </div>
        <div style={{ textAlign: 'right', fontStyle: 'italic' }}>
          {helpText}
        </div>
      </div>
    )
  }
}

AssessmentNotes.propTypes = {
  activeAppointment: PropTypes.shape({
    assessment_note: PropTypes.shape({
      section_note: PropTypes.string,
    }),
    today_open_encounter: PropTypes.object,
    patient_id: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
  setUpdatedActiveAppointment: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    textMacros: PropTypes.array,
  }).isRequired,
}
