import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { TextField, InputAdornment } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import SearchIcon from '@mui/icons-material/Search'

const USER = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
})

const USERS = PropTypes.arrayOf(USER)

const propTypes = {
  assignedUsers: USERS.isRequired,
  users: USERS.isRequired,
}

const getAvailableUsers = (users, assignedUsers) => {
  return users
    .filter((u) => assignedUsers.findIndex((user) => user.id === u.id) === -1)
    .map((u) => u.name)
}

class UserSearch extends Component {
  constructor(props) {
    super(props)
    const { assignedUsers } = this.props
    this.state = {
      assignedUsers,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleOptionSelected = this.handleOptionSelected.bind(this)
  }

  handleChange(event) {
    const { assignedUsers } = this.state
    const id = parseInt(event.target.value, 10)
    this.setState({ assignedUsers: assignedUsers.filter((u) => u.id !== id) })
  }

  handleOptionSelected(name) {
    const { users } = this.props
    const { assignedUsers } = this.state
    const newUser = users.find((user) => user.name === name)
    this.setState({ assignedUsers: [...assignedUsers, newUser] })
  }

  render() {
    const { users } = this.props
    const { assignedUsers } = this.state
    const availableUsers = getAvailableUsers(users, assignedUsers)
    const showAssignedUsers = assignedUsers.map((u) => (
      <div key={u.id} data-key={u.id} className="collection-check-box">
        <label htmlFor={`care_team_user_ids_${u.id}`}>
          <input type="checkbox" value={u.id} checked="checked" name="care_team[user_ids][]" id={`care_team_user_ids_${u.id}`} onChange={this.handleChange} />
          <span>{u.name}</span>
        </label>
      </div>
    ))

    return (
      <>
        <Autocomplete
          freeSolo
          options={availableUsers}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={this.handleChange}
              label="Search users here"
            />
          )}
          onChange={(_event, value, reason) => {
            if (reason === 'selectOption') {
              this.handleOptionSelected(value)
            }
          }}
          id="user-autocomplete"
        />
        <div style={{ marginTop: '1em' }} className="users-list">
          {showAssignedUsers}
        </div>
      </>
    )
  }
}

UserSearch.propTypes = propTypes

const mountReact = () => {
  const reactNode = document.getElementById('users-search')
  const reactData = $(reactNode).data()
  if (reactData) {
    const {
      assignedUsers,
      users,
    } = reactData
    ReactDOM.render(
      <UserSearch
        assignedUsers={assignedUsers}
        users={users}
      />,
      reactNode,
    )
  }
}

$(mountReact)
