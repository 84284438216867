import React from 'react'
import PropTypes from 'prop-types'

const Ttr = props => (
  <div>
    <div className="row">
      <div className="col s6 score-header-container">
        <div className="chad-score-header">Time in Therapeutic Range (TTR)</div>
        <div className="chad-score-header">2.0 - 3.0</div>
      </div>
      <div className="col s3 bubble_wrap">
        <div className="ascvd-score-text bubble-number-xl">
          {props.options.form_info.score}%
        </div>
        <h5 className="center">Rosendaal</h5>
      </div>
      <div className="col s3 bubble_wrap">
        <div className="ascvd-score-text bubble-number-xl">
          {props.options.form_info.traditional}%
        </div>
        <h5 className="center">Traditional</h5>
      </div>
    </div>
    <DataTable data={this.props.options.form_info.data} />
  </div>
)

Ttr.propTypes = {
  options: PropTypes.shape({
    form_info: PropTypes.shape({
      score: PropTypes.string,
      traditional: PropTypes.string,
    }),
  }),
}

const dataRow = (inrData, date) => {
  if (inrData.length === 2) {
    return (
      <tr key={date}>
        <td>{`${date.substring(5, 10)}-${date.substring(0, 4)}`}</td>
        <td>{inrData[0]}</td>
        <td>{inrData[1]}</td>
        <td />
        <td />
      </tr>
    )
  }
  return (
    <tr key={date}>
      <td>{`${date.substring(5, 10)}-${date.substring(0, 4)}`}</td>
      <td>{inrData[0]}</td>
      <td>{inrData[1]}</td>
      <td>{inrData[2]}</td>
      <td>{inrData[3]}% ({inrData[4]}/{inrData[5]})</td>
    </tr>
  )
}

const DataTable = (props) => {
  const dataHash = props.data
  const orderedKeys = Object.keys(dataHash).sort()
  const rows = []
  _.forEach(orderedKeys, (key) => {
    const inrData = dataHash[key]
    rows.push(dataRow(inrData, key))
  })

  return (
    <table className="striped centered">
      <thead>
        <tr>
          <th>Date</th>
          <th>INR</th>
          <th>Days Ago</th>
          <th>INR Diff</th>
          <th>Days in Range / Total Days in Period</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  )
}

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
}

export default Ttr
