import React, { useState, useEffect, useContext, useCallback } from 'react'

import { Grid, Paper, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { path, pathOr } from 'ramda'
import PropTypes from 'prop-types'

import { OrdersDisabledEmr, Rule as RuleType } from '../../athenaTypes'

// eslint-disable-next-line import/no-cycle
import { RuleContext } from './rulesList'
import Action from './Action'
import AthenaOrderPrefTypeahead from '../../reusableComponents/AthenaOrderPrefTypeahead'
import EpicOrderPrefTypeahead from '../../reusableComponents/EpicOrderPrefTypeahead'
import CernerOrderPrefTypeahead from '../../reusableComponents/CernerOrderPrefTypeahead'
import AddOrderButton from './AddOrderButton'
import CardBody from './CardBody'
import CardHeader from './CardHeader'
import Description from './Description'
import Diagnosis from './Diagnosis'
import DiagnosisInput from './DiagnosisInput'
import DiagnosisTypeahead from '../../reusableComponents/DiagnosisTypeahead'
import ExternalLink from './ExternalLink'
import FollowupAction from './FollowupAction'
import FollowupActionInput from './FollowupActionInput'
import OrderPreferenceList from './OrderPreferenceList'
import OrderingModeDropdown from './OrderingModeDropdown'
import RemoveOrderButton from './RemoveOrderButton'
import EditOrderPreferenceButton from './EditOrderPreferenceButton'
import RuleName from './RuleName'
import RuleSource from './RuleSource'
import RuleSubscriptionStatus from './RuleSubscriptionStatus'
import ToggleComboOrderButton from './ToggleComboOrderButton'
import UUID from './UUID'

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '1em 1.5em',
  height: '99%',
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  maxWidth: '100%',
  marginTop: '0.5em',
  marginBottom: '1em',
}))

const propTypes = {
  index: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  rule: RuleType.isRequired,
  setOpenId: PropTypes.func.isRequired,
  toggleStepTherapy: PropTypes.func.isRequired,
  turnSubscriptionActiveSilent: PropTypes.func.isRequired,
  updateOrderPreference: PropTypes.func.isRequired,
  emr: PropTypes.string.isRequired
}

const MaterialUIRule = ({ 
  index,
  open,
  rule,
  setOpenId,
  toggleStepTherapy,
  turnSubscriptionActiveSilent,
  updateOrderPreference,
  emr
}) => {

  const [diagnosis, setDiagnosis] = useState('')
  const [orderDisplayText, setOrderDisplayText] = useState('No follow up action is currently associated with this guideline')

  const orders = pathOr([], ['options', 'orders'], rule.order_preference)
  const allOrdersFilled = !orders.map(order => order.order_name).includes(undefined)
  const orderSet = path(['options', 'order_set'], rule.order_preference)
    
  const updateOrderDisplayText = () => {
    const selections = path(['options', 'selections'], rule.order_preference)
    let orderPrefNames = orders.map(order => order.order_name)
    const orderPrefAction = path(['action'], rule.order_preference)

    if (orderPrefAction && !orderPrefNames) {
      orderPrefNames = [orderPrefAction]
    } else if (rule.rule_type === 'documentation') {
      orderPrefNames = [orderPrefAction] || [rule.action]
    }

    let newOrderDisplayText =
      'No follow up action is currently associated with this guideline'
    const orderingMode = path(
      ['options', 'orders', 0, 'ordering_mode'],
      rule.order_preference
    )
    if (orderSet && orders.length == 2) {
      newOrderDisplayText = "Combo Order Selected"
    } else if (selections) {
      if (orderPrefNames.length === 2 && orderPrefNames[1] === undefined) {
        newOrderDisplayText = `Order ${orderPrefNames[0]}`
      } else if (orderPrefNames.length === 2 && orderPrefNames[1] !== undefined) {
        newOrderDisplayText = [`Order 1 ${orderPrefNames[0]}`, `Order 2 ${orderPrefNames[1]}`]
      } else if (orderPrefNames.length > 2) {
        newOrderDisplayText = "Multiple Order Options Selected"
      } else {
        newOrderDisplayText = `Order ${orderPrefNames[0]}`
      }
    } else if (orderingMode) {
      newOrderDisplayText = `${orderingMode} ${orderPrefNames[0]}`
    } else if (rule.rule_type === 'documentation') {
      newOrderDisplayText = orderPrefAction || rule.action
    } else if (orderPrefNames.length === 1) {
      newOrderDisplayText = `Order ${orderPrefNames[0]}`
    } else {
      newOrderDisplayText = 'No follow up action is currently associated with this guideline'
    }

    setOrderDisplayText(newOrderDisplayText)
  }

  const updateOrderPreferences = (options) => {
    $.ajax({
      method: 'put',
      url: `/admin/order_preferences/${rule.order_preference.id}`,
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ ...rule.order_preference, options }),
    })
      .done(() => {
        // Update the order preferences and possibly your UI if needed
        updateOrderPreference({ ...rule.order_preference, options: options });
      })
      .fail((error) => {
        // Handle errors, show an error message, or log the error
        flash.error('There was an issue updating the order preference');
        console.log(error);
      });
  }

  const updateOrderPrefName = (_, newOrderPrefOption) => {
    let orderPrefName = path(['orders', 0, 'order_name'], newOrderPrefOption)
    if (path(['order_set'], newOrderPrefOption)) {
      orderPrefName = newOrderPrefOption.name
    }

    let newOrderDisplayText =
      'No follow up action is currently associated with this guideline'
    const orderingMode = path(
      ['orders', 0, 'ordering_mode'],
      newOrderPrefOption
    )
    if (orderingMode) {
      newOrderDisplayText = `${orderingMode} ${orderPrefName}`
    } else {
      newOrderDisplayText = `Order ${orderPrefName}`
    }

    setOrderDisplayText(newOrderDisplayText)
  }

  const updateDiagnosis = () => {
    const orderPreferenceDiagnosis = path(
      ['order_preference', 'options', 'diagnosis_name'],
      rule
    )

    let newDiagnosis = rule.snomed_diagnosis_name
    if (orderPreferenceDiagnosis) {
      newDiagnosis = orderPreferenceDiagnosis
    } else if (path(['order_preference', 'options', 'order_set'], rule)) {
      newDiagnosis = 'order set diagnosis codes'
    }

    setDiagnosis(newDiagnosis)
  }

  useEffect(() => {
    updateOrderDisplayText()
    updateDiagnosis()
  }, [])

  const handleSelect = ({ target }) => {
    const subscriptionId = path(['subscription', 'id'], rule)
    const activeSilent = target.value
    if (subscriptionId) {
      turnSubscriptionActiveSilent(subscriptionId, activeSilent)
    }
  }

  const { setSize } = useContext(RuleContext)

  const bodyRef = useCallback((node) => {
    if (node !== null) {
      setSize(index, 166 + node.getBoundingClientRect().height)
    }
  }, [])

  const handleClick = (event) => {
    // Toggle body unless interacting with select field.
    const { classList } = event.target
    if (classList && !classList.contains("Mui-selected")) {
      if (open) {
        setOpenId(null)
        setSize(index, 166)
      } else {
        setOpenId(rule.id)
      }
    }
  }

  let OrderTypeahead
  switch(emr) {
    case "athena": 
      OrderTypeahead = AthenaOrderPrefTypeahead
    break
    case "epic":
      OrderTypeahead = EpicOrderPrefTypeahead
    break
    case "cerner":
      OrderTypeahead = CernerOrderPrefTypeahead
    break
  }

  const ordersDisabledUI = (
    OrdersDisabledEmr.includes(emr)
  )

  const ordersEnabledUi = (
    !OrdersDisabledEmr.includes(emr)
  )

  return (
    <StyledPaper key={rule.id}>
      {ordersEnabledUi && (
      <CardHeader handleClick={handleClick} ruleId={rule.id}>
        <Grid
          container
          item
          direction="column"
          justify="space-between"
          align-items="flex-start"
          xs={9}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
          >
            <RuleSource source={rule.default_display_code || rule.source} />
            <RuleName name={rule.name} />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
          >

            <FollowupAction followUpActions={orderDisplayText} />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
          >
            <Diagnosis ruleType={rule.rule_type} diagnosis={diagnosis} />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            style={{
              marginTop: '1em'
            }}
          >
          </Grid>
        </Grid>
        <RuleSubscriptionStatus status={rule.subscription ? rule.subscription.active_silent : 'off'} onChange={handleSelect} />
      </CardHeader>
      )}
      {ordersDisabledUI && (
      <CardHeader handleClick={handleClick} ruleId={rule.id}>
        <Grid
          container
          item
          direction="column"
          justify="space-between"
          align-items="flex-start"
          xs={9}
        >
          <Grid
            container
            direction="row"
            justify="flex-start"
          >
            <RuleSource source={rule.default_display_code || rule.source} />
            <RuleName name={rule.name} />
          </Grid>
        </Grid>
        <RuleSubscriptionStatus status={rule.subscription ? rule.subscription.active_silent : 'off'} onChange={handleSelect} />
      </CardHeader>
      )}
      {ordersEnabledUi && (
      <CardBody open={open} bodyRef={bodyRef} ruleId={rule.id}>
        <StyledDivider/>
        <Action 
          action={rule.order_preference.action || rule.action}
          ordersEnabledUi={ordersEnabledUi}
          updateAction={(action) => {
            $.ajax({
              method: 'put',
              url: `/admin/order_preferences/${rule.order_preference.id}`,
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify({...rule.order_preference, action})
            })
              .done(()=> {
                updateOrderPreference({...rule.order_preference, action: action})
              })
              .fail((error) => {
                flash.error('There was an issue updating the action')
                console.log(error)
              })
          }}
        />
        <Description
          description={rule.order_preference?.description || rule.description}
          ordersEnabledUi={ordersEnabledUi}
          updateDescription={(description) => {
            $.ajax({
              method: 'put',
              url: `/admin/order_preferences/${rule.order_preference.id}`,
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify({...rule.order_preference, description })
            }).
              done(() => {
                updateOrderPreference({...rule.order_preference, description: description})
              })
              .fail((error) => {
                flash.error('There was an issue updating the description')
                console.log(error)
              })
          }}
        />

        <FollowupActionInput>
          { orders.map((order, index) => (
            <div key={`order-${index}`}>
              <OrderTypeahead
                orderPreference={rule.order_preference}
                setOrderDisplayText={setOrderDisplayText}
                orderIndex={index}
                updateOrderPref
                columns="12"
                updateOrderPreference={updateOrderPreference} 
                showHelperText={false}
              />
              {!orderSet &&
              <RemoveOrderButton orderIndex={index} orderPreference={rule.order_preference} updateOrderPreference={updateOrderPreference} />
              }
              
              <EditOrderPreferenceButton
              orderIndex={index}
              orderPreference={rule.order_preference}
              updateOrderPreferences={updateOrderPreferences}
            />
            
            </div>
          ))}
          { orders.length === 0 &&
            <OrderTypeahead
              orderPreference={rule.order_preference}
              setOrderDisplayText={setOrderDisplayText}
              updateOrderPref
              columns="12"
              updateOrderPreference={updateOrderPreference}
              showHelperText={false}
            />
          }
        </FollowupActionInput>
        <Grid
          container
          direction="row"
          justify="flex-start">
          <Grid item>
            <ToggleComboOrderButton orderPreference={rule.order_preference} updateOrderPreference={updateOrderPreference} />
            {orders.length > 0 && allOrdersFilled && !orderSet &&
            <AddOrderButton orderPreference={rule.order_preference} updateOrderPreference={updateOrderPreference} />
            }
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
        >
        </Grid>
        <OrderingModeDropdown
          pref={rule.order_preference}
          dropdownOptions={['Prescribe', 'Administer']}
          emr={emr}
        />
        { rule.rule_type === "add" && (
          <DiagnosisInput>
            <DiagnosisTypeahead
              pref={rule.order_preference}
              rule={rule}
              updateOptions={updateDiagnosis}
              updateOrderPref
              columns={12}
              updateOrderPreference={updateOrderPreference}
            />
          </DiagnosisInput>
        )}
        <ExternalLink href={rule.link} />
        <UUID uuid={rule.uuid} />
      </CardBody>
      )}
      {ordersDisabledUI && (
        <CardBody open={open} bodyRef={bodyRef} ruleId={rule.id}>
        <StyledDivider/>
          <Action action={rule.action} ordersEnabledUi={ordersEnabledUi} />
          <Description description={rule.description} ordersEnabledUi={ordersEnabledUi}/>
        <Grid
          container
          direction="row"
          justify="flex-start"
        >
        </Grid>
        <ExternalLink href={rule.link} />
        <UUID uuid={rule.uuid} />
      </CardBody>
      )}
    </StyledPaper>
  )
}

MaterialUIRule.propTypes = propTypes
export default MaterialUIRule
