import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import moment from 'moment'

const userCountText = users => `${users.length} ${pluralize('User', users.length)}`

class ApproveUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pendingUsers: this.props.pendingUsers.map(u => ({ ...u, checked: false })),
      approving: false,
    }

    this.checkboxClickHandler = this.checkboxClickHandler.bind(this)
    this.approveUsers = this.approveUsers.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  checkboxClickHandler(userId) {
    const pendingUsers = this.state.pendingUsers
    const user = _.find(pendingUsers, u => u.id === userId)
    const newState = !user.checked
    user.checked = newState
    this.setState({ pendingUsers })
  }

  approveUsers() {
    this.setState(() => ({ approving: true }), () => {
      flash.notice("Approving users...", 1000)
      const usersToApprove = this.state.pendingUsers.filter(u => u.checked)
      const remainingUsers = this.state.pendingUsers.filter(u => !u.checked)
      $.post('/admin/users/approve_users', { user: { user_ids: usersToApprove.map(u => u.id) } })
      .done(() => {
        flash.success(`${userCountText(usersToApprove)} ${usersToApprove.length === 1 ? 'has' : 'have' } been approved to use Avhana`, 50000) // previously had 3000 timeout
        this.setState({ pendingUsers: remainingUsers })
      })
      .fail((error) => {
        if (checkTimeout(error) === true) {
          redirectToLogin()
        } else {
          console.error('Approve Users Error', error)
        }
      })
      this.setState({ approving: false })
    })
  }

  handleClick(event) {
    event.preventDefault()
    if (window.confirm('Are you sure you want to dismiss this user?')) {
      const userId = parseInt(event.target.dataset.id, 10)
      const remainingUsers = this.state.pendingUsers.filter(u => u.id !== userId)
      $.ajax({
        type: 'DELETE',
        url: '/admin/dismiss_user',
        data: { user: { id: userId } },
      })
      .done(() => this.setState({ pendingUsers: remainingUsers }))
      .fail((error) => {
        if (checkTimeout(error) === true) {
          redirectToLogin()
        } else {
          console.error('Dismiss User Error', error)
        }
      })
    }
  }

  render() {
    let pendingUsersRows = _.map(this.state.pendingUsers, user => (
      <tr key={user.id}>
        <td>{user.full_name}</td>
        <td>{user.position}</td>
        <td>{user.department_name}</td>
        <td>{moment(user.created_at).format('MM/DD/YYYY')}</td>
        <td>
          <label htmlFor={`user-${user.id}`} className="rec-order-checkbox-container">
            <input type="checkbox" className="filled-in" id={`user-${user.id}`} checked={user.checked} onClick={() => { this.checkboxClickHandler(user.id) }} />
            <span />
          </label>
        </td>
        <td>
          <button className="dismiss-user" data-id={user.id} href="#" onClick={this.handleClick}>Dismiss</button>
        </td>
      </tr>
      )
    )
    if (this.state.pendingUsers.length === 0) {
      pendingUsersRows = (
        <tbody>
          <br />
          <p>There are no unapproved users</p>
        </tbody>

      )
    }

    const checkedUsers = this.state.pendingUsers.filter(u => u.checked)
    const disabled = checkedUsers.length === 0 || this.state.approving

    return (
      <div className="row">
        <div className="card">
          <div className="card-content">
            <span className="card-title">{`User Access Requests at ${this.props.organization.name}`}</span>
            <table className="bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Department</th>
                  <th>Access Requested Date</th>
                  <th>Approve?</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {pendingUsersRows}
              </tbody>
            </table>
          </div>
          <div className="card-action right-align">
            <button className={`waves-effect waves-light btn ${disabled ? 'disabled' : ''}`} disabled={disabled} href="#" onClick={this.approveUsers}>
              {`Approve ${userCountText(checkedUsers)}`}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

ApproveUsers.propTypes = {
  pendingUsers: PropTypes.arrayOf(PropTypes.object)
}

const mountReact = () => {
  const reactNode = document.getElementById('pending-users')
  const reactData = $(reactNode).data()
  if (reactData) {
    ReactDOM.render(
      <ApproveUsers
        organization={reactData.currentorganization.organization}
        pendingUsers={reactData.pendingusers}
      />,
      reactNode
    )
  }
}

$(mountReact)
