import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'

import UserFormStep1 from './userFormStep1.jsx'
import UserFormStep2 from './userFormStep2.jsx'
import UserFormStep3 from './userFormStep3.jsx'

export const joinTerms = (terms) => {
  if (terms === null || terms === undefined) throw new Error("not an array")
  if (terms.hasOwnProperty('length') && terms.length < 2) throw new Error("has less than two terms")
  if (terms.length === 2) {
    return terms.join(' and ')
  }
  return `${terms.slice(0, terms.length - 1).join(', ')}, and ${terms[terms.length - 1]}`
}

export const parseErrorMessage = (error) => {
  const { responseJSON } = error
  if (responseJSON === null || responseJSON === undefined) {
    return 'Submit error. Try again'
  }
  const keys = Object.keys(responseJSON)
  // This could be more robust, but we're assuming this is just to handle
  // uniqueness constraints.
  if (keys.length === 1) {
    return `${keys[0]} has already been taken.`
  }
  return `${joinTerms(keys)} have already been taken.`
}

const validateNoBlanks = (fields) => {
  const valid = !(_.findKey(fields, el => el === '' ))
  if (!valid) {
    flash.error('All fields must be completed.')
    return false
  }
  return true
}

export default class UserFormStateMachine extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      firstName: '',
      lastName: '',
      emrUserName: '',
      email: '',
      medicalAssistant: true,
      billable: false,
      ssoAccessPermitted: true,
      admin: false,
      departmentsList: [],
      organizationId: props.isAdmin ? props.defaultOrganizationId : '',
      providerGroupingId: props.isAdmin ? props.defaultProviderGroupingId : '',
      goLiveDate: moment(),
    }
    this.updateMedicalAssistantBoolean = this.updateMedicalAssistantBoolean.bind(this)
    this.saveAndContinue = this.saveAndContinue.bind(this)
    this.updateCheckBox = this.updateCheckBox.bind(this)
    this.decrementCounter = this.decrementCounter.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.typeaheadOptionSelected = this.typeaheadOptionSelected.bind(this)
    this.handleProviderGroupSelect = this.handleProviderGroupSelect.bind(this)
    this.handleOrgSelect = this.handleOrgSelect.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDepartmentClick = this.handleDepartmentClick.bind(this)
  }

  handleInputChange (event) {
    const fieldKey = event.target.id
    let newState = {}
    newState[fieldKey] = event.target.value
    this.setState(newState)
  }

  updateMedicalAssistantBoolean (formEl) {
    const boolean = (formEl.value === 'true') ? true : false
    this.setState({ medicalAssistant: boolean })
  }

  updateCheckBox (event) {
    const fieldKey = event.target.id
    let newState = {}
    newState[fieldKey] = !this.state[fieldKey]
    this.setState(newState)
  }

  handleOrgSelect(event) {
    const orgId = event.target.value
    this.setState({ organizationId: orgId })
  }

  handleProviderGroupSelect(event) {
    const providerGroupingId = event.target.value
    this.setState({ providerGroupingId })
  }
 
  handleDateChange(date) {
    this.setState({ goLiveDate: date })
  }

  handleDepartmentClick(event) {
    const element = event.target.parentNode
    const name = element.dataset.key
    this.setState({ departmentsList: this.state.departmentsList.filter(dept => dept.name !== name) })
  }

  submitData() {
    const department_ids = _.map(this.state.departmentsList, 'objectID')
    const user = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      emr_user_name: this.state.emrUserName.toLowerCase().replace(/ /g,''),
      email: this.state.email,
      medical_assistant: this.state.medicalAssistant,
      billable: this.state.billable,
      sso_access_permitted: this.state.ssoAccessPermitted,
      admin: this.state.admin,
      organization_id: this.state.organizationId,
      provider_grouping_id: this.state.providerGroupingId,
      go_live_date: this.state.goLiveDate.format(),
      department_ids
    }

    $.post('/admin/users/add', { user })
    .done(response => { this.incrementCounter() })
    .fail(error => {
      if (window.checkTimeout(error) === true) {
        redirectToLogin()
      } else {
        const message = parseErrorMessage(error)
        flash.error(message)
      }
    })
  }

  saveAndContinue(event) {
    if (this.state.step === 1) {
      if (validateNoBlanks(_.pick(this.state, ['firstName', 'lastName', 'emrUserName', 'organizationId'])) === false) return
      const username = this.state.emrUserName.toLowerCase().replace(/ /g, '')
      this.verifyEmrUserName(username).then(({ username_valid }) => {
        if (!username_valid && !this.state.medicalAssistant) {
          window.flash.error('This EMR username is not valid.')
          return
        }
        this.verifyUserDoesNotExist(username).then(({ user_exist }) => {
          if (!user_exist) {
            this.incrementCounter()
          } else {
            window.flash.error('This user already exists.')
          }
        })
      })
    } else if (this.state.step === 3) {
      this.submitData()
    } else {
      this.incrementCounter()
    }
  }

  incrementCounter () {
    this.setState({ step: this.state.step + 1 })
  }

  decrementCounter () {
    this.setState({ step: this.state.step - 1 })
  }

  /**
   * @method optionSelected fires when a Typeahead choice is selected
   * and makes AJAX call to update the order preference in Rails for that OrderPreference id
   * or change state
   * @param {opt} object from the array of order options in the Typeahead -
   */
  typeaheadOptionSelected(opt) {
    const departmentsList = this.state.departmentsList
    departmentsList.push(opt)
    this.setState({ departmentsList })
  }


  verifyUserDoesNotExist(userName) {
    return $.post('/admin/users/verify_user_does_not_exist', { user: { emr_user_name: userName, organization_id: this.state.organizationId } })
    .done(() => true)
    .fail((error) => {
      if (window.checkTimeout(error) === true) {
        redirectToLogin()
      } else {
        window.flash.error('This user already exists.')
      }
    })
  }

  verifyEmrUserName(userName) {
    return $.post('/admin/users/verify_emr_user_name', { user: { emr_user_name: userName, organization_id: this.state.organizationId } })
    .done(() => true)
    .fail((error) => {
      if (window.checkTimeout(error) === true) {
        redirectToLogin()
      } else {
      }
      return false
    })
  }

  render() {
    let step
    switch (this.state.step) {
      case 1:
        step = <UserFormStep1
                  ref='step1'
                  firstName={ this.state.firstName }
                  lastName={ this.state.lastName }
                  emrUserName={ this.state.emrUserName }
                  medicalAssistant={ this.state.medicalAssistant }
                  handleInputChange= { this.handleInputChange }
                  updateMedicalAssistantBoolean={ this.updateMedicalAssistantBoolean }
                  handleOrgSelect={ this.handleOrgSelect }
                  handleProviderGroupSelect={ this.handleProviderGroupSelect }
                  organizationId={ this.state.organizationId }
                  organizations={ this.props.organizations }
                  providerGroupingId={ this.state.providerGroupingId }
                  email={ this.state.email }
                  isAdmin={this.props.isAdmin}
                  defaultProviderGroupingId={this.props.defaultProviderGroupingId}
                  defaultOrganizationId={this.props.defaultOrganizationId}
               />
      break

      case 2:
        const activeOrganization = this.props.organizations.find(o => o.id === parseInt(this.state.organizationId))
        const allDepartments = activeOrganization.departments
        step = (
          <UserFormStep2
            ref='step2'
            departments={this.state.departmentsList}
            organizationId={this.state.organizationId}
            onOptionSelected={this.typeaheadOptionSelected}
            allDepartments={allDepartments}
            handleClick={this.handleDepartmentClick}
          />
        )

      break

      case 3:
        step = <UserFormStep3
                ref='step3'
                billable= { this.state.billable }
                ssoAccessPermitted= { this.state.ssoAccessPermitted }
                admin= { this.state.admin }
                goLiveDate= { this.state.goLiveDate.toDate() }
                updateCheckBox = { this.updateCheckBox }
                handleDateChange = { this.handleDateChange }
               />

      break

    case 4:
      step = (
        <div>
          <h6>{`${this.state.firstName} ${this.state.lastName} has been created`}</h6>
          {this.state.medicalAssistant === false &&
            <h6>{`A care team called "${this.state.firstName} ${this.state.lastName} Care Team" has been created ${this.state.departmentsList[0] ? `in the ${this.state.departmentsList[0]}` : 'without a'} department.`} <a href="/admin/care_teams">Click here</a> to edit that care team.</h6>
          }
        </div>
      )
      break
    }

    let buttons
    if (this.state.step === 4) {
      buttons =
        (<div>
          <button className='waves-effect waves-light btn' onClick={ () => {window.location.reload(true)} }>Add Another User</button>
        </div>)
    } else {
      buttons =
        (<div>
          <button className='waves-effect waves-light btn' onClick={ this.saveAndContinue }>Save and Continue</button>
          <button className='waves-effect waves-light btn-flat' onClick={ this.decrementCounter }>Back</button>
         </div>)
    }

    return(
      <div className='row' style={{ paddingLeft: '1em' }}>
        <div className='col s12'>
          <h1>Create a new Avhana user</h1>
        </div>
        { step }
        { buttons }
      </div>
    )
  }
}

let mountReact = () => {
  let reactNode = document.getElementById('render-create-user-react')
  // render dashboard on pages where the proper div exists
  const reactData = $(reactNode).data()
  if (reactData) {
    ReactDOM.render(<UserFormStateMachine organizations={reactData.organizations} isAdmin={reactData.admin === ""} defaultProviderGroupingId={reactData.providerGroupingId} defaultOrganizationId={reactData.organizationId} />, reactNode)
  }
}

$(mountReact)
