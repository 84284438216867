import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import AthenaOrderPrefTypeaheadBasic from '../reusableComponents/AthenaOrderPrefTypeaheadBasic'

class OrderPreferenceReferenceForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderFields: {},
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.optionSelected = this.optionSelected.bind(this)
  }

  handleSubmit(evt) {
    const { crudMethod, ruleId, id } = this.props
    const { orderFields } = this.state
    evt.preventDefault()
    evt.stopPropagation()

    const url = crudMethod === 'post'
      ? `/root/rules/${ruleId}/order_preference_references`
      : `/root/rules/${ruleId}/order_preference_references/${id}`

    $.ajax({
      method: crudMethod,
      url,
      data: {
        order_preference_reference: {
          order: orderFields,
        },
      },
    })
      .done(() => {
        window.flash.success('Successfully updated!', 50000)
      })
      .fail((err) => {
        if (checkTimeout(err) === true) {
          redirectToLogin()
        } else {
          flash.error('Error updating your Order Preference Reference. Contact support@avhana.com if you need further assistance.')
          console.error(err)
        }
      })
  }

  optionSelected(typeaheadObject) {
    const newOrderFields = {
      order_name: typeaheadObject.name,
      order_type: typeaheadObject.order_type,
      order_type_id: typeaheadObject.ordertypeid,
    }

    this.setState({ orderFields: newOrderFields })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row opr-field">
          <div className="col s8">
            <div className="row flex">
              <div className="col s11">
                <AthenaOrderPrefTypeaheadBasic
                  optionSelected={this.optionSelected}
                  columns="12"
                />
              </div>
            </div>
          </div>
        </div>
        <button className="btn waves-effect waves-light" type="submit">
          Submit
        </button>
      </form>
    )
  }
}

OrderPreferenceReferenceForm.propTypes = {
  crudMethod: PropTypes.string.isRequired,
  ruleId: PropTypes.number.isRequired,
  id: PropTypes.number,
}

OrderPreferenceReferenceForm.defaultProps = {
  id: null,
}

const mountReact = () => {
  const reactNode = document.getElementById('order-preference-reference-form')
  const reactData = $(reactNode).data()
  if (reactData) {
    ReactDOM.render(
      <OrderPreferenceReferenceForm
        crudMethod={reactData.crudmethod}
        id={reactData.id}
        ruleId={reactData.ruleid}
      />,
      reactNode,
    )
  }
}

$(mountReact)
