import React, { useState } from 'react'
import { Chip, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

export default function ValueSetAutocomplete(props) {
  const [options, setOptions] = useState([])
  const [values, setValues] = useState(props.value_set_names)

  const handleChange = (event) => {
    const { value } = event.target
    if (value.length >= 3) {
      $.ajax({
        method: 'GET',
        url: '/root/value_sets_name_search',
        data: { query: value },
      })
        .done((response) => {
          setOptions(response)
        })
        .fail((error) => {
          console.error(error)
        })
    } else {
      setOptions([])
    }
  }

  const getOptionLabel = (option) => option

  return (
    <>
      <Autocomplete
        freeSolo
        multiple
        id="tags-standard"
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleChange}
            variant="standard"
            label="Value Sets (Used in rule logic.  First value set becomes target medication in Medication Pricing Table)"
          />
        )}
        value={values}
        filterSelectedOptions
        renderTags={(_, getTagProps) => {
          return values.map((name, index) => (
            <Chip
              variant="outlined"
              label={name}
              {...getTagProps({ index })}
              onDelete={() => {
                setValues(values.filter((v) => v !== name))
              }}
            />
          ))
        }}
        onChange={(_event, value, reason) => {
          if (reason === 'selectOption') {
            // Internal value list for autocomplete is a list we can't prune.
            // Just get last value.
            const v = value[value.length - 1]
            setValues([...values, v])
          }
        }}
      />
      <input type="hidden" name="rule[value_set_names]" value={values} />
    </>
  )
}
