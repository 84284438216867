import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import MUITable from '@mui/material/Table'
import Header from './Header'
import Body from './Body'

const Table = ({ data }) => (
  <>
    <TableContainer>
      <MUITable>
        <Header />
        <Body data={data} />
      </MUITable>
    </TableContainer>
  </>
)

export default Table