/* global math */

import React from 'react'
import PropTypes from 'prop-types'

const getTotalInches = (rawFeet, rawInches) => {
  let feetInput
  if (rawFeet.length > 0) {
    feetInput = parseInt(rawFeet, 10)
  } else {
    feetInput = 0
  }

  let inchesInput
  if (rawInches.length > 0) {
    inchesInput = parseInt(rawInches, 10)
  } else {
    inchesInput = 0
  }

  return (feetInput * 12) + inchesInput
}

export default class PqrsBmi extends React.Component {

  constructor(props) {
    super(props)
    this.state = { bmi: 0, feet: '', inches: '', pounds: '', age: props.patient.age }
    this.updateVitals = this.updateVitals.bind(this)
    this.parseVitals = this.parseVitals.bind(this)
    this.postPatientInfoAndVitals = this.postPatientInfoAndVitals.bind(this)
    this.submitNote = this.submitNote.bind(this)
  }

  componentDidMount() {
    $.get(`/patients/${this.props.patient.organization_id}/${this.props.patient.emr_patient_id}/most_recent_vital`)
    .always(() => this.setState({ loading: false }))
    .done((vitals) => {
      if (vitals) {
        if (vitals.height) {
          const totalFeet = math.unit(vitals.height, 'cm').to('feet').toJSON().value
          const feet = Math.floor(totalFeet)
          const inches = math.round((totalFeet - feet) * 12, 1)
          this.setState({ feet, inches })
        } else if (vitals.weight) {
          const pounds = math.round(math.unit(vitals.weight, 'kg').to('lbs').toJSON().value, 1)
          this.setState({ pounds })
        }
      }
    })
    .fail((errors) => {
      if (checkTimeout(errors) === true) {
        redirectToLogin()
      } else {
        flash.notice('There was a problem loading the current vitals data. Contact support@avhana.com for further assistance.')
      }
    })
  }

  updateVitals() {
    const weightInput = parseInt(this.weightField.value, 10)
    const totalInches = getTotalInches(this.feetField.value, this.inchesField.value)

    let bmiRounded
    // calculate bmi for height (in) and weight (lbs)
    if (totalInches !== 0 && !isNaN(weightInput)) {
      const bmi = ((weightInput) / (totalInches * totalInches)) * 703
      bmiRounded = math.round(bmi, 1)
    } else {
      bmiRounded = 0
    }

    // pass data to update state fxtn
    const vitals = {
      bmi: bmiRounded,
      feet: this.feetField.value,
      inches: this.inchesField.value,
      pounds: this.weightField.value,
    }

    this.setState(vitals)
  }

  updateRecommendation() {
    const bmi = this.state.bmi
    const age = this.state.age
    let recommendation = ''

    if (bmi > 0) {
      if ((age >= 18) && (age <= 64)) {
        if (bmi < 18.5) {
          recommendation = (
            <div className="row">
              <div className="col s6">
                BMI below recommended range, create follow-up plan
              </div>
              <div className="col s5 offset-s1">
                <button className="btn" onClick={this.postPatientInfoAndVitals}>
                  Send vitals and document
                </button>
              </div>
            </div>
          )
        } else if (bmi >= 25) {
          recommendation = (
            <div className="row">
              <div className="col s6">
                BMI above recommended range, create follow-up plan
              </div>
              <div className="col s5 offset-s1">
                <button className="btn" onClick={this.postPatientInfoAndVitals}>
                  Send vitals and document
                </button>
              </div>
            </div>
          )
        } else {
          recommendation = (
            <div className="row">
              <div className="col s6">
                BMI is within recommended range
              </div>
              <div className="col s3 offset-s3">
                <button className="btn" onClick={this.parseVitals}>Send vitals</button>
              </div>
            </div>
          )
        }
      } else if (age >= 65) {
        if (bmi < 23) {
          recommendation = (
            <div className="row">
              <div className="col s6">
                BMI below recommended range, create follow-up plan
              </div>
              <div className="col s5 offset-s1">
                <button className="btn" onClick={this.postPatientInfoAndVitals}>
                  Send vitals and document
                </button>
              </div>
            </div>
          )
        } else if (bmi >= 30) {
          recommendation = (
            <div className="row">
              <div className="col s6">
                BMI above recommended range, create follow-up plan
              </div>
              <div className="col s5 offset-s1">
                <button className="btn" onClick={this.postPatientInfoAndVitals}>
                  Send vitals and document
                </button>
              </div>
            </div>
          )
        } else {
          recommendation = (
            <div className="row">
              <div className="col s6">
                BMI is within recommended range
              </div>
              <div className="col s3 offset-s3">
                <button className="btn" onClick={this.parseVitals}>Send vitals</button>
              </div>
            </div>
          )
        }
      } else { // This should not happen (patient too young). Shouldn't fire in Drools.
        recommendation = (
          <div className="row">
            <div className="col s3 offset-s3">
              <button className="btn" onClick={this.parseVitals}>Send vitals</button>
            </div>
          </div>
        )
      }
    }

    return recommendation
  }

  postPatientInfoAndVitals(event) {
    this.parseVitals(event)
    this.submitNote()
  }

  submitNote() {
    const customOrderPref = {
      order_type: 'patient_info',
      order_type_id: 281648, // body mass index: care instructions
      diagnosis_code: 60621009, // Body mass index (observable entity)
      icd10: 'Z68',
      provider_note: `Patient's BMI (${this.state.bmi}) is outside the recommended range.`,
    }
    const recId = this.props.id
    const orderInfo = { recId, customOrderPref }
    $.post('/orders/send', orderInfo)

    .done(() => {
      this.props.submittedToEhr(this.props.rule.name)
    })
    .fail((error) => {
      flash.error('There was an error submitting the note to the EHR.')
      console.error(error, { context: `PQRS BMI Error message: ${error.responseText}` })
    })
  }

  parseVitals(event) {
    // WEIGHT convert lbs to grams
    // HEIGHT convert ft+in to cm
    event.preventDefault()
    const recId = this.props.id
    const totalInches = getTotalInches(this.state.feet, this.state.inches)
    const height = math.unit(totalInches, 'inches').to('cm').toJSON().value
    const weight = math.unit(this.state.pounds, 'lbs').to('g').toJSON().value

    if (height === 0 || isNaN(weight) || this.state.bmi === 0) {
      flash.error('Invalid height and weight')
    } else {
      // format for Athena
      const formattedHeight = [{ value: height, clinicalelementid: 'VITALS.HEIGHT' }]
      const formattedWeight = [{ value: weight, clinicalelementid: 'VITALS.WEIGHT' }]
      const formattedBmi = [{ value: this.state.bmi, clinicalelementid: 'VITALS.BMI' }]
      const rec = [{ recId }]
      const formattedVitals = [formattedHeight, formattedWeight, formattedBmi, rec]

      // send formattedVitals to Ajax function
      this.sendVitals(JSON.stringify(formattedVitals), event)
    }
  }

  sendVitals(formattedVitals, clickEvent) {
    const $card = $(clickEvent.target).closest('.collection-item')
    $.ajax({
      type: 'POST',
      url: '/orders/vitals',
      dataType: 'json',
      contentType: 'application/json',
      data: formattedVitals,
    })

    .done((data) => {
      $card.toggle('slide', { direction: 'left' }, 300, () => this.remove())

      flash.success(data.success_msg, 50000) // previously had 3000 second timeout
    })
    .fail((error) => {
      _(error.responseJSON).forEach((err) => {
        flash.error(`${_.capitalize(err)}. Contact support@avhana.com if you need further assistance.`)
      })
      console.error(error, { context: `Submit PQRS BMI Vitals Error message: ${error.responseText}` })
    })
  }

  render() {
    const recommendation = this.updateRecommendation()

    return (
      <div>
        <div className="row">
          <div className="col s5 offset-s1">
            <form onChange={this.updateVitals.bind(this)}>
              <div className="row">
                <div className="input-field col s6">
                  <input
                    className="col s9"
                    placeholder=""
                    ref={(el) => { this.feetField = el }}
                    id="feet"
                    type="number"
                    min="0"
                    max="9"
                    value={this.state.feet}
                    onChange={(e) => this.setState({ feet: e.target.value })}
                  />
                  <label htmlFor="feet" className="active">Height</label>
                  <p className="col s3 input-label">ft.</p>
                </div>
                <div className="input-field col s6">
                  <input
                    className="col s9"
                    placeholder=""
                    ref={(el) => { this.inchesField = el }}
                    id="inches"
                    type="number"
                    min="0"
                    max="12"
                    value={this.state.inches}
                    onChange={(e) => this.setState({ inches: e.target.value })}
                  />
                  <label htmlFor="inches" />
                  <p className="col s3 input-label">in.</p>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s6">
                  <input
                    className="col s9"
                    placeholder=""
                    ref={(el) => { this.weightField = el }}
                    id="weight"
                    type="number"
                    min="0"
                    max="900"
                    value={this.state.pounds}
                    onChange={(e) => this.setState({ pounds: e.target.value })}
                  />
                  <label htmlFor="weight" className="active">Weight</label>
                  <p className="col s3 input-label">lbs.</p>
                </div>
              </div>
            </form>
          </div>
          <div className="col s6 rule-card-score-container">
            <div className="row">
              <div className="col s6">
                <div className="rule-card-score-header">
                  Body Mass Index
                </div>
              </div>
              <div className="col s6">
                <div className="bubble-number-decimal">
                  {this.state.bmi}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {recommendation}
        </div>
      </div>
    )
  }
}

PqrsBmi.propTypes = {
  patient: PropTypes.shape({
    organization_id: PropTypes.number,
    age: PropTypes.number,
    emr_patient_id: PropTypes.string,
  }),
  id: PropTypes.number,
  submittedToEhr: PropTypes.func,
  rule: PropTypes.shape({
    name: PropTypes.string,
  }),
}
