import PropTypes from 'prop-types'
import React, { Component } from 'react'
import algoliasearch from 'algoliasearch'
import { InputAdornment, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'

const propTypes = {
  pref: PropTypes.shape({
    id: PropTypes.number,
    options: PropTypes.shape({
      orders: PropTypes.arrayOf(
        PropTypes.shape({
          order_type: PropTypes.string,
        })
      ),
    }),
  }),
  rec: PropTypes.shape({
    id: PropTypes.number,
    options: PropTypes.shape({
      orders: PropTypes.arrayOf(
        PropTypes.shape({
          order_type: PropTypes.string,
        })
      ),
    }),
  }),
  updateOrderPref: PropTypes.bool.isRequired,
  updateOptions: PropTypes.func.isRequired,
  orderAnything: PropTypes.bool,
  columns: PropTypes.number,
  style: PropTypes.shape({}),
  updateOrderPreference: PropTypes.func.isRequired,
}
const defaultProps = {
  orderAnything: false,
  columns: 8,
  style: {},
  rec: {},
  pref: {},
}

export default class DiagnosisTypeahead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      diagnosisName: '',
      options: [],
      prevOptions: [],
    }

    this.handleChange = this.handleChange.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.clearState = this.clearState.bind(this)
    this.optionSelected = this.optionSelected.bind(this)
  }

  /**
   * @method getDict fires onChange of the Typeahead
   * @param {text} user input text from Typeahead
   */
  getDict(text) {
    const client = algoliasearch(
      'Q5ZBLQF81V',
      'df6aba0fce0130170bd701c1b4089506'
    )
    const index = client.initIndex('Snomed')
    // Can do stricter ordering by changing the ranking order on algolia to put filters
    // first (before typo, geo, etc.). See here: https://www.algolia.com/doc/guides/relevance/ranking#ranking-formula-a-tie-breaking-algorithm
    index
      .search(text, {
        hitsPerPage: 35,
        page: 0,
      })
      .then((content) => {
        if (content.hits.length > 0) {
          const { options } = this.state
          this.setState({ prevOptions: options, options: content.hits })
        }
      })
      .catch((err) => {
        console.fail(err)
      })
  }

  /**
   * @method handleChange clears existing timer and sets new timer
   * with getDict
   * @param {event} event with value of Typeahead input
   */
  handleChange(event) {
    // need to use this to access these vars within setTimeout scope
    const { value } = event.target
    this.setState({ diagnosisName: value })
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.getDict(value)
    }, 300)
  }

  clearState() {
    this.setState({ diagnosisName: '', options: [] })
  }

  /**
   * @method clearInput clears input on typeahead and sets cursor focus to search input
   */
  clearInput() {
    this.clearState()
    this.input.focus()
  }

  optionSelected(name) {
    const { prevOptions, options } = this.state
    let opt = options.find((o) => o.diagnosis_name === name)
    if (!opt) {
      opt = prevOptions.find((o) => o.diagnosis_name === name)
    }
    const {
      rec,
      pref,
      orderAnything,
      updateOrderPref,
      updateOptions,
      updateOrderPreference
    } = this.props
    const updatedOrderPreference = {...pref, options: {...pref.options, diagnosis_name: opt.diagnosis_name, diagnosis_code: opt.diagnosis_code}}
    let recId
    if (rec) {
      recId = rec.id
    }
    let prefId
    if (pref) {
      prefId = pref.id
    }
    if (!orderAnything) {
      if (updateOrderPref) {
        const data = JSON.stringify({
          ...updatedOrderPreference,
          rec_id: recId,
        })
        // AJAX call to update organization order preference in db if flag set
        $.ajax({
          method: 'put',
          url: `/admin/order_preferences/${prefId}`,
          dataType: 'json',
          contentType: 'application/json',
          data
        })
          .done((response) => {
            this.setState(response.orders[0])
            updateOptions(recId, response)
            flash.success('Diagnosis changed successfully', 50000) // previously had 3000 second timeout
            updateOrderPreference(updatedOrderPreference)
          })
          .fail((error) => {
            flash.error('Diagnosis could not be changed.')
            console.error(error, {
              context: `DiagnosisTypeahead update order pref error message: ${error.responseText}`,
            })
          })
      } else {
        const data = JSON.stringify({
          rec_id: recId,
          newOptions,
        })
        // AJAX call to update organization order preference in db if flag set
        $.ajax({
          method: 'put',
          url: '/recommendations/update',
          dataType: 'json',
          contentType: 'application/json',
          data
        })
          .done((response) => {
            this.setState(response.orders[0])
            updateOptions(recId, response)
            flash.success('Diagnosis changed successfully', 50000) // previously had 3000 second timeout
          })
          .fail((error) => {
            flash.error('Diagnosis could not be changed.')
            console.error(error, {
              context: `DiagnosisTypeahead update diagnosis error message: ${error.responseText}`,
            })
          })
      }
    } else {
      this.setState({ ...newOptions.orders[0], diagnosisName: name })
      updateOptions(recId, newOptions)
    }
  }

  render() {
    const { orderAnything } = this.props
    const { diagnosisName, options } = this.state

    let helperText
    if (!orderAnything) {
      helperText = (
        <p
          className="thin left"
          style={{ padding: '0', fontSize: '0.95em', fontWeight: 'bold' }}
        >
          Use the search box above to change the diagnosis code used for this
          order.
        </p>
      )
    }

    return (
      <>
        <Autocomplete
          freeSolo
          options={options.map((o) => o.diagnosis_name)}
          style={{ 
            paddingLeft: '2.25em',
            paddingRight: '2.25em'
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={this.handleChange}
              label="Search diagnoses here"
              defaultValue={diagnosisName}
            />
          )}
          onChange={(_event, value, reason) => {
            if (reason === 'selectOption') {
              this.optionSelected(value)
            }
          }}
          id="diagnosis-autocomplete"
        />
        {helperText}
      </>
    )
  }
}

DiagnosisTypeahead.defaultProps = defaultProps
DiagnosisTypeahead.propTypes = propTypes
