import React from 'react'
import PropTypes from 'prop-types'
import MarkdownParser from '../../../reusableComponents/MarkdownParser.jsx'

export default class HighRiskMed extends React.Component {
  constructor(props) {
    super(props)
    this.toast = this.toast.bind(this)
  }

  toast() {
    this.props.submittedToEhr(this.props.rule.name)
  }

  render() {
    const medText = this.props.options.form_info.names.map(name => (
      <div style={{ paddingLeft: '2em' }}>
        Patient is on <b>{name}</b>
      </div>
    ))

    const description = <MarkdownParser markdown={this.props.rule.description} />

    return (
      <div>
        {medText}
        <div className="rule-description-markdown">
          {description}
        </div>
        <button className="btn right" onClick={this.toast}>OK</button>
      </div>
    )
  }
}

HighRiskMed.propTypes = {
  submittedToEhr: PropTypes.func,
  rule: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  options: PropTypes.shape({
    form_info: PropTypes.shape({
      names: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
}
