import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

const UserFormStep3 = props => (
  <div className="col s12">
    <p>
      <label htmlFor="billable">
        <input
          type="checkbox"
          id="billable"
          checked={props.billable}
          onChange={props.updateCheckBox}
        />
        <span>Is this user billable?</span>
      </label>
    </p>
    <p>
      <label htmlFor="ssoAccessPermitted">
        <input
          type="checkbox"
          id="ssoAccessPermitted"
          checked={props.ssoAccessPermitted}
          onChange={props.updateCheckBox}
        />
        <span>Should this user have SSO access?</span>
      </label>
    </p>
    <p>
      <label htmlFor="admin">
        <input
          type="checkbox"
          id="admin"
          checked={props.admin}
          onChange={props.updateCheckBox}
        />
        <span>Is this user an admin?</span>
      </label>
    </p>
    <p>
      <label htmlFor="goLiveDate">Go Live date</label>
      <DatePicker selected={props.goLiveDate} onChange={props.handleDateChange} name="goLiveDate" />
    </p>
  </div>
)

UserFormStep3.propTypes = {
  billable: PropTypes.bool,
  updateCheckBox: PropTypes.func,
  ssoAccessPermitted: PropTypes.bool,
  admin: PropTypes.bool,
  goLiveDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func,
}

export default UserFormStep3
