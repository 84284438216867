import PropTypes from 'prop-types'
import React from 'react'

import AthenaOrderPrefTypeahead from './AthenaOrderPrefTypeahead'
import DiagnosisTypeahead from './DiagnosisTypeahead'

export default class OrderAnything extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderSet: false,
      diagnosisName: '',
      orderName: '',
      config: { orders: [{}] },
    }
    this.disableSubmit = this.disableSubmit.bind(this)
    this.updateDiagnosis = this.updateDiagnosis.bind(this)
    this.updateOrder = this.updateOrder.bind(this)
    this.submitOrder = this.submitOrder.bind(this)
    this.clearInput = this.clearInput.bind(this)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { emrPatientId } = this.props
    const newPatient = newProps.emrPatientId !== emrPatientId
    // otherwise will make patients call any time there are updates upstream
    if (newPatient) {
      this.getData(newProps)
    }
  }

  clearInput() {
    // clear typeahead input and re initialize state
    try {
      this.orderPrefTypeahead.clearState()
      this.diagnosisTypeahead.clearState()
      this.setState({
        orderSet: false,
        diagnosisName: '',
        orderName: '',
        config: { orders: [{}] },
      })
    } catch (e) {
      console.error(e)
    }
  }

  updateDiagnosis(_, diagnosisNameObject) {
    // merge diagnosis information (snomed code, diagnosis name)
    // so you dont overwrite order info for non order sets
    let { config } = this.state
    const order = config.orders[0]
    Object.assign(order, diagnosisNameObject.orders[0])
    // update diagnosis name and config
    const diagnosisName = diagnosisNameObject.orders[0].order_name
    config = { orders: [order] }
    this.setState({ diagnosisName, config })
  }

  updateOrder(_recId, newOptions) {
    // merge order information (ordertypeid, order_type, order_name)
    // and then set state to display the chosen order in UI
    const { config } = this.state
    if (newOptions.order_set) {
      const updatedConfig = Object.assign(config, newOptions)
      const orderName = newOptions.name
      this.setState({ orderSet: true, orderName, config: updatedConfig })
    } else {
      // so you dont overwrite diagnosis for non order sets
      const order = config.orders[0]
      Object.assign(order, newOptions.orders[0])
      // update order name and config
      const orderName = newOptions.orders[0].order_name
      const updatedConfig = { orders: [order] }
      this.setState({ orderSet: false, orderName, config: updatedConfig })
    }
  }

  submitOrder() {
    const { emrPatientId, organizationId, addRecToRecsList } = this.props
    const { config } = this.state
    const params = {
      emr_patient_id: emrPatientId,
      organization_id: organizationId,
      orderConfig: config,
    }
    // make the call
    $.ajax({
      type: 'POST',
      url: '/orders/order_anything',
      data: JSON.stringify(params),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        const orderStatus = response.order_status
        if (orderStatus === 'order queued') {
          addRecToRecsList(response.rec.recommendation)
          // TODO figure out React animation for queued orders
          // use scrollTop jquery module to show user order queued at top of page
          $('main').animate({ scrollTop: 0 }, 1000)
        } else if (orderStatus === 'order successful') {
          addRecToRecsList(response.rec.recommendation)
        }

        // clear typeahead and note field
        this.clearInput()
      },
      error: (error) => {
        if (checkTimeout(error) === true) {
          redirectToLogin()
        } else {
          const err = error.responseJSON.errors
          flash.error(
            `${err}. Contact support@avhana.com if you need further assistance.`,
          )
          console.error(error, { context: `OrderAnything error message: ${error.responseText}` })
        }
      },
    })
  }

  disableSubmit() {
    const { config, diagnosisName, orderName } = this.state
    if (_.isEqual(config.orders, [{}])) {
      return true
    }
    if ((diagnosisName === '' || orderName === '') && config.orders.length === 1) {
      return true
    }
    return false
  }

  render() {
    const { orderSet } = this.state
    const formInvalid = this.disableSubmit()
    let diagnosisTypeahead
    if (orderSet) {
      diagnosisTypeahead = <p>Orders will use diagnosis codes associated with order set</p>
    } else {
      diagnosisTypeahead = (
        <DiagnosisTypeahead
          ref={(el) => { this.diagnosisTypeahead = el }}
          updateOptions={this.updateDiagnosis}
          updateOrderPref={false}
          orderAnything
          columns={12}
          style={{ paddingLeft: '0px' }}
        />
      )
    }

    return (
      <div className="custom-order-container" style={{ margin: '0 0.5rem' }}>
        <h6>Place a custom order</h6>
        <div className="card custom-order-card">
          <div className="card-content">
            <div className="row" style={{ marginBottom: '0px' }}>
              <div style={{ paddingBottom: '0.5em' }} className="col s12">
                <span className="bold-text">
                  Submit orders unrelated to the recommendations above
                </span>
              </div>
              <div className="col s6">
                <p className="rule-action-title">1. Search for an order</p>
                <AthenaOrderPrefTypeahead
                  ref={(el) => { this.orderPrefTypeahead = el }}
                  updateOptions={this.updateOrder}
                  pref={{ options: { orders: [{}] } }}
                  rec={{ options: { orders: [{}] } }}
                  updateOrderPref={false}
                  orderAnything
                  style={{ paddingLeft: '0px' }}
                  columns="12"
                />
              </div>
              <div className="col s6">
                <p className="rule-action-title">2. Select a diagnosis to order with</p>
                {diagnosisTypeahead}
              </div>
            </div>
            <div className="row" style={{ marginBottom: '0px' }}>
              <div className="col s10 offset-s1">
                <button
                  type="button"
                  className="btn right"
                  onClick={this.submitOrder}
                  disabled={formInvalid}
                >
                  Send Order to EHR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

OrderAnything.propTypes = {
  emrPatientId: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  addRecToRecsList: PropTypes.func.isRequired,
}
