import React from 'react'
import PropTypes from 'prop-types'

const FollowupAction = ({ followUpActions }) => {
  if (typeof followUpActions === "object") {
    return (
      <div>
        {followUpActions.map((followUpAction) => (
          <p>{followUpAction}</p>
        ))}
      </div>
    )
  }
  return <p>{followUpActions}</p>
}

FollowupAction.propTypes = {
  followUpActions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
}

export default FollowupAction
