import React from 'react'
import PropTypes from 'prop-types'

/**
 * component to render markdown
 * takes in prop of type string that has markdown and coverts it to html
 */

const Scoliosis = (props) => {
  const image = (
    <div className="scoliosis_chart">
      <img src="http://res.cloudinary.com/avhana/image/upload/c_scale,f_auto,h_600/v1461699353/F1.large_ishjdr.jpg" alt="scoliosis chart" className="image_sizer" />
    </div>
  )

  const text = (
    <div>
      {props.rule.description}
    </div>
  )

  return (
    <div>
      {text}
      {image}
    </div>
  )
}

Scoliosis.propTypes = {
  rule: PropTypes.shape({
    description: PropTypes.string,
  }),
}
export default Scoliosis
