import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

const DiagnosisInput = ({ children }) => (
  <Grid
    container
    direction="column"
  >
    <Grid
      item
      xs={12}
    >
      <h6>Diagnosis</h6>
    </Grid>
    <Grid
      item
      xs={12}
    >
      {children}
    </Grid>
  </Grid>
)

DiagnosisInput.propTypes = {
  children: PropTypes.element.isRequired
}

export default DiagnosisInput
