import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const AppointmentNotes = (props) => {
  const { activeAppointment } = props
  const appointmentNotes = activeAppointment.appointment_notes || []
  if (appointmentNotes.length > 0) {
    const notesList = appointmentNotes.map((note) => (
      <div className="row">
        <div
          style={{
            wordWrap: 'break-word',
            fontSize: '16px',
          }}
        >
          {`${note.note_text}`}
        </div>
        <div>
          {
            `created by ${note.created_by}
              on ${moment(note.ehr_created_at).format('ll')}`
          }
        </div>
      </div>
    ))

    return (
      <div>
        <p>
          {notesList}
        </p>
      </div>
    )
  }
  return (
    <div>
      <p>
        No appointment notes exist for this patient
      </p>
    </div>
  )
}

AppointmentNotes.propTypes = {
  activeAppointment: PropTypes.shape({
    appointment_notes: PropTypes.arrayOf(PropTypes.shape({
      note_text: PropTypes.string,
      ehr_created_at: PropTypes.instanceOf(Date),
    })),
  }).isRequired,
}

export default AppointmentNotes
