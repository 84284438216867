import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'

const AddOrderButton = ({ orderPreference, updateOrderPreference }) => {
  const ordersPath = R.lensPath(['options', 'orders'])
  const selectionsPath = R.lensPath(['options', 'selections'])
  const orderSetPath = R.lensPath(['options', 'order_set'])
  const orders = R.view(ordersPath, orderPreference) || []
  const selections = R.view(selectionsPath, orderPreference)
  if (orders.length === 4) return null
  const onClick = () => {
    let updatedOrderPreference = R.set(ordersPath, [...orders, {}], orderPreference)
    if (!selections) {
      updatedOrderPreference = R.set(selectionsPath, true, updatedOrderPreference)
      updatedOrderPreference = R.set(orderSetPath, false, updatedOrderPreference)
    }
    updateOrderPreference(updatedOrderPreference)
  }
  return <button type="button" onClick={onClick}>Add Order</button>
}

AddOrderButton.propTypes = {
  orderPreference: PropTypes.shape({
    options: PropTypes.shape({
      orders: PropTypes.arrayOf(PropTypes.shape())
    })
  }).isRequired,
  updateOrderPreference: PropTypes.func.isRequired,
}

export default AddOrderButton
