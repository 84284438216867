import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { SCREENING_RULE_UUIDS } from './RecommendationsList'

const defaultData = (name) => ({
  name,
  score: null,
  date: null,
  checked: false,
})

const buildBloodPressure = (name, data) => {
  const innerData = data['last vitals']
  if (!innerData) return defaultData(name)
  const score = {
    systolic: innerData.systolic,
    diastolic: innerData.diastolic,
  }
  let date = innerData.bp_date
  if (date) {
    // Dates are returned as arrays, but Ruby and JS index months differently.
    date = date.map((v, i) => (i === 1 ? v - 1 : v))
  }
  const checked = true
  return {
    name,
    score,
    date,
    checked,
  }
}

const buildBMI = (name, data) => {
  const innerData = data['last vitals']
  if (!innerData) return defaultData(name)
  const { bmi: score } = innerData
  let { bmi_date: date } = innerData
  if (date) {
    // Dates are returned as arrays, but Ruby and JS index months differently.
    date = date.map((v, i) => (i === 1 ? v - 1 : v))
  }
  const checked = true
  return {
    name,
    score,
    date,
    checked,
  }
}

const buildFallRisk = (name, data) => {
  const innerData = data[name]
  if (!innerData) return defaultData(name)
  const score = null
  const { due: checked } = innerData
  let { date } = innerData
  if (date) {
    date = date.map((v, i) => (i === 1 ? v - 1 : v))
  }
  return {
    name,
    score,
    date,
    checked,
  }
}

const buildPHQ9 = (name, data) => {
  const innerData = data[name]
  if (!innerData) return defaultData(name)
  const score = null
  const { due: checked } = innerData
  let { date } = innerData
  if (date) {
    date = date.map((v, i) => (i === 1 ? v - 1 : v))
  }
  return {
    name,
    score,
    date,
    checked,
  }
}

const buildScreening = (uuid, data) => {
  const name = SCREENING_RULE_UUIDS[uuid]
  if (name === 'Blood Pressure') {
    return buildBloodPressure(name, data)
  }
  if (name === 'BMI') {
    return buildBMI(name, data)
  }
  if (name === 'Fall Risk') {
    return buildFallRisk(name, data)
  }
  if (name === 'PHQ-9') {
    return buildPHQ9(name, data)
  }
  return null
}

const Screenings = ({ data, patient: { age } }) => {
  if (age <= 12) return null
  const screeningUUIDs = age <= 64 ? Object.keys(SCREENING_RULE_UUIDS).slice(0, 3) : Object.keys(SCREENING_RULE_UUIDS)

  const allScreenings = screeningUUIDs.map((uuid) => buildScreening(uuid, data))
  const screeningViews = allScreenings.map((screening) => {
    let score = 'N/A'
    let date = 'N/A'
    if (screening.name === 'Blood Pressure') {
      if (screening.score && screening.score.systolic && screening.score.diastolic) {
        score = `${screening.score.systolic}/${screening.score.diastolic}`
        date = moment(screening.date).format('MM/DD/YY')
      }
    } else if (screening.score) {
      score = screening.score
      date = moment(screening.date).format('MM/DD/YY')
    }
    return (
      <div>
        <ul className="collapsible collapsible-accordion">
          <li className="collection-item avatar recommendation-card">
            <div className="collapsible-header collapsible-header-padding">
              <div className="col s12 flex align-center">
                <div style={{ marginLeft: 0 }} className="col s12 m9 rec-title-subtitle-container">
                  <div className="row decrease-line-height-more">
                    <div className="col s12 rule-action-title-container">
                      <span className="rule-action-title">
                        <b>{screening.name}</b>
                      </span>
                    </div>
                    <div className="col s12">
                      <div className="rule-action-subtitle display-inline-text">
                        Date: {date}
                      </div>
                      <div className="rule-action-subtitle display-inline-text" style={{ marginLeft: '0.5rem' }}>
                        Score: {score}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col s12 m2 l1 flex align-center justify-center" style={{ paddingRight: '0 !important', paddingLeft: '1.75rem !important' }}>
                  <div className="rec-order-checkbox-container flex justify-center">
                    <label htmlFor={`${screening.name}_screening`}>
                      <input
                        type="checkbox"
                        className="filled-in"
                        id={`${screening.name}_screening`}
                        defaultChecked={screening.checked}
                        data-key={screening.name}
                      />
                      <span />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    )
  })

  return screeningViews
}

Screenings.propTypes = {
  data: PropTypes.shape({}).isRequired,
  patient: PropTypes.shape({}).isRequired,
}

export default Screenings
