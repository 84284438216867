import React, { forwardRef } from 'react'
import { func, oneOfType, string, arrayOf } from 'prop-types'
import { VariableSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ListSubheader } from '@mui/material'

// eslint-disable-next-line import/no-cycle
import Rule from './Rule'
// eslint-disable-next-line import/no-cycle
import AllscriptsRule from './AllscriptsRule'
// eslint-disable-next-line import/no-cycle
import MaterialUIRule from './MaterialUIRule'

import { EMR, RuleCategory, Rule as RuleType, ID } from '../../athenaTypes'

const propTypes = {
  category: RuleCategory.isRequired,
  displayedRules: arrayOf(oneOfType([RuleType, string])).isRequired,
  emr: EMR.isRequired,
  toggleStepTherapy: func.isRequired,
  turnSubscriptionActiveSilent: func.isRequired,
  updateOrderPreference: func.isRequired,
}

export const RuleContext = React.createContext({})

const RulesList = ({
  category,
  displayedRules,
  emr,
  toggleStepTherapy,
  turnSubscriptionActiveSilent,
  updateOrderPreference,
}) => {
  const DEFAULT_ITEM_HEIGHT = category === 'Previsit' ? 166 : 148
  const listRef = React.useRef()
  const sizeMap = React.useRef({})
  const setSize = React.useCallback((index, size) => {
    const newSizes = Object.assign(
      {},
      ...Object.keys(sizeMap.current).map((i) => ({
        [i]: DEFAULT_ITEM_HEIGHT,
      }))
    )
    sizeMap.current = { ...newSizes, [index]: size }
    listRef.current.resetAfterIndex(0)
  }, [])

  const getSize = React.useCallback(
    (index) => sizeMap.current[index] || DEFAULT_ITEM_HEIGHT,
    []
  )

  const [openId, setOpenId] = React.useState(null)
  const RuleComponent = (emr !== 'allscripts') ? MaterialUIRule : AllscriptsRule

  const buildRule = ({ index, style }) => {
    const rule = displayedRules[index]
    const innerComponent = typeof rule === 'object' ? (
      <RuleComponent
        key={rule.id}
        rule={rule}
        turnSubscriptionActiveSilent={turnSubscriptionActiveSilent}
        toggleStepTherapy={toggleStepTherapy}
        index={index}
        open={rule.id === openId}
        setOpenId={setOpenId}
        emr={emr}
        updateOrderPreference={updateOrderPreference}
      />
    ) : (
      <ListSubheader>
        <h1>{rule}</h1>
      </ListSubheader>
    )
    return (
      <div style={style}>
        {innerComponent}
      </div>
    )
  }

  const innerElementType = forwardRef(({ style, ...rest }, ref) => (
    <div
      style={{
        ...style,
        paddingTop: 5
      }}
      ref={ref}
      {...rest}
    />
  ))

  return (
    <div style={{ height: '1000px' }}>
      <RuleContext.Provider value={{ setSize }}>
        <AutoSizer>
          {({ width }) => (
            <VariableSizeList
              ref={listRef}
              width={width}
              height={1000}
              itemSize={getSize}
              itemCount={displayedRules.length}
              innerElementType={innerElementType}
            >
              {buildRule}
            </VariableSizeList>
          )}
        </AutoSizer>
      </RuleContext.Provider>
    </div>
  )
}

RulesList.propTypes = propTypes

export default RulesList
