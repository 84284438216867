import React from 'react'
import PropTypes from 'prop-types'
import DepartmentsTypeahead from '../reusableComponents/DepartmentsTypeahead'

const DEPARTMENT = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.id,
})

const propTypes = {
  departments: PropTypes.arrayOf(DEPARTMENT).isRequired,
  allDepartments: PropTypes.arrayOf(DEPARTMENT).isRequired,
  onOptionSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
}

export default class UserFormStep2 extends React.Component {
  constructor(props) {
    super(props)
    this.handleOptionSelected = this.handleOptionSelected.bind(this)
    this.typeahead = null
  }

  handleOptionSelected(option) {
    const { onOptionSelected } = this.props
    onOptionSelected(option)
    this.typeahead.typeahead.setEntryText('')
  }

  render() {
    const {
      departments, allDepartments, handleClick,
    } = this.props
    const departmentsList = departments.map((dept) => (
      <div data-key={dept.name} className="collection-check-box">
        <label htmlFor={`user_department_ids_${dept.id}`}>
          <input type="checkbox" value={dept.id} checked="checked" name="user[department_ids][]" id={`user_department_ids_${dept.id}`} onClick={handleClick} />
          <span>{dept.name}</span>
        </label>
      </div>
    ))
    const options = allDepartments.filter((d) => departments.findIndex((dept) => dept.id !== d.id))

    return (
      <div className="row">
        <div className="col s12">
          <DepartmentsTypeahead
            ref={(el) => { this.typeahead = el }}
            onOptionSelected={this.handleOptionSelected}
            options={options}
          />
        </div>
        <div className="col s12">
          {departmentsList}
        </div>
      </div>
    )
  }
}

UserFormStep2.propTypes = propTypes
