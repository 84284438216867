import React from 'react'
import PropTypes from 'prop-types'

export default class RadioButtonsInline extends React.Component {
  updateFormState(event) {
    const key = this.props.groupName
    const value = event.target.value
    const answer = this.props.labels[value]
    const questionId = this.props.questionId
    this.props.updateFormState({ key, questionId, value: parseInt(value, 10), altValue: answer })
  }

  render() {
    const labels = this.props.labels
    const radioButtons = _.map(labels, (value, index) =>
      <div key={index} className="col s1">
        <input
          name={this.props.groupName}
          type="radio"
          id={`${this.props.groupName}_${value}`}
          value={index}
          defaultChecked={index === this.props.selected}
          onChange={this.updateFormState.bind(this)}
        />
        <label htmlFor={`${this.props.groupName}_${value}`} />
      </div>
    )

    return (
      <div className="row">
        <div className="col s8">
          {this.props.groupName}
        </div>
        {radioButtons}
      </div>
    )
  }
}

RadioButtonsInline.propTypes = {
  groupName: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  questionId: PropTypes.string,
  selected: PropTypes.bool,
  updateFormState: PropTypes.func,
}
