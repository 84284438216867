import React from 'react'
import { bool, func } from 'prop-types'
import M from 'materialize-css'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

// eslint-disable-next-line import/no-cycle
import Rule from './Rule'
import { Rules, Tags } from '../athenaTypes'

const propTypes = {
  displayedRules: Rules.isRequired,
  turnRuleOnOff: func.isRequired,
  toggleMigrationStatus: func.isRequired,
  tags: Tags.isRequired,
  isStaging: bool.isRequired,
}

export const RuleContext = React.createContext({})

const RulesList = ({ displayedRules, tags, turnRuleOnOff, toggleMigrationStatus, isStaging }) => {
  const listRef = React.useRef()
  const sizeMap = React.useRef({})
  const setSize = React.useCallback((index, size) => {
    const newSizes = Object.assign({}, ...(Object.keys(sizeMap.current).map((i) => ({
      [i]: 140,
    }))))
    sizeMap.current = { ...newSizes, [index]: size }
    listRef.current.resetAfterIndex(0)
  }, [])
  const getSize = React.useCallback((index) => sizeMap.current[index] || 140, [])

  React.useEffect(() => {
    const elems = document.querySelectorAll('.collapsible')
    M.Collapsible.init(elems, {})
  })

  const innerElementType = React.forwardRef((props, ref) => (
    <ul ref={ref} className="collapsible popout" {...props} />
  ))

  const toggleRule = (id, state) => turnRuleOnOff(id, state)

  const buildRule = ({ index, style }) => {
    const rule = displayedRules[index]
    return (
      <Rule
        key={rule.id}
        rule={rule}
        toggleRule={toggleRule}
        toggleMigrationStatus={toggleMigrationStatus}
        tags={tags}
        style={style}
        index={index}
        isStaging={isStaging}
      />
    )
  }
  return (
    <RuleContext.Provider value={{ setSize }}>
      <AutoSizer>
        {({ width }) => (
          <List
            ref={listRef}
            innerElementType={innerElementType}
            width={width}
            height={800}
            itemSize={getSize}
            itemCount={displayedRules.length}
          >
            {buildRule}
          </List>
        )}
      </AutoSizer>
    </RuleContext.Provider>
  )
}

RulesList.propTypes = propTypes

export default RulesList
