import React from 'react'
import MaterializeTextInput from '../../../reusableComponents/MaterializeTextInput.jsx'

export default class Das28 extends React.Component {
  constructor(props) {
    super(props)
    // for Pfizer demo, use 6 month score of 6.96
    this.prevScore = { value: 6.96, date: '3/2/2016' }
    this.state = { score: 0, formValues: {}, percentMessage: 0 }
    this.calculateDas28Score = this.calculateDas28Score.bind(this)
    this.getDas28Message = this.getDas28Message.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.updateParentScoreState = this.updateParentScoreState.bind(this)
  }

  componentDidMount() {
    // for Pfizer demo, use a value of 55 for the ESR (mm/hr)
    const valuesFromDrools = {
      esr: 55
    }
    const scoreFromDrools = 0
    this.setState({ formValues: valuesFromDrools })
    this.updateParentScoreState(scoreFromDrools)
  }

  updateFormState(formEl) {
    const key = formEl.key
    const newFormValues = this.state.formValues
    newFormValues[key] = formEl.value
    this.setState({ formValues: newFormValues })
    this.calculateDas28Score.bind(this)
  }

  getDas28Message(newScoreRounded) {
    let percentMessage
    if (newScoreRounded < this.prevScore.value) {
      const percentDec = math.round(((this.prevScore.value - newScoreRounded)/this.prevScore.value) * 100, 1)
      percentMessage = percentDec + '% decrease'
    } else if (newScoreRounded > this.prevScore.value) {
      const percentInc = math.round(((newScoreRounded - this.prevScore.value)/this.prevScore.value) * 100, 1)
      percentMessage = percentInc + '% increase'
    }

    this.setState({ percentMessage })
  }

  calculateDas28Score () {
    const formValsState = this.state.formValues
    var newScore =
      0.555*(Math.sqrt(formValsState.tenderJointCount))+0.284
      *(Math.sqrt(formValsState.swollenJointCount))
      +Math.log(formValsState.esr)*0.7
      +(formValsState.vas)*0.0142

    // prevent NaN, -Infinity
    if (newScore > 0) {
      var newScoreRounded = math.round(newScore, 2)
      this.setState({ score: newScoreRounded })
      this.getDas28Message(newScoreRounded)
      this.updateParentScoreState(newScoreRounded)
    }
  }

  updateParentScoreState (score) {
    this.props.updateParentScoreState(score)
  }

  render () {
    let percentMessage
    const formInfo = this.state.formValues

    // display improvement score only if score is above 0
    if (this.state.score > 0) {
      percentMessage =
        <div>
          Current DAS28 score is a <span className="bold-text">{this.state.percentMessage}</span> from the last recorded score of <span className="bold-text">{this.prevScore.value} on {this.prevScore.date}</span>.
        </div>
    }

    return (
      <form onChange={this.calculateDas28Score} className="chad-score-form recommendation-body body-container">
        <div className="row">
          <div className="col s6">
            <div className="row">
              <div className="col s6 offset-s1">
                <MaterializeTextInput
                  formId={1}
                  label="tender joint count (0-28)"
                  data={{ key: 'tenderJointCount', value: formInfo.tenderJointCount }}
                  updateFormState={this.updateFormState}
                />
                <MaterializeTextInput
                  formId={2}
                  label="swollen joint count (0-28)"
                  data={{ key: 'swollenJointCount', value: formInfo.swollenJointCount }}
                  updateFormState={this.updateFormState}
                />
                <MaterializeTextInput
                  formId={3}
                  label="ESR (mm/hr)"
                  data={{ key: 'esr', value: formInfo.esr }}
                  updateFormState={this.updateFormState}
                />
                <MaterializeTextInput
                  formId={4}
                  label="VAS general health patient (mm)"
                  data={{ key: 'vas', value: formInfo.vas }}
                  updateFormState={this.updateFormState}
                />
              </div>
            </div>
          </div>
          <div className="col s6 chad-score-container">
            <div className="row">
              <div className="col s7">
                <br/>
                <div className="ascvd-score-header">DAS28 Score</div>
              </div>
              <div className="col s5">
                <div className="chad-score-text bubble-number-xl">{this.state.score}</div>
              </div>
            </div>
            <div className="row">
              <div className="col s10 chad-score-message">
                <br/>
                <div>{percentMessage}</div>
                <br/>
                <div>The DAS28 score is an instrument used to measure the disease activity in people with Rheumatoid Arthritis.</div>
                <br/>
                <a target="_blank" href="http://www.das-score.nl/das28/en/" className="right">View source</a>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}
