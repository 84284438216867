import { OrdersDisabledEmr } from '../../athenaTypes'
import AbdominalPain from './cardBodyTypes/AbdominalPain.jsx';
import AbnormalVitals from './cardBodyTypes/AbnormalVitals.jsx';
import AdultImms from './cardBodyTypes/AdultImms.jsx';
import AllscriptsOrderPrefTypeahead from '../../reusableComponents/AllscriptsOrderPrefTypeahead.jsx';
import AmdHasExam from './cardBodyTypes/AmdHasExam.jsx';
import AmdNoExam from './cardBodyTypes/AmdNoExam.jsx';
import Aoe from './cardBodyTypes/Aoe.jsx';
import Asa from './cardBodyTypes/Asa.jsx';
import AscvdScore from './cardBodyTypes/AscvdScore.jsx';
import AthenaOrderPrefTypeahead from '../../reusableComponents/AthenaOrderPrefTypeahead.jsx';
import Cataract from './cardBodyTypes/Cataract.jsx';
import ChadScore from './cardBodyTypes/ChadScore.jsx';
import DiagnosisTypeahead from '../../reusableComponents/DiagnosisTypeahead.jsx';
import Flu from './cardBodyTypes/Flu.jsx';
import FraminghamScore from './cardBodyTypes/FraminghamScore.jsx';
import GenericImms from './cardBodyTypes/GenericImms.jsx';
import HighRiskMed from './cardBodyTypes/HighRiskMed.jsx';
import MarkdownParser from '../../reusableComponents/MarkdownParser.jsx';
import MorseFallScale from './cardBodyTypes/MorseFallScale.jsx';
import OrderingModeDropdown from '../../reusableComponents/OrderingModeDropdown.jsx';
import Phq9 from './cardBodyTypes/Phq9.jsx';
import PqrsBmi from './cardBodyTypes/PqrsBmi.jsx';
import PqrsHba1c from './cardBodyTypes/PqrsHba1c.jsx';
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Scoliosis from './cardBodyTypes/Scoliosis.jsx';
import SeniorImms from './cardBodyTypes/SeniorImms.jsx';
import TobaccoScreening from './cardBodyTypes/TobaccoScreening.jsx';
import Ttr from './cardBodyTypes/Ttr.jsx';

const propTypes = {
  id: PropTypes.number.isRequired,
  rec: PropTypes.object.isRequired,
  emr: PropTypes.string.isRequired,
  rule_type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  rule: PropTypes.object.isRequired,
  order_preference: PropTypes.object.isRequired,
  encounter: PropTypes.object,
  options: PropTypes.object,
  updateOptions: PropTypes.func,
  updateRecStatus: PropTypes.func,
  addRecToRecsList: PropTypes.func,
  removeRecommendation: PropTypes.func,
  selectTab: PropTypes.func,
  sendOrder: PropTypes.func,
  submittedToEhr: PropTypes.func,
  recommendation: PropTypes.object,
  // priority: ,
  // guideline: ,
}

export default function CardBody(props) {
  const cardBody = renderTabs(props)

  return (
    <div className='collapsible-body'>
      <div className='body-container'>
        {cardBody}
        <p style={{ paddingLeft: '3em' }}><a href={props.rule.link} target="_blank">Link to rule source</a></p>
      </div>
    </div>
  )
}

const renderTabs = (props) => {
  const emr = props.emr
    // check order pref exists and if it does make sure order type exists
    // ternary needed because athena options have an order array nested in them but other ehrs do not
    const orderPrefExists = props.rec && props.rec.options.orders ? props.rec.options.orders : props.rec.options
    const ruleAction = props.rule_type
    if (props.noActionButtons) {
      return renderRemoveTabs(props)
    } else {
      switch (ruleAction) {
        case 'add':
          if (orderPrefExists) {
            return renderAddTabs(props)
          } else {
            // default to description tab only if no order pref
            return renderRemoveTabs(props)
          }
      case 'remove':
        return renderRemoveTabs(props)
      case 'follow up':
        if (emr === 'athena') {
          return renderFollowUpTabs(props)
        } else {
          return renderRemoveTabs(props)
        }
      default:
        // default to description tab only
        return renderRemoveTabs(props)
      }
  }
}

const identifyActive = (name, activeTab) => (
  (name === activeTab) ? ' active' : ''
)

const renderAddTabs = (props) => {
  const activeTab = props.activeTab
  const emr = props.emr

  const tabs = ['Description', 'Change Order', 'Change Diagnosis']
  const descriptionOnlyTab = ['Description']

  const tabNavItems = renderTabNavItems(tabs, props)
  const descriptionOnlyTabNavItems = renderTabNavItems(descriptionOnlyTab, props)


  const descriptionPanel = renderDescriptionPanel(props)
  const recommendationSource = renderRecSourceInfo(props)
  const orderPreferencePanel = renderOrderPreferencePanel(props)
  const diagnosisPanel = renderDiagnosisPanel(props)

  return (
    <div className="tabs-container">
      <div className="tab-nav">
        {emr === 'athena' && (
          <ul role="tablist" className="tab-nav-items">
            {tabNavItems}
          </ul>
        )}
        {OrdersDisabledEmr.includes(emr) && (
          <ul role="tablist" className="tab-nav-items">
            {descriptionOnlyTabNavItems}
          </ul>
        )}
      </div>
      <div className="tab-panels">
        <div
          role="tabpanel"
          className={'tab-panel description' +
                     identifyActive('Description', activeTab)}
        >
          {recommendationSource}
          {descriptionPanel}
        </div>
        <div
          role="tabpanel"
          className={'tab-panel order-preference' +
                     identifyActive('Change Order', activeTab)}
        >
          {orderPreferencePanel}
        </div>
        <div
          role="tabpanel"
          className={'tab-panel diagnosis-preference' +
                     identifyActive('Change Diagnosis', activeTab)}
        >
          {diagnosisPanel}
        </div>
      </div>
    </div>
  )
}

const renderRemoveTabs = (props) => {
  const activeTab = props.activeTab

  const tabs = ['Description']
  const tabNavItems = renderTabNavItems(tabs, props)

  const descriptionPanel = renderDescriptionPanel(props)
  const recommendationSource = renderRecSourceInfo(props)

  return (
    <div className="tabs-container">
      <div className="tab-nav">
        <ul role="tablist" className="tab-nav-items">
          {tabNavItems}
        </ul>
      </div>
      <div className="tab-panels">
        <div
          role="tabpanel"
          className={'tab-panel description' +
                     identifyActive('Description', activeTab)}
        >
          {recommendationSource}
          {descriptionPanel}
        </div>
      </div>
    </div>
  )
}

const renderFollowUpTabs = (props) => {
  const activeTab = props.activeTab

  const tabs = ['Description']
  const tabNavItems = renderTabNavItems(tabs, props)

  const descriptionPanel = renderDescriptionPanel(props)
  const recommendationSource = renderRecSourceInfo(props)

  return (
    <div className="tabs-container">
      <div className="tab-nav">
        <ul role="tablist" className="tab-nav-items inside-recommendation">
          {tabNavItems}
        </ul>
      </div>
      <div className="tab-panels">
        <div
          role="tabpanel"
          className={'tab-panel description' +
                     identifyActive('Description', activeTab)}
        >
          {recommendationSource}
          {descriptionPanel}
        </div>
      </div>
    </div>
  )
}

const renderTabNavItems = (navItems, { selectTab, activeTab }) => {
  return (
    _.map(navItems, (navItem, index) =>
      renderTabNavItem(
        navItem,
        index,
        activeTab,
        selectTab
      )
    )
  )
}

const renderTabNavItem = (navItem, index, activeTab, selectTab) => {
  const onClick = (event) => {
    const clickedTab = event.target.innerText
    selectTab(clickedTab)
  }

  return (
    <li
      role="tab"
      key={index}
      className={'tab-nav-item' + identifyActive(navItem, activeTab)}
      onClick={onClick}
    >
      <p><a>{navItem}</a></p>
    </li>
  )
}

const renderDescriptionPanel = (props) => {
  const partialPath = props.rule.partial_path
  switch (partialPath) {
    case 'abdominal_pain':
      if (props.emr === 'athena') {
        return <AbdominalPain key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'abnormal_vitals':
      if (props.emr === 'athena') {
        return <AbnormalVitals key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'adolescent_imms':
      if (props.emr === 'athena') {
        const vaccines =
          [
            {
              name: 'Tdap',
              doses: [1],
              ages: ['Age >= 11'],
              maxAgeInWeeks: [936],
              genericCvx: "115",
            }, {
              name: 'Meningococcal',
              doses: [1, 2],
              ages: ['Age 11-12', 'Age 16'],
              maxAgeInWeeks: [936, 936],
              genericCvx: "108",
            }, {
              name: 'HPV',
              doses: [1, 2, 3],
              ages: ['Age 11-12', 'Age 11-12', 'Age 11-12'],
              maxAgeInWeeks: [1092, 1092, 1092],
              genericCvx: "137",
            }
          ]
        return <GenericImms key={props.id} {...props} vaccines={vaccines} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'adult_imms':
      if (props.emr === 'athena') {
        return <AdultImms key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'amd_no_exam':
      if (props.emr === 'athena') {
        return <AmdNoExam key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'amd_has_exam':
      if (props.emr === 'athena') {
        return <AmdHasExam key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'aoe':
      if (props.emr === 'athena') {
        return <Aoe key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'asa':
      if (props.emr === 'athena') {
        return <Asa key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'ascvd_score':
      if (props.emr === 'athena') {
        return <AscvdScore key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'cataract':
      if (props.emr === 'athena') {
        return <Cataract key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'childhood_imms_general':
      if (props.emr === 'athena') {
        const vaccines =
          [
            {
              name: 'Hep B',
              doses: [1, 2, 3],
              ages: ['birth', '1 month (4 weeks after previous dose)', '6 months (8 weeks after previous dose)'],
              maxAgeInWeeks: [936, 936, 936],
              genericCvx: "45",
            }, {
              name: 'Rotavirus',
              doses: [1, 2, 3],
              ages: ['2 months', '4 months (4 weeks after previous dose)', '6 months (4 weeks after previous dose)'],
              maxAgeInWeeks: [15, 35, 35],
              genericCvx: "122",
            }, {
              name: 'DTaP',
              doses: [1, 2, 3, 4, 5],
              ages: ['2 months', '4 months (4 weeks after previous dose)', '6 months (4 weeks after previous dose)', '15 months (6 months after previous dose)', '4 years (6 months after previous dose)'],
              maxAgeInWeeks: [364, 364, 364, 364, 364],
              genericCvx: "107",
            }, {
              name: 'Hib',
              doses: [1, 2, 3, 4],
              ages: ['2 months', '4 months (8 weeks after previous dose)', '6 months (4 weeks after previous dose)', '12 months (8 weeks after previous dose)'],
              maxAgeInWeeks: [260, 260, 260, 260],
              genericCvx: "17",
            }, {
              name: 'Pneumococcal',
              doses: [1, 2, 3, 4],
              ages: ['2 months', '4 months (8 weeks after previous dose)', '6 months (8 weeks after previous dose)', '12 months (8 weeks after previous dose)'],
              maxAgeInWeeks: [260, 260, 260, 260],
              genericCvx: "152",
            }, {
              name: 'IPV',
              doses: [1, 2, 3, 4, 5],
              ages: ['2 months', '4 months (4 weeks after previous dose)', '6 months (4 weeks after previous dose)', '4 years (6 months after previous dose)', '4 years (6 months after previous dose)'],
              maxAgeInWeeks: [936, 936, 936, 936, 364],
              genericCvx: "10",
            }, {
              name: 'MMR',
              doses: [1, 2],
              ages: ['12 months', '4 years (4 weeks after previous dose)'],
              maxAgeInWeeks: [936, 936],
              genericCvx: "03",
            }, {
              name: 'Varicella',
              doses: [1, 2],
              ages: ['12 months', '4 years (3 months after previous dose)'],
              maxAgeInWeeks: [936, 624],
              genericCvx: "21",
            }, {
              name: 'Hep A',
              doses: [1, 2],
              ages: ['12 months', '18 months (6 months after previous dose)'],
              maxAgeInWeeks: [936, 936],
              genericCvx: "31",
            }
          ]
        return <GenericImms key={props.id} {...props} vaccines={vaccines} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'flu':
      if (props.emr === 'athena') {
        return <Flu key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'high_risk_med':
      if (props.emr === 'athena') {
        return <HighRiskMed key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'morse_fall_scale':
      if (props.emr === 'athena') {
        return <MorseFallScale key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'phq9':
      if (props.emr === 'athena') {
        return <Phq9 key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'pqrs_bmi':
      if (props.emr === 'athena') {
        return <PqrsBmi key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'pqrs_hba1c':
      if (props.emr === 'athena') {
        return <PqrsHba1c key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'scoliosis':
      return <Scoliosis key={props.id} {...props} />

    case 'senior_imms':
      if (props.emr === 'athena') {
        return <SeniorImms key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    case 'tobacco_screening':
      if (props.emr === 'athena') {
        return <TobaccoScreening key={props.id} {...props} />
      } else {
        return <MarkdownParser markdown={props.rule.description} />
      }

    default:
      return <MarkdownParser markdown={props.rule.description} />
  }
}

const renderOrderPreferencePanel = ({ id, rec, order_preference, updateOptions, emr }) => {
  let orderingModeDropdown
  if (rec.options.order_set === false && rec.options.orders[0]["order_type"] === 'vaccination') {
    orderingModeDropdown =
      <OrderingModeDropdown
        updateOptions={updateOptions}
        pref={order_preference}
        dropdownOptions={['Prescribe',
                          'Administer',
                        ]}
        updateOrderPref={false}
        rec={rec}
        style={{ marginLeft: '16.666665%' }}
        columns={'3'}
      />
  }

  let orderPrefTypeahead
  if (emr === 'athena') {
    orderPrefTypeahead =
      <AthenaOrderPrefTypeahead
        updateOptions={updateOptions}
        pref={order_preference}
        updateOrderPref={false}
        rec={rec}
        style={{ marginLeft: '16.666665%' }}
      />
  } else if (emr === 'allscripts') {
    orderPrefTypeahead =
      <AllscriptsOrderPrefTypeahead
        updateOptions={updateOptions}
        pref={order_preference}
        updateOrderPref={false}
        rec={rec}
        style={{ marginLeft: '16.666665%' }}
      />
  }

  return (
    <div>
      {orderPrefTypeahead}
      <div className="row">
        {orderingModeDropdown}
      </div>
    </div>

    // this will need to render an additional form here with order type specific fields
  )
}

const renderRecSourceInfo = ({ rec, emr }) => {
  let sourceDisplayText

  switch (rec.fired_from) {
    case 'drools':
      sourceDisplayText = 'Avhana Health'
      break
    case 'athena':
      sourceDisplayText = 'athenahealth'
      break
    case 'cerner':
      sourceDisplayText = 'HealtheIntent'
      break
    default:
      sourceDisplayText = 'unidentified'
  }

  return (
    <div className="row" style={{ padding: '0px 18px'}}>
      {emr === 'athena' && (
        <div className="col s12 valign-wrapper center-align">
          <i className="material-icons tiny" style={{ fontSize: '1.2rem' }}>info_outline</i>
          <span style={{ paddingLeft: '5px' }}>{`Source: ${sourceDisplayText}`}</span>
        </div>
      )}
    </div>
  )
}

const renderDiagnosisPanel = ({ id, rec, order_preference, rule, updateOptions }) => {
  if (rec.options.order_set) {
    return (
      <p style={{margin: '2em 2em 0em 2em'}}>Orders will use diagnosis codes associated with order set</p>
    )
  } else {
    return (
      <div>
        <DiagnosisTypeahead
          updateOptions={updateOptions}
          pref={order_preference}
          rule={rule}
          rec={rec}
          updateOrderPref={false}
          style={{ marginLeft: '16.666665%' }}
        />
      </div>
    )
  }
}

CardBody.propTypes = propTypes
