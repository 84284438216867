import React from 'react'
import moment from 'moment'

export default class OutstandingOrders extends React.Component {
  displayable(doc) {
    // outstanding orders not from today but ordered within 90 days
    // TODO exlude today order
    const orderedWithin90Days = moment(doc.last_status_change) >= moment().subtract(90, 'days')
    const isDisplayable = (
      doc.document_class === 'ORDER' &&
      doc.status !== 'CLOSED' &&
      orderedWithin90Days
    )

    return isDisplayable
  }

  render() {
    const orderedPatientDocuments =
      _.chain(this.props.documents)
      .uniqBy('emr_order_id')
      .filter(doc => this.displayable(doc))
      .orderBy('last_status_change', 'desc')
      .value()

    const outstandingOrders = _.map(orderedPatientDocuments, (doc) => {
      const orderedDate = moment(doc.document_created).format('MM/DD/YY')

      return (
        <div key={guid()}>
          <b>{`${doc.description}`}</b>
          <div style={{ paddingBottom: '0.5em' }}>
            {`- Ordered ${orderedDate}, status: ${doc.status}`}
          </div>
        </div>
      )
    })

    const outstandingOrdersDisplay = outstandingOrders.length > 0 ? outstandingOrders : <p>None</p>

    return (
      <div style={{ paddingTop: '10px', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
        <div className="last-visit bold-text" style={{ fontSize: '16px' }}>
          Outstanding Orders (last 90 days only)
        </div>
        <div>
          <ul style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem', marginTop: '2px' }}>
            {outstandingOrdersDisplay}
          </ul>
        </div>
      </div>
    )
  }
}
