import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import DepartmentsTypeahead from '../reusableComponents/DepartmentsTypeahead'

const DEPARTMENTS = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string.isRequired,
}))

const propTypes = {
  organizationId: PropTypes.number.isRequired,
  departments: DEPARTMENTS.isRequired,
  assignedDepartments: DEPARTMENTS.isRequired,
}

class DepartmentSearch extends Component {
  constructor(props) {
    super(props)
    const { assignedDepartments } = props
    this.state = {
      departments: assignedDepartments,
    }
    this.onOptionSelected = this.onOptionSelected.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  onOptionSelected(opt) {
    const { departments } = this.state
    this.setState({ departments: [...departments, opt] })
  }

  handleClick(event) {
    const element = event.target.parentNode
    const name = element.dataset.key
    const { departments } = this.state
    this.setState({ departments: departments.filter((dept) => dept.name !== name) })
  }

  render() {
    const { organizationId, departments } = this.props
    const { departments: selectedDepartments } = this.state
    const availableDepartments = departments.filter((dept) => (
      selectedDepartments.findIndex((d) => d.id === dept.id) === -1
    ))
    const showSelectedDepartments = selectedDepartments.map((dept) => (
      <div className="collection-check-box">
        <label data-key={dept.name} htmlFor={`user_department_ids_${dept.id}`}>
          <input type="checkbox" value={dept.id} checked="checked" name="user[department_ids][]" id={`user_department_ids_${dept.id}`} onClick={this.handleClick} />
          <span>{dept.name}</span>
        </label>
      </div>
    ))
    return (
      <div>
        <DepartmentsTypeahead
          organizationId={organizationId}
          onOptionSelected={this.onOptionSelected}
          options={availableDepartments}
        />
        <div className="departments-list">
          { showSelectedDepartments }
        </div>
      </div>
    )
  }
}

DepartmentSearch.propTypes = propTypes

const mountReact = () => {
  const reactNode = document.getElementById('user-departments')
  const reactData = $(reactNode).data()
  if (reactData) {
    const {
      organizationId,
      departments,
      assignedDepartments,
    } = reactData
    ReactDOM.render(
      <DepartmentSearch
        organizationId={organizationId}
        departments={departments}
        assignedDepartments={assignedDepartments}
      />,
      reactNode,
    )
  }
}

$(mountReact)
