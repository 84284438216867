import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

// Create header for Diagnosis Code Table

const Header = () => (
  <TableHead>
    <TableRow>
      <TableCell align="left">
        <h4>
          Description
        </h4>
      </TableCell>
      <TableCell align="left">
        <h4>
          Code
        </h4>
      </TableCell>
    </TableRow>
  </TableHead>
)

export default Header
