import React from 'react'
import PropTypes from 'prop-types'
import AppointmentNotes from './AppointmentNotes'
import AssessmentNotes from './AssessmentNotes'
import HpiNotes from './HpiNotes'

// this combines Avhana and Appt Notes

const Notes = (props) => {
  let appointmentNotes
  const { activeAppointment, currentUser, setUpdatedActiveAppointment } = props
  if (activeAppointment) {
    appointmentNotes = <AppointmentNotes activeAppointment={activeAppointment} />
  }

  return (
    <div>
      <h6 className="header">Notes from the EHR</h6>
      <div className="card horizontal z-depth-1">
        <div className="card-content">
          {appointmentNotes}
        </div>
      </div>
      <h6 className="header">Notes for next encounter</h6>
      <div className="card horizontal z-depth-1">
        <div className="card-content notes">
          <HpiNotes
            activeAppointment={activeAppointment}
            currentUser={currentUser}
            setUpdatedActiveAppointment={setUpdatedActiveAppointment}
          />
        </div>
      </div>
      <div className="card horizontal z-depth-1">
        <div className="card-content notes">
          <AssessmentNotes
            activeAppointment={activeAppointment}
            currentUser={currentUser}
            setUpdatedActiveAppointment={setUpdatedActiveAppointment}
          />
        </div>
      </div>
    </div>
  )
}

const TEXT_MACRO = PropTypes.shape({
  expansion: PropTypes.string.isRequired,
  shortcut: PropTypes.string.isRequired,
})

const HPI_NOTE = PropTypes.shape({
  section_note: PropTypes.string.isRequired,
})

Notes.propTypes = {
  activeAppointment: PropTypes.shape({
    hpi_note: HPI_NOTE,
  }).isRequired,
  currentUser: PropTypes.shape({
    text_macros: PropTypes.arrayOf(TEXT_MACRO),
  }).isRequired,
  setUpdatedActiveAppointment: PropTypes.func.isRequired,
}

export default Notes
