import M from 'materialize-css'

;(() => {
  const guidelineElement = document.querySelector(`#rule_form_type_guideline`)
  if (guidelineElement) {
    guidelineElement.addEventListener('click', () => {
      const baseURL = `${window.location.origin}/root/rules/new`
      window.location = `${baseURL}?form_type=guideline`
    })
  }

  const medicationElement = document.querySelector(`#rule_form_type_medication_substitution`)
  if (medicationElement) {
    medicationElement.addEventListener('click', () => {
      const baseURL = `${window.location.origin}/root/rules/new`
      window.location = `${baseURL}?form_type=medication_substitution`
    })
  }

  const orderCoverageElement = document.querySelector(`#rule_form_type_order_coverage`)
  if (orderCoverageElement) {
    orderCoverageElement.addEventListener('click', () => {
      const baseURL = `${window.location.origin}/root/rules/new`
      window.location = `${baseURL}?form_type=order_coverage`
    })
  }

  // Remove additional fields.
  document.querySelector('form')?.addEventListener('click', (event) => {
    for (
      let { target } = event;
      target && target !== this;
      target = target.parentNode
    ) {
      if (target.classList?.contains('remove_fields')) {
        target.previousElementSibling.value = '1'
        target.closest('fieldset').hidden = true
        event.preventDefault()
        break
      }
    }
  })

  // Handle link to add new fields.
  document.querySelectorAll('.add_fields').forEach((target) => {
    target.addEventListener('click', (event) => {
      const time = new Date().getTime()
      const regex = new RegExp(target.dataset.id, 'g')
      const template = document.createElement('template')
      template.innerHTML = target.dataset.fields.replace(regex, time)
      target.insertAdjacentElement('beforebegin', template.content.firstChild)
      event.preventDefault()
    })
  })

  // Character counter  
  const el = document.querySelector("#action")
  if (el) {
    const characterCounter = new M.CharacterCounter(el)
  }
})()