import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@mui/material'

const CardHeader = ({ handleClick, children, ruleId }) => (
  <Grid
    container
    direction="row"
    onClick={handleClick}
    data-id={`rule-header-${ruleId}`}
    style={{
      cursor: "pointer"
    }}
  >
    { children }
  </Grid>
)

CardHeader.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  handleClick: PropTypes.func.isRequired,
  ruleId: PropTypes.number.isRequired,
}

export default CardHeader
