import React from 'react'
import PropTypes from 'prop-types'
import { Grid, InputLabel, Select, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledGrid = styled(Grid)(() => ({
  paddingTop: '1em',
  paddingBottom: '1em'
}))

const StyledSelectGrid = styled(Grid)(() => ({
  width: '50%'
}))

const StyledSelect = styled(Select)(() => ({
  width: '100%'
}))

const RuleSubscriptionStatus = ({ status, onChange }) => 
   (
    <Grid
      item
      xs={3}
    >
      <StyledGrid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={6}
      >
        <Grid item>
          <InputLabel id="subscription-status">Current Mode:</InputLabel>
        </Grid>
        <StyledSelectGrid item>
          <StyledSelect labelId="subscription-status" id="active-state" value={status} onChange={onChange}>
            <MenuItem value="off">Off</MenuItem>
            <MenuItem value="silent">Silent</MenuItem>
            <MenuItem value="active">Active</MenuItem>
          </StyledSelect>
        </StyledSelectGrid>
      </StyledGrid>
    </Grid>
  )


RuleSubscriptionStatus.propTypes = {
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default RuleSubscriptionStatus
