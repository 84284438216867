import React, { useState } from 'react'

import { Rule as RuleType } from '../athenaTypes'

const propTypes = {
  rule: RuleType.isRequired,
}

const EncounterTypeToggles = ({ rule }) => {
  const [encounterTypes, setEncounterTypes] = useState(rule.encounter_type)

  const handleChange = (event) => {
    const encounterType = event.target.name
    const index = encounterTypes.indexOf(encounterType)
    let newEncounterTypes
    if (index === -1) {
      newEncounterTypes = [...encounterTypes, encounterType]
    } else {
      newEncounterTypes = encounterTypes.filter((e) => e !== encounterType)
    }
    setEncounterTypes(newEncounterTypes)
    $.ajax({
      method: 'put',
      url: `/root/rules/${rule.id}/encounter_types`,
      data: { encounter_type: newEncounterTypes },
    })
      .done(() => null)
      .fail((err) => {
        if (checkTimeout(err) === true) {
          redirectToLogin()
        } else {
          flash.error('Error updating the encounter types.')
          console.error(err)
        }
      })
  }

  return (
    <div className="row rule-option">
      <div className="col s3">
        <b>Encounter Type</b>
      </div>
      <div className="col s9">
        <label htmlFor={`rule_${rule.id}_encounter_type_encounter`}>
          <input
            className="filled-in"
            type="checkbox"
            name="CDS ENCOUNTER"
            id={`rule_${rule.id}_encounter_type_encounter`}
            checked={
              encounterTypes.indexOf('CDS ENCOUNTER') !== -1 ? 'checked' : ''
            }
            onChange={handleChange}
          />
          <span>Encounter</span>
        </label>
        <label htmlFor={`rule_${rule.id}_encounter_type_previsit`}>
          <input
            className="filled-in"
            type="checkbox"
            name="AVHANA PREPLAN"
            id={`rule_${rule.id}_encounter_type_previsit`}
            checked={
              encounterTypes.indexOf('AVHANA PREPLAN') !== -1 ? 'checked' : ''
            }
            onChange={handleChange}
          />
          <span>Pre-Visit</span>
        </label>
        <label htmlFor={`rule_${rule.id}_encounter_type_cds_preplanning`}>
          <input
            className="filled-in"
            type="checkbox"
            name="CDS PREPLAN"
            id={`rule_${rule.id}_encounter_type_cds_preplanning`}
            checked={
              encounterTypes.indexOf('CDS PREPLAN') !== -1 ? 'checked' : ''
            }
            onChange={handleChange}
          />
          <span>CDS Preplanning</span>
        </label>
      </div>
    </div>
  )
}

EncounterTypeToggles.propTypes = propTypes
export default EncounterTypeToggles
