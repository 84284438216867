// takes in Athena encounter and returns display status text for React
window.getEncounterStatusText = function encounterStatusText(encounter, appointmentStatus = undefined) {
  // encounter status: "CLOSED", "OPEN", "DELETED", "PEND", "REVIEW"
  // appointment_status: cancelled, future, checked in, checked out, charge entered

  const encounterStatus = encounter ? encounter.status : undefined
  let encounterDisplayText

  // check for cancelled appointment first
  if (appointmentStatus === 'cancelled') {
    encounterDisplayText = 'Cancelled'
  } else if (encounterStatus === 'OPEN' || encounterStatus === 'REVIEW') {
    encounterDisplayText = 'Checked In'
  } else if (encounterStatus === 'CLOSED') {
    encounterDisplayText = 'Checked Out'
  }

  return encounterDisplayText
}
