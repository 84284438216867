import { FlashAuto } from '@mui/icons-material'
import React, { useState } from 'react' 

const StagingPatientForm = () => {
  const [emrId, setEmrId] = useState('') 

  const handleInputChange = (event) => {
    setEmrId(event.target.value) 
  } 

  const handleSubmit = (event) => {
    event.preventDefault() 

    const formData = new FormData()
    formData.append("emrId", emrId)

    $.ajax({
      method: 'post',
      url: 'patient_search/patient_refresh/emr_patient_id',
      contentType: false,
      processData: false,
      data: formData,
      enctype: 'application/x-www-form-urlencoded'
    })
    .done((result) => {
      flash.success(`Staging Patient ${emrId} Created`, 50000)
      setEmrId('')
    })
    .fail((error) => {
      console.error('Error:', error)
      flash.error(`There was an error creating staging patient ${emrId}. Please make sure you have the correct EMR Patient ID.`)
    })
  } 

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          EMR ID:
          <input
            type="text"
            value={emrId}
            onChange={handleInputChange}
          />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  ) 
} 

export default StagingPatientForm 
