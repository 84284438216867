import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Grid } from '@mui/material'

const MigrationToggle = ({ lastMigrated, status, onClick }) => {
  const migratedMessage = lastMigrated ? (
    <div style={{ fontWeight: 'bold' }}>
      Last migrated:
      {' '}
      {moment(lastMigrated).fromNow()}
    </div>
  ) : null
  let statusMessage
  if (status === "deployed") {
    statusMessage = <div>In production</div>
  } else if (status === "ready" && lastMigrated) {
    statusMessage = <div>Sending updated rule to production</div>
  } else if (status === "testing") {
    statusMessage = <div>Not in production</div>
  }
  return (
    <Grid 
      component="label" 
      container
      alignItems="center"
      spacing={1}
    >
      <Grid item>
        {statusMessage}
        {migratedMessage}
      </Grid>
      <Grid item>
        <button type="button" onClick={onClick}>Push to Production</button>
      </Grid>
    </Grid>
  )
}

MigrationToggle.propTypes = {
  status: PropTypes.oneOf(["testing", "ready", "deployed"]).isRequired,
  onClick: PropTypes.func.isRequired,
  lastMigrated: PropTypes.string
}

MigrationToggle.defaultProps = {
  lastMigrated: null,
}

export default MigrationToggle
