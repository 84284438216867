import M from 'materialize-css'
import NativeListener from 'react-native-listener'
import PropTypes from 'prop-types'
import React from 'react'

import MarkdownParser from '../reusableComponents/MarkdownParser.jsx'

const sendDiagnosis = (dxId) => {
  $.post('/suspected_diagnoses/send_diagnosis', { suspected_diagnosis_id: dxId })
  .done(() => {
    flash.success('Diagnosis recorded in patient encounter', 50000) // previously had 2000 second timeout
  })
  .fail(() => {
    flash.error('There was an error submitting this diagnosis to the EHR.')
  })
}

const cardHeader = (dx) => {
  const actionButton = (
    <NativeListener stopClick onClick={() => sendDiagnosis(dx.id)}>
      <button
        className="waves-effect waves-light btn planned-dx-button"
        type="submit" name="action"
        id="send_diagnosis_button"
      >
        Send
      </button>
    </NativeListener>
  )

  // HF-demo make this dynamic off of data
  return (
    <div className="row collapsible-header collapsible-header-padding rule-action-title">
      <div className="col s12 m9">
        <div className="row">
          <div className="col s12">
            <b>{dx.display_name}</b>
          </div>
          <div className="col s12" style={{ fontSize: '12px' }}>
            <span style={{ fontWeight: 'bold' }}>{dx.icd10_code}</span>
          </div>
          <div className="col s12" style={{ fontSize: '11px' }}>
            <div>{`Suspected Condition, RAF Weight ${dx.raf_weight}`}</div>
          </div>
        </div>
      </div>
      <div className="col s12 m3 center-align">
        {actionButton}
      </div>
    </div>
  )
}

const identifyActive = (name, activeTab) => (
  (name === activeTab) ? ' active' : ''
)

const renderTabNavItem = (navItem, index, activeTab, selectTab) => {
  const onClick = (event) => {
    const clickedTab = event.target.innerText
    selectTab(clickedTab)
  }

  return (
    <li
      role="tab"
      key={index}
      className={`tab-nav-item${identifyActive(navItem, activeTab)}`}
      onClick={onClick}
    >
      <p><a>{navItem}</a></p>
    </li>
  )
}

const renderTabNavItems = (navItems, { selectTab, activeTab }) => (
  navItems.map((navItem, index) => (
    renderTabNavItem(
      navItem,
      index,
      activeTab,
      selectTab
    )
  ))
)

export default class SuspectedDiagnosis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'Evidence',
      suspectedDiagnoses: [],
    }

    this.selectTab = this.selectTab.bind(this)
  }

  componentDidMount() {
    $.get('/suspected_diagnoses/get_diagnoses', { patient_id: this.props.patientId })
    .done((response) => {
      this.setState({ suspectedDiagnoses: response.suspected_diagnoses })
    })
  }

  componentDidUpdate() {
    const elems = document.querySelectorAll('.collapsible')
    M.Collapsible.init(elems, {})
  }

  selectTab(clickedTab) {
    this.setState({ activeTab: clickedTab })
  }

  cardBody(dx, activeTab) {
    const tabs = ['Evidence']
    const tabNavItems = renderTabNavItems(tabs, { selectTab: this.selectTab, activeTab })

    const evidences = dx.diagnosis_evidences.map(evidence => (
      <div>
        <div className="col s12">
          <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>{`Clinical Record: ${evidence.date_display} ${evidence.provider_name}`}</b>
        </div>
        <div className="col s12 no-left-padding-p-tag">
          <MarkdownParser markdown={evidence.markdown_display} />
        </div>
      </div>
    ))

    return (
      <div className="collapsible-body">
        <div className="body-container">
          <div className="tabs-container">
            <div className="tab-nav">
              <ul role="tablist" className="tab-nav-items">
                {tabNavItems}
              </ul>
            </div>
            <div className="tab-panels">
              <div
                role="tabpanel"
                className={`tab-panel notes${identifyActive('Evidence', activeTab)}`}
              >
                <div>
                  <p>
                    <div className="row">
                      {evidences}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    // hack
    let dashboardDiagnosis
    if (this.state.suspectedDiagnoses.length > 0) {
      const dxComponents = this.state.suspectedDiagnoses.map((dx) => {
        const cardheader = cardHeader(dx)
        const cardbody = this.cardBody(dx, this.state.activeTab)

        return (
          <li className="collection-item avatar recommendation-card">
            {cardheader}
            {cardbody}
          </li>
        )
      })

      dashboardDiagnosis = (
        <div>
          <h6 style={{ padding: '0' }}>
            Suspected Diagnoses
          </h6>
          <ul className="collapsible collapsible-accordion">
            {dxComponents}
          </ul>
        </div>
      )
    }

    return (
      <div>
        { dashboardDiagnosis }
      </div>
    )
  }
}

SuspectedDiagnosis.propTypes = {
  patientId: PropTypes.string,
}
