import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })
  )
}

const CareCenter = ({ organizations }) => {
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [departmentIds, setDepartmentIds] = useState('')
  const [organization, setOrganization] = useState('')

  const handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append("name", name)
    formData.append("phone_number", phoneNumber)
    formData.append("organization_id", organization)
    formData.append("department_ids", departmentIds)

    $.ajax({
      method: 'POST',
      url: '/root/care_center/create',
      contentType: false,
      processData: false,
      data: formData,
    })
    .done(() => {
      flash.success('Care Center successfully Created', 50000)
    })
    .fail((error) => {
      flash.error(`Error creating your Care Center. ${error.responseJSON.error}`, 50000)
      console.error(error)
    })
  }
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={name}
        onChange={event => setName(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Phone Number"
        value={phoneNumber}
        onChange={event => setPhoneNumber(event.target.value)}
        required
        helperText={phoneNumber.replace(/\D/g, '').length !== 10 ? 'Phone number must be 10 digits' : ''}
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <Autocomplete
        id="organization-options"
        options={organizations}
        getOptionLabel={(organizations) => organizations.name}
        renderInput={(params) => <TextField {...params} label="Organization" value={organization} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
        onChange={(event, value) => setOrganization(value.id)}
      />
      <TextField
        label="Department Ids"
        value={departmentIds}
        onChange={event => setDepartmentIds(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <button type="submit">Submit</button>
    </form>
  )
}

CareCenter.propTypes = propTypes

export default CareCenter
