import React from 'react'
import PropTypes from 'prop-types'

export default class MaterializeSwitch extends React.Component {

  updateFormState(event) {
    const key = this.props.data.key
    const checked = event.target.checked
    const value = checked ? this.props.valueOn : this.props.valueOff
    this.props.updateFormState({ key, value })
  }

  render() {
    return (
      <div className={`row ${this.props.containerClassName || ''}`}>
        <div className="col s7">
          <p className="radio-button-container">{this.props.label}</p>
        </div>
        <div className="col s5">
          <div className="switch col s12">
            <label htmlFor={this.props.label}>
              No
              <input
                aria-label={this.props.label}
                type="checkbox"
                id={this.props.label}
                defaultChecked={this.props.selected}
                onChange={this.updateFormState.bind(this)}
                disabled={this.props.disabled || false}
              />
              <span className="lever" />
              Yes
            </label>
          </div>
        </div>
        {this.props.helperDiv}
      </div>

    )
  }
}

MaterializeSwitch.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string,
  }),
  valueOn: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  valueOff: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
  updateFormState: PropTypes.func,
  disabled: PropTypes.bool,
  helperDiv: PropTypes.element,
}
