import React from 'react'
import PropTypes from 'prop-types'
import RadioButtons from '../reusableComponents/RadioButtons.jsx'

const UserFormStep1 = (props) => {
  // for practice selector
  const practiceOptions = _.map(props.organizations, org => (
    <option
      value={org.id}
      selected={props.isAdmin && props.defaultOrganizationId === org.id}
    >
      {org.name}
    </option>
  ))

  // for provider group selector
  let selectedOrg = null
  if (props.isAdmin) {
    selectedOrg = _.find(props.organizations, { id: props.defaultOrganizationId })
  } else {
    selectedOrg = _.find(props.organizations, { id: parseInt(props.organizationId, 10) })
  }

  let providerGroupOptions = []
  if (selectedOrg) {
    providerGroupOptions = _.map(selectedOrg.provider_groupings, group => (
      <option
        value={group.id}
        selected={props.isAdmin && props.defaultProviderGroupingId === group.id}
      >
        {group.name}
      </option>
    ))
  }

  let providerGroupSelectSection
  if (providerGroupOptions.length > 0) {
    providerGroupSelectSection = (
      <div>
        <label htmlFor="provider-group">Provider group</label>
        <select
          id="provider-group"
          className="browser-default"
          onChange={props.handleProviderGroupSelect}
          value={props.isAdmin ? props.defaultProviderGroupingId : props.providerGroupingId}
          disabled={props.isAdmin}
        >
          <option value="" />
          { providerGroupOptions }
        </select>
      </div>
    )
  }

  return (
    <div className="col s12">
      <div className="input-field">
        <input
          type="text"
          id="firstName"
          value={props.firstName}
          onChange={props.handleInputChange}
        />
        <label htmlFor="firstName">First name</label>
      </div>

      <div className="input-field">
        <input
          type="text"
          id="lastName"
          value={props.lastName}
          onChange={props.handleInputChange}
        />
        <label htmlFor="lastName">Last name</label>
      </div>

      <div className="input-field">
        <input
          type="text"
          id="emrUserName"
          value={props.emrUserName}
          onChange={props.handleInputChange}
        />
        <label htmlFor="emrUserName">Emr username</label>
      </div>

      <div className="input-field">
        <input
          type="text"
          id="email"
          value={props.email}
          onChange={props.handleInputChange}
        />
        <label htmlFor="email">Email</label>
      </div>

      <label htmlFor="practice">Practice</label>
      <select
        id="practice"
        className="browser-default"
        onChange={props.handleOrgSelect}
        value={props.isAdmin ? props.defaultOrganizationId : props.organizationId}
        disabled={props.isAdmin}
      >
        <option value="" />
        { practiceOptions }
      </select>

      {providerGroupSelectSection}

      <RadioButtons
        formId="provider-nonprovider"
        groupName={'Is this user a provider or staff:'}
        labels={[
          { name: 'Provider', value: false },
          { name: 'Staff/non-provider', value: true },
        ]}
        selected={props.medicalAssistant}
        updateFormState={props.updateMedicalAssistantBoolean}
      />
    </div>

  )
}

UserFormStep1.propTypes = {
  defaultOrganizationId: PropTypes.string,
  defaultProviderGroupingId: PropTypes.string,
  email: PropTypes.string,
  emrUserName: PropTypes.string,
  firstName: PropTypes.string,
  handleInputChange: PropTypes.func,
  handleOrgSelect: PropTypes.func,
  handleProviderGroupSelect: PropTypes.func,
  isAdmin: PropTypes.bool,
  lastName: PropTypes.string,
  medicalAssistant: PropTypes.bool,
  organizationId: PropTypes.string,
  organizations: PropTypes.arrayOf(PropTypes.object),
  providerGroupingId: PropTypes.string,
  updateMedicalAssistantBoolean: PropTypes.func,
}

export default UserFormStep1
