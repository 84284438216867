import {
  arrayOf, bool, oneOf, number, shape, string,
} from 'prop-types'

export const ID = number

export const EMR = oneOf(['athena', 'allscripts', 'epic', 'cerner', 'modernizing_medicine', 'dr_chrono'])
export const OrdersDisabledEmr = ['cerner', 'modernizing_medicine', 'dr_chrono']
export const NoRecsEmr = ['athena', 'cerner', 'modernizing_medicine', 'dr_chrono']

export const Source = string
export const Sources = arrayOf(Source)

export const RuleCategory = oneOf(['Advisories', 'Previsit'])

export const Styles = shape()

export const OrderPreference = shape({
  options: shape({
    entry_name: string,
    diagnosis_name: string,
  }),
  use_step_therapy: bool,
  action: string,
  description: string,
})

export const Tag = shape({
  id: ID,
  name: string,
})

export const Tags = arrayOf(Tag)

export const Subscription = shape({
  id: ID,
  active: bool,
})

export const Rule = shape({
  default_display_code: string,
  description: string,
  id: ID,
  link: string,
  name: string,
  order_preference: OrderPreference,
  rule_type: string,
  snomed_diagnosis_name: string,
  source: string,
  subscription: Subscription,
  tags: arrayOf(Tag),
  migrated_at: string
})

export const Rules = arrayOf(Rule)

export const Order = shape({
  order_type: string,
})

export const Orders = arrayOf(Order)

export const Options = shape({
  orders: Orders,
})

export const Observation = shape({

})

export const Patient = shape({
  observations: arrayOf(Observation),
})

export const Appointment = shape({
  patient: Patient,

})

export const Diagnosis = shape({

})

export const Diagnoses = arrayOf(Diagnosis)

export const Recommendation = shape({})

export const Recommendations = arrayOf(Recommendation)
