import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@mui/material'

const FollowupActionInput = ({ children }) => (
  <Grid
    container
    direction="column"
  >
    <Grid
      item
      xs={12}
    >
      <h6>Follow Up Action(s)</h6>
    </Grid>
    <Grid
      item
      xs={12}
    >
      {children}
    </Grid>
  </Grid>
)

FollowupActionInput.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired
}

export default FollowupActionInput
