import React from 'react'
import PropTypes from 'prop-types'

const EncounterStatusWidgetIE = (props) => {
  let widgetImage
  const encounter = props.encounter
  const encounterStatus = window.getEncounterStatusText(encounter)
  if (!encounter) {
    widgetImage = (
      <img alt="previsit" src="/previsit_widget.png" className="image_sizer" />
    )
  } else if (encounterStatus === 'Checked In') {
    widgetImage = (
      <img alt="checked in" src="/checkin_widget.png" className="image_sizer" />
    )
  } else if (encounterStatus === 'Checked Out') {
    widgetImage = (
      <img alt="checked out" src="/checkout_widget.png" className="image_sizer" />
    )
  } else if (encounterStatus === 'Pre-Visit') {
    widgetImage = (
      <img alt="previsit" src="/previsit_widget.png" className="image_sizer" />
    )
  }

  return (
    <div className="col s8 offset-s2">
      <div className="ie-boxes">
        <div className="box">
          <div className="img-wrapper">
            {widgetImage}
          </div>
        </div>
      </div>
    </div>
  )
}

EncounterStatusWidgetIE.propTypes = {
  encounter: PropTypes.shape({}),
}

export default EncounterStatusWidgetIE
