import NativeListener from 'react-native-listener'
import React from 'react'
import PropTypes from 'prop-types'

import DashboardDiagnosisNote from '../notes/DashboardDiagnosisNote.jsx'
import OrderAnythingForDiagnosis from './OrderAnythingForDiagnosis.jsx'

const autoSaveNoteAndDiagnosis = (plannedDiagnosis) => {
  $.ajax({
    method: 'put',
    url: `/planned_diagnoses/${plannedDiagnosis.id}`,
    data: { note: plannedDiagnosis.note, status: plannedDiagnosis.status },
  })
  .fail(error => window.handleErrorOrRedirectToLogin(error, 'Diagnosis Note Update Error'))
}

const identifyActive = (name, activeTab) => (
  (name === activeTab) ? ' active' : ''
)

const renderTabNavItem = (navItem, index, activeTab, selectTab) => {
  const onClick = (event) => {
    const clickedTab = event.target.innerText
    selectTab(clickedTab)
  }

  return (
    <li
      role="tab"
      key={index}
      className={`tab-nav-item${identifyActive(navItem, activeTab)}`}
      onClick={onClick}
    >
      <p><a>{navItem}</a></p>
    </li>
  )
}

const renderTabNavItems = (navItems, { selectTab, activeTab }) => (
  navItems.map((navItem, index) => (
    renderTabNavItem(
      navItem,
      index,
      activeTab,
      selectTab
    )
  ))
)

export default class DashboardDiagnosis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'Notes',
      note: props.plannedDiagnosis.note || '',
    }
    this.selectTab = this.selectTab.bind(this)
    this.sendDiagnosis = this.sendDiagnosis.bind(this)
    this.undoDiagnosisAndNote = this.undoDiagnosisAndNote.bind(this)
    this.handleTextBoxChanges = this.handleTextBoxChanges.bind(this)
    // Debounce so we are not swamping the DB
    this.autoSaveNoteAndDiagnosis = _.debounce(autoSaveNoteAndDiagnosis, 500)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newNote = (newProps.plannedDiagnosis.note !== this.props.plannedDiagnosis.note)
    if (newNote) {
      this.setState({ note: newProps.plannedDiagnosis.note })
    }
  }

  selectTab(clickedTab) {
    this.setState({ activeTab: clickedTab })
  }

  sendDiagnosis() {
    $.ajax({
      url: `/planned_diagnoses/${this.props.plannedDiagnosis.id}/send_diagnosis`,
      method: 'PUT',
      success: (response) => {
        // status will be "" if it was sent directly to EHR (ie patient already checked in)
        // so we want to display "diagnosis sent" but not update this in the db otherwise
        // the planned DX will stay stuck in "diagnosis sent"
        let statusToDisplay
        if (response.planned_diagnosis.status === '') {
          statusToDisplay = 'diagnosis sent'
        } else {
          // status is "diagnosis queued" when queued successfully
          statusToDisplay = response.planned_diagnosis.status
        }
        const plannedDiagnosis = this.props.plannedDiagnosis
        plannedDiagnosis.note = this.state.note
        plannedDiagnosis.status = statusToDisplay
        this.props.updatePlannedDiagnosis(plannedDiagnosis)
      },
      error: (error) => {
        if (checkTimeout(error) === true) {
          redirectToLogin()
        } else {
          console.error(error, { name: 'diagnosis error', message: 'DashboardDiagnosis.jsx sendOrder(), Error sending order' })
          this.setState({ orderSent: false })
          flash.error(
            'There was an error submitting the diagnosis. Contact support@avhana.com if you need further assistance.'
          )
        }
      },
    })
  }

  undoDiagnosisAndNote() {
    const plannedDiagnosis = this.props.plannedDiagnosis
    plannedDiagnosis.status = ''
    this.props.updatePlannedDiagnosis(plannedDiagnosis)
    autoSaveNoteAndDiagnosis(plannedDiagnosis)
  }

  handleTextBoxChanges(event) {
    const note = event.target.value
    this.setState({ note })

    const plannedDiagnosis = this.props.plannedDiagnosis
    plannedDiagnosis.note = note
    autoSaveNoteAndDiagnosis(plannedDiagnosis)
  }

  cardHeader() {
    const plannedDiagnosis = this.props.plannedDiagnosis
    const emr = this.props.emr
    const diagnosis = plannedDiagnosis.diagnosis
    let addressedDate
    if (diagnosis.last_encounter_date) {
      addressedDate = `last encounter ${diagnosis.last_encounter_date}`
    } else {
      addressedDate = `start date ${diagnosis.start_date}`
    }

    const rafWeight = diagnosis.raf ? ` | RAF weight: ${diagnosis.raf}` : ''

    let actionButton
    if (plannedDiagnosis.status === 'diagnosis queued') {
      actionButton = (
        <NativeListener stopClick onClick={this.sendDiagnosis}>
          <button
            className="waves-effect waves-light btn grey planned-dx-button"
            type="submit" name="action"
            id="send_diagnosis_button"
            style={{ padding: '0px 21.68px' }}
          >
            Sending
          </button>
        </NativeListener>
      )
    } else if (plannedDiagnosis.status === 'diagnosis sent') {
      actionButton = <div style={{ fontSize: '12px', paddingTop: '1.3em', fontWeight: 'bold' }}>Sent</div>
    } else if (emr === 'athena') {
      actionButton = (
        <NativeListener stopClick onClick={this.sendDiagnosis}>
          <button
            className="waves-effect waves-light btn planned-dx-button"
            type="submit" name="action"
            id="send_diagnosis_button"
          >
            Send
          </button>
        </NativeListener>
      )
    }

    let plusNote
    if (plannedDiagnosis.note && plannedDiagnosis.note.length > 0) {
      plusNote = <div style={{ fontSize: '12px', textAlign: 'center' }}>+ Note</div>
    }

    let cancelOrderButton
    if (plannedDiagnosis.status === 'diagnosis queued') {
      cancelOrderButton = (
        <div className="center-align">
          <span className="col s12">
            <NativeListener stopClick onClick={() => this.undoDiagnosisAndNote()}>
              <a className="waves-effect waves-light planned-dx-button">
                Undo
              </a>
            </NativeListener>
          </span>
        </div>
      )
    }

    return (
      <div className="row collapsible-header collapsible-header-padding rule-action-title">
        <div className="col s12 m9">
          <div className="row">
            <div className="col s12">
              <b>{diagnosis.name}</b>
            </div>
            <div className="col s12" style={{ fontSize: '12px' }}>
              <span style={{ fontWeight: 'bold' }}>{diagnosis.icd10}</span>
              {rafWeight}
            </div>
            <div className="col s12" style={{ fontSize: '11px' }}>
              <div>{addressedDate}</div>
            </div>
          </div>
        </div>
        <div className="col s12 m3 center-align">
          {actionButton}
          {plusNote}
          {cancelOrderButton}
        </div>
      </div>
    )
  }

  cardBody(activeTab) {
    const tabs = ['Notes', 'Orders']
    const tabNavItems = renderTabNavItems(tabs, { selectTab: this.selectTab, activeTab })

    return (
      <div className="collapsible-body">
        <div className="body-container">
          <div className="tabs-container">
            <div className="tab-nav">
              <ul role="tablist" className="tab-nav-items">
                {tabNavItems}
              </ul>
            </div>
            <div className="tab-panels">
              <div
                role="tabpanel"
                className={`tab-panel notes${identifyActive('Notes', activeTab)}`}
              >
                <DashboardDiagnosisNote
                  note={this.state.note}
                  plannedDiagnosis={this.props.plannedDiagnosis}
                  handleTextBoxChanges={this.handleTextBoxChanges}
                  pastEncounter={this.props.activeAppointment.past_encounter}
                  currentUser={this.props.currentUser}
                />
              </div>
              <div
                role="tabpanel"
                className={`tab-panel orders${identifyActive('Orders', activeTab)}`}
              >
                <OrderAnythingForDiagnosis
                  emrPatientId={this.props.activeAppointment.emr_patient_id}
                  organizationId={this.props.activeAppointment.organization_id}
                  plannedDiagnosis={this.props.plannedDiagnosis}
                  queuedOrders={this.props.queuedOrders}
                  // props below are all needed to render queued order <Recommendation />
                  emr={this.props.emr}
                  patient={this.props.patient}
                  encounter={this.props.encounter}
                  undoOrder={this.props.undoOrder}
                  updateOptions={this.props.updateOptions}
                  updateRecStatus={this.props.updateRecStatus}
                  removeRecommendation={this.props.removeRecommendation}
                  // also used in <OrderAnythingForDiagnosis />
                  addRecToRecsList={this.props.addRecToRecsList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const cardheader = this.cardHeader()
    const cardbody = this.cardBody(this.state.activeTab)
    const preVisitDashboard = (
      this.props.emr === 'athena'
    )
    const dashboardDiagnosis = (
      <ul className="collapsible collapsible-accordion">
        <li className="collection-item avatar recommendation-card">
          {cardheader}
          {preVisitDashboard ? cardbody : null}
        </li>
      </ul>
    )

    return (
      <div style={{ margin: '0 0.5rem' }}>
        { dashboardDiagnosis }
      </div>
    )
  }
}

DashboardDiagnosis.propTypes = {
  plannedDiagnosis: PropTypes.shape({
    note: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.string,
  }),
  updatePlannedDiagnosis: PropTypes.func,
  activeAppointment: PropTypes.shape({
    emr_patient_id: PropTypes.string,
    organization_id: PropTypes.number,
    past_encounter: PropTypes.shape({}),
  }),
  queuedOrders: PropTypes.arrayOf(PropTypes.shape({})),
  emr: PropTypes.string,
  patient: PropTypes.shape({}),
  encounter: PropTypes.shape({}),
  undoOrder: PropTypes.func,
  updateOptions: PropTypes.func,
  updateRecStatus: PropTypes.func,
  removeRecommendation: PropTypes.func,
  addRecToRecsList: PropTypes.func,
  currentUser: PropTypes.shape({}),
}
