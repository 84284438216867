import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Dialog from '@mui/material/Dialog'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'

const propTypes = {
  patient: PropTypes.object.isRequired,
  addRecToRecsList: PropTypes.func,
}

export default class GenericImms extends Component {
  constructor (props) {
    super(props)
    this.getRows = this.getRows.bind(this)
    this.submitVaccineOrder = this.submitVaccineOrder.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.documentVaccine = this.documentVaccine.bind(this)
    this.state = { data: {}, recs: {}, op_options: {}, orders: {}, open: false, date: null, selectedCvx: null, selectedName: null }
  }

  UNSAFE_componentWillMount () {
    // Label recs from drools by concept name
    let recs = {}
    _.forEach(this.props.options.form_info, function(v, k) { // For each rec from drools
      const splitString =  k.split(" ")
      let key = splitString.slice(1, splitString.length).join(" ") // Take all but first word
      recs[key] = v
    })
    this.setState({recs})

    // Get OPs for recs and label as ordered if they're queued
    $.get('/patients/vaccine_details', { recs: recs, patient_id: this.props.patient.id })
    .done(data => {
      const op_options = data.options // OP options
      const queued = data.queued // Recs that are queued
      let newOrders = this.state.orders

      // Label orders as queued if needed (instead of ordered)
      _.forEach(queued, function(concept) {
        newOrders[concept] = { status: 'Queued', date: 'Queued' }
      })
      this.setState({ op_options, orders: newOrders })
    })
    .fail(error => {
      if (checkTimeout(error) === true) {
        redirectToLogin()
      } else {
        flash.error('There was an error populating immunizations')
        console.error(error, {context: `Submit Vaccine Details Error message: ${error.responseText}`})
      }
    })

    // Get patient's vaccination history
    $.get('/patients/get_immunization_info', { emr_patient_id: this.props.patient.emr_patient_id, organization_id: this.props.patient.organization_id })
    .done(data => {
      this.setState({data})
    })
    .fail(error => {
      if (checkTimeout(errors) === true) {
        redirectToLogin()
      } else {
        flash.error('There was an error populating immunizations')
        console.error(error, {context: `Get Imm Info Error message: ${error.responseText}`})
      }
    })
  }

  // Submit vaccine to EHR encounter
  submitVaccineOrder(name, recUuid) {
    const orderInfo = {
      recUuid: recUuid,
      patientId: this.props.patient.id,
      op_options: this.state.op_options[name],
    }
    $.post('/orders/order_vaccine_from_table', orderInfo)
    .done(response => {
      // If successful mark as ordered, else as queued
      const order = { status: 'Ordered', date: 'Today' }
      let newOrders = this.state.orders
      newOrders[name] = order
      this.setState({ orders: newOrders })

      const orderStatus = response.order_status
      let actionTaken
      if (orderStatus === 'order queued') {
        actionTaken = 'queued'
        this.props.addRecToRecsList(response.rec.recommendation)
      } else if (orderStatus === 'order successful') {
        actionTaken = 'submitted'
      }
      const message = `${name} ${actionTaken} successfully`
      flash.success(message, 50000) // previously had 3000 second timeout
    })
    .fail(error => {
      flash.error('There was an error submitting to the EHR.')
      console.error(error, {context: `Order Vaccine Error message: ${error.responseText}`})
    })
  }

  // Document historical vaccine to EHR
  documentVaccine() {
    // Return if date is null or date is after today
    if ((!this.state.date) || (this.state.date === 'Invalid date') || ((moment.now() - this.state.date) < 0)) {
      return
    }

    // close modal
    this.setState({ open: false })
    const patientId = this.props.patient.id
    const date = moment(this.state.date).format('MM/DD/YYYY')

    $.post('/orders/historical_vaccines',
      {
        patient_id: patientId,
        rec_id: this.props.id,
        administer_date: date,
        cvx: this.state.selectedCvx
      }
    )
    .done(response => {
      // If successful mark same as other completed vaccines
      const displayDate = moment(this.state.date).format('YYYY-MM-DD')
      const order = { status: 'Completed', date: displayDate }
      let newOrders = this.state.orders
      newOrders[this.state.selectedName] = order
      this.setState({ orders: newOrders })
      flash.success(response.success_msg, 50000) // previously had 3000 second timeout
    })
    .fail(error => {
      flash.error('There was an error sending recording previous vaccine')
      console.error(error, {context: `Submit Historical Vaccines Error message: ${error.responseText}`})
      
    })
  }

  // Open modal and set which vaccine was selected
  handleOpen(selectedCvx, selectedName) {
    this.setState({ open: true })
    this.setState({ selectedCvx, selectedName, date: null })
  }

  // Close modal
  handleCancel() {
    this.setState({ open: false })
  }

  // Update date
  handleDateChange(_ignore, date) {
    this.setState({ date })
  }

  // Build table of vaccines
  getRows(name, numDoses, recommendedAges, maxAgeInWeeks, ageInWeeks, cvx, flipper) {
    const dosesGotten = this.state.data[name]
    const recUuid = this.state.recs[name]
    const op_options = this.state.op_options[name]
    const ordered = this.state.orders[name]

    // Alternate white and gray blocks for vaccine types
    let className = 'centered'
    if ((flipper % 2) === 0) {
      className = 'light-grey-section centered'
    }

    let tempDoseGotten
    let buttonAdded = false
    let row
    const rows = numDoses.map((doseNumber, index) => { // Need arrow function so we can access 'this'
      // See if this series was received
      _.forEach(dosesGotten, function(imm) {
      	if (imm.series == doseNumber) {
          tempDoseGotten = imm
        }
      })

      const displayName = `${name} ${doseNumber}`

      // Logic: If vaccine gotten, show, else first time not gotten show if needs
      // dose, else show ineligible
      if (tempDoseGotten) {
        row =
          <tr key={displayName} className={className}>
            <td>{displayName}</td>
            <td>{tempDoseGotten.date}</td>
            <td>Completed</td>
          </tr>
      } else if (recUuid && !buttonAdded) {
        buttonAdded = true
        if (ordered) {
          row =
            <tr key={displayName} className={className}>
              <td>{displayName}</td>
              <td>{ordered.date}</td>
              <td>{ordered.status}</td>
            </tr>
        } else {
          let button
          if (op_options) {
            let orderName = op_options.order_name.split('mL')[0] + 'mL'
            button =
              <button className='btn' onClick={() => this.submitVaccineOrder(name, recUuid)}>
                {orderName}
              </button>
          } else {
            button =
              <button disabled className='btn' onClick={() => this.submitVaccineOrder(name, recUuid)}>
                Order Vaccine
              </button>
          }
          row =
            <tr key={displayName} className={className}>
              <td>{displayName}</td>
              <td></td>
              <td>
                <div>
                  {button}
                  <br></br>
                  <div style={{ cursor: 'pointer', paddingTop: '6px' }}>
                    <a className='custom-dismiss-button' onClick={() => this.handleOpen(cvx, name)}>
                      Document {name} vaccine already received
                    </a>
                  </div>
                </div>
              </td>
            </tr>
        }
      } else if ((name !== 'PCV13') && (recommendedAges[index] !== 'Recommended Age 65')) { // For senior imms, deals with PPSV 23 vs PCV 13
        let message = `Recommended at age ${recommendedAges[index]}`
        if (maxAgeInWeeks[index] < ageInWeeks) {
          message = "No catch up recommended"
        }
        row =
          <tr key={displayName} className={className}>
            <td>{displayName}</td>
            <td></td>
            <td>{message}</td>
          </tr>
      }
      tempDoseGotten = null
      return row
    })
    return rows
  }

  render() {
    const muiTheme = createTheme({
      fontFamily: 'Lato, sans-serif',
      palette: {
        primary1Color: '#1684F9',
      },
    })

    // Display age above table
    const birth = moment(this.props.patient.birthdate)
    const nowDate = moment(new Date())
    const yearsOld = nowDate.diff(birth, 'years')
    const monthsOld = nowDate.diff(birth, 'months') - (yearsOld * 12)

    const ageInWeeks = nowDate.diff(birth, 'weeks')

    // Actions for modal
    const actions = [
      <a href="#" className="waves-effect btn-flat" onClick={this.handleCancel}>Cancel</a>,
      <a href="#" className="waves-effect btn-flat" onClick={this.documentVaccine}>Document In EHR</a>,
    ]

    const rows = this.props.vaccines.map((vaccineInfo, index) => {
      return this.getRows(vaccineInfo.name, vaccineInfo.doses, vaccineInfo.ages, vaccineInfo.maxAgeInWeeks, ageInWeeks, vaccineInfo.genericCvx, index)
    })

    return (
      <div>
        <div className="row">
          <h5>
            Patient is {yearsOld} year(s) and {monthsOld} month(s) old
          </h5>
        </div>
        <div className="row">
          <div className="row">
            <table className="centered">
              <thead>
                <tr>
                  <th>Vaccine</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
        <ThemeProvider theme={muiTheme}>
          <Dialog
            title="Record previous immunization"
            actions={actions}
            open={this.state.open}
          >
            <DatePicker
              hintText="Choose date received"
              onChange={this.handleDateChange}
              container="inline"
              autoOk
              maxDate={new Date()}
              mode="portrait"
            />
          </Dialog>
        </ThemeProvider>
      </div>
    )
  }
}
