import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import TextareaAutosize from 'react-textarea-autosize'

const Item = ({ entity: { shortcut, expansion } }) => (
  <div style={{ cursor: 'pointer' }}>
    <b>{shortcut}</b>: {expansion}
  </div>
)

Item.propTypes = {
  entity: PropTypes.shape({
    shortcut: PropTypes.string,
    expansion: PropTypes.string,
  }).isRequired,
}

const Loading = () => <div>Loading</div>

const ResizeTextArea = React.forwardRef((props, ref) => (
  <TextareaAutosize inputRef={ref} {...props} />
))

export default class HpiNotes extends Component {
  constructor(props) {
    super(props)

    const hpiNote = props.activeAppointment.hpi_note
    this.state = {
      draftSaved: true,
      text: (hpiNote && hpiNote.section_note) || '',
      checkedIn: !!props.activeAppointment.today_open_encounter,
    }

    this.handleTextBoxChanges = this.handleTextBoxChanges.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.debouncedHandleSubmit = _.debounce(this.handleSubmit, 1000)
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { activeAppointment } = this.props
    const { text } = this.state
    const newHpiNote = newProps.activeAppointment.hpi_note
    const newNote = (newHpiNote && newHpiNote.section_note) || ''

    const noteChanged = (newNote !== text)
    const appointmentChanged = newProps.activeAppointment.id !== activeAppointment.id

    if (noteChanged || appointmentChanged) {
      this.setState({
        text: newNote,
        checkedIn: !!newProps.activeAppointment.today_open_encounter,
      })
    }
  }

  // this prevents un-needed re-renders, and also resolves the cursor issue?
  shouldComponentUpdate(newProps, newState) {
    const { draftSaved, text, checkedIn } = this.state
    const draftSavedChanged = (newState.draftSaved !== draftSaved)
    const noteChanged = (newState.text !== text)
    const checkedInChange = (newState.checkedIn !== checkedIn)
    if (draftSavedChanged || noteChanged || checkedInChange) {
      return true
    }
    return false
  }

  handleTextBoxChanges(event) {
    const text = event.target.value
    this.setState({ text, draftSaved: false })
    this.debouncedHandleSubmit(event)
  }

  handleSubmit() {
    const { activeAppointment, setUpdatedActiveAppointment } = this.props
    const { text } = this.state
    const { patient_id, id } = activeAppointment // eslint-disable-line camelcase
    const params = { patient_id, section_note: text, appointment_id: id }
    $.post('/hpi_notes', params)
      .done(() => {
        this.setState({ draftSaved: true })

        const updatedHpiNote = { ...activeAppointment.hpi_note, section_note: text }
        const updatedActiveAppointment = { ...activeAppointment, hpi_note: updatedHpiNote }
        setUpdatedActiveAppointment(updatedActiveAppointment)
      })
      .fail((error) => { window.handleErrorOrRedirectToLogin(error, 'HpiNotes Save Error') })
  }

  render() {
    const { currentUser: { text_macros: textMacros } } = this.props
    const { checkedIn, draftSaved, text } = this.state
    let helpText
    let updatedText
    let placeholderText

    if (draftSaved && text !== '') {
      updatedText = 'Draft saved. Note will be sent to next patient encounter.'
      helpText = 'To view entire saved note, click inside the text box and press the down arrow key'
    }

    if (checkedIn && text !== '') {
      updatedText = 'Patient is currently checked in. Note has been sent to the current encounter.'
    }

    if (checkedIn) {
      placeholderText = 'Notes cannot be entered if the patient is already checked in'
    } else {
      placeholderText = 'Type your note here'
    }

    const ua = window.navigator.userAgent
    const usingIE = ua.indexOf('MSIE') > 0 || ua.indexOf('Trident') > 0
    const textarea = (
      usingIE
        ? (
          <textarea
            id="hpi_text_area"
            className="materialize-textarea"
            placeholder={placeholderText}
            disabled={checkedIn}
            value={text}
            onChange={this.handleTextBoxChanges}
          />
        ) : (
          <ReactTextareaAutocomplete
            id="hpi_text_area"
            className="materialize-textarea"
            trigger={{
              '.': {
                dataProvider: (token) => textMacros
                  .filter((t) => t.shortcut.startsWith(token))
                  .map(({ shortcut, expansion }) => ({ shortcut, expansion }))
                  .slice(0, 5),
                component: Item,
                output: (item) => item.expansion,
                allowWhiteSpace: true,
              },
            }}
            textAreaComponent={ResizeTextArea}
            loadingComponent={Loading}
            placeholder={placeholderText}
            disabled={checkedIn}
            value={text}
            onChange={this.handleTextBoxChanges}
            itemStyle={{
              marginTop: '0.5rem',
              marginBottom: '0.5rem',
              cursor: 'pointer',
            }}
            minChar={2}
          />
        )
    )
    return (
      <div>
        <div className="card-title queued-notes-title" style={{ paddingBottom: '0em' }}>
          HPI Notes
        </div>
        <div>
          {textarea}
        </div>
        <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
          {updatedText}
        </div>
        <div style={{ textAlign: 'right', fontStyle: 'italic' }}>
          {helpText}
        </div>
      </div>
    )
  }
}

const TEXT_MACRO = PropTypes.shape({
  expansion: PropTypes.string.isRequired,
  shortcut: PropTypes.string.isRequired,
})

const HPI_NOTE = PropTypes.shape({
  section_note: PropTypes.string.isRequired,
})

HpiNotes.propTypes = {
  activeAppointment: PropTypes.shape({
    hpi_note: HPI_NOTE,
  }).isRequired,
  currentUser: PropTypes.shape({
    text_macros: PropTypes.arrayOf(TEXT_MACRO),
  }).isRequired,
  setUpdatedActiveAppointment: PropTypes.func.isRequired,
}
