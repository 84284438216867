import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import NotesSubmitButton from '../../../reusableComponents/NotesSubmitButton.jsx'
import RadioButtons from '../../../reusableComponents/RadioButtons.jsx'

export default class AbdominalPain extends React.Component {
  constructor (props) {
    super(props)
    this.state = { text: '', lastMenstrualPeriod: moment(), exception: '', hasLmp: false }
    this.submitNote = this.submitNote.bind(this)
    this.submitVitals = this.submitVitals.bind(this)
    this.sendBack = this.sendBack.bind(this)
    this.updateTextBox = this.updateTextBox.bind(this)
    this.updateException = this.updateException.bind(this)
    this.updateLastMenstrual = this.updateLastMenstrual.bind(this)
  }

  UNSAFE_componentWillMount() {
    let hasLmp = this.props.options.form_info.lmp_date ? true : false
    if (hasLmp) {
      let lastMenstrualPeriod = moment(this.props.options.form_info.lmp_date)
      this.setState({ lastMenstrualPeriod })
    }

    this.setState({ hasLmp })
  }

  updateTextBox (event) {
    var newText = event.target.value
    this.setState({ text: newText })
  }

  updateLastMenstrual (date) {
    this.setState({ lastMenstrualPeriod: date }, () => this.resetText())
  }

  updateException (formEl) {
    let exception = formEl.value

    // don't reset text box with prefilled text until state has been mutated
    this.setState({ exception }, () => this.resetText())
  }

  resetText () {
    let text = 'Last menstrual period was ' + this.state.lastMenstrualPeriod.fromNow() + '. HCG was not ordered because patient has ' + this.state.exception
    this.setState({ text })
  }

  updateLmp () {
    if (this.state.hasLmp) {
      let lmpDate = moment(this.props.options.form_info.lmp_date)

      // Convert to days ago
      var lmp =
        <div className="row">
          <p className="left" style={{ float: 'left' }}>Last menstrual period was <span className="bold-text">{lmpDate.fromNow()}</span></p>
        </div>
    } else {
      var lmp =
        <div className="row">
          <p className="left" style={{ float: 'left' }}>Last menstrual period was</p>
          <div className="col s4" style={{ float: 'left' }}>
            <DatePicker
              showTodayButton="Today"
              selected={this.state.lastMenstrualPeriod}
              onChange={this.updateLastMenstrual}
              placeholderText="Select a date"
            />
          </div>
        </div>
    }

    return lmp
  }

  sendBack () {
    if (this.state.hasLmp) {
      this.submitNote()
    } else {
      this.submitVitals()
      this.submitNote()
    }
  }

  submitNote () {
    let customOrderPref = {
      order_type: 'patient_info',
      order_type_id: 281445, //abdominal pain: care instructions
      diagnosis_code: 314212008, //Abdominal pain - cause unknown
      icd10: 'R10.9',
      provider_note: this.state.text,
    }
    const recId = this.props.id
    const orderInfo = { recId: recId, customOrderPref: customOrderPref }
    $.post('/orders/send', orderInfo)

    .done(response => {
      this.props.submittedToEhr(this.props.rule.name)
    })
    .fail(error => {
      flash.error('There was an error submitting the note to the EHR.')
      console.error(error, {context: `AbdominalPain Note Error message: ${error.responseText}`})
      
    })
  }

  submitVitals () {
    var recId = this.props.id
    var rec = [{ recId: recId }]
    var formattedLmp = [{ value: this.state.lastMenstrualPeriod.format('MM/DD/YYYY'), clinicalelementid: 'VITALS.LMP' }]
    var formattedVitals = [formattedLmp, rec]

    $.ajax({
      type: 'POST',
      url:  '/orders/vitals',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(formattedVitals),
    })

    .done(data => {
      flash.success(data.success_msg, 50000) // previously had 3000 second timeout
    })
    .fail(error => {
      _(error.responseJSON).forEach(err => {
        flash.error(_.capitalize(err) + '. Contact support@avhana.com if you need further assistance.')
      })
      console.error(error, {context: `AbdominalPain Vitals Error message: ${error.responseText}`})
    })
  }

  render () {
    const lmpContainer = this.updateLmp()
    const buttonText = this.state.hasLmp ? 'Submit Note to EHR' : 'Submit Note and LMP to EHR'

    return (
      <div>
        <div className="row">
          <div className="row">
            <p className="left bold-text" style={{ float: 'left' }}>Fill out fields below documenting why HCG was not ordered or press Order above to order HCG</p>
          </div>
          {lmpContainer}
          <div className="row">
            <RadioButtons
              formId={this.props.id}
              groupName={'HCG should be ordered for a female with abdominal pain. Exceptions include:'}
              labels={[
                { name: 'Hysterectomy', value: 'Hysterectomy' },
                { name: 'Known Pregnancy', value: 'Known Pregnancy' },
                { name: 'Pre or Post Menarche', value: 'Pre or Post Menarche' },
              ]}
              selected={this.state.exception}
              updateFormState={this.updateException}
            />
          </div>
          <div className="row">
            <div className="input-field col s10 offset-s1">
              <textarea
                id="abdominal-pain"
                className="materialize-textarea"
                onChange={this.updateTextBox}
                value={this.state.text}
                placeholder="Enter note here explaining why HCG was not ordered or press ORDER above to order HCG"
              />
              <label htmlFor="icon_prefix2"></label>
            </div>
            <NotesSubmitButton
              encounter={this.props.encounter}
              submitNote={this.submitNote}
            />
          </div>
        </div>
      </div>
    )
  }
}
