import React from 'react'
import PropTypes from 'prop-types'
import NativeListener from 'react-native-listener'
import EnhancedDismissButton from '../../reusableComponents/EnhancedDismissButton.jsx'

const determineNoOrderHeader = (props) => {
  const justDismissButton = (
    <div className="center-align">
      <EnhancedDismissButton
        dismissRec={props.dismissRec}
        rule={props.rule}
      />
    </div>
  )

  const orderLinks = (
    <span className="display-inline-text rec-card-link-container hide-on-small-only">
      <a className="rec-card-link display-inline-text">See why</a>
    </span>
  )

  return {
    title: props.rule.action,
    subtitle: `${props.rule.default_display_code}: ${props.rule.name}`,
    recPreText: '',
    suffix: '',
    orderLinks,
    actionSection: justDismissButton,
  }
}

determineNoOrderHeader.propTypes = {
  rule: PropTypes.shape({
    default_display_code: PropTypes.string,
    name: PropTypes.string,
    action: PropTypes.string,
  }),
}

const determineAddHeader = (props) => {
  // check rec exists and if it does make sure order type exists
  const optionsExist = (
    props.rec && props.rec.options.orders
      ? props.rec.options.orders
      : props.rec.options
  )

  let title
  let diagnosisName
  let recPreText = 'Order '
  const suffix = ''

  if (optionsExist) {
    const recOrderPref = props.rec.options

    // if order set, dont show "Orders using diagnosis code ..." text
    // for order set, defaults to multiple diagnoses configured on the order set
    if (recOrderPref && recOrderPref.order_set === false) {
      diagnosisName = (
        <div className="rule-action-subtitle display-inline-text">
          Diagnosis: <b>{recOrderPref.orders[0].diagnosis_name}</b>
        </div>
      )
    } else if (recOrderPref && recOrderPref.order_set) {
      diagnosisName = (
        <div className="rule-action-subtitle display-inline-text">
          Diagnosis: uses codes associated with order set
        </div>
      )
    }

    if (recOrderPref && recOrderPref.order_set === false) {
      title = recOrderPref.orders[0].order_name
      // Set to Administer or Prescribe for vaccines, default to Administer
      const orderType = recOrderPref.orders[0].order_type
      if (orderType === 'vaccination') {
        let orderingMode = 'Administer'
        if (recOrderPref.orders[0].ordering_mode) {
          orderingMode = recOrderPref.orders[0].ordering_mode
        }
        recPreText = `${orderingMode} `
      }
    } else if (recOrderPref && recOrderPref.order_set) {
      title = recOrderPref.name
    } else if (recOrderPref && props.emr === 'allscripts') {
      title = recOrderPref.entry_name
    } else {
      title = ' could not be found for this guideline'
    }
  } else {
    title = ' preference needs to be configured by Avhana support'
  }

  const actionSection = (
    <div className="right-align rec-order-checkbox-container">
      <label htmlFor={`rec-${props.rec.id}`}>
        <input type="checkbox" className="filled-in" id={`rec-${props.rec.id}`} checked={props.rec.checked} onChange={() => { props.updateRecCheckboxState(props.rec.id) }} />
        <span />
      </label>
    </div>
  )

  const orderLinks = (
    <span className="display-inline-text rec-card-link-container hide-on-small-only">
      <NativeListener onClick={() => props.selectTab('Description')}>
        <a>See why</a>
      </NativeListener>
      <span className="rule-action-subtitle">|</span>
      <NativeListener onClick={() => props.selectTab('Change Order')}>
        <a>Change order</a>
      </NativeListener>
    </span>
  )

  let diagnosisLink
  if (props.rec.options.order_set) {
    diagnosisLink = (
      <span className="display-inline-text rec-card-link-container hide-on-small-only">
        <NativeListener onClick={() => props.selectTab('Change Diagnosis')}>
          <a>Change</a>
        </NativeListener>
      </span>
    )
  }

  const subtitle = `${props.rule.default_display_code}: ${props.rule.name}`

  return {
    title,
    diagnosisName,
    subtitle,
    orderLinks,
    diagnosisLink,
    recPreText,
    suffix,
    actionSection,
  }
}

const determineRemoveHeader = (props) => {
  let title
  if (props.options.form_info) {
    title = props.options.form_info.name
  }

  const removeButton = (
    <div className="center-align">
      <div className="">
        <NativeListener stopClick onClick={props.removeOrder}>
          <button className="waves-effect waves-light btn blue-bkg">
            Remove
          </button>
        </NativeListener>
        <div className="dismiss-button-label">
          <EnhancedDismissButton
            dismissRec={props.dismissRec}
            rule={props.rule}
          />
        </div>
      </div>
    </div>
  )

  const orderLinks = (
    <span className="display-inline-text rec-card-link-container hide-on-small-only">
      <a>See why</a>
    </span>
  )

  return {
    title,
    subtitle: `${props.rule.default_display_code}: ${props.rule.name}`,
    recPreText: 'Remove ',
    suffix: '',
    orderLinks,
    actionSection: removeButton,
  }
}

const determineFollowUpHeader = (props) => {
  //  to vew when no encounter
  const startButton = (
    <div className="center-align">
      <div className="">
        <button className="waves-effect waves-light btn blue-bkg">
          Start
        </button>
        <div className="dismiss-button-label">
          <EnhancedDismissButton
            dismissRec={props.dismissRec}
            rule={props.rule}
          />
        </div>
      </div>
    </div>
  )

  const orderLinks = (
    <span className="display-inline-text rec-card-link-container hide-on-small-only">
      <a className="rec-card-link display-inline-text">See why</a>
    </span>
  )

  return {
    title: props.rule.action,
    subtitle: `${props.rule.default_display_code}: ${props.rule.name}`,
    recPreText: '',
    suffix: '',
    orderLinks,
    actionSection: startButton,
  }
}

const determineDocumentationHeader = (props) => {
  const orderDocumentationButton = (
    <div className="right-align rec-order-checkbox-container">
      <label htmlFor={`rec-${props.rec.id}`}>
        <input type="checkbox" className="filled-in" id={`rec-${props.rec.id}`} checked={props.rec.checked} onChange={() => { props.updateRecCheckboxState(props.rec.id) }} />
        <span />
      </label>
    </div>
  )

  const orderLinks = (
    <span className="display-inline-text rec-card-link-container hide-on-small-only">
      <a className="rec-card-link display-inline-text">See why</a>
    </span>
  )

  return {
    title: props.rule.action,
    subtitle: `${props.rule.default_display_code}: ${props.rule.name}`,
    recPreText: '',
    suffix: '',
    orderLinks,
    actionSection: orderDocumentationButton,
  }
}

const determineHeader = (props) => {
  const preVisitDashboard = (
    props.emr === 'athena'
  )
  if (props.noActionButtons) {
    return determineNoOrderHeader(props)
  }
  const ruleAction = props.rule_type
  switch (ruleAction) {
    case 'add':
      if (preVisitDashboard) {
        return determineAddHeader(props)
      }
    case 'remove':
      if (preVisitDashboard) {
        return determineRemoveHeader(props)
      }
    case 'follow up':
      if (preVisitDashboard) {
        return determineFollowUpHeader(props)
      }
      return determineNoOrderHeader(props)
    case 'documentation':
      if (preVisitDashboard) {
        return determineDocumentationHeader(props)
      }
    default:
      return determineNoOrderHeader(props)
  }
}

const getOutstandingOrderTitle = (props) => {
  const rec = props.rec
  if (rec.options && rec.options.orders && rec.options.orders[0].outstanding_order) {
    return (
      <div className="rule-action-subtitle display-inline-text">
        <br />
        {`There is an oustanding order from ${props.rec.options.orders[0].outstanding_date}. Order again?`}
      </div>
    )
  }
  return null
}

getOutstandingOrderTitle.propTypes = {
  rec: PropTypes.shape({
    options: PropTypes.shape({
      orders: PropTypes.arrayOf(PropTypes.shape({
        outstanding_order: PropTypes.bool,
        outstanding_date: PropTypes.string,
      })),
    }),
  }),
}

export default function CardHeader(props) {
  const headerInfo = determineHeader(props)
  const outstandingTitle = getOutstandingOrderTitle(props)

  return (
    <div className="collapsible-header collapsible-header-padding">
      <div className="col s12 flex align-center">
        <div style={{ marginLeft: 0 }} className="col s12 m9 rec-title-subtitle-container">
          <div className="row decrease-line-height-more">
            <div className="col s12 rule-action-title-container">
              <a className="rule-action-title">
                <b>{headerInfo.recPreText + headerInfo.title}</b> {headerInfo.suffix}
                {outstandingTitle}
              </a>
            </div>
            <div className="col s12">
              {headerInfo.diagnosisName}
              {headerInfo.diagnosisLink}
              {headerInfo.orderLinks}
            </div>
          </div>
        </div>
        <div className="col s12 m2 l1 flex align-center justify-center" style={{ paddingRight: '0 !important', paddingLeft: '1.75rem !important' }}>
          {headerInfo.actionSection}
        </div>
      </div>
    </div>
  )
}

