import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { TextField, InputAdornment } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

import SearchIcon from '@mui/icons-material/Search'

const propTypes = {
  onOptionSelected: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  department: PropTypes.shape({
    name: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
}

const defaultProps = {
  department: { name: '' },
  options: [],
}

class DepartmentsTypeahead extends Component {
  constructor(props) {
    super(props)
    const { department: { name } } = props
    this.state = {
      entry: name,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleOptionSelected = this.handleOptionSelected.bind(this)
    this.clearInput = this.clearInput.bind(this)
  }

  handleChange(event) {
    const text = event.target.value
    this.setState({ entry: text })
  }

  /**
   * @method clearInput clears input on typeahead and sets cursor focus to search input
   */
  clearInput() {
    this.setState({ entry: '' })
  }

  handleOptionSelected(name) {
    const { onOptionSelected } = this.props
    const { options } = this.props
    const option = options.find((o) => o.name === name)
    onOptionSelected(option, this)
  }

  render() {
    const { entry } = this.state
    const { options } = this.props
    return (
      <>
        <Autocomplete
          freeSolo
          options={options.map((o) => o.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              label="Search departments here"
            />
          )}
          onChange={(_event, value, reason) => {
            if (reason === 'selectOption') {
              this.handleOptionSelected(value)
            }
          }}
          value={entry}
          id="autocomplete"
        />
      </>
    )
  }
}

DepartmentsTypeahead.propTypes = propTypes
DepartmentsTypeahead.defaultProps = defaultProps

export default DepartmentsTypeahead
