import React from 'react'
import PropTypes from 'prop-types'

const DiagnosisOrders = (props) => {
  const { index, diagnosisOrders: { orders, diagnosis } } = props
  let ordersList
  if (orders.length > 0) {
    ordersList = orders.map((order) => (
      <li key={`${diagnosis}-order-${order.orderid}`} style={{ listStyleType: 'circle', paddingLeft: '1em' }}>
        <div>{`-${order.description}`}</div>
      </li>
    ))
  } else {
    ordersList = <p>No orders could be found</p>
  }

  return (
    <div>
      <div style={{ marginTop: '1em' }}>
        <b>
          { `${index}. ${_.capitalize(diagnosis)}` }
        </b>
      </div>
      <ul style={{ marginTop: '0em' }}>
        { ordersList }
      </ul>
    </div>
  )
}

DiagnosisOrders.propTypes = {
  diagnosisOrders: PropTypes.shape({
    orders: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      orderid: PropTypes.string,
    })),
    diagnosis: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
}

export default DiagnosisOrders
