export default function setCurrentUser(userData) {
  // config User object so can access within deeply nested components
  try {
    return {
      id: userData.currentuserid,
      name: userData.currentusername,
      organization: userData.currentuserorganization,
      organization_id: userData.orgid,
    }
  } catch (err) {
    return null
  }
}
