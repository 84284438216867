// for user input violates
function InputException(message) {
  this.message = message;
  this.name = 'Input Exception'
}

// sanitize user input
window.AscvdUserInput = function UserInput(gender, age, race, totalChol, hdlChol, sysBP, hypertension, diabetes, smoker) {
  this.gender = gender
  this.age = age
  this.race = race
  this.totalChol = totalChol
  this.hdlChol = hdlChol
  this.sysBP = sysBP
  this.hypertension = hypertension === 'false' ? false : true
  this.diabetes = diabetes === 'false' ? false : true
  this.smoker = smoker === 'false' ? false : true
  this.isAA = (race.toLowerCase().includes('african american')) || (race.toLowerCase().includes('black'))
  this.isMale = (this.gender == 'Male')
  this.ValidateInput = function() {
    var errors = new Array()
    if (this.gender != 'Male' && this.gender != 'Female')
      errors.push(new InputException('Gender must be Male or Female'))
    if (this.age < 20 || this.age > 79)
      errors.push(new InputException('Valid age range is 20 - 79'))
    if (!this.race)
      errors.push(new InputException('Race must be indicated as White or African American or Other'))
    if (this.totalChol < 130 || this.totalChol > 320)
      errors.push(new InputException('Valid range for total cholesterol is 130-320'))
    if (this.hdlChol < 20 || this.hdlChol > 100)
      errors.push(new InputException('Valid range for HDL cholesterol is 20-100'))
    if (this.sysBP < 90 || this.sysBP > 200)
      errors.push(new InputException('Valid range for systolic blood pressure is 90-200'))
    return errors
  }
}

// 10 year risk calculator
window.TenYearASCVD = function TenYearASCVD(input) {
  this.errors = input.ValidateInput()
  if (input.age < 40 || input.age > 79) {
    this.errors.push(new InputException('This calculator only provides 10-year risk estimates for individuals 40 to 79 years of age.'))
  }
  this.hasErrors = this.errors.length > 0 ? true : false

  function ascvdValues(userInput) {
    this.lnAge = Math.log(userInput.age)
    this.lntot = Math.log(userInput.totalChol)
    this.lnhdl = Math.log(userInput.hdlChol)
    this.trlnsbp = Math.log(userInput.sysBP) * Number(userInput.hypertension)
    this.ntlnsbp = Math.log(userInput.sysBP) * Number(!userInput.hypertension)
    this.age2 = this.lnAge * this.lnAge
    this.agetc = this.lnAge * this.lntot
    this.agehdl = this.lnAge * this.lnhdl
    this.agetsbp = this.lnAge * this.trlnsbp
    this.agentsbp = this.lnAge * this.ntlnsbp
    this.agesmoke = this.lnAge * Number(userInput.smoker)
    this.agedm = this.lnAge * Number(userInput.diabetes)
    this.s010 = function() {
      var ret = 0
      if (userInput.isAA && !userInput.isMale)
        ret = 0.95334
      if (!userInput.isAA && !userInput.isMale)
        ret = 0.96652
      if (userInput.isAA && userInput.isMale)
        ret = 0.89536
      if (!userInput.isAA && userInput.isMale)
        ret = 0.91436
      return ret
    }
    this.mnxb = function() {
      var ret = 0
      if (userInput.isAA && !userInput.isMale)
        ret = 86.6081
      if (!userInput.isAA && !userInput.isMale)
        ret = -29.1817
      if (userInput.isAA && userInput.isMale)
        ret = 19.5425
      if (!userInput.isAA && userInput.isMale)
        ret = 61.1816
      return ret
    }
    this.predict = function() {
      var ret = 0
      if (userInput.isAA && !userInput.isMale)
        ret = 17.1141 * this.lnAge + 0.9396 * this.lntot + (-18.9196 * this.lnhdl) + 4.4748 * this.agehdl + 29.2907 * this.trlnsbp + (-6.4321 * this.agetsbp) + 27.8197 * this.ntlnsbp + (-6.0873 * this.agentsbp) + 0.6908 * Number(userInput.smoker) + 0.8738 * Number(userInput.diabetes)
      if (!userInput.isAA && !userInput.isMale)
        ret = (-29.799 * this.lnAge) + 4.884 * this.age2 + 13.54 * this.lntot + (-3.114 * this.agetc) + (-13.578 * this.lnhdl) + 3.149 * this.agehdl + 2.019 * this.trlnsbp + 1.957 * this.ntlnsbp + 7.574 * Number(userInput.smoker) + (-1.665 * this.agesmoke) + 0.661 * Number(userInput.diabetes)
      if (userInput.isAA && userInput.isMale)
        ret = 2.469 * this.lnAge + 0.302 * this.lntot + (-0.307 * this.lnhdl) + 1.916 * this.trlnsbp + 1.809 * this.ntlnsbp + 0.549 * Number(userInput.smoker) + 0.645 * Number(userInput.diabetes)
      if (!userInput.isAA && userInput.isMale)
        ret = 12.344 * this.lnAge + 11.853 * this.lntot + (-2.664 * this.agetc) + (-7.99 * this.lnhdl) + 1.769 * this.agehdl + 1.797 * this.trlnsbp + 1.764 * this.ntlnsbp + 7.837 * Number(userInput.smoker) + (-1.795 * this.agesmoke) + 0.658 * Number(userInput.diabetes)
      return ret
    }
    this.cvdPredict = function() {
      return (1 - Math.pow(this.s010(), Math.exp(this.predict() - this.mnxb())))
    }
  }
  var currentCalc = new ascvdValues(input)
  var optimalCalc = new ascvdValues(new AscvdUserInput(input.gender, input.age, input.race, 170, 50, 110, false, false, false))

  const risk = currentCalc.cvdPredict() * 100
  let formattedRisk
  if (input.age >= 40 && input.age < 80) {
    formattedRisk = `${Math.round(risk, 1).toString()}%`
  } else {
    formattedRisk = 'NA'
  }

  return {
    YourRisk: risk,
    formattedRisk: formattedRisk,
    Optimal: optimalCalc.cvdPredict() * 100,
    hasErrors: this.hasErrors,
    errors: this.errors
  }
}

//Lifetime ASCVD score
window.LifetimeASCVD = function LifetimeASCVD(input) {
  this.errors = input.ValidateInput();
  if (input.age < 20 || input.age > 59) {
    this.errors.push(new InputException('Lifetime Risk Calculator only provides lifetime risk estimates for individuals 20 to 59 years of age.'))
  }

  this.hasErrors = this.errors.length > 0 ? true : false;

  function lifetimeAscvdValues(input) {
    var sexText = String(input.gender).toLowerCase()
    var lifetimeASCVDrisk = (sexText == 'male' ? 5 : 8)
    var yourASCVDrisk = 0
    var ascvdTable = {
        "female": {
            "major2": 50,
            "major1": 39,
            "elevated": 39,
            "notOptimal": 27,
            "allOptimal": 8
        },
        "male": {
            "major2": 69,
            "major1": 50,
            "elevated": 46,
            "notOptimal": 36,
            "allOptimal": 5
        },
    }

    //Logic taken from http://tools.acc.org/ASCVD-Risk-estimator/
    var major = (input.totalChol >= 240 ? 1 : 0) +
                ((input.sysBP >= 160 ? 1 : 0) +
                (input.hypertension ? 1 : 0)) +
                (input.smoker ? 1 : 0) +
                (input.diabetes ? 1 : 0)
    var elevated = ((((input.totalChol >= 200 && input.totalChol < 240) ? 1 : 0) +
                   ((input.sysBP >= 140 && input.sysBP < 160 && input.hypertension == false) ? 1 : 0)) >= 1 ? 1 : 0) *
                   (major == 0 ? 1 : 0)
    var allOptimal = (((input.totalChol < 180 ? 1 : 0) +
                     ((input.sysBP < 120 ? 1 : 0) * (input.hypertension ? 0 : 1))) == 2 ? 1 : 0) *
                     (major == 0 ? 1 : 0)
    var notOptimal = ((((input.totalChol >= 180 && input.totalChol < 200) ? 1 : 0) +
                     ((input.sysBP >= 120 && input.sysBP < 140 && input.hypertension == false) ? 1 : 0)) *
                     (elevated == 0 ? 1 : 0) * (major == 0 ? 1 : 0)) >= 1 ? 1 : 0

    if (major > 1)
        yourASCVDrisk = ascvdTable[sexText]["major2"]
    if (major == 1)
        yourASCVDrisk = ascvdTable[sexText]["major1"]
    if (elevated == 1)
        yourASCVDrisk = ascvdTable[sexText]["elevated"]
    if (notOptimal == 1)
        yourASCVDrisk = ascvdTable[sexText]["notOptimal"]
    if (allOptimal == 1)
        yourASCVDrisk = ascvdTable[sexText]["allOptimal"]

    return yourASCVDrisk
  }

  const risk = lifetimeAscvdValues(input)
  let formattedRisk
  if (input.age >= 20 && input.age < 60) {
    formattedRisk = `${Math.round(risk, 1).toString()}%`
  } else {
    formattedRisk = 'NA'
  }

  return {
    YourRisk: risk,
    formattedRisk: formattedRisk,
    hasErrors: this.hasErrors,
    errors: this.errors
  }
}


// get recommendation based on score
window.AscvdGetRecommendationText = function GetRecommendationText(diabetes, age, cvRisk) {
  var textConstants = {
    YHM: {
      Title: 'Consider High-Intensity Statin for Patient With Diabetes',
      Description: 'High-intensity statin therapy should be initiated or continued for adults 40 to 75 years of age with diabetes mellitus. High-intensity statin therapy is reasonable for adults 40 to 75 years of age with diabetes mellitus with a >=7.5% estimated 10-year ASCVD risk unless contraindicated. It is reasonable to evaluate the potential for ASCVD benefits and for adverse effects, for drug-drug interactions, and to consider patient preferences when deciding to initiate, continue, or intensify statin therapy.'
    },
    YLM: {
      Title: 'Consider Moderate-Intensity Statin for Patient With Diabetes',
      Description: 'Moderate-intensity statin therapy should be initiated or maintained for adults 40 to 75 years of age with diabetes mellitus. It is reasonable to evaluate the potential for ASCVD benefits and for adverse effects, for drug-drug interactions, and to consider patient preferences when deciding to initiate, continue, or intensify statin therapy.'
    },
    Y_H: {
      Title: 'Not In A Statin Benefit Group Due To Age > 75 Years',
      Description: 'In adults with diabetes mellitus, who are <40 or >75 years of age, it is reasonable to evaluate the potential for ASCVD benefits and for adverse effects, for drug-drug interactions, and to consider patient preferences when deciding to initiate, continue, or intensify statin therapy.'
    },
    Y_L: {
      Title: 'Not In A Statin Benefit Group Due To Age <40 Years',
      Description: ''
    },
    NHM: {
      Title: 'Consider Moderate to High-Intensity Statin',
      Description: 'Before initiating statin therapy, it is reasonable for clinicians and patients to engage in a discussion which considers the potential for ASCVD risk reduction benefits and for adverse effects, for drug-drug interactions, and patient preferences for treatment. This discussion also provides the opportunity to re-emphasize healthy-lifestyle habits and address other risk factors. The 4 major statin benefit groups for whom the ASCVD risk reduction clearly outweighs the risk of adverse events based on a strong body of evidence are: secondary prevention in individuals with clinical ASCVD, primary prevention in individuals with primary elevations of LDL-C ≥190 mg/dL, primary prevention in individuals with diabetes 40 to 75 years of age who have LDL-C 70 to 18 mg/dL, and primary prevention in individual without diabetes and with estimated 10-year ASCVD risk ≥7.5%, 40 to 75 years of age who have LDL-C 70 to 189 mg/dL.'
    },
    NMM: {
      Title: 'Consider Moderate-Intensity Statin',
      Description: 'It is reasonable to offer treatment with a moderate-intensity statin to adults 40–75 years of age, with LDL-C 70–189 mg/dL, without clinical ASCVD or diabetes, and with an estimated 10-year ASCVD risk of 5% to 7.5%. Before initiation of statin therapy for the primary prevention of ASCVD in adults with LDL-C 70–189 mg/dL without clinical ASCVD or diabetes, it is reasonable for clinicians and patients to engage in a discussion that considers the potential for ASCVD risk-reduction benefits and for adverse effects and drug–drug interactions, as well as patient preferences for treatment.'
    },
    N_H: {
      Title: 'Not In Statin Benefit Group Due To Age > 75 Years',
      Description: 'Patient is over 75 years of age. Before initiating statin therapy, it is reasonable for clinicians and patients to engage in a discussion which considers the potential for ASCVD risk reduction benefits and for adverse effects, for drug-drug interactions, and patient preferences for treatment. In individuals for whom after quantitative risk assessment a risk-based treatment decision is uncertain, additional factors may be considered to inform treatment decision making.  These factors may include primary LDL-C >=160 mg/dL or other evidence of genetic hyperlipidemias, family history of premature ASCVD with onset <55 years of age in a first degree male relative or <65 years of age in a first degree female relative, high-sensitivity C-reactive protein >2 mg/L, CAC score >=300 Agatston units or >=75 percentile for age, sex, and ethnicity, ankle-brachial index <0.9, or elevated lifetime risk of ASCVD. Additional factors may be identified in the future.'
    },
    NL_: {
      Title: 'Not In Statin Benefit Group Due To 10-Year ASCVD Risk <5%',
      Description: 'In individuals for whom after quantitative risk assessment a risk-based treatment decision is uncertain, additional factors may be considered to inform treatment decision making. These factors may include primary LDL-C >=160 mg/dL or other evidence of genetic hyperlipidemias, family history of premature ASCVD with onset <55 years of age in a first degree male relative or <65 years of age in a first degree female relative, high-sensitivity C-reactive protein >=2 mg/L, CAC score >=300 Agatston units or >=75 percentile for age, sex, and ethnicity, ankle-brachial index <0.9, or elevated lifetime risk of ASCVD. Additional factors may be identified in the future.'
    },
    N_L: {
      Title: 'Not In Statin Benefit Group Due To Age < 40 Years',
      Description: 'Patient is below 40 years of age. Before initiating statin therapy, it is reasonable for clinicians and patients to engage in a discussion which considers the potential for ASCVD risk reduction benefits and for adverse effects, for drug-drug interactions, and patient preferences for treatment. In individuals for whom after quantitative risk assessment a risk-based treatment decision is uncertain, additional factors may be considered to inform treatment decision making.  These factors may include primary LDL-C >=160 mg/dL or other evidence of genetic hyperlipidemias, family history of premature ASCVD with onset <55 years of age in a first degree male relative or <65 years of age in a first degree female relative, high-sensitivity C-reactive protein >2 mg/L, CAC score >=300 Agatston units or >=75 percentile for age, sex, and ethnicity, ankle-brachial index <0.9, or elevated lifetime risk of ASCVD. Additional factors may be identified in the future.'
    }
  };
  this.diabetes = diabetes
  this.title = ''
  this.description = ''
  if (this.diabetes) {
    if (age > 75) {
      this.title = textConstants.Y_H.Title
      this.description = textConstants.Y_H.Description
    }
    if (age < 40) {
      this.title = textConstants.Y_L.Title
      this.description = textConstants.Y_L.Description
    }
    if (age >= 40 && age <= 75) {
      if (cvRisk < 7.5) {
        this.title = textConstants.YLM.Title
        this.description = textConstants.YLM.Description
      } else {
        this.title = textConstants.YHM.Title
        this.description = textConstants.YHM.Description
      }
    }
  } else {
    if (age > 75) {
      this.title = textConstants.N_H.Title
      this.description = textConstants.N_H.Description
    }
    if (age < 40) {
      this.title = textConstants.N_L.Title
      this.description = textConstants.N_L.Description
    }
    if (age >= 40 && age <= 75) {
      if (cvRisk < 7.5) {
        this.title = textConstants.NMM.Title
        this.description = textConstants.NMM.Description
      } else {
        this.title = textConstants.NHM.Title
        this.description = textConstants.NHM.Description
      }
    }
    if (cvRisk < 5) {
      this.title = textConstants.NL_.Title
      this.description = textConstants.NL_.Description
    }
  }
}
