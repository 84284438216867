import React from 'react'
import PropTypes from 'prop-types'

export default class AdultImms extends React.Component {
  constructor(props) {
    super(props)
    this.getRows = this.getRows.bind(this)
    this.submitVaccineOrder = this.submitVaccineOrder.bind(this)
    this.state = { data: {}, recs: {}, orders: {} }
  }

  UNSAFE_componentWillMount() {
    // Label by concept name
    const recs = {}
    _.forEach(this.props.options.form_info, (v, k) => {
      const splitString = k.split(' ')
      let key = splitString.slice(1, splitString.length).join(' ')
      recs[key] = v
      key = splitString.slice(splitString.length - 1, splitString.length).join(' ')
      recs[key] = v
    })
    this.setState({ recs })

    $.get('/patients/get_immunization_info', {
      emr_patient_id: this.props.patient.emr_patient_id,
      organization_id: this.props.patient.organization_id,
    })
    .done((data) => {
      this.setState({ data })
    })
    .fail((error) => {
      if (checkTimeout(error) === true) {
        redirectToLogin()
      } else {
        flash.error('There was an error populating immunizations')
        console.error(error, { context: `Get AdultImms Error Error message: ${error.responseText}` })
      }
    })
  }

  getRows(name, numDoses, recommendedAges) {
    const dosesGotten = this.state.data[name]
    const recUuid = this.state.recs[name]
    const ordered = this.state.orders[name]

    let tempDoseGotten
    let buttonAdded = false
    let row
    const rows = numDoses.map((value, index) => { // Need arrow function so we can access 'this'
      _.forEach(dosesGotten, (imm) => {
        if (imm.series === value) {
          tempDoseGotten = imm
        }
      })

      // Logic: Is vaccine gotten, show, else first time not gotten show if needs
      // dose, else show ineligible
      if (tempDoseGotten) {
        row = (
          <tr key={`${name}${index}`}>
            <td>{name}</td>
            <td>{tempDoseGotten.date}</td>
            <td>Completed</td>
          </tr>
        )
      } else if (recUuid && !buttonAdded) {
        buttonAdded = true
        if (ordered) {
          row = (
            <tr key={`${name}${index}`}>
              <td>{name}</td>
              <td>Today</td>
              <td>Ordered</td>
            </tr>
          )
        } else {
          row = (
            <tr key={`${name}${index}`}>
              <td>{name}</td>
              <td />
              <td>
                <button className="btn" onClick={() => this.submitVaccineOrder(name, recUuid)}>
                  Order
                </button>
              </td>
            </tr>
          )
        }
      } else if (name !== 'PCV13') {
        row = (
          <tr key={`${name}${index}`}>
            <td>{name}</td>
            <td />
            <td>{recommendedAges[index]}</td>
          </tr>
        )
      }
      tempDoseGotten = null
      return row
    })
    return rows
  }

  submitVaccineOrder(name, recUuid) {
    const orderInfo = { recUuid, patientId: this.props.patient.id }
    $.post('/orders/order_vaccine_from_table', orderInfo)
    .done(() => {
      const order = { status: 'Ordered' }
      const newOrders = this.state.orders
      newOrders[name] = order
      this.setState({ orders: newOrders })
      flash.success(`Submitted ${name} vaccination to EHR`, 50000) // previously had 3000 second timeout
    })
    .fail((error) => {
      flash.error('There was an error submitting to the EHR.')
      console.error(error, { context: `Submit AdultImms Error Error message: ${error.responseText}` })
    })
  }

  render() {
    // Better to have these as objects if it starts getting complicated
    const td = this.getRows('Td', [1], ['Every 10 Years'])
    const pneumococcal = this.getRows('PCV13', [1], ['Recommended at age 19 for certain conditions'])

    return (
      <div className="row">
        <div className="row">
          <table className="striped centered">
            <thead>
              <tr>
                <th>Vaccine</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {td}
              {pneumococcal}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

AdultImms.propTypes = {
  options: PropTypes.shape({
    form_info: PropTypes.shape({}),
  }),
  patient: PropTypes.shape({
    id: PropTypes.number,
    emr_patient_id: PropTypes.string,
    organization_id: PropTypes.number,
  }),
}
