import React from 'react'
import PropTypes from 'prop-types'

import { Collapse, Grid } from '@mui/material'

const CardBody = ({ open, children, bodyRef, ruleId }) => (
  <Collapse in={open} timeout="auto" unmountOnExit data-id={`rule-body-${ruleId}`}>
    <Grid
      container
      direction="column"
      ref={bodyRef}
    >
      {children}
    </Grid>
  </Collapse>
)

CardBody.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  bodyRef: PropTypes.func.isRequired,
  ruleId: PropTypes.number.isRequired
}

export default CardBody
