import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export default class NotesSubmitButton extends React.Component {
  constructor (props) {
    super(props)
    this.submitNote = this.props.submitNote.bind(this)
  }

  render() {
    let button

    if (this.props.encounter){
      button =
        <button className='btn right' onClick={this.submitNote}>Submit Note to EHR</button>
    } else {
      button =
        <button className='btn right' disabled>Requires Open Encounter</button>
    }
    return (
      <div className="col s10 offset-s1">
        { button }
      </div>
    )
  }
}

NotesSubmitButton.propTypes = {
  submitNote: PropTypes.func.isRequired
}
