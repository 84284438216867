import PropTypes from 'prop-types'
import React, { Component } from 'react'

const propTypes = {
  pref: PropTypes.object.isRequired,
  rec: PropTypes.object,
  updateOrderPref: PropTypes.bool,
  dropdownOptions: PropTypes.array,
  updateOptions: PropTypes.func,
  columns: PropTypes.string,
  style: PropTypes.object,
}

/**
 * This component takes in values from the options column on an OrderPreference
 * and implements a dropdown so user can change the ordering mode for immunizations
 */
export default class OrderingModeDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { orderingMode: '' }
    this.updateOrderingMode = this.updateOrderingMode.bind(this)
  }

  componentDidMount() {
    // If you have the rec, use it, otherwise fall back to OP
    // This also happens in updateOrderingMode method
    const { pref, rec } = this.props
    const orderingMode = rec
      ? rec.options.orders[0].ordering_mode
      : pref.options.orders[0].ordering_mode
    this.setState({ orderingMode })
  }

  /**
   * note that this currently changes the default order preference at the organization level
   * this will likely need refactoring to set defaults at both the organization and user levels
   * @method optionSelected fires when a dropdown choice is selected
   * and makes AJAX call to update the order preference in Rails for that OrderPreference id
   * or change state
   */
  updateOrderingMode(event) {
    const orderingMode = event.target.value
    const options = {
      orders: [{ ordering_mode: orderingMode }],
    }
    let recId
    if (this.props.rec) {
      recId = this.props.rec.id
    }
    let prefId
    if (this.props.pref) {
      prefId = this.props.pref.id
    }
    if (this.props.updateOrderPref) {
      const data = JSON.stringify({
        ...pref,
        options,
        rec_id: recId,
      })
      // AJAX call to update organization order preference in db if flag set
      $.ajax({
        method: 'put',
        url: `/admin/order_preferences/${prefId}`,
        dataType: 'json',
        contentType: 'application/json',
        data
      })
      .done((response) => {
        this.setState(response.orders[0])
        this.props.updateOptions(recId, response)
        flash.success('Order changed successfully', 50000) // previously had 3000 timeout
      })
      .fail((error) => {
        flash.error('Order could not be changed.')
        console.error(error, { context: `OrderingMdeDropdown update error message: ${error.responseText}` })
      })
    } else {
      const data = JSON.stringify({
        rec_id: recId,
        options,
      })
      // AJAX call to update organization order preference in db if flag set
      $.ajax({
        method: 'put',
        url: '/recommendations/update',
        dataType: 'json',
        contentType: 'application/json',
        data
      })
      .done((response) => {
        this.setState(response.orders[0])
        this.props.updateOptions(recId, response)
        flash.success('Order changed successfully', 50000) // previously had 3000 timeout
      })
      .fail((error) => {
        flash.error('Order could not be changed.')
        console.error(error, { context: `OrderingMdeDropdown update error message: ${error.responseText}` })
      })
    }
  }

  render() {
    const options = _.map(this.props.dropdownOptions, (el, i) =>
      <option key={i} value={el}>{el}</option>
    )

    let cols
    if (this.props.columns) {
      cols = `col s${this.props.columns}`
    } else {
      cols = 'col s8'
    }

    return (
      <div className={cols} style={this.props.style}>
        <br />
        <label htmlFor="orderingMode">Select Ordering Mode</label>
        <select
          name="orderingMode"
          className="browser-default"
          onChange={this.updateOrderingMode}
          defaultValue={this.state.orderingMode}
        >
          {options}
        </select>
      </div>
    )
  }
}

OrderingModeDropdown.propTypes = propTypes
