import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import RadioButtonsInline from '../../../reusableComponents/RadioButtonsInline.jsx'

const labels = [
  '1. Little interest or pleasure in doing things',
  '2. Feeling down, depressed, or hopeless',
  '3. Trouble falling or staying asleep, or sleeping too much',
  '4. Feeling tired or having little energy',
  '5. Poor appetite or overeating',
  '6. Feeling bad about yourself - or that you are a failure or have ' +
  'let yourself or your family down',
  '7. Trouble concentrating on things, such as reading the newspaper ' +
  'or watching television',
  '8. Moving or speaking so slowly that other people could have ' +
  'noticed? Or the opposite - being so fidgety or restless that ' +
  'you have been moving around a lot more than usual',
  '9. Thoughts that you would be better off dead or of hurting yourself in some way',
]

export default class Phq9 extends React.Component {
  constructor(props) {
    super(props)
    this.state = { score: 0, formValues: [] }
  }

  updateFormState(formEl) {
    let formValuesState = this.state.formValues
    formValuesState.unshift(formEl)
    formValuesState = _.uniqBy(formValuesState, 'questionId')
    this.calculateScore(formValuesState)
  }

  calculateScore(newFormValues) {
    let newScore = 0
    const sortedValues = _.sortBy(newFormValues, 'questionId')
    sortedValues.forEach((entry) => {
      // last question does not factor into score
      if (entry.questionId !== 9) { newScore += entry.value }
    })

    this.setState({ formValues: sortedValues, score: newScore })
  }

  documentUpload(event) {
    event.preventDefault()
    const answers = this.state.formValues
    const totalScore = this.state.score
    const phqData = { answers, total_score: totalScore, rec_id: this.props.id, athena_name: 'PHQ-9' }
    const $card = $(event.target).closest('.collection-item')
    flash.notice('Questionnaire sent (please wait)', 1000)
    $.post('/orders/upload', phqData)
    .done(() => {
      $card.toggle('slide', { direction: 'left' }, 300, () => { this.remove() })
      flash.success('Questionnaire submitted successfully', 50000) // previously had 3000 second timeout
    })
    .fail((error) => {
      _.forEach(error.responseJSON.errors, (err) => {
        flash.error(
          `${_.capitalize(err)}. Contact support@avhana.com if you need further assistance.`
        )
      })
      console.error(error, { context: `Phq9 Upload Error message: ${error.responseText}` })
    })
  }

  render() {
    const radios = labels.map((label, index) => (
      <RadioButtonsInline
        key={index}
        questionId={index}
        groupName={label}
        labels={[
          'Not at all',
          'Several days',
          'More than half the days',
          'Nearly every day',
        ]}
        updateFormState={this.updateFormState.bind(this)}
      />
    ))
    const formVals = this.state.formValues

    return (
      <div className="row">
        <div className="col s10 offset-s1">
          <form>
            <div className="row">
              <div className="col s8 bold-text">
                Over the last two weeks, how often have you been bothered by any of the
                following problems?
              </div>
              <div className="col s1 bold-text">
                Not at all
              </div>
              <div className="col s1 bold-text">
                Several days
              </div>
              <div className="col s1 bold-text">
                More than half the days
              </div>
              <div className="col s1 bold-text">
                Nearly every day
              </div>
            </div>
            {radios}
            <div className="row">
              <div className="col s1 offset-s8">
                Not difficult at all
              </div>
              <div className="col s1">
                Some-what difficult
              </div>
              <div className="col s1">
                Very difficult
              </div>
              <div className="col s1">
                Extremely difficult
              </div>
            </div>
            <RadioButtonsInline
              questionId={9}
              groupName={
                '10. If you checked off any problems, how difficult ' +
                'have these problems made it for you to do your work, take ' +
                'care of things at home, or get along with other people?'}
              labels={[
                'Not difficult at all',
                'Somewhat difficult',
                'Very difficult',
                'Extremely difficult'
              ]}
              updateFormState={this.updateFormState.bind(this)}
            />
            <div className="row">
              <p className="total-score green-text col s3 offset-s7 bold-text">
                {`Total Score: ${this.state.score}`}
              </p>
              <button
                className={`
                  depression-screening-form-submit waves-effect waves-light btn blue-bkg col s2
                `}
                disabled={formVals.length !== 10}
                onClick={this.documentUpload.bind(this)}
              >
                Submit
              </button>
            </div>
          </form >
        </div>
      </div>
    )
  }
}

Phq9.propTypes = {
  id: PropTypes.string,
}
