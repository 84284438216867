import PropTypes from 'prop-types'
import React from 'react'

import LoadingSkeleton from '../loadingSkeleton/LoadingSkeleton'
import OrderAnything from '../reusableComponents/OrderAnything'
import Recommendation from './Recommendation'
import Screenings from './Screenings'
import RecommendationsPageHeader from './RecommendationsPageHeader'
import Score from './components/Score'
import loadingToastContent from '../utilities/loadingToast.json'
import { NoRecsEmr } from '../athenaTypes'

/**
 * @class RecommendationsList This is the main component for the Recommendations page
 * and is the only place where we manage the state of an actual Recommendation
 * and any thing tied to it (Rule, OrderPreference, etc) so to rerender things
 * on Recommendations like the OrderPreference we need to change state here
 *
 * TODO - be more granular about how we manage state we should do it all here
 * ideally we would just manage the state of the list here but manage the state
 * of the OrderPreference on a Recommendation child deeper down the DOM (this
 * will reduce the number of components we have to traverse back through to get
 * back to this component and update the state)
 */

export const SCREENING_RULE_UUIDS = {
  '1cc18e69-29c8-49f5-8b26-eece6fa074a4': 'Blood Pressure',
  '1f1e000f-8755-4b0e-bbe5-f3c0af882588': 'BMI',
  '2ef82406-030b-444e-8dec-c4ee9e334bf1': 'PHQ-9',
  '5d5d9791-d31f-47ac-9a80-53965ee6cdc0': 'Fall Risk'
}

export default class RecommendationsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ordersProcessing: false,
    }
    this.dismissScore = this.dismissScore.bind(this)
    this.updateRecCheckboxState = this.updateRecCheckboxState.bind(this)
    this.orderAllChecked = this.orderAllChecked.bind(this)
  }

  updateRecCheckboxState(recId) {
    const { recsList, updateRecsList } = this.props
    const recommendation = recsList.find((rec) => rec.id === recId)
    const newState = !recommendation.checked
    recommendation.checked = newState
    updateRecsList(recsList)
  }

  dismissScore(props) {
    const { removeRecommendation } = this.props
    const { id } = props
    const recommendationId = parseInt(id, 10)
    const title = props.rule.name
    $.ajax({
      url: `/recommendations/dismiss/${recommendationId}`,
      method: 'PUT',
      done: () => {
        removeRecommendation(recommendationId)
        flash.success(`Hidden: ${title}`, 50000) // previously had 3000 second timeout
      },
    })
  }

  orderAllChecked(ordersChecked) {
    // ordersProcessing is a temporary state that prevents the user from
    // double clicking on send orders
    const { updateRecStatus } = this.props
    const { ordersProcessing } = this.state
    if (!ordersProcessing) {
      this.setState({ ordersProcessing: true })
      const recIds = ordersChecked.map((o) => o.id)

      const content = loadingToastContent.el1
      const message = flash.notice(content, 200000)

      $.ajax({
        url: '/orders/order_many',
        method: 'POST',
        data: { recIds },
        success: (response) => {
          const orderStatus = response.order_status
          if (orderStatus === 'order queued') {
            ordersChecked.forEach((rec) => {
              updateRecStatus(parseInt(rec.id, 10), 'order queued')
            })
          } else if (orderStatus === 'order successful') {
            ordersChecked.forEach((rec) => {
              updateRecStatus(parseInt(rec.id, 10), 'order successful')
            })
          }
        },
        error: (error) => {
          if (checkTimeout(error) === true) {
            redirectToLogin()
          } else {
            console.error(error, { context: 'Recommendation.jsx sendOrder(), Error sending order', recIds })
            this.setState({ ordersProcessing: false })
            error.responseJSON.errors.forEach((err) => {
              flash.error(
                `${_.capitalize(err)}. Contact support@avhana.com if you need further assistance.`,
              )
            })
          }
        },
        complete: () => {
          flash.dismissMessage(message)
          this.setState({ ordersProcessing: false })
        },
      })
    } else {
      flash.error('Orders are in progress.')
    }
  }

  render() {
    // sub component declarations, see return method below
    const {
      addRecToRecsList,
      emr,
      emrDepartmentId,
      emrPatientId,
      encounter,
      header,
      loading,
      organizationId,
      patient,
      patientSearchPage,
      recsList,
      removeRecommendation,
      updateOptions,
      updateRecStatus,
      updateEncounter,
    } = this.props

    let recGroupings
    let recsPageHeader
    let scoreGroupings
    let customOrderBox
    let orderableCountUi

    if (!loading) {
      if (recsList.length > 0) {
        // separate scores and all other recs
        const recsListPartitioned = _.partition(recsList, { rule_type: 'score' })
        const scoresList = recsListPartitioned[0]
        const recsListNoScores = recsListPartitioned[1]

        // separate queued orders and all other recs
        const recsListNoQueued = _.filter(recsListNoScores, { status: 'pending' })

        const recGroups = _.groupBy(recsListNoQueued, (rec) => {
          const diagnosis = Object.keys(SCREENING_RULE_UUIDS).indexOf(rec.rule.uuid) !== -1 ? 'Screenings' : rec.rule.snomed_diagnosis_name || 'Health Maintenance'
          return diagnosis
        })

        // Object elements are sorted by order in which they were created.
        // Want alphabetical order but with immunizations at top
        const sortedRecGroups = _.pick(recGroups, ['Screenings', 'Immunization'])
        Object.keys(_.omit(recGroups, 'Immunization')).sort().forEach((key) => {
          sortedRecGroups[key] = recGroups[key]
        })

        if (emr!=="athena") delete sortedRecGroups.Screenings

        const scoreComponents = scoresList.map((value) => (
          <Score key={`${value.name}-${value.id}`} formInfo={value} dismissScore={this.dismissScore} />
        ))

        if (scoresList.length > 0) {
          scoreGroupings = (
            <div style={{ margin: '0 0.5rem' }}>
              <h6 className="rec-page-title">Scores</h6>
              {scoreComponents}
              <br />
            </div>
          )
        }

        const ordersChecked = recsList.filter((rec) => (
          rec.checked === true
          && rec.status === 'pending'
          && (rec.rule_type === 'add' || rec.rule_type === 'documentation')
        ))

        orderableCountUi = (
          <div className="col s12 right-align">
          {emr === 'athena' && (
            <button
              type="button"
              className="waves-effect waves-light btn blue-bkg multi-order-button"
              onClick={() => { this.orderAllChecked(ordersChecked) }}
              disabled={!ordersChecked.length}
            >
              {`Order (${ordersChecked.length})`}
            </button>
          )}
          </div>
        )

        recGroupings = _.map(sortedRecGroups, (value, key) => (
          <RecommendationsGroup
            key={key}
            emr={emr}
            recsList={value}
            diagnosisKey={key}
            updateOptions={updateOptions}
            updateRecStatus={updateRecStatus}
            addRecToRecsList={addRecToRecsList}
            removeRecommendation={removeRecommendation}
            encounter={encounter}
            patient={patient}
            updateRecCheckboxState={this.updateRecCheckboxState}
          />
        ))
      } else if (NoRecsEmr.includes(emr)) {
        recGroupings = (
          <p className="bold-text no-recs-text" style={{ paddingLeft: '1em' }}>There are no recommendations for this patient.</p>
        )
      }
    } else {
      recGroupings = <LoadingSkeleton />
    }

    if (patientSearchPage) {
      recsPageHeader = (
        <RecommendationsPageHeader
          header={header}
          organizationId={organizationId}
          emrDepartmentId={emrDepartmentId}
          emrPatientId={emrPatientId}
          emr={emr}
          encounter={encounter}
          updateEncounter={updateEncounter}
        />
      )
    }

    // display custom order box after loading finished
    if (!loading) {
      if (emr === 'athena') {
        customOrderBox = (
          <OrderAnything
            emrPatientId={emrPatientId}
            organizationId={organizationId}
            {...this.props}
          />
        )
      }
    }

    return (
      <div className="recs-list-container" style={{ paddingRight: '1.5%' }}>
        {recsPageHeader}
        <div>
          {orderableCountUi}
          {recGroupings}
        </div>
        {scoreGroupings}
        {customOrderBox}
      </div>
    )
  }
}

const renderRecommendation = (rec, {
  patient, encounter, emr, updateOptions, addRecToRecsList, updateRecStatus,
  removeRecommendation, updateRecCheckboxState,
}) => (
    <Recommendation
      key={rec.id}
      rec={rec}
      patient={patient}
      encounter={encounter}
      emr={emr}
      updateOptions={updateOptions}
      addRecToRecsList={addRecToRecsList}
      updateRecStatus={updateRecStatus}
      removeRecommendation={removeRecommendation}
      updateRecCheckboxState={updateRecCheckboxState}
      {...rec}
    />
  )

renderRecommendation.propTypes = {
  patient: PropTypes.shape({}).isRequired,
  encounter: PropTypes.shape({}).isRequired,
  emr: PropTypes.string.isRequired,
  updateOptions: PropTypes.shape({}).isRequired,
  addRecToRecsList: PropTypes.func.isRequired,
  updateRecStatus: PropTypes.func.isRequired,
  removeRecommendation: PropTypes.func.isRequired,
  updateRecCheckboxState: PropTypes.func.isRequired,
}

const renderRecommendations = ({ recsList, ...props }) => {
  return recsList.map((rec) => renderRecommendation(rec, props))
}

const renderScreenings = ({ recsList, patient, ...props }) => {
  const screeningData = Object.assign(
    {},
    ...recsList.map(({ rule: { uuid }, options }) => ({
      [uuid]: options.form_info,
    })),
  )

  if (props.emr === 'athena') {
    return <Screenings data={screeningData} patient={patient} />
  }
}

// functions for organizing Recommendations
const RecommendationsGroup = (props) => {
  const { diagnosisKey, patient: { age } } = props
  let recs = null
  if (diagnosisKey === 'Screenings') {
    if (age <= 12) return null
    recs = renderScreenings(props)
  } else {
    recs = renderRecommendations(props)
  }
  return (
    <div className="recommendation-card-container">
      <span className="rule-problem-group">{diagnosisKey}</span>
      {recs}
      <br />
    </div>
  )
}

RecommendationsGroup.propTypes = {
  diagnosisKey: PropTypes.string.isRequired,
}

RecommendationsList.propTypes = {
  recsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateRecsList: PropTypes.func.isRequired,
  id: PropTypes.string,
  rule: PropTypes.shape({
    name: PropTypes.string,
  }),
  removeRecommendation: PropTypes.func.isRequired,
  updateRecStatus: PropTypes.func.isRequired,
  patient: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    show_screening_ui: PropTypes.bool,
  }).isRequired,
  emr: PropTypes.string.isRequired,
  updateOptions: PropTypes.func.isRequired,
  addRecToRecsList: PropTypes.func.isRequired,
  encounter: PropTypes.shape({}),
  patientSearchPage: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  emrDepartmentId: PropTypes.string.isRequired,
  emrPatientId: PropTypes.string.isRequired,
  updateEncounter: PropTypes.func.isRequired,
}

RecommendationsList.defaultProps = {
  encounter: null,
  id: null,
  rule: null,
}
