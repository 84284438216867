import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

import DiagnosisOrders from '../reusableComponents/DiagnosisOrders.jsx'

const propTypes = {
  activeAppointment: PropTypes.object,
}

export default function PreviousAppointment({ activeAppointment }) {
  // encounter details for the previous appointment
  let encounter
  if (activeAppointment.past_encounter) {
    encounter = activeAppointment.past_encounter.details
  }

  let encounterOrders
  if (!_.isEmpty(encounter) && encounter.orders.length > 0) {
    encounterOrders = _.map(encounter.orders, (diagnosisOrders, i) => (
      <DiagnosisOrders
        key={diagnosisOrders.diagnosissnomed}
        diagnosisOrders={diagnosisOrders}
        index={i + 1}
      />
    ))
  } else {
    encounterOrders = <p>No orders could be found from the last patient encounter.</p>
  }

  let provider = ''
  let encounterTimeAgo
  if (!_.isEmpty(encounter)) {
    const firstName = encounter.providerfirstname
    const lastName = encounter.providerlastname
    if (firstName && lastName) { provider = ` with ${firstName} ${lastName}` }

    const encounterVisitName = encounter.encountervisitname || encounter.encountertype
    const encounterDate = moment(encounter.encounterdate, 'MM/DD/YYYY').fromNow()
    encounterTimeAgo = (
      <div>
        <b>{`${encounterVisitName}`}</b>
        {' was '}
        <b>{`${encounterDate}`}</b>
      </div>
    )
  }

  return (
    <div style={{ paddingTop: '10px', paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
      <div className="last-visit bold-text" style={{ fontSize: '16px' }}>
        {'Last Patient Encounter' + provider}
      </div>
      <div style={{ fontSize: '13px' }}>
        {encounterTimeAgo}
      </div>
      {encounterOrders}
    </div>
  )
}

PreviousAppointment.propTypes = propTypes
