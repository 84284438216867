import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Grid } from '@mui/material'

const propTypes = {
  description: PropTypes.string,
  updateDescription: PropTypes.func.isRequired,
  ordersEnabledUi: PropTypes.bool.isRequired,
}

const defaultProps = {
  description: '',
}

const Description = ({ description, updateDescription, ordersEnabledUi }) => {
  const [editing, setEditing] = useState(false)
  const [editableDescription, setEditableDescription] = useState(description)
  const textarea = useRef(null)

  const toggleAndFocus = () => {
    if (ordersEnabledUi) {
      setEditing(true)
      setTimeout(() => textarea.current.focus(), 300)
    } else {
      setEditing(false)
    }
  }

  const handleClick = (event) => {
    if (!editing) {
      event.preventDefault()
      toggleAndFocus()
    }
  }

  const saveDescription = (desc) => {
    updateDescription(desc)
  }

  const autosave = useCallback(_.debounce((desc) => { saveDescription(desc) }, 1000), [])

  const handleBlur = () => {
    setEditing(false)
    saveDescription(editableDescription)
  }

  const handleChange = (event) => {
    setEditableDescription(event.target.value)
    // autosave(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !editing) {
      // User hit enter key
      event.preventDefault()
      toggleAndFocus()
    }
  }

  const content = editing ? (
    <textarea
      className="col s9 textarea"
      onBlur={handleBlur}
      value={editableDescription}
      onChange={handleChange}
      ref={textarea}
    />
  ) : (
    <div role="button" onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={0}>
      {editableDescription}
    </div>
  )

  return (
    <Grid
      container
      direction="row"
    >
      <Grid
        item
        xs={3}
      >
        <b>Description</b>
      </Grid>
      <Grid
        item
        xs={9}
      >
        { content }
      </Grid>
    </Grid>
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps
export default Description
