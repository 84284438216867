import React, { useState } from 'react'

export default function CareCenter() {
  const [file, setFile] = useState(undefined)
  const handleFileSelect = (event) => {
    setFile(event.target.files[0])
  }

  const handleFileSubmit = (event) => {
    const formData = new FormData()
    formData.append("file", file)
    event.preventDefault()
    $.ajax({
      method: 'post',
      url: '/root/care_center/care_center_csv',
      contentType: false,
      processData: false,
      data: formData,
      enctype: 'multipart/form-data'
    })
      .done(() => {
        flash.success('Care Center csv successfully imported', 50000)

      })
      .fail((error) => {
        flash.error(`Error importing your care center. ${error.responseJSON.errors}`, 50000)
        console.error(error)
      })
  }

  return (
    <>
      <form onSubmit={handleFileSubmit}>
        <h6>
          Care Center import(csv)
        </h6>
        <input
          type="file"
          id="file-select"
          accept=".csv"
          onChange={handleFileSelect}
        />
        <button type="submit">
          Upload
        </button>
      </form>
    </>
  )
}