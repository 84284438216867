import React from 'react'
import PropTypes from 'prop-types'
import bowser from 'bowser'
import EncounterStatusWidget from './statusWidget/EncounterStatusWidget.jsx'
import EncounterStatusWidgetIECompatible from './statusWidget/EncounterStatusWidgetIECompatible.jsx'

export default class RecommendationsPageHeader extends React.Component {

  componentDidMount() {
    if (this.props.emr === 'athena' || 'cerner') {
      $.post(
        '/refresh_encounter/',
        { emrPatientId: this.props.emrPatientId, organizationId: this.props.organizationId }
      ).done((response) => {
        if (response.encounter) {
          this.props.updateEncounter(response.encounter)
        }
      })
    }
  }

  render() {
    let encounterWidget
    if (this.props.emr === 'athena') {
      if (bowser.chrome || bowser.safari) {
        encounterWidget = <EncounterStatusWidget {...this.props} />
      } else {
        encounterWidget = <EncounterStatusWidgetIECompatible {...this.props} />
      }
    }

    return (
      <div>
        <div className="row widget-container">
          {encounterWidget}
        </div>
      </div>
    )
  }
}

RecommendationsPageHeader.propTypes = {
  emr: PropTypes.string,
  header: PropTypes.string,
  updateEncounter: PropTypes.func,
  emrPatientId: PropTypes.string,
  organizationId: PropTypes.string,
  emrDepartmentId: PropTypes.string,
}
