import NativeListener from 'react-native-listener'
import React, { useState } from 'react'
import { func, number, bool } from 'prop-types'
import { path } from 'ramda'

// eslint-disable-next-line import/no-cycle
import { RuleContext } from './rulesList'

import AthenaOrderPrefTypeahead from '../../reusableComponents/AthenaOrderPrefTypeahead'
import DiagnosisTypeahead from '../../reusableComponents/DiagnosisTypeahead'
import OrderingModeDropdown from '../../reusableComponents/OrderingModeDropdown'
import { Rule as RuleType, Styles, ID } from '../../athenaTypes'
import Description from './Description'
import Action from './Action'

const propTypes = {
  rule: RuleType.isRequired,
  turnSubscriptionActiveSilent: func.isRequired,
  toggleStepTherapy: func.isRequired,
  style: Styles.isRequired,
  index: number.isRequired,
  orgId: ID.isRequired,
  toggleUIActive: func.isRequired,
  uiActive: bool,
  updateOrderPreferenceFirstOrderName: func.isRequired,
  updateOrderPreferenceDiagnosis: func.isRequired,
  updateOrderPreferenceDescription: func.isRequired,
  updateOrderPreferenceAction: func.isRequired,
}

const defaultProps = {
  uiActive: false,
}

const styles = {
  collapsibleHeader: {
    lineHeight: '2em',
    padding: '1.5em 1em',
  },
  chipContainer: {
    paddingLeft: '0.5em',
  },
  chip: {
    backgroundColor: '#f2f2f2',
    fontSize: '12px',
  },
  ruleNameSpan: {
    paddingLeft: '1em',
    fontSize: '17px',
  },
  noPadding: { padding: '0' },
}

const Rule = ({
  rule,
  turnSubscriptionActiveSilent,
  toggleStepTherapy,
  updateOrderPreferenceFirstOrderName,
  updateOrderPreferenceDiagnosis,
  updateOrderPreferenceDescription,
  updateOrderPreferenceAction,
  style,
  index,
  orgId,
  toggleUIActive,
  uiActive,
}) => {
  const [orderDisplayText, setOrderDisplayText] = useState(
    'No follow up action is currently associated with this guideline'
  )
  const [diagnosisName, setDiagnosisName] = useState('')
  const { setSize } = React.useContext(RuleContext)
  const header = React.useRef()
  const body = React.useRef()

  const handleClick = () => {
    toggleUIActive(rule.id)
    setTimeout(() => {
      const headerHeight = header.current.getBoundingClientRect().height
      const bodyHeight = body.current.getBoundingClientRect().height
      const newHeight =
        bodyHeight > 100 ? headerHeight + bodyHeight + 20 : headerHeight
      setSize(index, newHeight)
    }, 300)
  }

  const updateOrderDisplayText = () => {
    let orderPrefName = path(
      ['order_preference', 'options', 'orders', 0, 'order_name'],
      rule
    )
    const orderPrefAction = path(['order_preference', 'action'], rule)
    if (!orderPrefName) {
      orderPrefName = path(
        ['order_preference', 'options', 'orders', 0, 'name'],
        rule
      )
    }
    if (orderPrefAction && !orderPrefName) {
      orderPrefName = orderPrefAction
    } else if (path(['order_preference', 'options', 'order_set'], rule)) {
      orderPrefName = path(['order_preference', 'options', 'name'], rule)
    } else if (rule.rule_type === 'documentation') {
      orderPrefName = orderPrefAction || rule.action
    }

    let newOrderDisplayText =
      'No follow up action is currently associated with this guideline'
    const orderingMode = path(
      ['order_preference', 'options', 'orders', 0, 'ordering_mode'],
      rule
    )
    if (orderingMode) {
      newOrderDisplayText = `${orderingMode} ${orderPrefName}`
    } else if (rule.rule_type === 'documentation') {
      newOrderDisplayText = orderPrefAction || rule.action
    } else if (orderPrefName) {
      newOrderDisplayText = `Order ${orderPrefName}`
    }

    setOrderDisplayText(newOrderDisplayText)
  }

  const updateDiagnosisName = (_, diagnosisNameObject) => {
    const newDiagnosisName = path(
      ['orders', 0, 'diagnosis_name'],
      diagnosisNameObject
    )
    setDiagnosisName(newDiagnosisName)
  }

  const updateDiagnosis = () => {
    const orderPreferenceDiagnosis = path(
      ['order_preference', 'options', 'diagnosis_name'],
      rule
    )
    let newDiagnosisName = rule.snomed_diagnosis_name
    if (orderPreferenceDiagnosis) {
      newDiagnosisName = orderPreferenceDiagnosis
    } else if (path(['order_preference', 'options', 'order_set'], rule)) {
      newDiagnosisName = 'order set diagnosis codes'
    } else {
      const diagnosisNameFromDb = path(
        ['order_preference', 'options', 'orders', 0, 'diagnosis_name'],
        rule
      )
      if (diagnosisNameFromDb) {
        newDiagnosisName = diagnosisNameFromDb
      }
    }

    setDiagnosisName(newDiagnosisName)
  }

  React.useEffect(() => {
    updateOrderDisplayText()
    updateDiagnosis()
  })

  const doToggleStepTherapy = (event) => {
    toggleStepTherapy(
      rule.id,
      rule.order_preference.id,
      event.target.checked,
      rule.order_preference.options
    )
  }

  const updateOrderPrefName = (_, newOrderPrefOption) => {
    let orderPrefName = path(['orders', 0, 'order_name'], newOrderPrefOption)
    if (path(['order_set'], newOrderPrefOption)) {
      orderPrefName = newOrderPrefOption.name
    }

    let newOrderDisplayText =
      'No follow up action is currently associated with this guideline'
    const orderingMode = path(
      ['orders', 0, 'ordering_mode'],
      newOrderPrefOption
    )
    if (orderingMode) {
      newOrderDisplayText = `${orderingMode} ${orderPrefName}`
    } else {
      newOrderDisplayText = `Order ${orderPrefName}`
    }

    setOrderDisplayText(newOrderDisplayText)
  }

  const handleSelect = ({ target }) => {
    const subscriptionId = path(['subscription', 'id'], rule)
    const activeSilent = target.value
    if (subscriptionId) {
      turnSubscriptionActiveSilent(subscriptionId, activeSilent)
    } else {
      console.error(
        `Unable to find subscription id for rule ${rule.id} in organization ${orgId}`
      )
    }
  }

  const buildDropdown = (orderPreference) => {
    const orderSet = path(['options', 'order_set'], orderPreference)
    const orderType = path(
      ['options', 'orders', 0, 'order_type'],
      orderPreference
    )
    if (!orderSet && orderType === 'vaccination') {
      return (
        <OrderingModeDropdown
          pref={orderPreference}
          dropdownOptions={['Prescribe', 'Administer']}
          updateOrderPref
          style={{ marginLeft: '11px' }}
          columns="4"
        />
      )
    }
    return null
  }

  const buildDiagnosisTypeahead = (orderPreference) => {
    const orderSet = path(['options', 'order_set'], orderPreference)
    if (orderPreference.options && !orderSet) {
      return (
        <DiagnosisTypeahead
          pref={orderPreference}
          rule={rule}
          updateOptions={updateDiagnosisName}
          updateOrderPref
          columns={12}
          updateOrderPreferenceDiagnosis={updateOrderPreferenceDiagnosis}
        />
      )
    }
    return (
      <div style={{ paddingTop: '4em' }}>
        The diagnosis order codes associated with this athenahealth order set
        will be used
      </div>
    )
  }

  const buildPreferenceComponent = (orderPreference) => {
    if (rule.rule_type === 'documentation' || !orderPreference) {
      return null
    }

    const orderingModeDropdown = buildDropdown(orderPreference)
    const diagnosisTypeahead = buildDiagnosisTypeahead(orderPreference)

    if (orderPreference.options) {
      return (
        <div>
          <div className="row">
            <div className="col s12">
              <h6>
                <b>Follow Up Action</b>
              </h6>
            </div>
            <div className="col s12">
              <AthenaOrderPrefTypeahead
                setOrderDisplayText={setOrderDisplayText}
                pref={orderPreference}
                updateOptions={updateOrderPrefName}
                updateOrderPref
                columns="12"
                updateOrderPreferenceFirstOrderName={updateOrderPreferenceFirstOrderName}
              />
            </div>
          </div>
          <div className="row">{orderingModeDropdown}</div>
          <div className="row">
            <div className="col s12" style={{ marginTop: '1em' }}>
              <h6>
                <b>Diagnosis</b>
              </h6>
            </div>
            <div className="col s12">{diagnosisTypeahead}</div>
          </div>
          <div className="row">
            <div className="s12">
              <div className="switch" style={{ marginBottom: '3em' }}>
                <label htmlFor={`rule${rule.id}`}>
                  <b>do not duplicate past meds</b>
                  <input
                    type="checkbox"
                    id={`rule${rule.id}`}
                    defaultChecked={rule.order_preference.step_therapy}
                    onChange={doToggleStepTherapy}
                  />
                  <span className="lever" />
                </label>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col s12">
          <h6>
            <b>Follow Up Action</b>
          </h6>
        </div>
        <div className="col s12">
          <p style={{ padding: '0.2rem' }}>
            No follow up action is currently associated with this guideline
          </p>
        </div>
      </div>
    )
  }

  const buildCurrentDiagnosisDisplay = () => {
    if (rule.rule_type !== 'remove') {
      return (
        <div
          className="col s12"
          style={{ padding: '0em 0em 1em 1em', lineHeight: '1.5em' }}
        >
          <span className="rule-action-subtitle-black">
            Orders using diagnosis code <b>{diagnosisName}</b>
          </span>
        </div>
      )
    }
    return null
  }

  const sourceTag = (
    <div className="chip" style={styles.chip}>
      {rule.source}
    </div>
  )

  const ruleTags = rule.tags.map((t) => (
    <div key={t.id} style={styles.chip} className="chip">
      {t.name}
    </div>
  ))

  const orderPreference = rule.order_preference
  const orderPreferenceComponent = buildPreferenceComponent(orderPreference)
  const currentDiagnosisDisplay = buildCurrentDiagnosisDisplay()

  return (
    <li
      className={`collection-item avatar recommendation-card ${
        uiActive && 'active'
      }`}
      style={style}
    >
      <div
        className="collapsible-header rule-header"
        style={styles.collapsibleHeader}
        ref={header}
        onClick={handleClick}
      >
        <div style={{ width: '100%' }}>
          <div className="col s9">
            <div className="row">
              <div
                className="col s12"
                style={{ paddingLeft: '1em', lineHeight: '1.5em' }}
              >
                <span className="bold-text">
                  {rule.default_display_code || rule.source}
                </span>
                <span style={styles.ruleNameSpan}>{rule.name}</span>
              </div>
              <div
                className="col s12"
                style={{ padding: '0.5em 0em .7em 1em', lineHeight: '1.5em' }}
              >
                <div className="rule-action-subtitle">
                  <b>{orderDisplayText}</b>
                  <div>
                    <a
                      data-method="delete"
                      href={`order_preferences/${rule.order_preference.id}`}
                    >
                      Remove Order
                    </a>
                  </div>
                </div>
              </div>
              {currentDiagnosisDisplay}
              <div
                className="col s12 tags-container"
                style={styles.chipContainer}
              >
                {ruleTags}
                {sourceTag}
              </div>
            </div>
          </div>
          <NativeListener stopClick>
            <div className="col s3" style={{ paddingTop: '1em' }}>
              <label htmlFor="active-state">Current Mode</label>
              <div className="input-field col s12">
                <select
                  id="active-state"
                  className="browser-default"
                  onChange={handleSelect}
                  defaultValue={
                    rule.subscription ? rule.subscription.active_silent : 'off'
                  }
                >
                  <option value="off">Off</option>
                  <option value="silent">Silent</option>
                  <option value="active">Active</option>
                </select>
              </div>
            </div>
          </NativeListener>
        </div>
      </div>
      <div className="collapsible-body" ref={body}>
        <div className="row rule-option" style={{ padding: '1em 1em 2em 1em' }}>
          <div className="col s12">
            <div className="row" style={{ padding: '1em 0em 0em 0.8em' }}>
              {orderPreferenceComponent}
              <div className="col m10">
                <Action
                  action={orderPreference.action || rule.action}
                  updateAction={(action) => {
                    $.ajax({
                      method: 'put',
                      url: `/admin/order_preferences/${orderPreference.id}`,
                      dataType: 'json',
                      contentType: 'application/json',
                      data: JSON.stringify({...orderPreference, action})
                    })
                      .done(()=> {
                        updateOrderPreferenceAction(orderPreference.id, action)
                      })
                      .fail((error) => {
                        flash.error('There was an issue updating the action')
                        console.log(error)
                      })
                  }}
                />
                <Description
                  description={
                    orderPreference.description || rule.description
                  }
                  updateDescription={(description) => {
                    $.ajax({
                      method: 'put',
                      url: `/admin/order_preferences/${orderPreference.id}`,
                      dataType: 'json',
                      contentType: 'application/json',
                      data: JSON.stringify({...orderPreference, description })
                    }).
                      done(() => {
                        updateOrderPreferenceDescription(orderPreference.id, description)
                      })
                      .fail((error) => {
                        flash.error('There was an issue updating the description')
                        console.log(error)
                      })
                  }}
                />
                <div className="row rule-option">
                  <div className="col s3">
                    <b>Rule UUID</b>
                  </div>
                  <div className="col s9">{rule.uuid}</div>
                </div>
                <a
                  href={rule.link}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={styles.noPadding}
                >
                  Link to rule source
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

Rule.propTypes = propTypes
Rule.defaultProps = defaultProps

export default Rule
