import React from 'react'
import PropTypes from 'prop-types'
import { Grid, ListItemText } from '@mui/material'



const RuleName = ({ name }) => {
  return (
    <Grid item>
      <ListItemText sx={{paddingLeft: '1em', fontSize: '17px'}} primary={name} />
    </Grid>
  )
}

RuleName.propTypes = {
  name: PropTypes.string.isRequired
}

export default RuleName
