import React from 'react'
import Recommendation from './Recommendation.jsx'
import AthenaOrderPrefTypeahead from '../reusableComponents/AthenaOrderPrefTypeahead.jsx'

export default class OrderAnythingForDiagnosis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderSet: false,
      orderName: '',
      config: { orders: [{}] }
    }
    this.disableSubmit = this.disableSubmit.bind(this)
    this.updateOrder = this.updateOrder.bind(this)
    this.submitOrder = this.submitOrder.bind(this)
    this.clearInput = this.clearInput.bind(this)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const newPatient = newProps.emrPatientId !== this.props.emrPatientId
    // otherwise will make patients call any time there are updates upstream
    if (newPatient) {
      this.getData(newProps)
    }
  }

  clearInput() {
    // clear typeahead input and re initialize state
    try {
      this.refs.orderPrefTypeahead.refs.typeaheadInput.setEntryText('')
      this.setState({
        orderSet: false,
        orderName: '',
        config: { orders: [{}] }
      })
    } catch (e) {
      console.error(e)
    }
  }

  updateOrder(_recId, newOptions) {
    // merge order information (ordertypeid, order_type, order_name)
    // and then set state to display the chosen order in UI
    if (newOptions.order_set) {
      const config = Object.assign(this.state.config, newOptions)
      const orderName = newOptions.name
      this.setState({ orderSet: true, orderName, config })
    } else {
      // so you dont overwrite diagnosis for non order sets
      const order = this.state.config.orders[0]
      Object.assign(order, newOptions.orders[0])
      // update order name and config
      const orderName = newOptions.orders[0].order_name
      const config = { orders: [order] }
      this.setState({ orderSet: false, orderName, config })
    }
  }

  orderConfigWithDiagnosis() {
    const order = this.state.config.orders[0]

    const { name, snomed } = this.props.plannedDiagnosis.diagnosis
    const diagnosisInfo = { diagnosis_name: name, diagnosis_code: snomed }

    // merge order option in state with diagnosis info in props without mutation
    const orderWithDiagnosis = Object.assign({}, order, diagnosisInfo)
    return { orders: [orderWithDiagnosis] }
  }

  submitOrder() {
    const params = {
      emr_patient_id: this.props.emrPatientId,
      organization_id: this.props.organizationId,
      orderConfig: this.orderConfigWithDiagnosis()
    }
    // make the call
    $.ajax({
      type: 'POST',
      url: '/orders/order_anything',
      data: JSON.stringify(params),
      dataType: 'json',
      contentType: 'application/json',
      success: (response) => {
        const orderStatus = response.order_status
        if (orderStatus === 'order queued' || orderStatus === 'order successful') {
          this.props.addRecToRecsList(response.rec.recommendation)
        }
        // clear typeahead and note field
        this.clearInput()
      },
      error: (error) => {
        if (checkTimeout(error) === true) {
          redirectToLogin()
        } else {
          const err = error.responseJSON.errors
          flash.error(
            err + '. Contact support@avhana.com if you need further assistance.'
          )
          console.error(error, { context: `OrderAnything error message: ${error.responseText}` })
        }
      },
    })
  }

  disableSubmit() {
    if (_.isEqual(this.state.config.orders, [{}])) {
      return true
    } else if (this.state.orderName === '') {
      return true
    } else {
      return false
    }
  }

  render() {
    const queuedComponents = this.props.queuedOrders.map(rec =>
      <Recommendation
        key={rec.id}
        rec={rec}
        patient={this.props.patient}
        emr={this.props.emr}
        encounter={this.props.encounter}
        undoOrder={this.props.undoOrder}
        updateOptions={this.props.updateOptions}
        updateRecStatus={this.props.updateRecStatus}
        addRecToRecsList={this.props.addRecToRecsList}
        removeRecommendation={this.props.removeRecommendation}
        {...rec}
      />
    )

    return (
      <div className="row" style={{ marginBottom: '0px' }}>
        <div style={{ paddingBottom: '0.5em' }} className="col s12">
          <span className="bold-text">Place a custom order for this diagnosis</span>
        </div>
        <div className="col s12">
          <AthenaOrderPrefTypeahead
            ref="orderPrefTypeahead"
            updateOptions={this.updateOrder}
            submitOrder={this.submitOrder}
            pref={{ options: { orders: [{}] } }}
            rec={{ options: { orders: [{}] } }}
            updateOrderPref={false}
            orderAnything={true}
            style={{ paddingLeft: '0px' }}
            columns={'12'}
          />
        </div>
        <div className="col s12">{queuedComponents}</div>
      </div>
    )
  }
}
