import React from 'react'
import PropTypes from 'prop-types'
import NativeListener from 'react-native-listener'
import MarkdownParser from '../../reusableComponents/MarkdownParser.jsx'
import ChadScore from '../cards/cardBodyTypes/ChadScore.jsx'
import AscvdScore from '../cards/cardBodyTypes/AscvdScore.jsx'
import FraminghamScore from '../cards/cardBodyTypes/FraminghamScore.jsx'
import HasBledScore from '../cards/cardBodyTypes/HasBledScore.jsx'
import Ttr from '../cards/cardBodyTypes/Ttr.jsx'
import Das28 from '../cards/cardBodyTypes/Das28.jsx'

const getScoreText = (data) => {
  const partialPath = data.rule.partial_path
  const formInfo = data.options.form_info
  switch (partialPath) {
    case 'framingham_score':
      return `LDL Risk ${formInfo.ldl_score}%, Total Cholesterol Risk ${formInfo.chol_score}%`
    case 'chad':
      return data.options.diagnosis_code
    default:
      return (formInfo && formInfo.score) || ''
  }
}

const renderScorePartial = (context) => {
  const data = context.props.formInfo
  const partialPath = data.rule.partial_path
  switch (partialPath) {
    case 'chad':
      return (
        <ChadScore
          key={data.id}
          updateParentScoreState={context.updateParentScoreState}
          {...data}
        />
      )
    case 'ascvd_score':
      return (
        <AscvdScore
          key={data.id}
          updateParentScoreState={context.updateParentScoreState}
          {...data}
        />
      )
    case 'framingham_score':
      return <FraminghamScore key={data.id} {...data} />
    case 'has_bled':
      return (
        <HasBledScore
          key={data.id}
          updateParentScoreState={context.updateParentScoreState}
          {...data}
        />
      )
    case 'ttr':
      return <Ttr key={data.id} {...data} />
    case 'das28':
      return (
        <Das28
          key={data.id}
          updateParentScoreState={context.updateParentScoreState}
          {...data}
        />
      )
    default:
      return <MarkdownParser markdown={data.rule.description} />
  }
}
export default class Score extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      score: undefined,
    }

    this.updateParentScoreState = this.updateParentScoreState.bind(this)
    this.dismissScore = this.dismissScore.bind(this)
  }

  componentDidMount() {
    const { formInfo } = this.props
    const score = getScoreText(formInfo)
    this.setState({ score })
  }

  updateParentScoreState(score) {
    this.setState({ score })
  }

  dismissScore() {
    this.props.dismissScore(this.props.formInfo)
  }

  render() {
    const context = this
    const scorePartial = renderScorePartial(context)

    return (
      <ul className="collapsible collapsible-accordion">
        <li className="collection-item avatar recommendation-card">
          <div className="collapsible-header collapsible-header-padding">
            <div className="row col s12">
              <span className="col s9 score-title-container">
                <div className="row decrease-line-height-more">
                  <span className="col s12 rule-action-title-container">
                    <a className="rule-action-title display-inline-text">
                      {`${this.props.formInfo.rule.name}: `}
                      <span
                        className="bold-text score-display-card-header"
                      >
                        {this.state.score}
                      </span>
                    </a>
                    <span className="display-inline-text rec-card-link-container">
                      <a className="rec-card-link display-inline-text">See why</a>
                    </span>
                  </span>
                </div>
              </span>
              <span className="col s3">
                <div>
                  <span className="col s12 custom-dismiss-container flex align-center">
                    <NativeListener stopClick onClick={this.dismissScore}>
                      <a className="right custom-dismiss-button-centered-vertical">Hide</a>
                    </NativeListener>
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className="collapsible-body">
            <div className="body-container">
              <div className="score-container">
                {scorePartial}
              </div>
            </div>
          </div>
        </li>
      </ul>
    )
  }
}

Score.propTypes = {
  formInfo: PropTypes.shape({
    rule: PropTypes.shape({
      name: PropTypes.string,
      partial_path: PropTypes.string,
    }),
    id: PropTypes.number,
    score: PropTypes.number,
    ldl_score: PropTypes.number,
    chol_score: PropTypes.number,
    options: PropTypes.shape({
      diagnosis_code: PropTypes.string,
    }),
  }).isRequired,
  dismissScore: PropTypes.func.isRequired,
}
