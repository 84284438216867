import React from 'react'
import NativeListener from 'react-native-listener'
import determineQueuedRecTitle from '../helperFunctions/determineQueuedRecTitle.js'

const determineQueuedHeader = (props) => {
  let recOptions
  if (props.rec) {
    recOptions = props.rec.options
  }

  let diagnosisName
  // If we set diagnosis options (in card or otherwise) use it, else fall back
  // to defaults on the rule
  if (recOptions.order_set) {
    diagnosisName = 'Uses diagnosis codes associated with order set'
  } else if (props.options.orders) {
    diagnosisName = props.options.orders[0].diagnosis_name
  } else {
    diagnosisName = props.options.diagnosis_name
  }

  const title = determineQueuedRecTitle(props.rec)

  const cancelOrderButton = (
    <div className="center-align">
      <span className="col s12 custom-dismiss-container valign-wrapper center-align">
        <NativeListener stopClick onClick={() => props.undoOrder(props.rec)}>
          <a className="waves-effect waves-light">
            Undo Order
          </a>
        </NativeListener>
      </span>
    </div>
  )

  return {
    title,
    diagnosis: diagnosisName || 'Health Maintenance',
    actionSection: cancelOrderButton,
  }
}

export default function QueuedOrder(props) {
  const headerInfo = determineQueuedHeader(props)

  return (
    <div className="row" style={{ marginBottom: '0px' }}>
      <div className="col s8">
        <p style={{ fontSize: '15px' }}><b>{`${headerInfo.title}`}</b>{`, ${headerInfo.diagnosis}`}</p>
      </div>
      <div className="col s4">
        {headerInfo.actionSection}
      </div>
    </div>
  )
}
