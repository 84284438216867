import React from 'react'
import { Grid, ListItemText } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

const StyledListItemText = styled(ListItemText)(() => ({
  primary: {
    fontWeight: 'bold',
  }
}))

const RuleSource = ({ source }) => (
  <Grid item>
    <StyledListItemText primary={source} />
  </Grid>
)

RuleSource.propTypes = {
  source: PropTypes.string.isRequired
}

export default RuleSource
