window.initializeRuleToggle =  function initializeRuleToggle() {
  $('.rule-toggle-activate').change((event) => {
    event.stopPropagation()
    const ruleId = event.target.value
    const { checked } = event.target
    const ruleState = checked ? 'is now active' : 'is now inactive'

    $.ajax({
      method: 'put',
      url: `/root/rules/${ruleId}/turn_on_off_rule`,
      cache: false,
      data: { active: checked },
      done: (response) => {
        flash.success(`${response.message} ${ruleState}`, 3000)
      },
      fail: (error) => {
        if (checkTimeout(error) === true) {
          redirectToLogin()
        } else {
          flash.error('There was an error updating the subscription')
          console.error(error.statusText, error.responseText)
        }
      },
    })
  })
}
