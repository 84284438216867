import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardHeader from './cards/CardHeader'
import CardBody from './cards/CardBody'
import QueuedOrder from './QueuedOrder'

const propTypes = {
  id: PropTypes.number.isRequired,
  rec: PropTypes.object.isRequired,
  emr: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  patient: PropTypes.object.isRequired,
  rule: PropTypes.object.isRequired,
  order_preference: PropTypes.object.isRequired,
  encounter: PropTypes.object,
  options: PropTypes.object,
  updateOptions: PropTypes.func,
  updateRecStatus: PropTypes.func,
  addRecToRecsList: PropTypes.func,
  removeRecommendation: PropTypes.func,
  undoOrder: PropTypes.func,
}

export default class Recommendation extends Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: 'Description', orderSent: false }
    this.selectTab = this.selectTab.bind(this)
    this.updateOptions = this.updateOptions.bind(this)
    this.sendOrder = this.sendOrder.bind(this)
    this.removeOrder = this.removeOrder.bind(this)
    this.dismissRec = this.dismissRec.bind(this)
    this.submittedToEhr = this.submittedToEhr.bind(this)
  }

  selectTab(clickedTab) {
    this.setState({ activeTab: clickedTab })
  }

  updateOptions(recId, newOptions) {
    this.props.updateOptions(recId, newOptions)
  }

  sendOrder() {
    if (this.state.orderSent === false) {
      this.setState({ orderSent: true })

      const recOptions = this.props.rec.options

      const recId = this.props.id

      const ordersExist = recOptions.orders ? true : false
      if (ordersExist) {
        $.ajax({
          url: '/orders/send',
          method: 'POST',
          data: { recId: recId },
          success: response => {
            const orderStatus = response.order_status
            if (orderStatus === 'order queued') {
              this.props.updateRecStatus(parseInt(recId), 'order queued')
              // TODO figure out React animation for queued orders
              // use scrollTop jquery module to show user order queued at top of page
              $('main').animate({ scrollTop: 0 }, 1000)
            } else if (orderStatus === 'order successful') {
              this.props.removeRecommendation(parseInt(recId))
            }
          },
          error: error => {
            if (checkTimeout(error) === true) {
              redirectToLogin()
            } else {
              console.error(error, { name: 'ordering error', message: 'Recommendation.jsx sendOrder(), Error sending order', recId })
              this.setState({ orderSent: false })
              flash.error(
                'There was an error submitting the order. Contact support@avhana.com if you need further assistance.'
              )
            }
          },
        })
      } else {
        this.setState({ orderSent: false })
        flash.error(
          'No order preference has been set. Please configure an order to send to the EMR.')
      }
    } else {
      flash.error('Order request has already been sent.')
    }
  }

  removeOrder() {
    if (this.props.options.form_info) {

      const canSendBack = this.props.options.form_info.code
      if (canSendBack) {

        if (this.state.orderSent === false) {
          this.setState({ orderSent: true })

          const recId = this.props.id
          const orderInfo = { recId: recId }

          const orderName = this.props.options.form_info.name

          $.post('/orders/remove', orderInfo)
            .done(
              response => {
                this.props.removeRecommendation(parseInt(recId))
                flash.success(`${orderName} marked as denied`, 50000) // previously had 3000 second timeout
              }
            ).fail(
              error => {
                console.error(error, { context: 'Recommendation.jsx sendOrder() Error removing order' })
                this.setState({ orderSent: false })
                _.forEach(error.errors.responseJSON.errors, err => {
                  flash.error(
                    `${_.capitalize(err)} Contact support@avhana.com if you need further assistance.`
                  )
                })
              }
            )
        } else {
          flash.error('Remove order request has already been sent.')
        }
      } else {
        flash.error('We cannot remove this order at this time.')
      }
    } else {
      flash.error('We cannot remove this type of order at this time.')
    }
  }

  dismissRec(title, reason) {
    const recId = parseInt(this.props.id);
    const data = { recId, reason }
    $.ajax({
      url: `/recommendations/dismiss/${recId}`,
      dataType: 'json',
      data: data,
      method: 'PUT',
      success: response => {
        this.props.removeRecommendation(recId);
        if (reason === "It's incorrect for this patient") {
          flash.error("Please add exclusion criteria to the patient's chart")
        } else if (reason === "It's already been satisfied for this patient") {
          flash.error("Please verify the intervention or measure is properly documented on the chart")
        } else {
          flash.success("Hidden!", 50000)
        }
      },
    })
  }

  submittedToEhr(title) {
    const recId = parseInt(this.props.id);
    $.ajax({
      url: `/recommendations/submit_to_ehr/${recId}`,
      method: 'PUT',
      success: response => {
        this.props.removeRecommendation(recId);
        flash.success(`Submitted: ${title}`, 50000) // previously had 3000 second timeout
      },
    })
  }

  render() {
    let recommendation
    if (this.props.status === 'order queued') {
      recommendation =
        <QueuedOrder
          selectTab={this.selectTab}
          sendOrder={this.sendOrder}
          removeOrder={this.removeOrder}
          undoOrder={this.props.undoOrder}
          dismissRec={this.dismissRec}
          {...this.props}
        />
    } else {
      recommendation =
        <ul className="collapsible collapsible-accordion">
          <li className="collection-item avatar recommendation-card">
            <CardHeader
              selectTab={this.selectTab}
              sendOrder={this.sendOrder}
              removeOrder={this.removeOrder}
              dismissRec={this.dismissRec}
              updateRecCheckboxState={this.props.updateRecCheckboxState}
              noActionButtons={this.props.noActionButtons}
              {...this.props}
            />
            <CardBody
              updateOptions={this.updateOptions}
              selectTab={this.selectTab}
              activeTab={this.state.activeTab}
              sendOrder={this.sendOrder}
              submittedToEhr={this.submittedToEhr}
              noActionButtons={this.props.noActionButtons}
              {...this.props}
            />
          </li>
        </ul>
    }

    return (
      <div>
        {recommendation}
      </div>
    )
  }
}

Recommendation.propTypes = propTypes
