import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, FormControlLabel, Switch } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = {
  thumb: {
    "&:before": {
      content: "none !important",
    },
    height: "18px !important",
    width: "18px !important",
    paddingLeft: "0 !important"
  }
}

const StyledSwitch = withStyles(styles)(Switch)

const DuplicateMedicationToggle = ({ checked, onChange }) => (
  <FormGroup row>
    <FormControlLabel
      control={(
        <StyledSwitch 
          checked={checked}
          onChange={onChange}
          name="stepTherapyToggle"
          color="primary"
          inputProps={{ 'aria-label': 'Step Therapy Toggle'}}
        />
      )}
      label="do not duplicate past meds"
      labelPlacement="end"
    />
  </FormGroup>
)

DuplicateMedicationToggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default DuplicateMedicationToggle
