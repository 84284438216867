// global guid

import React from 'react'
import ReactDOM from 'react-dom'
import jQuery from 'jquery'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT ;domain=.avhana.com;path=/`
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT ;`
}

const getFlashFromCookie = () => {
  let data = null
  if (document.cookie && document.cookie.search('flash') !== -1) {
    const cookies = document.cookie.split(';')
    const name = 'flash'
    let cookieValue = null

    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = jQuery.trim(cookies[i])
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        // fixes problems with Rails escaping.
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1).replace(/\+/g, '%20'))
        if (cookieValue.length > 0) break // there might be empty "flash=" cookies
      }
    }
    if (cookieValue) { deleteCookie('flash') }
    try {
      data = JSON.parse(cookieValue)
    } catch (e) {
      console.error(e)
    }
  }

  return data
}

class FlashMessages extends React.Component {
  constructor(props) {
    super(props)
    this.state = { messages: [] }
  }

  componentDidMount() {
    window.flash = this
    $(document).on('ready page:change', this.handleFlashMessagesHeader.bind(this))
    $(document).on("ajax:complete", () => {
      this.handleFlashMessagesHeader()
    })
  }

  error(message, timeout) {
    return this.showMessage('error', message, timeout)
  }

  success(message, timeout) {
    return this.showMessage('success', message, timeout)
  }

  notice(message, timeout) {
    return this.showMessage('notice', message, timeout)
  }

  alert(message, timeout) {
    return this.showMessage('alert', message, timeout)
  }

  showMessage(type, message, timeout) {
    const flashMessage = { type, message, timeout, id: guid() }
    const flashTimeout = timeout || 10000
    this.setState({ messages: [flashMessage] })
    setTimeout(() => { this.setState({ messages: [] }) }, flashTimeout)
    return flashMessage
  }

  handleFlashMessagesHeader() {
    const jsonMessages = getFlashFromCookie()
    if (jsonMessages) {
      jsonMessages.forEach((messageHash) => {
        const type = messageHash[0]
        const message = messageHash[1]
        this.showMessage(type, message)
      })
    }
  }

  dismissMessage(message) {
    const { messages } = this.state
    _.remove(messages, message)
    deleteCookie('flash')
    this.setState({ messages })
  }

  render() {
    const { messages } = this.state
    const flashMessages = messages.map((flash, index) => (
      <CSSTransition
        classNames="flashFade"
        timeout={{ enter: 500, exit: 300 }}
        key={index}
      >
        <div key={index} className={`flash row center-align ${flash.type}`}>
          <div className="col s10 offset-s1 center-align">{flash.message}</div>
          <div className="col s1">
            <i
              key={index}
              className="material-icons close"
              onClick={() => this.dismissMessage(flash)}
            >
              clear
            </i>
          </div>
        </div>
      </CSSTransition>
    ))

    return (
      <div className="flashContainer">
        <TransitionGroup>
          {flashMessages}
        </TransitionGroup>
      </div>
    )
  }
}

const documentReady = () => {
  const reactNode = document.getElementById('flash_messages')
  if (reactNode) {
    ReactDOM.render(<FlashMessages />, reactNode)
  }
}

$(documentReady)
