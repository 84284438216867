import React from 'react'
import Joyride from 'react-joyride'
import moment from 'moment'
import { steps } from './steps'

export default class PreVisitWalkthrough extends React.Component {
  componentDidUpdate() {
    // autostart tour on page load
    this.refs.joyride.start(true)
  }

  joyrideCallback(data) {
    try {
      if (data.type === 'finished') {
        // store if tour finished so we don't show again in same session
        sessionStorage.setItem('finishedTour', 'true')
      }
    } catch (error) {
      console.error(error, { context: 'joyride callback failure' })
    }
  }

  render() {
    return (
      <div>
        <Joyride
          ref="joyride"
          debug={false}
          steps={steps}
          type="continuous"
          autoStart
          run
          showStepsProgress
          allowClicksThruHole
          showSkipButton
          callback={this.joyrideCallback}
        />
      </div>
    )
  }
}
