// Inspired by http://papermashup.com/jquery-show-hide-plugin

(function($) {
  // pass in the class (".myClass") or id ("#myId") of element
  // as selector to show/hide
  $.fn.showHide = function(selector) {
    // hide by default
    $(selector).hide();

    // default vars for the plugin
    var defaults = {
      speed: "400",
      showText: "Show",
      hideText: "Hide"
    };
    var options = $.extend(defaults, options);

    $(this).click(function() {
      // this var stores which button you've clicked
      var toggleClick = $(this);
      // this reads the target div class to determine what to show/hide
      var toggleDiv = $(selector);
      // check if shown or hidden
      var isVisible  = $(toggleDiv).is(":visible");
      // here we show/hide the correct div
      if (isVisible === true) {
        toggleDiv.hide(options.speed);
        toggleClick.text(options.showText);
      } else {
        toggleDiv.show(options.speed);
        toggleClick.text(options.hideText);
      }

      return false;
    });
  };
})(jQuery);
