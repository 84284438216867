import React from 'react'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-textarea-autosize'
import moment from 'moment'
import MarkdownParser from '../reusableComponents/MarkdownParser.jsx'

const Item = ({ entity: { shortcut, expansion }}) => <div><b>{shortcut}</b>: {expansion}</div>
const Loading = ({ data }) => <div>Loading</div>

const ResizeTextArea = React.forwardRef((props, ref) => (
  <TextareaAutosize inputRef={ref} {...props} />
))

export default function DashboardDiagnosisNote(props) {
  const diagnosis = props.plannedDiagnosis.diagnosis

  const previousProblemListNote =
    diagnosis.problem_list_note || 'No problem list note could be found'

  const pastEncounter = props.pastEncounter || {}
  const pastEncounterDetails = pastEncounter.details || {}
  const pastEncounterDiagnoses = pastEncounterDetails.diagnoses || []
  const matchingPastDiagnosis = pastEncounterDiagnoses.find(
    d => d.snomedcode === diagnosis.snomed
  ) || {}
  const previousEncounterNote =
    matchingPastDiagnosis.note || 'No prior encounter note could be found'

  const noteDisplaySection = determineNoteDisplaySection(props)

  return (
    <div className="row">
      <div className="row">
        <div className="col s12">
          <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Problem List</b>
        </div>
        <div className="col s12 no-left-padding-p-tag">
          <MarkdownParser markdown={previousProblemListNote} />
        </div>
        <div className="col s12">
          <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Previous Encounter</b>
        </div>
        <div className="col s12 no-left-padding-p-tag">
          <MarkdownParser markdown={previousEncounterNote} />
        </div>
        <div className="col s12" style={{ paddingTop: '1.5em' }}>
          <b style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Add a Note</b>
        </div>
        <div className="col s12">{noteDisplaySection}</div>
      </div>
    </div>
  )
}

const determineNoteDisplaySection = ({ plannedDiagnosis, ...props }) => {
  let noteDisplaySection
  if (plannedDiagnosis.status === 'diagnosis sent') {
    const sentTimestamp = moment(plannedDiagnosis.updated_at).format('MM/D/YYYY')
    noteDisplaySection = (
      <div>
        <div style={{ fontSize: '14px', paddingTop: '1.5em' }}>
          {plannedDiagnosis.note}
        </div>
        <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {`sent to the EHR on ${sentTimestamp}`}
        </div>
      </div>
    )
  } else if (plannedDiagnosis.status === 'diagnosis queued') {
    noteDisplaySection = (
      <div>
        <div style={{ fontSize: '14px', paddingTop: '1.5em' }}>
          {plannedDiagnosis.note}
        </div>
        <div style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {`sending to patient's next appointment with ${plannedDiagnosis.sending_to_provider}`}
        </div>
      </div>
    )
  } else {
    const { currentUser: { text_macros: textMacros } } = props
    const ua = window.navigator.userAgent
    const usingIE = ua.indexOf('MSIE') > 0 || ua.indexOf('Trident') > 0
    noteDisplaySection =
      usingIE ? (
        <textarea
          className="materialize-textarea browser-default"
          placeholder="Enter a diagnosis note for your next encounter"
          value={props.note || ''}
          onChange={event => props.handleTextBoxChanges(event)}
        />) : (
      <ReactTextareaAutocomplete
        className="materialize-textarea browser-default"
        trigger={{
          '.': {
            dataProvider: token => textMacros
              .filter(t => t.shortcut.startsWith(token))
              .map(({ shortcut, expansion }) => ({ shortcut, expansion }))
              .slice(0, 5),
            component: Item,
            output: item => item.expansion,
            allowWhitespace: true,
          },
        }}
        textAreaComponent={ResizeTextArea}
        loadingComponent={Loading}
        placeholder="Enter a diagnosis note for your next encounter"
        value={props.note || ''}
        onChange={event => props.handleTextBoxChanges(event)}
        itemStyle={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          cursor: 'pointer',
        }}
        minChar={2}
      />
    )
  }
  return noteDisplaySection
}

DashboardDiagnosisNote.propTypes = {
  plannedDiagnosis: PropTypes.shape({
    diagnosis: PropTypes.shape({
      problem_list_note: PropTypes.string,
      snomed: PropTypes.string,
    }),
    status: PropTypes.string,
    updated_at: PropTypes.string,
    note: PropTypes.string,
  })
}
