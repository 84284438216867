import NativeListener from 'react-native-listener'
import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'

import determineQueuedRecTitle from '../helperFunctions/determineQueuedRecTitle.js'

const propTypes = {
  queuedRecsList: PropTypes.array,
  sentRecsList: PropTypes.array,
  queuedPlannedDiagnosesList: PropTypes.array,
  undoOrders: PropTypes.func,
  todayOpenEncounter: PropTypes.object,
}

export default function NextEncounterPlan(props) {
  const { queuedRecsList, sentRecsList, queuedPlannedDiagnosesList, undoOrders, todayOpenEncounter } = props

  // combine diagnoses that are queued with diagnoses that have queued recs
  // and create object with keys representing diagnoses with pre-planning/queueing
  const recsListToUse = todayOpenEncounter ? sentRecsList : queuedRecsList
  const groupedRecs = _.groupBy(recsListToUse, rec => getDiagnosisName(rec))
  const queuedPlannedDx = queuedPlannedDiagnosesList.reduce(
    (o, pd) => ({ ...o, [pd.diagnosis.name]: [] }), {}
  )
  const diagnosesQueuedOrWithRecs = Object.assign(queuedPlannedDx, groupedRecs)

  const diagnosesEncounterPlan = _
    .chain(diagnosesQueuedOrWithRecs)
    .toPairs()
    .sortBy(([diagnosisName, _recs]) => diagnosisName)
    .map(([diagnosisName, recs]) => formatForDisplay(
      diagnosisName,
      queuedPlannedDiagnosesList,
      recs,
      undoOrders,
      todayOpenEncounter
    )).value()

  const orderingStatus = todayOpenEncounter ? 'ordered' : 'queued'
  const diagnosesEncounterPlanDisplay = diagnosesEncounterPlan.length > 0 ? diagnosesEncounterPlan : <p>{`No documentation ${orderingStatus}`}</p>

  const encounterPlanHeading = todayOpenEncounter ? 'Current Encounter Plan (checked in)' : 'Plan for Next Encounter'

  return (
    <div className="collapsible next-encounter-plan">
      <div className="minichart-queued-orders bold-text" style={{ fontSize: '16px' }}>
        {encounterPlanHeading}
      </div>
      <div>
        <ul id="diagnoses-encounter-plan" style={{ marginTop: '2px' }}>
          {diagnosesEncounterPlanDisplay}
        </ul>
      </div>
    </div>
  )
}

const getDiagnosisName = (rec) => {
  let diagnosisName
  if (rec.options.orders) {
    diagnosisName = rec.options.orders[0].diagnosis_name || 'No Diagnosis'
  } else {
    // handles non-add aka documentation, follow up, no action rule types
    diagnosisName = _.startCase(rec.rule_type)
  }
  return diagnosisName
}

const formatForDisplay = (diagnosisName, queuedPlannedDiagnosesList, recs, undoOrders, todayOpenEncounter) => {
  const sortedRecs = _.sortBy(recs, rec => moment(rec.updated_at)).reverse()

  const sortedRecsDisplay = _.map(sortedRecs, (rec, index) => {
    const queuedRecTitle = determineQueuedRecTitle(rec)
    return (<li key={index}>{`- ${queuedRecTitle}`}</li>)
  })


  let undoOrdersByDiagnosis
  if (!todayOpenEncounter) {
    undoOrdersByDiagnosis = recs.length > 0 ? (
      <span>
        <NativeListener
          key={recs.map(r => r.id).join(_)}
          stopClick
          onClick={() => undoOrders(recs)}
        >
          <a href="#" className="waves-effect waves-light order-el">
            (Undo Orders)
          </a>
        </NativeListener>
      </span>
    ) : ''
  }

  const queuedPlannedDiagnosis = queuedPlannedDiagnosesList.find(
    plannedDx => plannedDx.diagnosis.name === diagnosisName
  )

  const encounterNoteForDiagnosis =
    queuedPlannedDiagnosis ? queuedPlannedDiagnosis.note : ''

  return (
    <li key={diagnosisName} className="order-diagnosis-el" style={{ paddingBottom: '0.5em' }}>
      <b>{diagnosisName} {undoOrdersByDiagnosis}</b>
      <li className="truncate">{encounterNoteForDiagnosis}</li>
      <ul>{sortedRecsDisplay}</ul>
    </li>
  )
}

NextEncounterPlan.propTypes = propTypes
