import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DashboardDiagnosis from './DashboardDiagnosis'
import SuspectedDiagnosis from './SuspectedDiagnosis'

const formatForDisplay = (plannedDiagnosis, groupedQueuedOrders, props) => {
  // lookup queued orders for planned diagnosis and sort
  const queuedOrders = groupedQueuedOrders[plannedDiagnosis.diagnosis.name] || []
  const sortedQueuedOrders = _.sortBy(
    queuedOrders,
    (rec) => moment(rec.updated_at),
  ).reverse()

  const {
    activeAppointment,
    addRecToRecsList,
    currentUser,
    emr,
    encounter,
    patient,
    removeRecommendation,
    undoOrder,
    updateOptions,
    updateRecStatus,
    updatePlannedDiagnosis,
  } = props

  return (
    <div key={plannedDiagnosis.id}>
      <DashboardDiagnosis
        plannedDiagnosis={plannedDiagnosis}
        activeAppointment={activeAppointment}
        updatePlannedDiagnosis={updatePlannedDiagnosis}
        queuedOrders={sortedQueuedOrders}
        // props below are all needed to render queued order <Recommendation />
        emr={emr}
        patient={patient}
        encounter={encounter}
        undoOrder={undoOrder}
        updateOptions={updateOptions}
        updateRecStatus={updateRecStatus}
        removeRecommendation={removeRecommendation}
        addRecToRecsList={addRecToRecsList} // also used in <OrderAnythingForDiagnosis />
        currentUser={currentUser}
      />
    </div>
  )
}

formatForDisplay.propTypes = {
  activeAppointment: PropTypes.shape().isRequired,
  addRecToRecsList: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  emr: PropTypes.string.isRequired,
  encounter: PropTypes.shape().isRequired,
  patient: PropTypes.shape().isRequired,
  removeRecommendation: PropTypes.func.isRequired,
  undoOrder: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  updatePlannedDiagnosis: PropTypes.func.isRequired,
  updateRecStatus: PropTypes.func.isRequired,
}

const determineDiagnosisList = ({ plannedDiagnosesList, queuedOrdersList, ...props }) => {
  // set up dictionary mapping diagnosis name to queued orders for that diagnosis name
  const groupedQueuedOrders = _.groupBy(
    queuedOrdersList,
    (rec) => rec.options.orders && rec.options.orders[0].diagnosis_name,
  )

  // filter out inactive, alphabetize, and finally format for display
  const diagnosisList = _(plannedDiagnosesList)
    .filter((pd) => pd.diagnosis && pd.diagnosis.active)
    .orderBy((pd) => [pd.diagnosis.last_encounter_date, pd.diagnosis.start_date, pd.diagnosis.name], ['desc', 'desc', 'asc'])
    .map((plannedDiagnosis) => formatForDisplay(
      plannedDiagnosis,
      groupedQueuedOrders,
      props,
    ))
    .value()

  return diagnosisList
}

const determineDiagnosisListDisplay = (props) => {
  let diagnosisListDisplay

  if (props.plannedDiagnosesList && props.plannedDiagnosesList[0] === 'reloading') {
    diagnosisListDisplay = <div>Reloading active problems for this patient</div>
  } else if (props.plannedDiagnosesList && props.plannedDiagnosesList.length > 0) {
    diagnosisListDisplay = determineDiagnosisList(props)
  } else {
    diagnosisListDisplay = <div>No active problems could be found for this patient</div>
  }

  return diagnosisListDisplay
}

class DashboardDiagnosisList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      reloaded: false,
    }
  }

  componentDidUpdate() {
    const { plannedDiagnosesList, updateActiveAppointment, activeAppointment } = this.props
    const { reloaded } = this.state
    const problemsListed = plannedDiagnosesList && plannedDiagnosesList.length > 0
    if (!problemsListed && !reloaded) {
      updateActiveAppointment(activeAppointment.id)
      this.setState({ reloaded: true })
    }
  }

  render() {
    const { activeAppointment, patient, updateActiveAppointment, emr } = this.props
    const diagnosisListDisplay = determineDiagnosisListDisplay(this.props)

    const noReloadButton = (
      emr === 'athena'
    )

    // HF-demo
    let suspectedDx
    if (patient.emr_patient_id === '179' && patient.organization_id === 4) {
      suspectedDx = <SuspectedDiagnosis patientId={patient.id} />
    }

    return (
      <div>
        <div>
          <div className="row" style={{ margin: '0' }}>
          {noReloadButton && (
            <h6 id="problem-list-header" className="col s6" style={{ padding: '0' }}>
              Problem List
              <a
                role="button"
                tabIndex="0"
                id="reload-problem-list"
                data-menu-tooltip="true"
                aria-label="Reload Problem List"
                onClick={() => updateActiveAppointment(activeAppointment.id)}
                onKeyDown={() => null}
              >
                <i className="material-icons center-icon" style={{ paddingLeft: '2.5rem' }}>
                  refresh
                </i>
              </a>
            </h6>
          )}
          </div>
          {diagnosisListDisplay}
          <br />
        </div>
        <div>
          {suspectedDx}
        </div>
      </div>
    )
  }
}

DashboardDiagnosisList.propTypes = {
  activeAppointment: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number,
    emr_patient_id: PropTypes.string,
    organization_id: PropTypes.number,
  }).isRequired,
  plannedDiagnosesList: PropTypes.arrayOf(PropTypes.shape({
    diagnosis: PropTypes.shape({
      active: PropTypes.bool,
    }),
  })).isRequired,
  updateActiveAppointment: PropTypes.func.isRequired,
}

export default DashboardDiagnosisList
