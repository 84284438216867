import PropTypes from 'prop-types'
import React from 'react'

import { Appointment, Diagnoses, Recommendations } from '../athenaTypes'
import LabWidget from './LabWidget'
import NextEncounterPlan from './NextEncounterPlan'
import OutstandingOrders from './OutstandingOrders'
import PreviousAppointment from './PreviousAppointment'

const propTypes = {
  activeAppointment: Appointment.isRequired,
  queuedPlannedDiagnosesList: Diagnoses.isRequired,
  queuedRecsList: Recommendations.isRequired,
  undoOrders: PropTypes.func.isRequired,
  patientSearchPage: PropTypes.bool.isRequired,
  sentRecsList: Recommendations.isRequired,
  todayOpenEncounter: PropTypes.bool.isRequired,
}

export default function MiniChart(props) {
  let prevApt
  let outstandingOrders
  let labWidget

  const {
    activeAppointment,
    patientSearchPage,
    queuedPlannedDiagnosesList,
    queuedRecsList,
    sentRecsList,
    todayOpenEncounter,
    undoOrders,
  } = props

  if (!patientSearchPage && activeAppointment.patient) {
    prevApt = <PreviousAppointment activeAppointment={activeAppointment} />
    outstandingOrders = (
      <OutstandingOrders documents={activeAppointment.patient.documents} />
    )
    labWidget = (
      <LabWidget observations={activeAppointment.patient.observations} />
    )
  }

  return (
    <div
      className="row"
      style={{
        height: '100vh',
        overflowY: 'scroll',
        marginRight: '10%',
        paddingTop: '0.5em',
      }}
    >
      <NextEncounterPlan
        queuedPlannedDiagnosesList={queuedPlannedDiagnosesList}
        queuedRecsList={queuedRecsList}
        sentRecsList={sentRecsList}
        undoOrders={undoOrders}
        todayOpenEncounter={todayOpenEncounter}
      />
      {prevApt}
      {labWidget}
      {outstandingOrders}
    </div>
  )
}

MiniChart.propTypes = propTypes
