import MenuItem from '@mui/material/MenuItem'
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Select } from '@mui/material'

const CareTeamSelector = (props) => {
  const muiTheme = createTheme({
    palette: {
      accent1Color: '#1684F9',
    },
  })

  const careTeams = _.map(props.careTeams, ct => (
    <MenuItem
      value={ct.id}
      label={ct.name}
      primaryText={ct.name}
    />
  ))

  const StyledSelect = styled(Select)({ 
    paddingLeft: '15px',
  })

  return (
    <ThemeProvider theme={muiTheme}>
      <div className="care-team-selector-field">
        <StyledSelect
          label="Select a care team"
          onChange={props.careTeamChangeHandler}
          fullWidth
          value={props.selectedCareTeam}
        >
          {careTeams}
        </StyledSelect>
      </div>
    </ThemeProvider>
  )
}

CareTeamSelector.propTypes = {
  careTeams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  careTeamChangeHandler: PropTypes.func,
  selectedCareTeam: PropTypes.string,
}

export default CareTeamSelector
