import React from 'react'
import { bool } from 'prop-types'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

const propTypes = {
  notProduction: bool.isRequired,
}

const Body = ({ data, setData, notProduction }) => {
  const handleClick = (id) => {
    if (confirm("Are you sure you want to delete this item?") === true) {
      $.ajax({
        url: `/root/value_sets/${id}`,
        type: 'DELETE',
      })
      .done(() => {
        const newData = data.filter((row) => id !== row.id)
        setData(newData)
        flash.success('Value set item deleted.', 50000)
      })
      .fail((error) => {
        console.error(error)
      })
    }
  }
  
  return (
    <>
    <TableBody>
      {data.map((row) => (
        <TableRow>
          <TableCell align="left">{row.value_set_name}</TableCell>
          <TableCell align="left">{row.description}</TableCell>
          <TableCell align="left">{row.code}</TableCell>
          <TableCell align="left">{row.code_system}</TableCell>
          {notProduction && (
            <TableCell align="left"><a href={`value_sets/${row.id}/edit`}>Edit</a><a name="deleteLink" id="deleteLink" onClick={(event) => {event.preventDefault(); handleClick(row.id) }}>Delete</a></TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  </>
  )
}

Body.propTypes = propTypes

export default Body
